// import * as React from 'react';
import { Box, Button, Typography, Divider, Grid, Dialog } from '@mui/material';

export default function ConfirmDialog(props) {
  const { onClose, open, title, msg, confirm, cancel, id } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <Grid container sx={{ p:1 }} >
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ m:1, textAlign:'center' }} >{ title }</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ m:1 }} />
        </Grid>
        <Grid item xs={12}>
        <Typography variant="h6"sx={{ m:1 }}>{ msg }</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ m:1 }} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ m:1, mr:2, display:'flex' }}>
            { !cancel && 
              <Box sx={{ flexGrow: 1 }}/>
            }
            <Button data-id={id} data-return={true} data-key={"confirm"} variant="contained" onClick={onClose} sx={{ ml:1 }}>{ confirm }</Button>
            <Box sx={{ flexGrow: 1 }}/>
            { cancel &&
              <Button variant="outlined" onClick={onClose} sx={{ ml:1 }}>{ cancel }</Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}

