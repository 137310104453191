import React from 'react';
import { Grid, Paper, Stack, Box, Button, Backdrop, 
  FormControl, InputLabel, Select, MenuItem, TextField,
  ToggleButtonGroup, ToggleButton, Autocomplete,
  CircularProgress, FormHelperText
  } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker,  } from '@mui/x-date-pickers';
import WeekPicker from './WeekPicker';
import locale_en from 'date-fns/locale/en-US';
import locale_ja from 'date-fns/locale/ja';
import ConfirmDialog from "../ConfirmDialog";
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import { systemStatus } from "../../atoms/systemStatus";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function EditReport() {
  const debug = false;
  if(locale_en && locale_ja) {
    locale_en.options.weekStartsOn = 0;
    locale_ja.options.weekStartsOn = 0;
  }

  const [temp, setTemp] = React.useState();
  const loadTemp = async() => {
    const tempFile = 'general.xlsx'; // B4 -> 1st header, B5 -> 1st item 
    fetchTemplate(tempFile);
  }

  const fetchTemplate = async(filename) => {
    const args = { auth:config[config.account]?.auth, filename:filename };
    const url = `${config[config.account]?.url}:${config[config.account]?.port}/api/template`;
    if(!config || !config[config.account]?.auth)return;
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(args) })
      .then(res => res.arrayBuffer()) // response by array buffer
      .then(data => {
      setTemp(data);
    });
  }

  const [config] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [status, setStatus] = useRecoilState(systemStatus);
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  const [dbconfig, setDbconfig] = React.useState(null);
  const [helpers, setHelpers] = React.useState({ rtype:'' });
  const [locale, setLocale] = React.useState();

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    setLocale(lang==='jp'?locale_ja:locale_en);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp?.value?tmp.value:'';
  }

  const getConfigs = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    setLoading(true);
    fetch(url, { method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    const filterList = [];
    if(getDBValue('approval_1')!=='1')filterList.push('sign1');
    if(getDBValue('approval_2')!=='1')filterList.push('sign2');
    if(getDBValue('approval_3')!=='1')filterList.push('sign3');
    if(getDBValue('free_entry_1')!=='1')filterList.push('fentry1');
    if(getDBValue('free_entry_2')!=='1')filterList.push('fentry2');
    if(getDBValue('free_entry_3')!=='1')filterList.push('fentry3');
    let newList = formatList.filter( v => (!filterList.includes(v.field)));
    setFormat(newList);
    loadCompanies();
    loadMachines();
    loadMagterials();
    loadProcesses();
    loadProducts();
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, dbconfig]);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    getConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

// ----------------------------- draggable related begin
  const [format, setFormat] = React.useState();
  const [container, setContainer] = React.useState();
  const [dragReady, setDragReady] = React.useState(false);

  React.useEffect(() => {
    if(format) {
      const show = status?.report?.find(v => v.id === 'show');
      const hide = status?.report?.find(v => v.id === 'hide');
      if(show && hide) {
        const show_array = JSON.parse(JSON.stringify(show.formats));
        const hide_array = JSON.parse(JSON.stringify(hide.formats));
        const show_conv = show_array.map(v => formatList.find(vv => {
          if(v.field === vv.field)return vv;
          else if(v.field.startsWith('process_') && vv.field.startsWith('process_'))return vv;
          else return null;
        }));
        const hide_conv = hide_array.map(v => formatList.find(vv => {
          if(v.field === vv.field)return vv;
          else if(v.field.startsWith('process_') && vv.field.startsWith('process_'))return vv;
          else return null;
        }));
        // console.log(show_conv);
        // console.log(hide_conv);
        setContainer([{id:'show', title:getLabel('report_output_target'), formats:show_conv},
          {id:'hide', title:getLabel('report_output_exception'), formats:hide_conv}]);
      } else {
        setContainer([{id:'show', title:getLabel('report_output_target'), formats:format},
          {id:'hide', title:getLabel('report_output_exception'), formats:[]}]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format]);

  React.useEffect(() => {
    if(container?.length > 0) {
      setDragReady(true);
    }
  }, [container]);

  const resetFormat = () => {
    const filterList = [];
    if(getDBValue('approval_1')!=='1')filterList.push('sign1');
    if(getDBValue('approval_2')!=='1')filterList.push('sign2');
    if(getDBValue('approval_3')!=='1')filterList.push('sign3');
    if(getDBValue('free_entry_1')!=='1')filterList.push('fentry1');
    if(getDBValue('free_entry_2')!=='1')filterList.push('fentry2');
    if(getDBValue('free_entry_3')!=='1')filterList.push('fentry3');
    let newList = formatList.filter( v => (!filterList.includes(v.field)));

    const preset = [{id:'show', title:getLabel('report_output_target'), formats:newList},
    // const preset = [{id:'show', title:getLabel('report_output_target'), formats:format},
    {id:'hide', title:getLabel('report_output_exception'), formats:[]}];
    setContainer(preset);
    const data = JSON.parse(JSON.stringify(preset)); //need a deep copy
    setStatus(prev => ({...prev, report:data }));
  }

  const onDragEnd = (result) => {
    const src = result.source; 
    const dst = result.destination;
    if(!dst)return;
    if(dst.index === src.index && dst.droppableId === src.droppableId)return;

    if(dst.droppableId === src.droppableId) {
      const scnt = container.find(v => v.id === src.droppableId);
      const sitm = scnt.formats;
      const [removed] = sitm.splice(src.index, 1);
      sitm.splice(dst.index, 0, removed);
    } else {
      const scnt = container.find(v => v.id === src.droppableId);
      const dcnt = container.find(v => v.id === dst.droppableId);
      const sitm = scnt.formats;
      const ditm = dcnt.formats;
      const [removed] = sitm.splice(src.index, 1);
      ditm.splice(dst.index, 0, removed);
    }

    const data = JSON.parse(JSON.stringify(container)); //need a deep copy
    setStatus(prev => ({...prev, report:data }));
  }
// ----------------------------- draggable related end
  const [conds, setConds] = React.useState([]);

  const prepareConditions = () => {
    setConds([]);
    conds.push({id:selectedCompany?.id, field:'client_id'});
    conds.push({id:selectedMachine?.id, field:'machine_id'});
    conds.push({id:selectedMaterial?.id, field:'material_id'});
    conds.push({id:selectedProcess?.id, field:'process_id'});
    conds.push({id:selectedSequence?.id, field:'sequence_id'});
    conds.push({id:selectedProduct?.id, field:'product_id'});
    conds.push({id:selectedRegUser?.userid, field:'userreg_id'});
    conds.push({id:selectedUpdUser?.userid, field:'userupd_id'});
    conds.push({id:selectedAp1User?.userid, field:'approval1'});
    conds.push({id:selectedAp2User?.userid, field:'approval2'});
    conds.push({id:selectedAp3User?.userid, field:'approval3'});
    conds.push({txt:fentry1, field:'fentry1'});
    conds.push({txt:fentry2, field:'fentry2'});
    conds.push({txt:fentry3, field:'fentry3'});
  }

  const reportSummary = async() => {
    setLoading(true);
    if(inputValidation(rtype)) {
      await loadTemp();
      await requestReport(rfrom, rto);
      // if(summary)createReport();
    }
    setLoading(false);
  }

  const createReport = async() => {
    if(summary?.length===0) {
      openNoSummary();
      return;
    }
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(temp);
    const filename = 'report_' + getTimeStamp(true) + '.xlsx';

    // -------------------- workbook properties --------------------
    workbook.creator = 'DBord';
    workbook.lastModifiedBy = 'DBord';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.calcProperties.fullCalcOnLoad = true;
    workbook.useStyles = true;
    const ws = workbook.getWorksheet(1);
    // ws.views = [{state:'frozen', ySplit:4, xSplit:2}]
    // ws.views = [{state:'frozen', ySplit:4}]

    // -------------------- sheet preparation --------------------
    let report_type = '';
    switch(rtype){
      case 1:
        report_type = getLabel('report_type_daily');
        break;
      case 2:
        report_type = getLabel('report_type_weekly');
        break;
      case 3:
        report_type = getLabel('report_type_monthly');
        break;
      case 4:
        report_type = getLabel('report_type_quarterly');
        break;
      case 5:
        report_type = getLabel('report_type_annually');
        break;
      case 6:
        report_type = getLabel('report_type_range')?getLabel('report_type_range'):'Custom Range Report';
        break;
      default:
    }
    ws.getCell(2, 2).value = `${report_type} (${getLabel('report_type')}: ${getDateStamp(rfrom)} - ${getDateStamp(rto)})`;

    const htmp = ws.findCell(4,2).style;
    const rtmp = ws.findCell(5,2).style;
    const colIndex = {
      target : container[0].formats.findIndex( v => v.field === 'target'),
      qualified : container[0].formats.findIndex( v => v.field === 'qualified'),
      rework : container[0].formats.findIndex( v => v.field === 'rework'),
      unqualified : container[0].formats.findIndex( v => v.field === 'unqualified'),
      efficiency : container[0].formats.findIndex( v => v.field === 'efficiency'),
      time: container[0].formats.findIndex( v => v.field === 'uptimenet')
    };
    // ----- mapping Data to Table
    container[0].formats.forEach((v, i) => {
      ws.getCell(4,3+i).style = Object.assign({}, htmp); // copy 1
      ws.getCell(4,3+i).value = v.title;
      [...Array(summary.length)].forEach((_vv, ii) => {
        ws.getCell(ii+5,2).style = {...rtmp}; // copy 2
        ws.getCell(ii+5,2).alignment = { indent:1, vertical:'middle', horizontal:'right'};
        ws.getCell(ii+5,2).value = ii+1;
        ws.getCell(ii+5,3+i).style = JSON.parse(JSON.stringify(rtmp)); // copy 3
        if(v.align==='right') {
          ws.getCell(ii+5,3+i).alignment = { indent:1, vertical:'middle', horizontal:'right' };
        } else { 
          ws.getCell(ii+5,3+i).alignment = { vertical:'middle' }; 
        }
        if(v.numFmt) {
          ws.getCell(ii+5,3+i).numFmt = v.numFmt;
        }
      });
    });

    // ----- Display Total Data
    const totalRow = ws.lastRow._number + 1;
    const totalStyleNumFmt = {
      alignment: { indent:1, vertical:'middle', horizontal:'right' },
      border: { bottom: {style:'double'} },
      fill: {bgColor: {indexed:64}, fgColor: {theme:0, tint:-0.1499984740745262}, pattern:'solid', type: 'pattern'},
      font: {color:{theme:1}, family:2, name:'Calibri', schema:'minor', size:20},
      numFmt: '#,##0;;-',
    }
    const totalPercentageNumFmt = {
      alignment: { indent:1, vertical:'middle', horizontal:'right' },
      border: { bottom: {style:'double'} },
      fill: {bgColor: {indexed:64}, fgColor: {theme:0, tint:-0.1499984740745262}, pattern:'solid', type: 'pattern'},
      font: {color:{theme:1}, family:2, name:'Calibri', schema:'minor', size:20},
      numFmt: '#,##0.00"%";;-'
    }
    if(colIndex.target>0) {
      let sumTrgCell = ws.getCell(totalRow,3+colIndex.target);
      sumTrgCell.style = totalStyleNumFmt;
      sumTrgCell.value = {formula: `SUM(${ws.getCell(5,sumTrgCell.col)._address}:${ws.getCell(totalRow-1,sumTrgCell.col)._address})`};
    }
    if(colIndex.qualified>0) {
      let sumQlfCell = ws.getCell(totalRow,3+colIndex.qualified);
      sumQlfCell.style = totalStyleNumFmt;
      sumQlfCell.value = {formula: `SUM(${ws.getCell(5,sumQlfCell.col)._address}:${ws.getCell(totalRow-1,sumQlfCell.col)._address})`};
    }
    if(colIndex.rework>0) {
      let sumRwkCell = ws.getCell(totalRow,3+colIndex.rework);
      sumRwkCell.style = totalStyleNumFmt;
      sumRwkCell.value = {formula: `SUM(${ws.getCell(5,sumRwkCell.col)._address}:${ws.getCell(totalRow-1,sumRwkCell.col)._address})`};
    }
    if(colIndex.unqualified>0) {
      let sumUnQlfCell = ws.getCell(totalRow,3+colIndex.unqualified);
      sumUnQlfCell.style = totalStyleNumFmt;
      sumUnQlfCell.value = {formula: `SUM(${ws.getCell(5,sumUnQlfCell.col)._address}:${ws.getCell(totalRow-1,sumUnQlfCell.col)._address})`};
      ws.getCell(totalRow+1,2+colIndex.unqualified).value = `Total Unqualified %`;
      ws.getCell(totalRow+1,2+colIndex.unqualified).alignment = { indent:1, horizontal:'right' };
      ws.getCell(totalRow+1,2+colIndex.unqualified).font = {color:{theme:1}, family:2, name:'Calibri', schema:'minor', size:20};
      ws.getCell(totalRow+1,3+colIndex.unqualified).value = {formula: `(${sumUnQlfCell._address}/(${sumUnQlfCell._address}+${ws.getCell(totalRow,3+colIndex.qualified)._address}))*100`};
      ws.getCell(totalRow+1,3+colIndex.unqualified).numFmt = '#,##0.00"%";;-';
      ws.getCell(totalRow+1,3+colIndex.unqualified).font = {color:{theme:1}, family:2, name:'Calibri', schema:'minor', size:20};
      ws.getCell(totalRow+1,3+colIndex.unqualified).border = { bottom: {style:'double'} };
    }
    if(colIndex.efficiency>0) {
      let calcEffCell = ws.getCell(totalRow,3+colIndex.efficiency);
      calcEffCell.style = totalPercentageNumFmt;
      calcEffCell.value = {formula: `(${ws.getCell(totalRow,3+colIndex.qualified)._address}/${ws.getCell(totalRow,3+colIndex.target)._address})*100`};
    }
    if(colIndex.time>0) {
      let sumTimeCell = ws.getCell(totalRow,3+colIndex.time);
      sumTimeCell.style = totalStyleNumFmt;
      sumTimeCell.value = {formula: `SUM(${ws.getCell(5,sumTimeCell.col)._address}:${ws.getCell(totalRow-1,sumTimeCell.col)._address})`};
    }
    
    // -------------------- plot data --------------------
    summary.forEach((s, si) => {
      Object.keys(s).forEach((k, ki) => {
        ws.getCell(5+si, 3+ki).value = s[k];
      });
    });

    // -------------------- adjust columns width and rows height --------------------
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = '12px';

    const stcol = 2;
    const strow = 4;
    const mxcol = format.length + 1;
    const mxrow = summary.length + 3;

    var mxheight = 0;
    for(var c=stcol; c<mxcol+stcol; c++) {
      var mxlen = 0;
      for(var r=strow; r<mxrow+strow; r++) {
        const metrics = context.measureText(ws.getCell(r, c).value);
        const width = metrics.width * 0.5;
        const height = (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent ) * 4;
        if(width>mxlen)mxlen=width;
        if(height>mxheight)mxheight=height;
      }
      ws.getColumn(c).width = mxlen;
    }

    for(var r1=strow; r1<mxrow+strow; r1++) {
      ws.getRow(r1).height = mxheight;
    }

    // -------------------- output data --------------------
    const uint8Array = await workbook.xlsx.writeBuffer(); // workbook.csv.writeBuffer()
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const download = require('downloadjs');
    download(blob, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); //application/vnd.ms-excel
  }

  const getTimeStamp = (seconds=false) => {
    let newDate = new Date()
    let yar = newDate.getFullYear();
    let mth = newDate.getMonth()+1;
    let dte = newDate.getDate();
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${yar}${mth<10?`0${mth}`:`${mth}`}${dte<10?`0${dte}`:`${dte}`}${hrs<10?`0${hrs}`:`${hrs}`}${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const getDateStamp = (sdate) => {
    let yar = sdate.getFullYear();
    let mth = sdate.getMonth()+1;
    let dte = sdate.getDate();
    const tmp = `${dte<10?`0${dte}`:`${dte}`}/${mth<10?`0${mth}`:`${mth}`}/${yar}`;
    return tmp;
  }

  const inputValidation = (data) => {
    let validated = true;
    if(!isNumber(data)) {
      setHelpers(helpers => ({...helpers, rtype:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, rtype:''}));
    return validated;
  }

  function isNumber(numVal){
    var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
    return pattern.test(numVal);
  } 

  const formatList = [
    { numFmt:'', align:'', title:getLabel('company_name'), field:'client_name' },
    { numFmt:'', align:'', title:getLabel('company_dept'), field:'client_dept' },
    { numFmt:'', align:'', title:getLabel('machine_name'), field:'machine_name' },
    { numFmt:'', align:'', title:getLabel('machine_type'), field:'machine_type' },
    { numFmt:'', align:'', title:getLabel('specification_material_name'), field:'material_name' },
    { numFmt:'', align:'', title:getLabel('specification_material_model'), field:'material_model' },
    { numFmt:'', align:'', title:getLabel('process_name'), field:(config[config.account].language==='en'?'process_en':config[config.account].language==='th'?'process_th':'process_jp') },
    { numFmt:'', align:'', title:getLabel('production_sequence'), field:'seqno' },
    { numFmt:'', align:'', title:getLabel('sequence_name'), field:'seqname' },
    { numFmt:'', align:'', title:getLabel('product_name'), field:'product_name' },
    { numFmt:'', align:'', title:getLabel('product_model'), field:'product_model' },
    { numFmt:'', align:'', title:getLabel('product_internal'), field:'product_internal' },
    { numFmt:'', align:'', title:getLabel('entry_process_start'), field:'timestf' },
    { numFmt:'', align:'', title:getLabel('entry_process_end'), field:'timeedf' },
    { numFmt:'#,##0;;-', align:'right', title:getLabel('entry_section_period'), field:'uptimenet' },
    { numFmt:'', align:'', title:getLabel('common_reg_user'), field:'userreg_name' },
    { numFmt:'', align:'', title:getLabel('common_upd_user'), field:'userupd_name' },
    { numFmt:'#,##0;;-', align:'right', title:getLabel('production_order_target'), field:'target' },
    { numFmt:'#,##0;;-', align:'right', title:getLabel('entry_qualified'), field:'qualified' },
    { numFmt:'#,##0;;-', align:'right', title:getLabel('entry_rework'), field:'rework' },
    { numFmt:'#,##0;;-', align:'right', title:getLabel('entry_unqualified'), field:'unqualified' },
    { numFmt:'#,##0.00"%";;-', align:'right', title:getLabel('entry_efficiency'), field:'efficiency' },
    { numFmt:'', align:'', title:(config[config.account].language==='en'?getDBValue('approval_1_en'):config[config.account].language==='th'?getDBValue('approval_1_th'):getDBValue('approval_1_jp')), field:'sign1' },
    { numFmt:'', align:'', title:(config[config.account].language==='en'?getDBValue('approval_2_en'):config[config.account].language==='th'?getDBValue('approval_2_th'):getDBValue('approval_2_jp')), field:'sign2' },
    { numFmt:'', align:'', title:(config[config.account].language==='en'?getDBValue('approval_3_en'):config[config.account].language==='th'?getDBValue('approval_3_th'):getDBValue('approval_3_jp')), field:'sign3' },
    { numFmt:'', align:'', title:(config[config.account].language==='en'?getDBValue('free_entry_1_en'):config[config.account].language==='th'?getDBValue('free_entry_1_th'):getDBValue('free_entry_1_jp')), field:'fentry1' },
    { numFmt:'', align:'', title:(config[config.account].language==='en'?getDBValue('free_entry_2_en'):config[config.account].language==='th'?getDBValue('free_entry_2_th'):getDBValue('free_entry_2_jp')), field:'fentry2' },
    { numFmt:'', align:'', title:(config[config.account].language==='en'?getDBValue('free_entry_3_en'):config[config.account].language==='th'?getDBValue('free_entry_3_th'):getDBValue('free_entry_3_jp')), field:'fentry3' },
  ];

  // ------------------ variables for report period selection ------------------
  const [rtype, setRtype] = React.useState('');

  const rtypeChange = (event) => {
    const val = event.target.value
    setRtype(val);
    switch(val) {
      case 1:
        calcRday(rday);
        break;
      case 2:
        calcRweek(rweek);
        break;
      case 3:
        calcRmonth(rmonth);
        break;
      case 4:
        calcRqmonth(rquarter);
        break;
      case 5:
        calcRyear(ryear);
        break;
      case 6:
        calcRangeFrom(rday);
        calcRangeTo(tday);
        break;
        default:
    }
    inputValidation(val);
  };

  const [rday, setRday] = React.useState(new Date());
  const [tday, setTday] = React.useState(new Date());

  const rangeFromChage = (event) => {
    setRday(event);
    calcRangeFrom(event);
  }
  const calcRangeFrom = (date) => {
    const dt = new Date(date);
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
  }
  const rangeToChage = (event) => {
    setTday(event);
    calcRangeTo(event);
  }
  const calcRangeTo = (date) => {
    const dt = new Date(date);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const rdayChange = (event) => {
    setRday(event);
    calcRday(event);
  };
  const calcRday = (date) => {
    const dt = new Date(date);
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const [rweek, setRweek] = React.useState(new Date());
  const rweekChange = (event) => {
    setRweek(event);
    calcRweek(event);
  };
  const calcRweek = (date) => {
    const mon = getMonday(date);
    const sun = getSunday(date);
    const from = new Date(`${mon.getFullYear()}/${mon.getMonth()+1}/${mon.getDate()} 00:00:00`);
    setRfrom(from);
    const to = new Date(`${sun.getFullYear()}/${sun.getMonth()+1}/${sun.getDate()} 23:59:59`);
    setRto(to);
  }

  const [rmonth, setRmonth] = React.useState(new Date());
  const rmonthChange = (event) => {
    setRmonth(event);
    calcRmonth(event);
  };
  const calcRmonth = (date) => {
    const dt = new Date(date);
    dt.setDate(1); // the first day of the month
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    dt.setDate(1); // the final day of the month
    dt.setMonth(dt.getMonth() + 1);
    dt.setDate(0);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const defaultDate = () => {
    const dt = new Date();
    // console.log(dt);
    return new Date(`${dt.getFullYear()}/01/01 00:00:00`);
  }
  const [rqmonth, setRqmonth] = React.useState(defaultDate);
  const rqmonthChange = (event) => {
    setRqmonth(event);
  };

  const [rquarter, setRquarter] = React.useState('0');
  const rquarterChange = (event, value) => {
    if(value !== null) {
      setRquarter(value);
      calcRqmonth(value);
    }
  };
  const calcRqmonth = (value) => {
    const dt = new Date(rqmonth);
    const mul = value * 3;
    dt.setMonth(dt.getMonth() + mul);
    dt.setDate(1); // the first day of the month
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    dt.setMonth(dt.getMonth() + 3);
    dt.setDate(0); // the final day of the 3rd month * 
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const [ryear, setRyear] = React.useState(new Date());
  const ryearChange = (event) => {
    setRyear(event);
    calcRyear(event);
  };
  const calcRyear = (value) => {
    const date = new Date(value);
    const from = new Date(`${date.getFullYear()}/01/01 00:00:00`);
    const to = new Date(`${date.getFullYear()}/12/31 23:59:59`);
    setRfrom(from);
    setRto(to);
  }

  const [rfrom, setRfrom] = React.useState();
  const [rto, setRto] = React.useState();
  // ------------------ report period selection variables ------------------

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1);
    return new Date(d.setDate(diff));
  }

  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? 0:7);
    return new Date(d.setDate(diff));
  }
  
  // ------------------ date calc supporting functions end ------------------
  const [summary, setSummary] = React.useState();

  const requestReport = async(from, to) => {
    if(!from && !to)return;
    const f_yr = from.getFullYear();
    const f_mn = from.getMonth()+1;
    const f_dt = from.getDate();
    const date_from = `${f_yr}-${f_mn<10?`0${f_mn}`:`${f_mn}`}-${f_dt<10?`0${f_dt}`:`${f_dt}`} 00:00:00`;
    const t_yr = to.getFullYear();
    const t_mn = to.getMonth()+1;
    const t_dt = to.getDate();
    const date_to = `${t_yr}-${t_mn<10?`0${t_mn}`:`${t_mn}`}-${t_dt<10?`0${t_dt}`:`${t_dt}`} 23:59:59`;
    prepareConditions();

    const url = `${config[config.account].url}:${config[config.account].port}/api/report`;
    setLoading(true);
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ from:date_from, to:date_to, conds:conds, header:container[0].formats, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      if(data.length !== undefined)setSummary(data);
      else setSummary();
      setLoading(false);
    });
  }

  React.useEffect(() => {
    if(summary)createReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[summary]);
// -------------------- Customer info  
  const [companies, setCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  React.useEffect(() => {
    if(companies.length > 0)setSelectedCompany(companies[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const loadCompanies = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/companies`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`, dept:'', field:'client_name' });
      setCompanies(data);
      setLoading(false);
    });
  }
// -------------------- Machine info  
  const [machines, setMachines] = React.useState([]);
  const [selectedMachine, setSelectedMachine] = React.useState(null);

  React.useEffect(() => {
    if(machines.length > 0)setSelectedMachine(machines[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines]);

  const loadMachines = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/machines`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`});
      setMachines(data);
      setLoading(false);
    });
  }
// -------------------- Material info  
  const [materials, setMaterials] = React.useState([]);
  const [selectedMaterial, setSelectedMaterial] = React.useState(null);

  React.useEffect(() => {
    if(materials.length > 0)setSelectedMaterial(materials[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials]);

  const loadMagterials = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/materials`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`});
      setMaterials(data);
      setLoading(false);
    });
  }
// -------------------- Process info  
  const [processes, setProcesses] = React.useState([]);
  const [selectedProcess, setSelectedProcess] = React.useState(null);

  React.useEffect(() => {
    if(processes.length > 0) {
      setSelectedProcess(processes[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processes]);

  const loadProcesses = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/processes`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`, name_en:`[ ${getLabel('common_all')} ]`, name_th:`[ ${getLabel('common_all')} ]`});
      setProcesses(data);
      setLoading(false);
    });
  }
// -------------------- Sequence info  
  const [sequences, setSequences] = React.useState([]);
  const [selectedSequence, setSelectedSequence] = React.useState(null);

  React.useEffect(() => {
    if(sequences.length > 0)setSelectedSequence(sequences[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequences]);

  React.useEffect(() => {
    if(selectedProcess && selectedProcess?.id !== 0) {
      setSelectedSequence(sequences[0]);
      loadSequences();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProcess]);

  const loadSequences = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/sequenceprocess`;
    fetch(url, { method: 'POST' ,headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ process:selectedProcess.id, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`});
      setSequences(data);
      setLoading(false);
    });
  }
// -------------------- Product info  
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  React.useEffect(() => {
    if(products.length > 0) {
      setSelectedProduct(products[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const loadProducts = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/products`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, model:`[ ${getLabel('common_all')} ]`, name:`[ ${getLabel('common_all')} ]`});
      setProducts(data);
      setLoading(false);
    });
  }
// -------------------- Register, Update and approval user info  
  const [regUsers, setRegUsers] = React.useState([]);
  const [selectedRegUser, setSelectedRegUser] = React.useState(null);
  const [updUsers, setUpdUsers] = React.useState([]);
  const [selectedUpdUser, setSelectedUpdUser] = React.useState(null);
  const [ap1Users, setAp1Users] = React.useState([]);
  const [selectedAp1User, setSelectedAp1User] = React.useState(null);
  const [ap2Users, setAp2Users] = React.useState([]);
  const [selectedAp2User, setSelectedAp2User] = React.useState(null);
  const [ap3Users, setAp3Users] = React.useState([]);
  const [selectedAp3User, setSelectedAp3User] = React.useState(null);

  React.useEffect(() => {
    if(regUsers.length > 0 && updUsers.length > 0) {
      setSelectedRegUser(regUsers[0]);
      setSelectedUpdUser(updUsers[0]);
      setSelectedAp1User(ap1Users[0]);
      setSelectedAp2User(ap2Users[0]);
      setSelectedAp3User(ap3Users[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regUsers, updUsers]);

  const loadUsers = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/users`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({userid:0, username:`[ ${getLabel('common_all')} ]`});
      setRegUsers(data);
      setUpdUsers(data);
      setAp1Users(data);
      setAp2Users(data);
      setAp3Users(data);
      setLoading(false);
    });
  }
  // -------------------- Free entries info  
  const [fentry1, setFentry1] = React.useState('');
  const [fentry2, setFentry2] = React.useState('');
  const [fentry3, setFentry3] = React.useState('');

  const [noSummary, setNoSummary] = React.useState(false);
  const openNoSummary = () => setNoSummary(true);
  const closeNoSummary = () => setNoSummary(false);
  
  // -------------------- Feed view --------------------  
  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={noSummary}
        onClose={closeNoSummary}
        title={ getLabel('report_dialog_download') }
        msg={ getLabel('report_dialog_nodata') }
        confirm={ getLabel('common_confirm') }
      />
      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('navigation_menu_reporting')}&nbsp;{ false && <Button size="small" variant="outlined" onClick={test}>DEV</Button>}</h4>
      </Box>
      <Paper sx={{ mx:2, my:0, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Stack>
              <FormControl fullWidth size="small" sx={{ mt:1, mb:0.5 }}>
                <InputLabel id="report-type">{ getLabel('report_type') }</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rtype}
                  label="Report Type"
                  onChange={rtypeChange}
                  error={helpers.rtype.length>0?true:false}
                >
                  <MenuItem value={1}>{ getLabel('report_daily') }</MenuItem>
                  <MenuItem value={2}>{ getLabel('report_weekly') }</MenuItem>
                  <MenuItem value={3}>{ getLabel('report_monthly') }</MenuItem>
                  <MenuItem value={4}>{ getLabel('report_quarterly') }</MenuItem>
                  <MenuItem value={5}>{ getLabel('report_Annually') }</MenuItem>
                  <MenuItem value={6}>{ getLabel('report_Range')?getLabel('report_Range'):'Custom Range Report' }</MenuItem>
                </Select>
                <FormHelperText error={helpers.rtype.length>0?true:false}>{helpers.rtype}</FormHelperText>
              </FormControl>
{/* ---------- Daily picker ---------- */}
              { (rtype && rtype === 1) || debug?
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <MobileDatePicker
                  sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} }}
                  label={ getLabel('report_target_day') }
                  inputFormat="dd/MM/yyyy"
                  value={rday}
                  onChange={rdayChange}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
                  okText={getLabel('common_ok')}
                  cancelText={getLabel('common_cancel')}
                />
              </LocalizationProvider>
              :''}
{/* ---------- Weekly picker ---------- */}
              { (rtype && rtype === 2) || debug?
              <WeekPicker
                label={ getLabel('report_target_week') }
                inputFormat={ `'${getLabel('report_week_of')}' MMM d` }
                value={rweek}
                onChange={rweekChange}
                okText={getLabel('common_ok')}
                cancelText={getLabel('common_cancel')}
            />
              :''}
{/* ---------- Monthly picker ---------- */}
              { (rtype && rtype === 3) || debug?
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <MobileDatePicker
                  sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} }}
                  views={['year', 'month']}
                  label={ getLabel('report_target_month') }
                  value={rmonth}
                  onChange={rmonthChange}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
                  okText={getLabel('common_ok')}
                  cancelText={getLabel('common_cancel')}
                />
              </LocalizationProvider>
              :''}
{/* ---------- Quarterly picker ---------- */}
              { (rtype && rtype === 4) || debug?
              <Stack>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <MobileDatePicker
                  sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} }}
                  views={['year', 'month']}
                  label={ getLabel('report_target_quarter_begin') }
                  value={rqmonth}
                  onChange={rqmonthChange}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
                  okText={getLabel('common_ok')}
                  cancelText={getLabel('common_cancel')}
                />
              </LocalizationProvider>
              <ToggleButtonGroup
                 sx={{ my:1 }}
                color="primary"
                value={rquarter}
                exclusive
                onChange={rquarterChange}
                aria-label="Quarter"
                size="medium"
              >
                <ToggleButton value="0">{ getLabel('report_target_quarter_1') }</ToggleButton>
                <ToggleButton value="1">{ getLabel('report_target_quarter_2') }</ToggleButton>
                <ToggleButton value="2">{ getLabel('report_target_quarter_3') }</ToggleButton>
                <ToggleButton value="3">{ getLabel('report_target_quarter_4') }</ToggleButton>
              </ToggleButtonGroup>
              </Stack>
              :''}
{/* ---------- Anually picker ---------- */}
              { (rtype && rtype === 5) || debug?
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <MobileDatePicker
                  sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} }}
                  views={['year']}
                  label={ getLabel('report_target_year') }
                  value={ryear}
                  onChange={ryearChange}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
                  okText={getLabel('common_ok')}
                  cancelText={getLabel('common_cancel')}
                />
              </LocalizationProvider>
              :''}
{/* ---------- Range picker ---------- */}              
              { (rtype && rtype === 6) || debug?
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                <MobileDatePicker
                  sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} }}
                  label={ getLabel('report_target_from') }
                  inputFormat="dd/MM/yyyy"
                  value={rday}
                  onChange={rangeFromChage}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
                  okText={getLabel('common_ok')}
                  cancelText={getLabel('common_cancel')}
                />
                <MobileDatePicker
                  sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} }}
                  label={ getLabel('report_target_to') }
                  inputFormat="dd/MM/yyyy"
                  value={tday}
                  onChange={rangeToChage}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
                  okText={getLabel('common_ok')}
                  cancelText={getLabel('common_cancel')}
                />
              </LocalizationProvider>
              :''}
{/* ---------- Customer picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedCompany || null }
                id="combo-company"
                getOptionLabel={(option) => option.name + (option.dept?` - ${option.dept}`:'') }
                options={companies}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('company_name')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedCompany(companies.find(v => v.id === value.id));
                  }
                }}
              />
              ) : null}
{/* ---------- Machine picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedMachine || null }
                id="combo-machine"
                getOptionLabel={(option) => option.name }
                options={machines}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('machine_name')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedMachine(machines.find(v => v.id === value.id));
                  }
                }}
              />
              ) : null}
{/* ---------- Material picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedMaterial || null }
                id="combo-material"
                getOptionLabel={(option) => option.name }
                options={materials}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('specification_material_name')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedMaterial(materials.find(v => v.id === value.id));
                  }
                }}
              />
              ) : null}
{/* ---------- Process picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedProcess || null }
                id="combo-process"
                getOptionLabel={(option) => config[config.account].language === 'en'?option.name_en:config[config.account].language === 'th'?option.name_th:option.name }
                options={processes}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('process_name')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedProcess(processes.find(v => v.id === value.id));
                  }
                }}
              />
              ) : null}
{/* ---------- Sequence picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedSequence || null }
                id="combo-sequence"
                getOptionLabel={(option) => option.name }
                options={sequences}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('sequence_name')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedSequence(sequences.find(v => v.id === value.id));
                  }
                }}
              />
              ) : null}
{/* ---------- Product picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedProduct || null }
                id="combo-product"
                getOptionLabel={(option) => `${option.model} - ${option.name}` }
                options={products}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('product_name')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedProduct(products.find(v => v.id === value.id));
                  }
                }}
              />
              ) : null}
{/* ---------- Register user picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedRegUser || null }
                id="combo-reguser"
                getOptionLabel={(option) => `${option.username}` }
                options={regUsers}
                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('common_reg_user')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedRegUser(regUsers.find(v => v.userid === value.userid));
                  }
                }}
              />
              ) : null}
{/* ---------- Update user picker ---------- */}
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedUpdUser || null }
                id="combo-upduser"
                getOptionLabel={(option) => `${option.username}` }
                options={updUsers}
                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('common_upd_user')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedUpdUser(updUsers.find(v => v.userid === value.userid));
                  }
                }}
              />
              ) : null}
            </Stack>
          </Grid>

          <Grid item xs={12} md={3} sx={{ p:1 }}>
            <Stack>
{/* ---------- Approval1 user picker ---------- */}
              { getDBValue('approval_1')==='1' ? (
              <Autocomplete
                disablePortal
                value = { selectedAp1User || null }
                id="combo-ap1user"
                getOptionLabel={(option) => `${option.username}` }
                options={ap1Users}
                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                renderInput={(params) => <TextField {...params} size="small"
                label={config[config.account].language==='en'?getDBValue('approval_1_en'):config[config.account].language==='th'?getDBValue('approval_1_th'):getDBValue('approval_1_jp')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedAp1User(ap1Users.find(v => v.userid === value.userid));
                  }
                }}
              />
              ) : null}
{/* ---------- Approval2 user picker ---------- */}
              { getDBValue('approval_2')==='1' ? (
              <Autocomplete
                disablePortal
                value = { selectedAp2User || null }
                id="combo-ap2user"
                getOptionLabel={(option) => `${option.username}` }
                options={ap2Users}
                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                renderInput={(params) => <TextField {...params} size="small"
                label={config[config.account].language==='en'?getDBValue('approval_2_en'):config[config.account].language==='th'?getDBValue('approval_2_th'):getDBValue('approval_2_jp')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedAp2User(ap2Users.find(v => v.userid === value.userid));
                  }
                }}
              />
              ) : null}
{/* ---------- Approval3 user picker ---------- */}
              { getDBValue('approval_3')==='1' ? (
              <Autocomplete
                disablePortal
                value = { selectedAp3User || null }
                id="combo-ap3user"
                getOptionLabel={(option) => `${option.username}` }
                options={ap3Users}
                isOptionEqualToValue={(option, value) => option.userid === value.userid}
                renderInput={(params) => <TextField {...params} size="small"
                label={config[config.account].language==='en'?getDBValue('approval_3_en'):config[config.account].language==='th'?getDBValue('approval_3_th'):getDBValue('approval_3_jp')} sx={{ mb:1 }} />}
                onChange={(_event, value) => {
                  if(value) {
                    setSelectedAp3User(ap3Users.find(v => v.userid === value.userid));
                  }
                }}
              />
              ) : null}
{/* ---------- Free entry1 search text ---------- */}
              { getDBValue('free_entry_1')==='1' ? (
              <TextField sx={{ mb:1 }} size="small" 
              label={config[config.account].language==='en'?getDBValue('free_entry_1_en'):config[config.account].language==='th'?getDBValue('free_entry_1_th'):getDBValue('free_entry_1_jp')}
              value={fentry1}
              onChange={(e) => { setFentry1(e.target.value); }}
              />
              ) : null}
{/* ---------- Free entry2 search text ---------- */}
              { getDBValue('free_entry_2')==='1' ? (
              <TextField sx={{ mb:1 }} size="small"
              label={config[config.account].language==='en'?getDBValue('free_entry_2_en'):config[config.account].language==='th'?getDBValue('free_entry_2_th'):getDBValue('free_entry_2_jp')}
              value={fentry2}
              onChange={(e) => { setFentry2(e.target.value); }}
              />
              ) : null}
{/* ---------- Free entry3 search text ---------- */}
              { getDBValue('free_entry_3')==='1' ? (
              <TextField sx={{ mb:1 }} size="small"
              label={config[config.account].language==='en'?getDBValue('free_entry_3_en'):config[config.account].language==='th'?getDBValue('free_entry_3_th'):getDBValue('free_entry_3_jp')}
              value={fentry3}
              onChange={(e) => { setFentry3(e.target.value); }}
              />
              ) : null}
{/* ---------- Reset for output conditions ---------- */}
              <Button sx={{ my:1 }} variant="outlined" color="primary" onClick={resetFormat} disableElevation>{ getLabel('report_reset_items') }</Button>
{/* ---------- Report output button ---------- */}
              <Button sx={{ my:1 }} variant="contained" color="primary" onClick={reportSummary} disableElevation>{ getLabel('report_output') }</Button>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>

            <Grid container sx={ { mt:0.5 }}>
              { dragReady &&
              <DragDropContext onDragEnd={onDragEnd}>
                { container.map((vv, ii) => (
                <Droppable droppableId={vv.id} key={vv.id} index={ii}>
                  {(provided, snapshot) => (
                  <Grid item xs={12} md={6}>
                    <Box sx={{ m:0.5, p:1, backgroundColor:'primary.main',
                    color:'primary.contrastText',borderRadius:"5px" } }>{ vv.title }</Box>
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ margin:"4px",padding:"4px",
                      border:"1px solid #CCCCCC",
                      minHeight:"100px",
                      opacity:1, borderRadius:"5px",
                      backgroundColor:snapshot.isDraggingOver?'#E0E0FF':'' }}
                    >
                      { vv.formats && vv.formats.map((v, i) => (
                      <Draggable draggableId={v.field} key={v.field} index={i}>
                        {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box sx={{ p:0.5, backgroundColor:snapshot.isDragging?'primary.dark':'',
                          color:snapshot.isDragging?'primary.contrastText':'' }}>{ v.title }</Box>
                        </div>
                        )}
                      </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  </Grid>
                  )}
                </Droppable>
                ))}
              </DragDropContext>
              }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}