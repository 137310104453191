import React from 'react';
import { Box, Button, Backdrop, CircularProgress, AppBar, Toolbar, Menu, MenuItem, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import { systemStatus } from "../../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';

import TranslateIcon from '@mui/icons-material/Translate';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
  // eslint-disable-next-line no-unused-vars
import BugReportIcon from '@mui/icons-material/BugReport';
import MenuIcon from '@mui/icons-material/Menu';

import Home from './Home';
import EditUser from './EditUser';
import EditCompany from './EditCompany';
import EditPerson from './EditPerson';
import EditMaterial from './EditMaterial';
import EditProduct from './EditProduct';
import EditMachine from './EditMachine';
import EditSpecification from './EditSpecification';
import EditProcess from './EditProcess';
import EditSequence from './EditSequence';
import EditProduction from './EditProduction';
import EditOrder from './EditOrder';
import EditMail from './EditMail';
import EditConfig from './EditConfig';
import EditReport from './EditReport';
// import DisplayReport from './DisplayReport';
import WorstListReport from './WorstListReport';
import EditWorkhour from './EditWorkhour';
// import EditDefect from './EditDefectiveList';
import EditDefect from './EditDefect';


export default function Frame(props) {
  const colorMode = props.colorMode;
  const theme = props.theme;

  let navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }
  
  const disp = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < disp.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < disp.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < disp.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < disp.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  })
  // eslint-disable-next-line no-unused-vars
  const [dps] = React.useState(getDisplaySize());

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    if(!siteRef.current.authPassed)navigate(`/${config.account}/signin`, { replace: true });
    if(siteRef.current.userrole!==1)navigate(`/${config.account}/`, { replace: true });
    if(!status.page) {
      setStatus(status => ({...status, page:'Home'}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const moveTop = (val) => {
    navigate(`/${config.account}/`, { replace: true });
  }

  const page = (val) => {
    // console.log(val);
    setStatus(status => ({...status, page:val}));
  }

  // eslint-disable-next-line no-unused-vars
  const test = () => {
    // console.log(status);
    // console.log(config);
  }

  return (
    <Box >

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <AppBar position='sticky' sx={{width:'100%'}} >
        <Toolbar variant="dense" sx={{ width:'100%'}}>
            {/* ---------- system menu (as admin home) ---------- */}
            <IconButton color="inherit" sx={{ ml: 0 }} onClick={() => {page('Home')}}>
              <MenuIcon fontSize="small"/>
            </IconButton>
            <Box sx={{ display:'flex', width:'100%'}}>
              <Typography noWrap sx={{ lineHeight:'2', textOverflow:'ellipsis' }}>
                { dps==='xs'?getLabel('navigation_short_title'):getLabel('navigation_title') }
              </Typography>
              <Box sx={{ flexGrow: 1 }}/>
              {/* ---------- signin user name ---------- */}
              <Button color="inherit" size="small" sx={{ whiteSpace:'nowrap' }} onClick={test}>
                { dps==='xs'||dps==='sm'?config[config.account].userid:config[config.account].username }
              </Button>

              {/* ---------- change language menu ---------- */}
              <IconButton color="inherit" sx={{ ml: 0 }} onClick={languageClick}>
                <TranslateIcon fontSize="small"/>
              </IconButton>
              <Menu anchorEl={anchorLanguage} open={selectLanguage} onClose={languageClose}>
                <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account].language==='jp'?true:false}>日本語</MenuItem>
                <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account].language==='en'?true:false}>English</MenuItem>
                <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account].language==='th'?true:false}>ไทย</MenuItem>
              </Menu>

              {/* ---------- change color mode ---------- */}
              <IconButton color="inherit" sx={{ ml: 0 }} onClick={() => colorMode.toggleColorMode()}>
                {theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="small"/> : <Brightness4Icon fontSize="small"/>}
              </IconButton>

              {/* ---------- exit to root menu ---------- */}
              <IconButton color="inherit" sx={{ ml: 0 }} onClick={moveTop}>
                <ExitToAppIcon fontSize="small"/>
              </IconButton>
            </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{width:'100%'}}>
        { status.page && status.page==='Home' && <Home/> }
        { status.page && status.page==='EditUser' && <EditUser/> }
        { status.page && status.page==='EditCompany' && <EditCompany/> }
        { status.page && status.page==='EditPerson' && <EditPerson/> }
        { status.page && status.page==='EditMaterial' && <EditMaterial/> }
        { status.page && status.page==='EditProduct' && <EditProduct/> }
        { status.page && status.page==='EditMachine' && <EditMachine/> }
        { status.page && status.page==='EditSpecification' && <EditSpecification/> }
        { status.page && status.page==='EditProcess' && <EditProcess/> }
        { status.page && status.page==='EditSequence' && <EditSequence/> }
        { status.page && status.page==='EditProduction' && <EditProduction/> }
        { status.page && status.page==='EditOrder' && <EditOrder/> }
        { status.page && status.page==='EditMail' && <EditMail/> }
        { status.page && status.page==='EditConfig' && <EditConfig/> }
        { status.page && status.page==='EditReport' && <EditReport/> }
        {/* { status.page && status.page==='DisplayReport' && <DisplayReport/> } */}
        { status.page && status.page==='WorstListReport' && <WorstListReport/> }
        { status.page && status.page==='EditWorkhour' && <EditWorkhour/> }
        { status.page && status.page==='EditDefect' && <EditDefect/> }

        {/* { status.page && status.page==='TestContent00' && <TestContent00/> }
        { status.page && status.page==='TestContent01' && <TestContent01/> }
        { status.page && status.page==='TestContent02' && <TestContent02/> }
        { status.page && status.page==='TestContent03' && <TestContent03/> }
        { status.page && status.page==='TestContent04' && <TestContent04/> }
        { status.page && status.page==='TestContent05' && <TestContent05/> } */}
      </Box>

    </Box>
  );
}

