import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Button, Autocomplete, Backdrop, CircularProgress } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import ConfirmDialog from "../ConfirmDialog";
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";


export default function EditProduction() {

  const [config] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, _setCaption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false);  
  const [labels, setLabels] = React.useState(null);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [isDisabled, setDisabled] = React.useState(false);
  const [recordData, setRecordData] = React.useState(
    { id:'', product:'', seq:'', company:'', process:'', sequence:'', period:'', machine:'', material:'',
    weight:'', unit:'', userreg:'', datereg:'', userupd:'',dateupd:'',user:config.userid, defectptrn:'',
    dname:'', pmodel:'', pname:'', mmodel:'', mname:'', cname:'', cdept:'', pcname:'',
    uname:'', maname:'', mamaker:'', sname:'', auth:config[config.account].auth }
  );
  const [helpers, setHelpers] = React.useState({ product:'', seq:'', sequence:'', company:'', process:'', period:'', machine:'', material:'', weight:'', unit:'', defectptrn:'' });
  const [recordList, setRecordList] = React.useState([]);

  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  // const [dPtrns, setDPtrns] = React.useState([]);
  // const [selectedDPtrn, setSelectedDPtrn] = React.useState(null);

  const [materials, setMaterials] = React.useState([]);
  const [selectedMaterial, setSelectedMaterial] = React.useState(null);

  const [companies, setCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  const [processes, setProcesses] = React.useState([]);
  const [selectedProcess, setSelectedProcess] = React.useState(null);

  const [sequences, setSequences] = React.useState([]);
  const [selectedSequence, setSelectedSequence] = React.useState(null);

  const [machines, setMachines] = React.useState([]);
  const [selectedMachine, setSelectedMachine] = React.useState(null);

  const [units, setUnits] = React.useState([]);
  const [selectedUnit, setSelectedUnit] = React.useState(null);

  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
      setRecordData(data => ({...data, user:siteRef.current.userid, auth:config[config.account].auth }));
    }
  }

  React.useEffect(() => {
    loadDefault();
    loadData();
    loadProducsts();
    loadMaterials();
    loadCompanies();
    loadProcesses();
    loadSequences();
    loadUnits();
    loadMachines();
    loadUsers();
    // loadDefectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setSelectedUsers([]);
    loadDailyMail(recordData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData]);

  React.useEffect(() => {
    setSelectedProduct(products.find(v => v.id === recordData.product));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.product]);

  React.useEffect(() => {
    setSelectedMaterial(materials.find(v => v.id === recordData.material));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.material]);

  React.useEffect(() => {
    setSelectedCompany(companies.find(v => v.id === recordData.company));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.company]);

  React.useEffect(() => {
    setSelectedProcess(processes.find(v => v.id === recordData.process));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.process]);

  React.useEffect(() => {
    setSelectedSequence(sequences.find(v => v.id === recordData.sequence));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.sequence]);

  React.useEffect(() => {
    setSelectedUnit(units.find(v => v.id === recordData.unit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.unit]);

  React.useEffect(() => {
    setSelectedMachine(machines.find(v => v.id === recordData.machine));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.machine]);

  // React.useEffect(() => {
  //   setSelectedDPtrn(dPtrns.find(v => v.id === recordData.defectptrn));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [recordData.defectptrn])
  
  const loadData = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/productions`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });
  }

  const loadProducsts = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/products`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setProducts(data);
      // setLoading(false);
    });
  }

  const loadMaterials = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/materials`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setMaterials(data);
      // setLoading(false);
    });
  }

  const loadCompanies = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/companies`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setCompanies(data);
      // setLoading(false);
    });
  }

  const loadProcesses = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/processes`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setProcesses(data);
      // setLoading(false);
    });
  }

  const loadSequences = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/sequences`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setSequences(data);
      // setLoading(false);
    });
  }

  const loadSequencesByProcess = async(process) => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/sequenceprocess`;
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ process:process, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setSequences(data);
      // setLoading(false);
    });
  }

  const loadUnits = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/units`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setUnits(data);
      // setLoading(false);
    });
  }

  const loadMachines = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/machines`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setMachines(data);
      // setLoading(false);
    });
  }

  const loadUsers = () => {
    // setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/users`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setUsers(data);
      // setLoading(false);
    });
  }

  // const loadDefectList = () => {
  //   setLoading(true);
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/defectgroup`;
  //   fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ auth:config[config.account].auth, id:null }) })
  //   .then(res => res.json())
  //   .then(data => {
  //     setDPtrns(data);
  //     setLoading(false);
  //   });
  // }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnDefs = [
    { headerName: getLabel('production_product_model'), field: "pmodel" },
    { headerName: getLabel('production_product_internal'), field: "imodel" },
    { headerName: getLabel('production_product_name'), field: "pname" },
    { headerName: getLabel('production_sequence'), field: "seq" },
    { headerName: getLabel('production_company_name'), field: "cname" },
    { headerName: getLabel('production_company_dept'), field: "cdept" },
    { headerName: getLabel('production_process'), field: config[config.account].language==='en'?'pcname_en':config[config.account].language==='th'?'pcname_th':'pcname' },
    { headerName: getLabel('sequence_info'), field: "sname" },
    { headerName: getLabel('production_period'), field: "period" },
    { headerName: getLabel('production_machine'), field: "maname" },
    { headerName: getLabel('production_material_model'), field: "mmodel" },
    { headerName: getLabel('production_material_name'), field: "mname" },
    { headerName: getLabel('production_weight'), field: "weight" },
    { headerName: getLabel('production_unit'), field: "uname" },
    // { headerName: getLabel('production_defectptrn'), field: "dname" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      let ncol = 0;
      cdef[ncol++].headerName = getLabel('production_product_model');
      cdef[ncol++].headerName = getLabel('production_product_internal');
      cdef[ncol++].headerName = getLabel('production_product_name');
      cdef[ncol++].headerName = getLabel('production_sequence');
      cdef[ncol++].headerName = getLabel('production_company_name');
      cdef[ncol++].headerName = getLabel('production_company_dept');
      cdef[ncol++].headerName = getLabel('production_process');
      cdef[ncol++].headerName = getLabel('sequence_info');
      cdef[ncol++].headerName = getLabel('production_period');
      cdef[ncol++].headerName = getLabel('production_machine');
      cdef[ncol++].headerName = getLabel('production_material_model');
      cdef[ncol++].headerName = getLabel('production_material_name');
      cdef[ncol++].headerName = getLabel('production_weight');
      cdef[ncol++].headerName = getLabel('production_unit');
      // cdef[ncol++].headerName = getLabel('production_defectptrn');
      cdef[ncol++].headerName = getLabel('common_reg_user');
      cdef[ncol++].headerName = getLabel('common_reg_date');
      cdef[ncol++].headerName = getLabel('common_upd_user');
      cdef[ncol++].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateData = async(event) => {
    setLoading(true);
    const data = event.data;
    await loadSequencesByProcess(data.process);
    setEditState(1);
    setRecordData({...recordData, id:data.id, product:data.product, seq:data.seq, company:data.company,
      process:data.process, sequence:data.sequence, period:data.period, machine:data.machine, material:data.material, weight:data.weight, unit:data.unit,
      cdept:data.cdept, cname:data.cname, mmodel:data.mmodel, mname:data.mname, pcname:data.pcname, pname:data.pname, pmodel:data.pmodel, uname:data.uname,
      maname:data.maname, mamaker:data.mamaker, sname:data.sname, defectptrn:data.defectptrn, dname:data.dname,
      userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, auth:config[config.account].auth });
    inputValidation(data);
    setLoading(false);
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.id===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  const loadDailyMail = (id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/dailymaillist`;
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ production:id, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      data.map(v => setSelectedUsers(selectedUsers => [...selectedUsers, {production:v.production, userid:v.userid, username:v.username, auth:config[config.account].auth}]));
    });

  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onModelUpdated: updateSelectedRow,
    localeText: localeText,
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  function isNumber(numVal){
    var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
    return pattern.test(numVal);
  } 

  const newRecord = () => {
    setRecordData({...recordData, id:'', product:'', seq:'', company:'', process:'', period:'', sequence:'' ,defectptrn:'', dname:'',
    machine:'', material:'', weight:'', unit:'', userreg:'', datereg:'', userupd:'',dateupd:'', auth:config[config.account].auth });
    setSelectedUsers([]);
    setEditState(0);
    setHelpers({ product:'', seq:'', sequence:'', company:'', process:'', period:'', machine:'', material:'', weight:'', unit:'', defectptrn:'' });
  }
  const inputValidation = (data) => {
    let validated = true;
    setHelpers({ product:'', seq:'', sequence:'', company:'', process:'', period:'', machine:'', material:'', weight:'', unit:'', defectptrn:'' });
    if(!isNumber(data.product)) {
      setHelpers(helpers => ({...helpers, product:'Required'}));
      validated = false;
    } 
    if(!isNumber(data.seq)) {
      setHelpers(helpers => ({...helpers, seq:'Required'}));
      validated = false;
    } 
    if(!isNumber(data.company)) {
      setHelpers(helpers => ({...helpers, company:'Required'}));
      validated = false;
    } 
    if(!isNumber(data.process)) {
      setHelpers(helpers => ({...helpers, process:'Required'}));
      validated = false;
    } 
    if(!isNumber(data.period)) {
      setHelpers(helpers => ({...helpers, period:'Required'}));
      validated = false;
    } 
    if(!isNumber(data.machine)) {
      setHelpers(helpers => ({...helpers, machine:'Required'}));
      validated = false;
    } 
    if(data.weight.trim().length>0 && data.unit<=0){
      setHelpers(helpers => ({...helpers, unit:'Required'}));
      validated = false;
    }
    return validated;
  }

  const [newMailing, setNewMailing] = React.useState(null);
  React.useEffect(() => {
    const id = newMailing;
    if(id !== null) {
      updateMailingList(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMailing, selectedUsers])

  const updateRecord = () => {
    setDisabled(true);
    if(inputValidation(recordData)) {
      if(editState===0) { // new record
        let url = `${config[config.account].url}:${config[config.account].port}/api/productionadd`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          setSelectedUsers(selectedUsers.map((user, _i) => ({...user, production:data.insertId, auth:config[config.account].auth}) )); 
          setNewMailing(data.insertId);
          setDisabled(false);
          setSid(data.insertId);
        });
      } else { // update record
        let url = `${config[config.account].url}:${config[config.account].port}/api/productionupdate`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(_data => {
          loadData();
          setDisabled(false);
          setSid(recordData.id);
        });
        setNewMailing(recordData.id);
      }
    } else {
      setDisabled(false);
    }
  }

  const updateMailingList = async(production) => {
    const productionid = [{ production:production, auth:config[config.account].auth }];
    Object.assign(productionid, selectedUsers);
    let url = `${config[config.account].url}:${config[config.account].port}/api/updatedailymaillist`;
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(productionid)})
    .then(res => res.json())
    .then(_data => {
      setNewMailing(null);
      loadData();
    });
  }

  const deleteRecord = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/productioninuse`;
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
    .then(res => res.json())
    .then(data => {
      Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
    });
    if(sum === 0) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/productiondelete`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
      .then(res => res.json())
      .then(_data => {
        newRecord();
        loadData();
      });
    } else {
      openInUse();
    }
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = () => setConfirm(true);
  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) deleteRecord();
    setConfirm(false);
  }

  const [inUse, setInUse] = React.useState(false);
  const openInUse = () => setInUse(true);
  const closeInUse = () => setInUse(false);


  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <ConfirmDialog 
        open={inUse}
        onClose={closeInUse}
        title={ getLabel('common_info') }
        msg={ getLabel('common_inuse_meg') }
        confirm={ getLabel('common_confirm') }
      />

      <Stack direction="row">
        <Box sx={{ mx:2, px:0 }}>
          <h4>{getLabel('production_list')}</h4>
        </Box>
      </Stack>

      <Box color="text.primary" style={{ height: `calc(40vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          { !aggDestroyed &&
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={recordList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}>
          </AgGridReact>
          }
        </div>
      </Box>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{editState===0?getLabel('production_info')+" - "+getLabel('common_new'):getLabel('production_info')+" - "+getLabel('common_update')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              { products ? (
              <Autocomplete
                disablePortal
                value = { selectedProduct || null }
                id="combo-product"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => (option.model?`${option.model} - `:'') + (option.internal?`${option.internal} - `:'') + option.name }  
                options={products}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('production_product')} helperText={ helpers.product } error={helpers.product.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, product: value? value.id:null});
                  setSelectedProduct(products.find(v => v.id === value? value.id:null));
                }}
              />) 
              : null}

              <TextField sx={{ mx:1, mb:1 }} id="seq" label={getLabel('production_sequence')} variant="standard" size="small" value={recordData.seq}
                onChange={(e) => setRecordData({...recordData, seq: e.target.value})}
                helperText={ helpers.seq } error={helpers.seq.length>0?true:false} />

              { companies ? (
              <Autocomplete
                disablePortal
                value = { selectedCompany || null }
                id="combo-company"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name + (option.dept?` - ${option.dept}`:'') }
                options={companies}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('production_company')} helperText={ helpers.company } error={helpers.company.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, company: value? value.id:null});
                  setSelectedCompany(companies.find(v => v.id === value? value.id:null));
                }}
              />) 
              : null}

              { processes ? (
              <Autocomplete
                disablePortal
                value = { selectedProcess || null }
                id="combo-process"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => config[config.account].language === 'en'?option.name_en:config[config.account].language === 'th'?option.name_th:option.name }
                options={processes}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('production_process')} helperText={ helpers.process } error={helpers.process.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, process: value? value.id:null});
                  setSelectedProcess(processes.find(v => v.id === value? value.id:null));
                  if (value) loadSequencesByProcess(value.id);
                }}
              />) 
              : null}

              { sequences ? (
              <Autocomplete
                disablePortal
                value = { selectedSequence || null }
                id="combo-sequence"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name }
                options={sequences}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('sequence_info')} helperText={ helpers.sequence } error={helpers.sequence.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, sequence: value? value.id:null});
                  setSelectedSequence(sequences.find(v => v.id === value? value.id:null));
                }}
              />) 
              : null}

              <TextField sx={{ mx:1, mb:1 }} id="period" label={getLabel('production_period')} variant="standard" size="small" value={recordData.period}
                onChange={(e) => setRecordData({...recordData, period: e.target.value})}
                helperText={ helpers.period } error={helpers.period.length>0?true:false} />

            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              { machines ? (
              <Autocomplete
                disablePortal
                value = { selectedMachine || null }
                id="combo-machine"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name + (option.maker?` - ${option.maker}`:'') }
                options={machines}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('production_machine')} helperText={ helpers.machine } error={helpers.machine.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, machine: value? value.id:null});
                  setSelectedMachine(machines.find(v => v.id === value? value.id:null));
                }}
              />) 
              : null}

              { materials ? (
              <Autocomplete
                disablePortal
                value = { selectedMaterial || null }
                id="combo-material"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name + (option.model?` - ${option.model}`:'') }
                options={materials}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('production_material')} helperText={ helpers.material } error={helpers.material.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, material: value? value.id:null});
                  setSelectedMaterial(materials.find(v => v.id === value? value.id:null));
                }}
              />) 
              : null}

              <TextField sx={{ mx:1, mb:1 }} id="weight" label={getLabel('production_weight')} variant="standard" size="small" value={recordData.weight}
                onChange={(e) => setRecordData({...recordData, weight: isNumber(e.target.value)?e.target.value:e.target.value.slice(0,-1)})}
                helperText={ helpers.weight } error={helpers.weight.length>0?true:false} />

              { units ? (
              <Autocomplete
                disablePortal
                value = { selectedUnit || null }
                id="combo-unit"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name }
                options={units}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('production_unit')} helperText={ helpers.unit } error={helpers.unit.length>0?true:false} />}
                onChange={(_event, value) => {
                  if(value) {
                    setRecordData({...recordData, unit: value.id});
                    setSelectedUnit(units.find(v => v.id === value.id));
                    if(helpers.unit.length>0)setHelpers(helpers => ({...helpers, unit:''}));
                  } else {
                    setRecordData({...recordData, unit:0});
                    setSelectedUnit(null);
                  }
                }}
              />) 
              : null}

              <FormControl sx={{ mx:1, mb:1 }} size="small">
                <InputLabel id="daily_report_to">{getLabel('production_report_to')}</InputLabel>
                <Select
                  id="daily_report_users"
                  labelId="daily_report_to"
                  size="small"
                  input={<OutlinedInput size="small" label={getLabel('production_report_to')} />}
                  value={selectedUsers || null}
                  renderValue={selectedUsers => selectedUsers.map(v => v.username).join(', ')}
                  onChange={(_event, value) => {
                    if(selectedUsers.some(user => user.userid === value.props.id)) {
                      setSelectedUsers(selectedUsers.filter(user => (user.userid !== value.props.id)));  // delete a hash from array (recreate array without the selected)
                    } else {
                      setSelectedUsers(selectedUsers => [...selectedUsers, {production:recordData.id, userid:value.props.id, username:value.props.value, auth:config[config.account].auth}]); // add a hash to array
                    }
                  }}
                  multiple>
                  {users.map((user) => (
                  <MenuItem key={user.userid} id={user.userid} value={user.username} size="small">
                    <Checkbox checked={selectedUsers.some(v => v.userid === user.userid)}/>
                    <ListItemText primary={user.username} />
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* { dPtrns ? (
              <Autocomplete
                disablePortal
                value = { selectedDPtrn || null }
                id="combo-defectptrn"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name }
                options={dPtrns}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('production_defectptrn')} 
                />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, defectptrn: value?value.id:null, dname: value?value.name:null });
                  setSelectedDPtrn(dPtrns.find(v => v.id === value.id? value.id:null));
                }}
              />) 
              : null} */}

            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => newRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('production_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => openConfirm()} disabled={editState===0?true:false} disableElevation>{getLabel('production_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" disabled={isDisabled} onClick={() => updateRecord()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>
      </Paper>
    </Box>
  );
}