import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
// eslint-disable-next-line no-unused-vars
import { MobileDatePicker, StaticDatePicker, DatePicker } from '@mui/x-date-pickers';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";

import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import endOfWeek from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import locale_en from 'date-fns/locale/en-US';
import locale_ja from 'date-fns/locale/ja';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
  })(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

export default function WeekPicker(props) {
  if(locale_en && locale_ja) {
    locale_en.options.weekStartsOn = 1;
    locale_ja.options.weekStartsOn = 1;
  }

  const [value, setValue] = React.useState(props.value);
  // const [value, setValue] = React.useState(null);
  const [config] = useRecoilState(systemConfig);
  const [locale, setLocale] = React.useState();

  React.useEffect(() => {
    const lang = config.language;
    setLocale(lang==='jp'?locale_ja:locale_en);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config.language]);

  
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(value, {locale:locale});
    const end = endOfWeek(value, {locale:locale});

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const onChangeHandler = (value) => {
    props.onChange(value);
    setValue(value);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}  locale={locale}>
      <MobileDatePicker
        sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'}, }}
        // displayStaticWrapperAs="desktop"
        disableMaskedInput={true}
        label={props.label}
        value={props.value}
        onChange={onChangeHandler}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
        inputFormat={props.inputFormat}
        okText={props.okText}
        cancelText={props.cancelText}
      />
    </LocalizationProvider>
  );
}
