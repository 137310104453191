import React from 'react';
import { Box, Button, Paper, Typography, TextField, Stack, Backdrop, CircularProgress } from '@mui/material';
import { FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemStatus } from "../atoms/systemStatus";
import { systemCaption } from "../atoms/systemCaption";
import { useNavigate } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TranslateIcon from '@mui/icons-material/Translate';

export default function SignIn({ prop }) {
  let navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [caption] = useRecoilState(systemCaption);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    if(siteRef.current.authPassed)navigate(`/${config.account}/`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [values, setValues] = React.useState({ email: '', password: '', showPassword: false, auth:config[config.account].auth });
  const [helpers, setHelpers] = React.useState({ email:'', password:'' });
  
  
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputValidation = () => {
    let validated = true;
    if(values.email.trim().length === 0) {
      setHelpers(helpers => ({...helpers, email:getLabel('common_required') }));
      validated = false;
    } else setHelpers(helpers => ({...helpers, email:''}));
    if(values.password.trim().length === 0) {
      setHelpers(helpers => ({...helpers, password:getLabel('common_required') }));
      validated = false;
    } else setHelpers(helpers => ({...helpers, password:''}));
    return validated;
  }

  const checkAuthentication = () => {
    // console.log(values);
    // console.log(config);
    const url = `${config[config.account].url}:${config[config.account].port}/api/auth`;
    // const url = siteRef.current.backend + '/api/auth2';
    // body: JSON.stringify({ auth:config[config.account].auth })

    const val = inputValidation();
    if(val) {
      setLoading(true);
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(values)})
      .then(res => res.json())
      .then(data => {
        if(!data.passed) {
          setHelpers(helpers => ({...helpers, email:getLabel('common_incorrect_email') }));
          setHelpers(helpers => ({...helpers, password:getLabel('common_incorrect_password') }));
        }
        setLoading(false);
        if(data.passed) {
          // console.log(data);
          setConfig(config => ({...config, [config.account]: {...config[config.account],
            userid:data.userid, username:data.username, authPassed:data.passed, userrole:data.sysrole?data.sysrole:4 }}));
          if(config[config.account].status_backup) {
            setStatus(config[config.account].status_backup);
          }
          navigate(`/${config.account}/`, { replace: true });
          // console.log(`Authentication passed.`);
        }
      });
    }
  }

  const refUserId = React.useRef('');
  const refUserPassword = React.useRef('');

  const keyEvents = (event) => {
    // console.log(event.keyCode);
    // console.log(event.target.id);
    if(event.target.id === 'email' && event.keyCode === 13) {
      refUserPassword.current.focus();
      // console.log('focus to password.');
    }
    if(event.target.id === 'standard-adornment-password' && event.keyCode === 13) {
      checkAuthentication();
      // console.log('submit login.');
    }
  }

  // const moveRegister = () => {
  //   navigate(`/${config.account}/register`, { replace: true });
  // }


  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ p:2, display:'flex', width:'100%' }}>
        <Button variant="outlined">{ getLabel('navigation_title') }</Button>
        <Box sx={{ flexGrow: 1 }}/>

          {/* ---------- change language menu ---------- */}
          <IconButton color="inherit" sx={{ mr: 1 }} onClick={languageClick}>
            <TranslateIcon fontSize="small"/>
          </IconButton>
          <Menu anchorEl={anchorLanguage} open={selectLanguage} onClose={languageClose}>
            <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account].language==='jp'?true:false}>日本語</MenuItem>
            <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account].language==='en'?true:false}>English</MenuItem>
            <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account].language==='th'?true:false}>ไทย</MenuItem>
          </Menu>

        {/* <Button variant="contained" onClick={moveRegister}>{ getLabel('common_register') }</Button> */}
      </Box>

      <Box sx={{ p:2, width:'100%', height:'80vh', display:'flex', justifyContent:'center', alignItems:'center' }} >
        <Paper sx={{ p:2, width:'400px'}}>
          <Stack direction="column">
            <Typography sx={{ m:1 }} variant="h6">{ getLabel('common_signin_form') }</Typography>
            <TextField sx={{ m:1 }} id="email" label={ getLabel('common_email') } variant="standard"
              value={values.email} onChange={handleChange('email')}
              onKeyDown={keyEvents}
              inputRef={refUserId}
              type="email" 
              helperText={ helpers.email } error={helpers.email.length>0?true:false} />

            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password" error={helpers.password.length>0?true:false}>{ getLabel('common_password') }</InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                onKeyDown={keyEvents}
                inputRef={refUserPassword}
                error={helpers.password.length>0?true:false}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="password-helper-text" error={helpers.password.length>0?true:false}>
                { helpers.password }
              </FormHelperText>
            </FormControl>

            <Button sx={{ m:2 }} variant="contained" onClick={checkAuthentication}>{ getLabel('common_signin') }</Button>
          </Stack>
        </Paper>
      </Box>

    </Box>
  );
}

