import * as React from 'react';
// import PropTypes from 'prop-types';
import { Dialog, Grid, Button, Typography, Divider, Box } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { useTheme } from '@mui/material/styles';

export default function ConfirmEntry(props) {
  const { onClose, model, name, qualified, unqualified, rework, open } = props;

  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [labels, setLabels] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = (event) => {
    const key = event.target.id;
    if(key === 'done')onClose(1);
    else if(key === 'complete')onClose(2);
    else onClose(0);
  };

  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  })
  // eslint-disable-next-line no-unused-vars
  const [dps, setDps] = React.useState(getDisplaySize());


  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">

      <Grid container sx={{ p:1 }}>

        <Grid item xs={12}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'10vh' }}>
            <Typography variant="h6">{ getLabel('entry_register_msg') }</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4} sx={{ p:1 }}>
          <Typography>{ getLabel('entry_model') }</Typography>
        </Grid>
        <Grid item xs={8} sx={{ p:1 }}>
          <Typography>{ model }</Typography>
        </Grid>

        <Grid item xs={4} sx={{ p:1 }}>
          <Typography>{ getLabel('entry_name') }</Typography>
        </Grid>
        <Grid item xs={8} sx={{ p:1 }}>
          <Typography>{ name }</Typography>
        </Grid>

        <Grid item xs={4} sx={{ p:1 }}>
          <Typography>{ getLabel('entry_qualified') }</Typography>
        </Grid>
        <Grid item xs={8} sx={{ p:1 }}>
          <Typography>{ qualified }</Typography>
        </Grid>

        <Grid item xs={4} sx={{ p:1 }}>
          <Typography>{ getLabel('entry_rework') }</Typography>
        </Grid>
        <Grid item xs={8} sx={{ p:1 }}>
          <Typography>{ rework }</Typography>
        </Grid>

        <Grid item xs={4} sx={{ p:1 }}>
          <Typography>{ getLabel('entry_unqualified') }</Typography>
        </Grid>
        <Grid item xs={8} sx={{ p:1 }}>
          <Typography>{ unqualified }</Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'10vh' }}>
            <Button id='done' variant='contained' size={dps==='xs'?'small':'large'} sx={ { fontSize:'small' }} onClick={handleClose}>{ getLabel('entry_register_check') }</Button>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'10vh', mx:1}}>
            <Button id='complete' variant='contained' size={dps==='xs'?'small':'large'} sx={{ fontSize:'small' }} onClick={handleClose}>
              { dps==='xs'?getLabel('entry_register_complete_mobile'):getLabel('entry_register_complete') }
            </Button>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'10vh' }}>
            <Button id='cancel' variant='contained' size='large' sx={dps==='xs'?{fontSize:'large'}:{fontSize:'small'}} onClick={handleClose}>{ getLabel('common_cancel') }</Button>
          </Box>
        </Grid>


      </Grid>

    </Dialog>
  );
}

  