import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Checkbox, Button, Backdrop, CircularProgress } from '@mui/material';
import { FormControl, FormGroup, FormControlLabel, InputLabel, Select, MenuItem } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import ConfirmDialog from "../ConfirmDialog";
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";


export default function EditUser() {
  const [config] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false); 
  const [labels, setLabels] = React.useState(null);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [isDisabled, setDisabled] = React.useState(false);
  const [userData, setUserData] = React.useState({id:'',name:'',signature:'',email:'', enabled:false, sysrole:4, userreg:'', datereg:'', userupd:'',dateupd:'', passwd:'', passwdc:'', user:config[config.account].userid });
  const [userList, setUserList] = React.useState([]);
  const [helpers, setHelpers] = React.useState({ id:'', name:'', email:'', passwd:'', passwdc:'',signature:'' });

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
      setUserData(data => ({...data, user:siteRef.current.userid, auth:config[config.account].auth }));
    }
  }
  
  React.useEffect(() => {
    loadDefault();
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const loadUsers = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/users`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setUserList(data);
      setLoading(false);
    });
  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const checkBoxRenderer = (params) => {
    const checkItem = (event) => {
      if(params.data.isactive)params.data.isactive = 0; else params.data.isactive = 1;
      params.api.refreshCells({columns : ["isactive","isactive"]});
    }
    return (
      <Checkbox color="primary" size="small" checked={params.value===1?true:false} onClick={checkItem} disabled></Checkbox>
    );
  }

  const roleName = function(e) {
    if(e.value === 1)return getLabel('user_role_administrator');
    if(e.value === 2)return getLabel('user_role_supervisor');
    if(e.value === 3)return getLabel('user_role_operator');
    if(e.value === 4)return getLabel('user_role_viewer');
    return "";
  }

  const columnDefs = [
    { headerName: getLabel('user_id'), field: "userid", lockPinned: true, pinned: "left", editable:false, }, //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: getLabel('user_name'), field: "username" }, // headerName:'initial display name'
    { headerName: getLabel('user_signature'), field: "signature" }, // headerName:'initial display name'
    { headerName: getLabel('user_email'), field: "email" },
    { headerName: getLabel('user_enabled'), field: "isactive", editable: false, cellRenderer: "CheckboxCellRenderer", },
    { headerName: getLabel('user_role'),  field: "sysrole", valueFormatter: roleName },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      var col = 0;
      cdef[col++].headerName = getLabel('user_id');
      cdef[col++].headerName = getLabel('user_name');
      cdef[col++].headerName = getLabel('user_signature');
      cdef[col++].headerName = getLabel('user_email');
      cdef[col++].headerName = getLabel('user_enabled');
      cdef[col++].headerName = getLabel('user_role');
      cdef[col++].headerName = getLabel('common_reg_user');
      cdef[col++].headerName = getLabel('common_reg_date');
      cdef[col++].headerName = getLabel('common_upd_user');
      cdef[col++].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const onSelectedRow = (event) => {
    setHelpers({ id:'', name:'', email:'', passwd:'', passwdc:'',signature:'' });
    setUserData({...userData, id:'',name:'',signature:'',email:'', enabled:false, sysrole:4, userreg:'', datereg:'', userupd:'',dateupd:'', passwd:'', passwdc:'', auth:config[config.account].auth})
    const data = event.data;
    const isactive = data.isactive===1?true:false;
    setEditState(1);
    setUserData({...userData, id:data.userid, name:data.username, signature:data.signature, email:data.email, enabled:isactive,
      sysrole:data.sysrole, userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, auth:config[config.account].auth});
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.userid===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    components: { CheckboxCellRenderer: checkBoxRenderer, },
    onRowClicked: onSelectedRow,
    onModelUpdated: updateSelectedRow,
    localeText: localeText,
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  // eslint-disable-next-line no-unused-vars
  function isNumber(numVal){
    var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
    return pattern.test(numVal);
  } 

  const inputValidation = (data) => {
    let validated = true;
    setHelpers({ id:'', name:'', email:'', passwd:'', passwdc:'',signature:'' });
    if(data.id.trim().length === 0) {
      setHelpers(helpers => ({...helpers, id:'Required'}));
      validated = false;
    }
    if(data.name.trim().length === 0) {
      setHelpers(helpers => ({...helpers, name:'Required'}));
      validated = false;
    }
    if(data.email.trim().length === 0) {
      setHelpers(helpers => ({...helpers, email:'Required'}));
      validated = false;
    }
    if(data.signature.trim().length === 0) {
      setHelpers(helpers => ({...helpers, signature:'Required'}));
      validated = false;
    }
    if(data.passwd || data.passwdc) {
      if(data.passwd.trim() !== data.passwdc.trim()) {
        setHelpers(helpers => ({...helpers, passwdc:'Password mismatching'}));
        validated = false;
      }
    };
    if(editState === 0 && (data.passwd !== undefined || data.passwdc !== undefined)) {
      if(data.passwd.trim().length === 0){
        setHelpers(helpers => ({...helpers, passwd: 'Required'}));
        validated = false;
      }
      if(data.passwdc.trim().length === 0){
        setHelpers(helpers => ({...helpers, passwdc: 'Required'}));
        validated = false;
      }
    };
    return validated;
  }

  const newUser = () => {
    setUserData({...userData, id:'',name:'',signature:'',email:'', enabled:false, sysrole:3, userreg:'', datereg:'', userupd:'',dateupd:'', passwd:'', passwdc:'', auth:config[config.account].auth });
    setHelpers({ id:'', name:'', email:'', passwd:'', passwdc:'',signature:'' });
    setEditState(0);
  }

  const updateUser = () => {
    if(inputValidation(userData)) {
      setDisabled(true);
      if(editState===0) { // new user
        setLoading(true);
        const url = `${config[config.account].url}:${config[config.account].port}/api/useradd`;
        fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userData)})
        .then(res => res.json())
        .then(data => {
          loadUsers();
          newUser();
          setDisabled(false);
          setLoading(false);
          setSid(userData.id);
        });
      } else { // update user
        setLoading(true);
        const url = `${config[config.account].url}:${config[config.account].port}/api/userupdate`;
        fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userData)})
        .then(res => res.json())
        .then(data => {
          loadUsers();
          setUserData({...userData, passwd:'', passwdc:''});
          setDisabled(false);
          setLoading(false);
          setSid(userData.id);
        });
      }
    }
  }

  const deleteUser = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/userinuse`;
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userData)})
    .then(res => res.json())
    .then(data => {
      Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
    });
    if(sum === 0) {
      setLoading(true);
      const url = `${config[config.account].url}:${config[config.account].port}/api/userdelete`;
      fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(userData)})
      .then(res => res.json())
      .then(data => {
        loadUsers();
        setLoading(false);
      });
    } else {
      openInUse();
    }
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = () => setConfirm(true);
  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) deleteUser();
    setConfirm(false);
  }
  const [inUse, setInUse] = React.useState(false);
  const openInUse = () => setInUse(true);
  const closeInUse = () => setInUse(false);


  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <ConfirmDialog 
        open={inUse}
        onClose={closeInUse}
        title={ getLabel('common_info') }
        msg={ getLabel('common_inuse_meg') }
        confirm={ getLabel('common_confirm') }
      />

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('user_list')}</h4>
      </Box>

      <Box color="text.primary" style={{ height: `calc(35vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          { !aggDestroyed &&
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={userList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}
          >
          </AgGridReact>
          }
        </div>
      </Box>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{editState===0?getLabel('user_info')+" - "+getLabel('common_new'):getLabel('user_info')+" - "+getLabel('common_update')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="userid" label={getLabel('user_id')} variant="standard" size="small" disabled={editState===1?true:false} value={userData.id}
                 onChange={(e) => setUserData({...userData, id: e.target.value})} helperText={ helpers.id } error={helpers.id.length>0?true:false}/>
              <TextField sx={{ mx:1, mb:1 }} id="username" label={getLabel('user_name')} variant="standard" size="small" value={userData.name} 
                onChange={(e) => setUserData({...userData, name: e.target.value})} helperText={ helpers.name } error={helpers.name.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="signature" label={getLabel('user_signature')} variant="standard" size="small" value={userData.signature} 
                onChange={(e) => setUserData({...userData, signature: e.target.value})} helperText={ helpers.signature } error={helpers.signature.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="email" label={getLabel('user_email')} variant="standard" size="small" value={userData.email} type="email"
                onChange={(e) => setUserData({...userData, email: e.target.value})} helperText={ helpers.email } error={helpers.email.length>0?true:false} />

              <FormGroup sx={{ mx:1, mb:1 }}>
                <FormControlLabel control={<Checkbox checked={userData.enabled}
                  onChange={() => setUserData({...userData, enabled: !userData.enabled})}/>} label={getLabel('user_enabled')} />
              </FormGroup>

              <FormControl sx={{ mx:1, mb:1 }}>
                <InputLabel id="user-role">{getLabel('user_role')}</InputLabel>
                <Select
                  id="user-role"
                  label={getLabel('user_role')}
                  size="small"
                  sx={{ mt:1, mr:1, height:0.72 }}
                  value={userData.sysrole}
                  onChange={(e) => setUserData({...userData, sysrole: e.target.value})}
                >
                  <MenuItem value={1}>{ getLabel('user_role_administrator') }</MenuItem>
                  <MenuItem value={2}>{ getLabel('user_role_supervisor') }</MenuItem>
                  <MenuItem value={3}>{ getLabel('user_role_operator') }</MenuItem>
                  <MenuItem value={4}>{ getLabel('user_role_viewer') }</MenuItem>
                </Select>
              </FormControl>

            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="passwd_new" label={getLabel('common_new_password')} variant="standard" size="small" value={userData.passwd} 
                onChange={(e) => setUserData({...userData, passwd: e.target.value})} helperText={ helpers.passwd } error={helpers.passwd.length>0?true:false} type="password"/>
              <TextField sx={{ mx:1, mb:1 }} id="passwd_cnf" label={getLabel('common_password') + ' (' + getLabel('common_confirm') + ')'} variant="standard" size="small" value={userData.passwdc}
                onChange={(e) => setUserData({...userData, passwdc: e.target.value})} helperText={ helpers.passwdc } error={helpers.passwdc.length>0?true:false} type="password"/>
              {/* <TextField sx={{ mx:1, mb:1 }} id="standard-basic" label={getLabel('common_reg_user')} variant="standard" size="small" disabled value={userData.userreg}/>
              <TextField sx={{ mx:1, mb:1 }} id="standard-basic" label={getLabel('common_reg_date')} variant="standard" size="small" disabled value={userData.datereg}/>
              <TextField sx={{ mx:1, mb:1 }} id="standard-basic" label={getLabel('common_upd_user')} variant="standard" size="small" disabled value={userData.userupd}/>
              <TextField sx={{ mx:1, mb:1 }} id="standard-basic" label={getLabel('common_upd_date')} variant="standard" size="small" disabled value={userData.dateupd}/> */}
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => newUser()} disabled={editState===0?true:false} disableElevation>{getLabel('user_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => openConfirm()} disabled={editState===0?true:false} disableElevation>{getLabel('user_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" color="primary" disabled={isDisabled} onClick={() => updateUser()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>

      </Paper>

    </Box>
  );
}
