import React from 'react';
import { Box, Button, Paper, Typography, Stack, Backdrop, CircularProgress } from '@mui/material';
import { FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { useNavigate } from 'react-router-dom';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TranslateIcon from '@mui/icons-material/Translate';

export default function Auth({ prop }) {
  let navigate = useNavigate();

  const [account] = React.useState((window.location.pathname).split("/")[1]);
  const [config, setConfig] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [authURL] = React.useState('https://dbord.asia:3999/authorize'); // fixed URL for the authorization
  const [authPassed, setAuthPassed] = React.useState(false);
  const [defLanguage] = React.useState('en');
  const [bypass, setBypass] = React.useState(false);
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setLabels(caption[valueLanguage]);
    }
  };

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = defLanguage;
    setLabels(caption[lang]);

    if(config[account]?.auth) {
      // console.log('already authorized!!!');
      // console.log(config[account]);
      setValues(v => ({...v, auth:config[account].auth}));
      setBypass(true);
    }

    // demo 
    if(account === 'demo') {
      setValues(v => ({...v, auth:'2TKQczh2h98AJ4WOIikz1OgWN/qpSONaREYCSdYK7ngMSgup.3v9O'}));
      setBypass(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [values, setValues] = React.useState({ auth: '', showPassword: false });
  const [helpers, setHelpers] = React.useState({ auth:'' });
  React.useEffect(() => {
    // if(account === 'demo' && values?.auth) {
    if(account && values?.auth && bypass) {
        checkAuthentication();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, bypass]);
  
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputValidation = () => {
    let validated = true;
    setHelpers({auth:''});
    if(values.auth.trim().length === 0) {
      setHelpers(helpers => ({...helpers, auth:getLabel('auth_code_required') }));
      validated = false;
    } else if(!authPassed) {
      setHelpers(helpers => ({...helpers, auth:getLabel('auth_failed') }));
      validated = false;
    }
    return validated;
  }

  const checkAuthentication = () => {
    const url = authURL;
    setLoading(true);
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({code:values.auth, account:account})})
    .then(res => res.json())
    .then(data => {
      setLoading(false);
      // console.log(data);0
      // console.log(config[data.app]);
      if(data?.app && data?.app === account) {
        // data.data["auth"] = authcode;
        let elem = config[data.app];
        if(elem) {
          Object.keys(elem).forEach(k => {
            // console.log(`${k}: ${elem[k]}`);
            // if(!data.data[k]) {
              data.data[k] = elem[k];
              // console.log(`${k}: ${data.data[k]}`);
            // }
          });
        }
        setAuthPassed(true);
        // console.log(data);
        data.data.auth = values.auth;
        setConfig(conf => ({...conf, account:data.app, [data.app]: data.data }));
        navigate(`/${account}/`, { replace: true });
      }
    });
    inputValidation();
  }

  const refAuth = React.useRef('');

  const keyEvents = (event) => {
    if(event.target.id === 'email' && event.keyCode === 13) {
      refAuth.current.focus();
    }
    if(event.target.id === 'standard-adornment-password' && event.keyCode === 13) {
      checkAuthentication();
    }
  }

  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ p:2, display:'flex', width:'100%' }}>
        <Button variant="outlined">{ getLabel('navigation_title') }</Button>
        <Box sx={{ flexGrow: 1 }}/>

          {/* ---------- change language menu ---------- */}
          <IconButton color="inherit" sx={{ mr: 1 }} onClick={languageClick}>
            <TranslateIcon fontSize="small"/>
          </IconButton>
          <Menu anchorEl={anchorLanguage} open={selectLanguage} onClose={languageClose}>
            <MenuItem dense data-value-language={'jp'} onClick={languageClose} >日本語</MenuItem>
            <MenuItem dense data-value-language={'en'} onClick={languageClose} >English</MenuItem>
            <MenuItem dense data-value-language={'th'} onClick={languageClose} >ไทย</MenuItem>
          </Menu>

      </Box>

      <Box sx={{ p:2, width:'100%', height:'80vh', display:'flex', justifyContent:'center', alignItems:'center' }} >
        <Paper sx={{ p:2, width:'600px'}}>
          <Stack direction="column">
            <Typography sx={{ m:1 }} variant="h6">{ getLabel('auth_title') }</Typography>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-auth" error={helpers.auth.length>0?true:false}>{ getLabel('auth_code') }</InputLabel>
              <Input
                id="standard-adornment-auth"
                type={values.showPassword ? 'text' : 'password'}
                value={values.auth}
                onChange={handleChange('auth')}
                onKeyDown={keyEvents}
                inputRef={refAuth}
                error={helpers.auth.length>0?true:false}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="auth-helper-text" error={helpers.auth.length>0?true:false}>
                { helpers.auth }
              </FormHelperText>
            </FormControl>

            <Button sx={{ m:2 }} variant="contained" onClick={checkAuthentication}>{ getLabel('auth_dbord') }</Button>
          </Stack>
        </Paper>
      </Box>

    </Box>
  );
}

