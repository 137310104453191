import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Button, Backdrop, CircularProgress,
   List, ListItem, ListItemText, ListItemButton, Tabs, Tab  } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import ConfirmDialog from "../ConfirmDialog";
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ClearIcon from '@mui/icons-material/Clear';

export default function EditDefect() {
  const [config] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [isDisabled, setDisabled] = React.useState(false);

  const [recordData, setRecordData] = React.useState({ 
    id:'', name_jp:'', name_en:'', name_th:'', user:config[config.account].userid, auth:config[config.account].auth
   });
  const [helpers, setHelpers] = React.useState({ name_jp:'', name_en:'', name_th:'' });
  const [recordList, setRecordList] = React.useState([]);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
      setRecordData(data => ({...data, user:siteRef.current.userid, auth:config[config.account].auth }));
    }
  }

  React.useEffect(() => {
    loadDefault();
    loadData();
    loadPatterns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const loadData = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/defecttypes`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user:config[config.account].userid, auth:config[config.account].auth, id:null }) })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });
  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnDefs = [
    { headerName: getLabel('defective_name') + ' (EN)', field: "name_en", dndSource:true, },
    { headerName: getLabel('defective_name') + ' (TH)', field: "name_th", dndSource:true, },
    { headerName: getLabel('defective_name') + ' (JP)', field: "name_jp", dndSource:true, },
    // { headerName: getLabel('common_reg_user'), field: "userreg" },
    // { headerName: getLabel('common_reg_date'), field: "datereg" },
    // { headerName: getLabel('common_upd_user'), field: "userupd" },
    // { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      cdef[0].headerName = getLabel('defective_name') + ' (EN)';
      cdef[1].headerName = getLabel('defective_name') + ' (TH)';
      cdef[2].headerName = getLabel('defective_name') + ' (JP)';
      // cdef[3].headerName = getLabel('common_reg_user');
      // cdef[4].headerName = getLabel('common_reg_date');
      // cdef[5].headerName = getLabel('common_upd_user');
      // cdef[6].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateData = (event) => {
    const data = event.data;
    setEditState(1);
    setRecordData({...recordData, id:data.id, name_en:data.name_en, name_jp:data.name_jp, name_th:data.name_th,
      userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, auth:config[config.account].auth});
    inputValidation(data);
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.id===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onModelUpdated: updateSelectedRow,
    localeText: localeText,
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const newRecord = () => {
    setRecordData({...recordData, id:'', name_en:'', name_jp:'', name_th:'', userreg:'', datereg:'', userupd:'',dateupd:'', auth:config[config.account].auth });
    setHelpers({ name_en:'', name_jp:'', name_th:'' });
    setEditState(0);
  }

  const inputValidation = (data) => {
    setHelpers(helpers => ({...helpers, name_jp:'', name_en:'', name_th:'', ptrn_name:''}))
    if(data) {
      let validated = true;
      if(data.name_jp.trim().length === 0) {
        setHelpers(helpers => ({...helpers, name_jp:'Required'}));
        validated = false;
      } 
      if(data.name_en.trim().length === 0) {
        setHelpers(helpers => ({...helpers, name_en:'Required'}));
        validated = false;
      } 
      if(data.name_th.trim().length === 0) {
        setHelpers(helpers => ({...helpers, name_th:'Required'}));
        validated = false;
      } 
      setDisabled(false);
      return validated;
    }
  }

  const updateRecord = () => {
    setDisabled(true);
    if(inputValidation(recordData)) {
      if(editState===0) { // new
        const url = `${config[config.account].url}:${config[config.account].port}/api/defecttypeadd`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          loadData();
          newRecord();
          setDisabled(false);
          setSid(data.insertId);
        });
      } else { // update
        const url = `${config[config.account].url}:${config[config.account].port}/api/defecttypeupdate`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(_data => {
          loadData();
          setDisabled(false);
          setSid(recordData.id);
        });
      }
    }
  }

  const deleteRecord = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defecttypeinuse`;
    // console.log(recordData);
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      // Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
      sum = Number(data[0].inuse);
    });
    if(sum === 0) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/defecttypedelete`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
      .then(res => res.json())
      .then(_data => {
        newRecord();
        loadData();
      });
    } else {
      openInUse();
    }
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = (e) => setConfirm(true);
  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) deleteRecord();
    setConfirm(false);
  }

  const [inUse, setInUse] = React.useState(false);
  const openInUse = (e) => setInUse(true);
  const closeInUse = (e) => setInUse(false); 

  const [patternItems, setPatternItems] = React.useState([]);
  const [patterns, setPatterns] = React.useState([]);
  const [pattern, setPattern] = React.useState({name:'', auth:config[config.account].auth, user:config[config.account].userid});

  const loadPatterns = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectgroup`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user:config[config.account].userid, auth:config[config.account].auth, id:null }) })
    .then(res => res.json())
    .then(data => {
      setPatterns(data);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    
  }, [patterns]);

  const updatePattern = () => {
    // setDisabled(true);

    if(editPtrn===0) { // new
      const url = `${config[config.account].url}:${config[config.account].port}/api/defectgroupadd`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(pattern)})
      .then(res => res.json())
      .then(data => {
        loadPatterns();
        // newRecord();
        // setDisabled(false);
        // setSid(data.insertId);
      });
    } else { // update
      const url = `${config[config.account].url}:${config[config.account].port}/api/defectgroupupdate`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(pattern)})
      .then(res => res.json())
      .then(_data => {
        loadPatterns();
        // loadData();
        // setDisabled(false);
        // setSid(recordData.id);
      });
    }
  }

  const deletePattern = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectgroupinuse`;
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(pattern)})
    .then(res => res.json())
    .then(data => {
      // Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
      sum = Number(data[0].inuse);
      // console.log(`sum: ${sum}`);
    });
    if(sum === 0) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/defectgroupdelete`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(pattern)})
      .then(res => res.json())
      .then(_data => {
        newPattern();
        loadPatterns();
      });
    } else {
      openInUse();
    }
  }

  const loadPatternItems = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectlist`;
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(pattern)})
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        if(data[0]?.id === null) {
          setPatternItems([]);
        } else {
          setPatternItems(data);
        }
        // loadPatterns();
        // setDisabled(false);
    });
  }

  const updatePatternItems = () => {
    const data = {gid:pattern.id, auth:pattern.auth, user:pattern.user};
    const list = patternItems.map((v,i) => {
      return {tid:v.id};
    });
    data.list = list;
    // console.log(data);
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectlistupdate`;
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)})
      .then(res => res.json())
      .then(_data => {
        // console.log(_data);
        // loadPatterns();
        // setDisabled(false);
    });
  }

  const deletePatternItem = async(tid) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectlistinuse`;
    const data = {id:pattern.id, tid:tid, auth:pattern.auth, user:pattern.user};
    // console.log(data);
    // console.log(patternItems);
    let sum = 0;
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)})
      .then(res => res.json())
      .then(data => {
        sum = data[0].inuse;
        // console.log(`sum: ${sum}`);
    });
    if(sum === 0) {
      setPatternItems(patternItems.filter(v => (v.id!==tid)));
      const url = `${config[config.account].url}:${config[config.account].port}/api/defectlistdelete`;
      fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)})
        .then(res => res.json())
        .then(_data => {
          // console.log(data);
          // console.log(`deletePatternItem`);
      });
    } else {
      openInUse();
    }
  }

  const dragOver = (e) => {
    var dragSupported = e.dataTransfer.types.length;
    if (dragSupported) {
      e.dataTransfer.dropEffect = 'move';
    }
    e.preventDefault();
  }

  const drop = (e) => {
    try {
      var jsonData = e.dataTransfer.getData('application/json');
      var data = JSON.parse(jsonData);
      let insert = true;
      patternItems.forEach(v => {
        if(data.id === v.id) {
          insert = false;
        }
      });
      if(insert)setPatternItems(v => [...v, data]);
    } catch(e) {
      // console.log(e);
      return;
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (e) => {
    if (!e.destination) {
      return;
    }
    const items = reorder(
      patternItems,
      e.source.index,
      e.destination.index
    );
    setPatternItems(items);
  }

  React.useEffect(() => {
    if(pattern.id) {
      loadPatternItems();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pattern]);

  React.useEffect(() => {
    if(pattern?.id && patternItems.length > 0) {
      updatePatternItems();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patternItems]);

  const [tab, setTab] = React.useState(null);

  React.useEffect(() => {
    // if(patterns.length > 0)setTab(patterns[0]?.id);
    if(patterns.length > 0) {
      tabChanged(null, patterns[0]?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patterns]);

  const tabChanged = (e, n) => {
    setTab(n);
    // const selected = patterns.map(v => { return v.id===n?v:null });
    const selected = patterns.filter(v => { return v.id===n?v:null });
    if(selected.length===1) {
      const seldata = selected[0]; 
      setPattern({...seldata, auth:config[config.account].auth, user:config[config.account].userid});
      // setPattern({name:'', auth:config[config.account].auth, user:config[config.account].userid});
      setEditPtrn(1);
    }
  }

  const [editPtrn, setEditPtrn] = React.useState(0); // 0 - new, 1 - update

  const newPattern = () => {
    setPattern({name:'', auth:config[config.account].auth, user:config[config.account].userid});
    setEditPtrn(0);
  }

  const [delPtrn, setDelPtrn] = React.useState(false);
  const openDelPtrn = (e) => setDelPtrn(true);

  const closeDelPtrn = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret)deletePattern();
    // console.log(`delete the pattern: ${ret}`);
    setDelPtrn(false);
  }



  // eslint-disable-next-line no-unused-vars
  const test = (e) => {
    // console.log(pattern);
    // console.log(patternItems);
  }

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <ConfirmDialog 
        open={inUse}
        onClose={closeInUse}
        title={ getLabel('common_info') }
        msg={ getLabel('common_inuse_meg') }
        confirm={ getLabel('common_confirm') }
      />

      <ConfirmDialog 
        open={delPtrn}
        onClose={closeDelPtrn}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <Grid container>
        <Grid item sm={6}>
          <Box sx={{ mx:2, px:0 }}>
            <h4>{getLabel('defective_types')}</h4>
          </Box>

          <Box color="text.primary" style={{ height: `calc(45vh)` }} sx={{ mx:2, mb:1, px:0 }}>
            <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
              { !aggDestroyed &&
              <AgGridReact
                defaultColDef={defaultColDef}
                // columnDefs={columnDefs}
                rowData={recordList}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                onFirstDataRendered={updateGridHeaders}
              >
              </AgGridReact>
              }
            </div>
          </Box>

          <Box sx={{ mx:2, px:0 }}>
            <h4>{editState===0?getLabel('defective_info')+" - "+getLabel('common_new'):getLabel('defective_info')+" - "+getLabel('common_update')}</h4>
          </Box>

          <Paper sx={{ mx:2, my:2, p:1 }}>
            <Grid container>
              <Grid item xs={12}>
                <Stack>
                  <TextField sx={{ mx:1, mb:1 }} id="name_en" label={getLabel('defective_name') + ' (EN)'} variant="standard" size="small" value={recordData.name_en} 
                    onChange={(e) => setRecordData({...recordData, name_en:e.target.value})}
                    helperText={ helpers.name_en } error={helpers.name_en.length>0?true:false} />
                  <TextField sx={{ mx:1, mb:1 }} id="name_th" label={getLabel('defective_name') + ' (TH)'} variant="standard" size="small" value={recordData.name_th} 
                    onChange={(e) => setRecordData({...recordData, name_th: e.target.value})}
                    helperText={ helpers.name_th } error={helpers.name_th.length>0?true:false} />
                  <TextField sx={{ mx:1, mb:1 }} id="name_jp" label={getLabel('defective_name') + ' (JP)'} variant="standard" size="small" value={recordData.name_jp} 
                    onChange={(e) => setRecordData({...recordData, name_jp: e.target.value})}
                    helperText={ helpers.name_jp } error={helpers.name_jp.length>0?true:false} />
                </Stack>
              </Grid>
            </Grid>

            <Box sx={{ display:'flex', py:1, px:1 }}>
              <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => newRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('defective_new')}</Button>
              <Box sx={{ flexGrow: 1 }}/>
              <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => openConfirm()} disabled={editState===0?true:false} disableElevation>{getLabel('defective_delete')}</Button>
              <Button sx={{ mr:0 }} size='small' variant="contained" disabled={isDisabled} onClick={() => updateRecord()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
            </Box>
          </Paper>
        </Grid> 

        <Grid item sm={6}>
          <Box sx={{ mx:2, px:0 }}>
            <h4>{getLabel('defective_pattern_list')}</h4>
          </Box>

          <Paper sx={{ p:2, mr:2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Stack>
                <TextField sx={{ mx:1, mb:1 }} id="name" label={getLabel('defective_newpattern_name')} variant="standard" size="small" value={pattern.name} 
                  onChange={(e) => setPattern({...pattern, name: e.target.value}) } />
                </Stack>
              </Grid>
            </Grid>
            
            <Box sx={{ display:'flex' }}>
              <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => newPattern()} disabled={editPtrn===0?true:false} disableElevation>{getLabel('defective_newpattern_btn')}</Button>
              {/* <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => test()} >test</Button> */}
              <Box sx={{ flexGrow: 1 }}/>
              <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => openDelPtrn()} disabled={editPtrn===0?true:false} disableElevation>{getLabel('defective_pattern_delete')}</Button>
              <Button sx={{ mr:0 }} size='small' variant="contained" disabled={isDisabled} onClick={() => updatePattern()} disableElevation>{editPtrn===0?getLabel('common_save'):getLabel('common_update')}</Button>
            </Box>
          </Paper>

          { (patterns!==null && tab!==null) &&
          <Tabs scrollButtons="auto" variant="scrollable" sx={{ mr:2, mt:2 }} value={tab} onChange={tabChanged}>
            { patterns?.map((v,i) => {
              return (
                <Tab key={i} label={v.name} value={v.id}/>
              );
            })}
          </Tabs>
          }

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
              <List id="patternList" onDragOver={dragOver} onDrop={drop}
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ mb:2, mr:2, p:1, border:1, borderRadius:"0px", height:'56vh',
                // overflow:true?'scroll':'initial',
                overflow:'auto', borderColor:'#CCC',
                backgroundColor:snapshot.isDraggingOver?'#EEE':'#FFF' }}
              >
                {patternItems?.map((v, i) => (
                  <Draggable key={v.id} draggableId={v.id.toString()} index={i}>
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{ backgroundColor:snapshot.isDragging?'primary.light':'', borderBottom: '1px solid #CCC' }}
                        dense
                      >
                        <DragIndicatorIcon fontSize="small" sx={{ width:'30px',mr:1}}/>
                        <ListItemText sx={{width:'30%', textAlign:'start'}}>{v.name_en}</ListItemText>
                        <ListItemText sx={{width:'30%', textAlign:'start'}}>{v.name_th}</ListItemText>
                        <ListItemText sx={{width:'30%', textAlign:'start'}}>{v.name_jp}</ListItemText>
                        <ListItemButton onClick={() => deletePatternItem(v.id)} disableRipple><ClearIcon fontSize="small"/></ListItemButton>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
              )}
            </Droppable>
          </DragDropContext>


        </Grid> 
      </Grid>

    </Box>
  );
}
