import React from 'react';
import { Box, Button, Grid, Backdrop, CircularProgress, Menu, MenuItem } from '@mui/material';
import { Paper, Stack, Typography, TextField, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from "./ConfirmDialog";
import TranslateIcon from '@mui/icons-material/Translate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function Product({ prop }) {
  let navigate = useNavigate();
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [ctime, setCtime] = React.useState();
  const [processes, setProcesses] = React.useState();
  const [clients, setClients] = React.useState();
  const [products, setProducts] = React.useState();
  const [machines, setMachines]  = React.useState();

  const [filtered, setFiltered] = React.useState();
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemspp, setItemspp] = React.useState(8);
  const [items, setItems] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [images, setImages] = React.useState([]);
  const [filter, setFilter] = React.useState('');

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    if(!siteRef.current.authPassed)navigate(`/${config.account}/signin`, { replace: true });
    loadProcesses();
    loadClients(status.processes);
    loadMachinesByProcess(status.processes);
    if(config[config.account].iforder===0)loadProducts(status.processes, status.companies);
    if(config[config.account].iforder===1)loadProductsByMachine(status.processes, status.machines);
    loadTemp();
    if(dps==='xs')setCtime(getCurrentTime(true));
    else setCtime(getCurrentTime(true) + ', ' + getCurrentDate());
    let interval = setInterval(() => {
      const time = dps==='xs'?getCurrentTime(true):getCurrentTime(true) + ', ' + getCurrentDate();
      setCtime(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(() =>{
    if(status.back) {
      if(config[config.account].iforder===0)loadProducts(status.processes, status.companies);
      if(config[config.account].iforder===1)loadProductsByMachine(status.processes, status.machines);
      setStatus(status => ({...status, back:false}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status.back])

  const loadProcesses = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/processes_lang`;
    const lang = config[config.account].language==='jp'?'name':('name_' + config[config.account].language);
    setLoading(true);
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({lang:lang, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      setProcesses(data);
      setLoading(false);
    });
  }

  const loadClients = (process_id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/companies_process`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:process_id, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setClients(data);
      setLoading(false);
    });
  }

  // const [ condProducts, setCondProducts] = React.useState({len:0, dvn:0, rem:0});

  const loadProducts = async(process_id, company_id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/products_process_company_imagepath`;
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ process:process_id, company:company_id, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setProducts(data);
      // let len = data.length;
      // let dvn = parseInt(len / 4);
      // const rem = len % 4;
      // if(rem > 0)dvn++;
      // setCondProducts({...condProducts, len:len, dvn:dvn, rem:rem });
      setLoading(false);
    });
  }

  const loadProductsByMachine = async(process_id, machine_id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/products_process_machine_imagepath`;
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ process:process_id, machine:machine_id, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setProducts(data);
      // let len = data.length;
      // let dvn = parseInt(len / 4);
      // const rem = len % 4;
      // if(rem > 0)dvn++;
      // setCondProducts({...condProducts, len:len, dvn:dvn, rem:rem });
      setLoading(false);
    });
  }

  const loadMachines = (production_id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/machine_production`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:production_id, auth:config[config.account].auth}) })
    .then(res => res.json())
    .then(data => {
      setMachines(data);
      setLoading(false);
    });
  }

  const [machinesproc, setMachinesproc] = React.useState();

  const loadMachinesByProcess = (process_id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/machineprocess`;
    setLoading(true);
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ process:process_id, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      setMachinesproc(data);
      setLoading(false);
    });
  }

  const getCurrentDate = (separator='-') => {
    let mname = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let newDate = new Date()
    let date = newDate.getDate();
    let month = mname[newDate.getMonth()];
    let year = newDate.getFullYear();
    return `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`;
  }

  const getCurrentTime = (seconds=false) => {
    let newDate = new Date()
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${hrs<10?`0${hrs}`:`${hrs}`}:${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `:${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const moveTop = () => {
    setStatus(status => ({...status, processes:'', companies:'', products:'', filter:'' }));
    navigate(`/${config.account}/`, { replace: true });
  }

  const moveCustomer = () => {
    setStatus(status => ({...status, companies:'', products:'' }));
    navigate(`/${config.account}/customer`, { replace: true });
  }

  const moveMachine = () => {
    setStatus(status => ({...status, companies:'', products:'', filter:'' }));
    navigate(`/${config.account}/machine`, { replace: true });
  }

  const updateProducts = (val) => {
    const id = val.target.id;
    if(config[config.account].iforder === 0) {
      loadMachines(id);
      setAnchorEl(val.currentTarget);
      setMachineSelect(true);
      setStatus(status => ({...status, products:id }));
    } else {
      setStatus(status => ({...status, products:id }));
      navigate(`/${config.account}/production`, { replace:true });
    }
  }

  const selectProduction = (id) => {
    setMachineSelect(false);
    setStatus(status => ({...status, products:id }));
    navigate(`/${config.account}/production`, { replace:true });

  }

  const [machineSelect, setMachineSelect ] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const closeMachineSelect = () => {
    setMachineSelect(false);
  }

  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  })
  // eslint-disable-next-line no-unused-vars
  const [dps, setDps] = React.useState(getDisplaySize());

  const test = (e) => {
    // console.log(items);
    // console.log(status);
    // console.log(config);
    // console.log(products);
    // console.log(machinesproc);
    // setAnchorEl(e.currentTarget);
    // setMachineSelect(true);
    // console.log(condProducts);
    // console.log(status);
    // console.log(getCurrentDate('/'));
    // console.log(getCurrentTime(true));
  }

  const [temp, setTemp] = React.useState();
  const loadTemp = async() => {
    const tempFile = 'monthly.xlsx';
    // fetch(tempFile, {mode:'no-cors'})
    // .then(res => res.arrayBuffer())
    // .then(buf => {
    //   const data = new Uint8Array(buf);
    //   setTemp(data);
    // })
    fetchTemplate(tempFile);
  }

  const fetchTemplate = async(filename) => {
    const args = { auth:config[config.account]?.auth, filename:filename };
    const url = `${config[config.account]?.url}:${config[config.account]?.port}/api/template`;
    if(!config || !config[config.account]?.auth)return;
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(args) })
      .then(res => res.arrayBuffer()) // response by array buffer
      .then(data => {
      setTemp(data);
    });
  }

  const [noSummary, setNoSummary] = React.useState(false);
  const openNoSummary = (e) => {
    setNoSummary(true);
  }
  const closeNoSummary = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) {
    }
    setNoSummary(false);
  }

  const [mreport, setMreport] = React.useState(null);
  React.useEffect (() => {
    if(mreport && mreport.length > 0) {
      // console.log(mreport);
      outputReportByMachine(status.machine);
    } else {
      if(mreport!==null)openNoSummary();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mreport]);

  const requestReportByMachine = (machine_id, year, month) => {
    const date_from = `${year}-${month<10?`0${month}`:`${month}`}-01 00:00:00`;
    const mend = new Date(year, month, 0).getDate();
    const date_to = `${year}-${month<10?`0${month}`:`${month}`}-${mend} 23:59:59`;
    const url = `${config[config.account]?.url}:${config[config.account]?.port}/api/summary_machine`;
    setLoading(true);
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ from:date_from, to:date_to, machine:machine_id, auth:config[config.account]?.auth })})
    .then(res => res.json())
    .then(data => {
      setMreport(data);
      // console.log(data);
      setLoading(false);
    });
  }

  const getTimeStamp = (seconds=false) => {
    let newDate = new Date()
    let yar = newDate.getFullYear();
    let mth = newDate.getMonth()+1;
    let dte = newDate.getDate();
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${yar}${mth<10?`0${mth}`:`${mth}`}${dte<10?`0${dte}`:`${dte}`}${hrs<10?`0${hrs}`:`${hrs}`}${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const outputReportByMachine = async(machine_id) => {
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(temp);
    const filename = 'monthly_' + getTimeStamp(true) + '.xlsx';
    workbook.creator = 'F-1 Tools';
    workbook.lastModifiedBy = 'F-1 Tools';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.calcProperties.fullCalcOnLoad = true;
    let worksheet = workbook.getWorksheet(1);
    // -------------------- copy sheets --------------------
    const rowmax = 26;
    const pages = Math.ceil(mreport.length / rowmax);
    if(pages>1) {
      for(var i=2; i<pages+1; i++) {
        let newsheet = workbook.addWorksheet(`Sheet${i}`);
        newsheet.model = Object.assign(worksheet.model, {
          mergeCells: worksheet.model.merges,
        });
        newsheet.name = `Sheet${i}`;
        const border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} }; 
        for(let i=2; i<17; i++) {
          newsheet.getCell(4, i).border = border;
          newsheet.getCell(5, i).border = border;
        }
      }
    }
    // -------------------- plot data --------------------
    for(var p=0; p<pages; p++) {
      worksheet = workbook.getWorksheet(p+1);
      let ridx = 2;
      let row = worksheet.getRow(ridx);
      row.getCell(2).value = mreport[0].machine_name + ' / ' + mreport[0].machine_type;
      ridx = 7;
      for(var r=0; r<rowmax; r++) {
        let v=mreport[r+(p*26)];
        if(!v)break;
        row = worksheet.getRow(ridx++);
        row.getCell(2).value = v.fentry1;
        const dt = v.timest.slice(0, 10);
        row.getCell(3).value = dt.slice(-2) + '/' + dt.slice(5,7) + '/' + dt.slice(2,4); 
        row.getCell(4).value = v.product_name; 
        row.getCell(5).value = v.product_model;
        row.getCell(6).value = v.seqname;
        const st = v.timest.slice(-8);
        row.getCell(7).value = st.slice(0,5);
        const ed = v.timeed.slice(-8);
        row.getCell(8).value = ed.slice(0,5);
        row.getCell(9).value = v.target;
        row.getCell(10).value = v.qualified;
        if(v.unqualified !== 0)row.getCell(11).value = v.unqualified;
        row.getCell(12).value = v.qualified + v.unqualified;
        row.getCell(13).value = v.fentry2;
        row.getCell(14).value = v.sign1;
        row.getCell(15).value = v.sign2;
        row.getCell(16).value = v.sign3;
      }
    }
    // -------------------- flush buffer to file --------------------
    const uint8Array = await workbook.xlsx.writeBuffer(); // workbook.csv.writeBuffer()
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const download = require('downloadjs');
    download(blob, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); //application/vnd.ms-excel
  }

  const [reportSelect, setReportSelect] = React.useState(false);
  const [anchorReport, setAnchorReport] = React.useState(null);
  const [monthList, setMonthList] = React.useState([]);
  const openReportSelect = (e) => {
    const dt = new Date();
    setMonthList([]);
    for(let i=0; i<12; i++) {
      const dn = new Date(dt.getFullYear(), dt.getMonth()-i, dt.getDate());
      setMonthList(monthList => ([...monthList, { month:dn.getMonth()+1, year:dn.getFullYear() } ]));
    }
    setAnchorReport(e.currentTarget);
    setReportSelect(true);
  }
  const closeReportSelect = (e, yr, mn) => {
    if(yr!==undefined&&mn!==undefined) {
      requestReportByMachine(status.machines, yr, mn);
    }
    setReportSelect(false);
  }

  React.useEffect(() => {
    if(products !== undefined) {
      setFiltered(products);

      if(status?.filter) {
        // console.log(`filter: ${status.filter}`);
        setFilter(status.filter);
        updateFilteredList(status.filter);
      }
  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  React.useEffect(() => {
    if(filtered !== undefined) {
      updatePage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered]);

  React.useEffect(() => {
    if(products !== undefined) {
      updatePage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const pageNext = () => {
    setPage(v => (v<pages?v+1:pages));
  }

  const pagePrev = () => {
    setPage(v => (v>1?v-1:1));
  }

  const updatePage = () => {
    setImages([]);
    setPages(Math.ceil(filtered.length / itemspp));
    setItems(filtered.slice((page-1) * itemspp, page * itemspp));
  }

  React.useEffect(() => {
    if(items !== null) {
      items.forEach((item) => {
        if(item.image.length > 0) {
          checkImage(item.pid, item.id);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const checkImage = async(id, rid) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/checkfile`;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:id, auth:config[config.account].auth })})
    .then(res => res.blob())
    .then(data => {
      if(data.type.match(/^image\//g)) {
        const imageURL = URL.createObjectURL(data);
        // console.log(`${rid}, ${id}, ${imageURL}`);
        items.map((v) => {
          if(v.id===rid)v.image = imageURL;
          return 0;
        });
        // items.map((v) => (v.id===rid?{...v, image:imageURL}:v));
        setImages(p => [...p, { id: rid, pid:id, image: imageURL }]);
      }
    });
  }

  const updateFilter = (e) => {
    const value = e.target.value; 
    setFilter(value);
    setStatus(status => ({...status, filter:value}));
    // console.log(value);

    if(value.length !== 0) {
      let newlist = [];
      products.map((v) => {
        const v1 = v.model?.includes(value);    
        const v2 = v.name?.includes(value);    
        const v3 = v.seqname?.includes(value);    
        if(v1 || v2 || v3) {
          // console.log(`${v.model}, ${v.name}, ${v.seqname}`);
          newlist.push(v);
        }
        return null;
      });
      setFiltered(newlist);
      // if(page > pages)setPage(pages);
    } else setFiltered(products);
  }

  const updateFilteredList = (value) => {
    if(value.length !== 0) {
      let newlist = [];
      products.map((v) => {
        const v1 = v.model?.includes(value);    
        const v2 = v.name?.includes(value);    
        const v3 = v.seqname?.includes(value);    
        if(v1 || v2 || v3) {
          // console.log(`${v.model}, ${v.name}, ${v.seqname}`);
          newlist.push(v);
        }
        return null;
      });
      setFiltered(newlist);
      // if(page > pages)setPage(pages);
    } else setFiltered(products);
  }

  React.useEffect(() => {
    // if((pages > 0) && (page > pages))setPage(pages);
    if(page > pages)setPage(pages===0?1:pages);
  }, [page, pages]);

  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Menu id="select-report" open={reportSelect} onClose={closeReportSelect} anchorEl={anchorReport}>
        { monthList.map((v) =>
        <MenuItem key={v.month} sx={{ py:1 }} onClick={(e) => closeReportSelect(e, v.year, v.month)}>{ v.month<10?`0${v.month}`:`${v.month}` }/{ v.year }</MenuItem>
        )}
      </Menu>

      <ConfirmDialog 
        open={noSummary}
        onClose={closeNoSummary}
        title={ getLabel('report_dialog_download') }
        msg={ getLabel('report_dialog_nodata') }
        confirm={ getLabel('common_confirm') }
      />

      { machines &&
      <Menu id="select-machine" open={machineSelect} onClose={closeMachineSelect} anchorEl={anchorEl}>
        {/* <MenuItem component="Typography" variant="h4">test</MenuItem> */}
        <Typography sx={{ p:1, pl:2, fontSize:'large' }}>{ getLabel('entry_message_machine') }</Typography>
        { machines.map((val) =>
        <MenuItem key={val.id}><Button variant="contained" sx={{ fontSize:'large' }} onClick={() => selectProduction(val.id)}>{ val.maname }: { val.matype }</Button></MenuItem>
        )}
      </Menu>
      }  

      <Box sx={{ p:2, pb:1, display:'flex', width:'100%' }}>
        <Button variant="contained" onClick={moveTop}>{ getLabel('entry_select_process') }</Button>
        { config[config.account].iforder===0?<Button sx={{ ml:1 }} variant="contained" onClick={moveCustomer}>{ getLabel('entry_select_customer') }</Button>:''}
        { config[config.account].iforder===1?<Button sx={{ ml:1 }} variant="contained" onClick={moveMachine}>{ getLabel('entry_select_machine') }</Button>:''}
        { config[config.account].iforder===1?<Button sx={{ ml:1 }} variant="contained" onClick={openReportSelect}>{ getLabel('entry_report_machine') }</Button>:''}
        <Box sx={{ flexGrow: 1 }}/>
          <IconButton
            color="inherit"
            sx={{ mr: 1 }}
            onClick={languageClick}
          >
            <TranslateIcon fontSize="small"/>
          </IconButton>
          <Menu
            anchorEl={anchorLanguage}
            open={selectLanguage}
            onClose={languageClose}
          >
            <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account].language==='jp'?true:false}>日本語</MenuItem>
            <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account].language==='en'?true:false}>English</MenuItem>
            <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account].language==='th'?true:false}>ไทย</MenuItem>
          </Menu>

          <Button variant="outlined" onClick={test} id="test">{ ctime }</Button>
      </Box>

      {/* <Box sx={{ width:'100%', height:'30vh' }}/> */}

      <Box sx={{ p:2, pb:0, display:'flex', width:'100%' }}>
        <Paper component={Stack} direction="column"
          sx={{ height:'6vh', width:'100%', p:1, textAlign:'center', justifyContent:'center', background:theme.palette.primary.main }}>
          <Typography variant="h6" color={theme.palette.primary.contrastText}>
            { processes && status && processes.find(v => v.id === Number(status.processes)).name}
          </Typography>
        </Paper>
      </Box>

      { config && config[config.account].iforder === 0?
      <Box sx={{ p:2, pb:1, display:'flex', width:'100%' }}>
      <Paper component={Stack} direction="column"
        sx={{ height:'6vh', width:'100%', p:1, textAlign:'center', justifyContent:'center', background:theme.palette.primary.main }}>
        <Typography variant="h6" color={theme.palette.primary.contrastText}>
          { clients && status && clients.find(v => v.id === Number(status.companies)).abbr}
        </Typography>
      </Paper>
      </Box>
      :''}

      { config && config[config.account].iforder === 1?
      <Box sx={{ p:2, pb:1, display:'flex', width:'100%' }}>
        <Paper component={Stack} direction="column"
          sx={{ height:'6vh', width:'100%', p:1, textAlign:'center', justifyContent:'center', background:theme.palette.primary.main }}>
          <Typography variant="h6" color={theme.palette.primary.contrastText}>
            { machinesproc && status && machinesproc.find(v => v.id === Number(status.machines)).name}&nbsp; / &nbsp; 
            { machinesproc && status && machinesproc.find(v => v.id === Number(status.machines)).type}
          </Typography>
        </Paper>
      </Box>
      :''}

      <Box sx={{ width:'100%', textAlign:'center' }}>
        <Typography variant="h5" sx={{ m:2 }}>{ getLabel('entry_message_parts') }</Typography>
      </Box>

      <Box>
        <Box sx={{ m:1 }}>
          <Box>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Typography sx={{ ml:2 }}>{ getLabel('common_keyword_filter') }</Typography>
              <TextField variant="outlined" value={filter} onChange={updateFilter} inputProps={{ style: { height:4 } }} sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'primary.main',
                    opacity: 0.5,
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                    opacity: 1,
                  },
                },
                color: 'primary.contrastText',
                mr: 1,
                ml: 2,
              }} />
            </Grid>
      </Box>

          <Button sx={{
            height:'10vh', width:'10px', zIndex:100,
            position: "absolute",
            top: '550px',
            left: '8px',
            color: 'primary.light',
            opacity: '1',
            '& .MuiSvgIcon-root': { fontSize: 45 },
          }} onClick={pagePrev}
          disabled={ page<=1?true:false }
          ><ArrowBackIosIcon/></Button>

          <Button sx={{
            height:'10vh', width:'10px', zIndex:100,
            position: "absolute",
            top: '550px',
            right: '0px',
            color: 'primary.light',
            opacity: '1',
            '& .MuiSvgIcon-root': { fontSize: 45 },
          }} onClick={pageNext}
          disabled={ page>=pages?true:false }
          ><ArrowForwardIosIcon/></Button>

        </Box>

        <Box>
          <Grid container spacing={2} sx={{ p:2 }}>
            {items?.map((v, i) =>
              <Grid key={i} item xs={12} sm={6}><Button id={v.id} variant="outlined"
              sx={{
                height:'12vh', fontSize:'medium', width:'100%',
                backgroundImage: `url('${v.image}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '9vh',
                backgroundPosition: '0.5vh',
                }}
                onClick={updateProducts}
                >{v.model}<br/>{v.name}<br/>{v.seqname}</Button></Grid>
            )}
        </Grid>
        </Box>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography>{page} / {pages}&nbsp;&nbsp;({filtered?.length} { getLabel('common_items') })</Typography>
          {/* <Button variant="outlined" sx={{ mr:1 }} onClick={test}>Page: {page}</Button>
          <Button variant="outlined" sx={{ mr:1 }} onClick={test}>Pages: {pages}</Button>
          <Button variant="outlined" sx={{ mr:1 }} onClick={test}>Total: {filtered?.length}</Button> */}
      </Grid>

      </Box>

    </Box>
  );
}

