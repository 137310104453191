import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, 
  Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

export default function TextInput(props) {
  const { onClose, value: valueProp, open, title="Edit the text",
    ok_text="OK", cancel_text="CANCEL", ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const textRef = React.useRef();
  const okRef = React.useRef();
  const cancelRef = React.useRef();

  React.useEffect(() => {
    if(open) {
      setValue(valueProp);
      textRef.current.focus();
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  function limitStrLen(str,limit) {
    return str.length<=limit? str:str.substring(0,limit);
  }

  const handleChange = (event) => {
    setValue(limitStrLen(event.target.value,255));
  };

  const keyEvents = (event) => {
    if(event.target.id === 'txtedit' && event.keyCode === 13) {
      okRef.current.focus();
    }
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent dividers>
      <TextField id="txtedit" inputRef={textRef}
        size="small" multiline
        onChange={handleChange}
        onKeyDown={keyEvents}
        value={value}
        sx={{ width:"100%"}}
      ></TextField>
      </DialogContent>
      <DialogActions>
        <Button id="btnok" variant='contained' ref={okRef} onKeyDown={keyEvents} onClick={handleOk}>{ ok_text }</Button>
        <Button id="btncancel" variant='outlined' ref={cancelRef} onKeyDown={keyEvents} onClick={handleCancel}>{ cancel_text }</Button>
      </DialogActions>
    </Dialog>
  );
}

TextInput.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};
