import * as React from 'react';
import { Box, Button, Typography, List, ListItem, Divider, Grid, Dialog, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


export default function UserListDialog(props) {
  const { onSelect, onClose, open, title, msg, users, confirm, cancel, type, searchText } = props;
  const userRef = React.useRef();
  const [userList, setUserList] = React.useState(users);
  React.useEffect(() => {
    userRef.current = users;
    setUserList(users)
    document.getElementById('searchInput')?.focus();
  },[users])

  const handleSearch = (e) => {
    let filter = userRef.current.filter(data => (data.username.toLowerCase()).includes(e.target.value.toLowerCase()));
    setUserList(filter);
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <Grid container sx={{ p:1 }}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ m:1 }}>{ title }</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ m:1 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6"sx={{ m:1 }}>{ msg }</Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchIcon fontSize={"large"} color={'primary'} sx={{ ml: 2 }}/>
          <TextField sx={{ mx:1, height:'3vh', width:'50%'}} variant='standard' color='primary' type="search" 
            id='searchInput' placeholder={searchText} onChange={handleSearch}/>
        </Grid>

        <Box sx={{ width:'100%', height:'40vh', overflow:'auto', m:1 }}>
          <List component="div" role="list">
          { userList && userList.map((v,i) => (
            <ListItem key={i} button role="listitem" data-type={type} onClick={(e) => onSelect(e, v.userid)}>{v.username}</ListItem>
          )) }
          </List>
        </Box>

        <Grid item xs={12}>
          <Divider sx={{ m:1 }} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ m:1, mr:2, display:'flex' }}>
            { !cancel && 
              <Box sx={{ flexGrow: 1 }}/>
            }
            { confirm &&
            <Button data-type={type} data-return={true} variant="contained" onClick={onClose} sx={{ ml:1 }}>{ confirm }</Button>
            }
            <Box sx={{ flexGrow: 1 }}/>
            { cancel &&
            <Button variant="contained" onClick={onClose} sx={{ ml:1 }}>{ cancel }</Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}