import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Box, Button, Backdrop, CircularProgress, IconButton, Grid, Stack, Typography, Divider  } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import { systemStatus } from "../../atoms/systemStatus";
// eslint-disable-next-line no-unused-vars
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import locale_en from 'date-fns/locale/en-US';
import locale_ja from 'date-fns/locale/ja';
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";

export default function WorstlistDialog(props) {
  const [record] = React.useState(props.recordData);
  const [condition] = React.useState(props.condition);
  const [column] = React.useState(props.column);
  const [open] = React.useState(props.open);
  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [dbconfig, setDbconfig] = React.useState(null);

  const [datefr, setDatefr] = React.useState(null);
  const [dateto, setDateto] = React.useState(null);
  const [datefrp, setDatefrp] = React.useState(null);
  const [datetop, setDatetop] = React.useState(null);

  const [gridData1, setGridData1] = React.useState([]);

  if(locale_en && locale_ja) {
    locale_en.options.weekStartsOn = 0;
    locale_ja.options.weekStartsOn = 0;
  }

  const getPeriods = (cond) => {
    let fdate = new Date(cond.datefr);
    let tdate = new Date(cond.dateto);
    setDatefr(new Date(cond.datefr));
    setDateto(new Date(cond.dateto));
    if(cond.period===3) {
      fdate = new Date(fdate.setMonth(fdate.getMonth() - 1));
      tdate = new Date(fdate.getFullYear(), fdate.getMonth()+1, 0);
      setDatefrp(fdate);
      setDatetop(tdate);
    } else if(cond.period===5) {
      fdate = new Date(fdate.setFullYear(fdate.getFullYear() - 1));
      tdate = new Date(fdate.getFullYear(), 12, 0);
      setDatefrp(fdate);
      setDatetop(tdate);
    }
  }

  // eslint-disable-next-line no-unused-vars
  const titleStyle = () => ({
    width:'98%',
    textAlign: 'center',
    fontSize: '0.875rem',
    lineHeight: '1.9',
    px:1.2, py:0.6,
    border: '1px solid primary.main',
    borderRadius: '4px',
    color: 'primary.contrastText',
    bgcolor: 'primary.main',
  });

  // eslint-disable-next-line no-unused-vars
  const alleftStyle = () => ({
    width:'95%',
    textAlign: 'left',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    px:1.2, py:0.6,
    border: '1px solid',
    borderRadius: '4px',
    color: 'primary.main',
  });

  // eslint-disable-next-line no-unused-vars
  const alrightStyle = () => ({
    width:'95%',
    textAlign: 'right',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    px:1.2, py:0.6,
    border: '1px solid',
    borderRadius: '4px',
    color: 'primary.main',
  });

  // eslint-disable-next-line no-unused-vars
  const almiddleStyle = () => ({
    width:'95%',
    textAlign: 'center',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    px:1.2, py:0.6,
    border: '1px solid',
    borderRadius: '4px',
    color: 'primary.main',
  });

  const getConfigs = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    // setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      // setLoading(false);
      // console.log(`setLoading - false`);
    });
  }

  // eslint-disable-next-line no-unused-vars
  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    getConfigs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(open) {
      // console.log(`open: ${open}`);
      // console.log(record);
      // console.log(condition);
      // setColumn(column.filter(v => (v.field !== 'detail')));
      // setGridData1([record]);
      // setGridData2([record]);
      getPeriods(condition);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const loadHistory = async() => {
    await getWorstHistory();
  }

  React.useEffect(() => {
    if(datefr !== null && dateto !== null && datefrp !== null && datetop !== null) {
      // console.log(`${datefr}\n${dateto}\n${datefrp}\n${datetop}`);
      // console.log(condition.match);
      if(condition.category===1) {
        // console.log(`unqualified`);
        // getTopUnqualified();
        // getWorstList(true);
        getWorstDefective(true);
        getWorstDefective(false);
        loadHistory();
      } else if(condition.category===2) {
        // console.log(`efficiency`);
        // getWorstEfficiency();
        // getWorstList(false);
        loadHistory();
      }
      // getWorstDefective(true);
      // getWorstDefective(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datefr, dateto, datefrp, datetop]);

  const getDispDate = (dte) => {
    if(dte) {
      let tmpd = `( ${dte.toLocaleString("en-US", { month: "short" })} / ${dte.getFullYear()} )`; 
      if(condition.period===5)tmpd = `( ${dte.getFullYear()} )`; 
      return tmpd;
    }
    return '';
  }

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

// -------------------- getting the reports  

  // const getWorstList = async(type) => {
  //   setLoading(true);
  //   const groupkey = condition.group; 
  //   const datefr = `${datefrp.toLocaleDateString('sv-SE')} 00:00:00`;
  //   const dateto = `${datetop.toLocaleDateString('sv-SE')} 23:59:59`;
  //   const limit = condition.limit;
  //   // setColumns();
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/worst_list`;
  //   await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ auth:config[config.account].auth, group:groupkey, datefr:datefr, dateto:dateto, type:type ,limit:limit })})
  //   .then(res => res.json())
  //   .then(data => {
  //     // console.log(data);
  //     const val = record[condition.match];
  //     setGridData1(data.filter(v => (v[condition.match] === val)));
  //     setLoading(false);
  //   });
  // }

  const getWorstHistory = async() => {
    setLoading(true);
    const period = condition.period===3?true:false;
    const group = condition.history;
    const id = record[condition.match];
    const date = `${datefr.toLocaleDateString('sv-SE')} 00:00:00`;
    // setColumns();
    const url = `${config[config.account].url}:${config[config.account].port}/api/worst_history`;
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth, group:group, id:id, date:date, period:period })})
    .then(res => res.json())
    .then(data => {
      // data.reverse();
      setGridData1(data);
      setLoading(false);
    });
  }
  
  // const getTopUnqualified = async() => {
  //   setLoading(true);
  //   const groupkey = condition.group;
  //   const datefr = `${datefrp.toLocaleDateString('sv-SE')} 00:00:00`;
  //   const dateto = `${datetop.toLocaleDateString('sv-SE')} 23:59:59`;
  //   const limit = condition.limit;
  //   // setColumns();
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/top_unqualified`;
  //   await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ auth:config[config.account].auth, group:groupkey, datefr:datefr, dateto:dateto, limit:limit })})
  //   .then(res => res.json())
  //   .then(data => {
  //     const val = record[condition.match];
  //     setGridData1(data.filter(v => (v[condition.match] === val)));
  //     setLoading(false);
  //   });
  // }

  // const getWorstEfficiency = async() => {
  //   setLoading(true);
  //   const groupkey = condition.group;
  //   const datefr = `${datefrp.toLocaleDateString('sv-SE')} 00:00:00`;
  //   const dateto = `${datetop.toLocaleDateString('sv-SE')} 23:59:59`;
  //   const limit = condition.limit;
  //   // setColumns();
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/worst_efficiency`;
  //   await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify({ auth:config[config.account].auth, group:groupkey, datefr:datefr, dateto:dateto, limit:limit })})
  //   .then(res => res.json())
  //   .then(data => {
  //     const val = record[condition.match];
  //     setGridData1(data.filter(v => (v[condition.match] === val)));
  //     setLoading(false);
  //   });
  // }

// -------------------- getting defective info.  
  const getKeyRecord = () => {
    if(condition) {
      let key ='';
      for(key in record) {
        if(key === condition.match)return record[key];
      }
    }
    return null;
  }

  const [colDefectSel, setColDefectSel] = React.useState(null);
  const [colDefectPrev, setColDefectPrev] = React.useState(null);
  const [dataDefectSel, setDataDefectSel] = React.useState(null);
  const [dataDefectPrev, setDataDefectPrev] = React.useState(null);

  const getWorstDefective = async(selected=true) => {
    setLoading(true);
    const group = condition.defect;
    const key = getKeyRecord();
    let dfrom = `${datefrp.toLocaleDateString('sv-SE')} 00:00:00`;
    let dto = `${datetop.toLocaleDateString('sv-SE')} 23:59:59`;
    if(selected) {
      dfrom = `${datefr.toLocaleDateString('sv-SE')} 00:00:00`;
      dto = `${dateto.toLocaleDateString('sv-SE')} 23:59:59`;
    }
    // setColumns();
    const url = `${config[config.account].url}:${config[config.account].port}/api/worst_defective`;
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth, group:group, key:key, datefr:dfrom, dateto:dto })})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      const lang = config[config.account].language;
      if(selected) {
        setColDefectSel(data.map((v,i) => ({ headerName:lang==='th'?v.name_th:lang==='jp'?v.name_jp:v.name_en, field:`v${i}`,
        type:'rightAligned', valueFormatter:val => val.data[`v${i}`].toLocaleString() || '' })));
        setDataDefectSel(Object.assign({}, ...data.map((v, i) => { return {[`v${i}`]: v.total}; })));
      } else {
        setColDefectPrev(data.map((v,i) => ({ headerName:lang==='th'?v.name_th:lang==='jp'?v.name_jp:v.name_en, field:`v${i}`,
        type:'rightAligned', valueFormatter:val => val.data[`v${i}`].toLocaleString() || '' })));
        setDataDefectPrev(Object.assign({}, ...data.map((v, i) => { return {[`v${i}`]: v.total}; })));
      }
      // setLoading(false);
    });
  }

// ---------------------------------------------- AGGrid Related
  const [, setLocale] = React.useState();
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);
  const [, setGridApi] = React.useState({});
  const [, setGridColumnApi] = React.useState({});

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    setLocale(lang==='jp'?locale_ja:locale_en);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const defaultColDef = {
    resizable: true,
    sortable: false,
    filter: false,
  };


  const getColumns = () => {
    let cols = [...column];
    cols = cols.filter(v => (v.field !== 'detail'));
    cols.unshift({headerName: getLabel('worstlist_year'), field:'year', cellStyle: {textAlign: "center", verticalAlign: "middle"}});
    if(condition.period===3)cols.unshift({headerName: getLabel('worstlist_month'), field:'smonth',  cellStyle: {textAlign: "center", verticalAlign: "middle"}});
    return cols;
  }

  const gridOptionsPrev = {
    columnDefs: getColumns(),
    rowSelection: 'single',
    localeText: localeText,
  };

  const gridOptDefectPrev = {
    columnDefs: colDefectPrev,
    rowSelection: 'single',
    localeText: localeText,
  };

  // const gridOptionsSel = {
  //   columnDefs: column.filter(v => (v.field !== 'detail')),
  //   rowSelection: 'single',
  //   localeText: localeText,
  // };

  const gridOptDefectSel = {
    columnDefs: colDefectSel,
    rowSelection: 'single',
    localeText: localeText,
  };

  const updateGridHeaders = (params) => {
    params.api.sizeColumnsToFit();
    window.setTimeout(() => {
      const colIds = params.columnApi.getAllColumns().map(c => c.colId);
      params.columnApi.autoSizeColumns(colIds);
    }, 50);
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

// ----------------------------------------------
  const test = async() => {
    // console.log(record);
    // console.log(column);
    // console.log(condition);
    // console.log(gridData1);
    // console.log(getColumns());
    // await getWorstHistory(true);
    // console.log(`${condition.match}: ${getKeyRecord()}`);
    // console.log(colDefectSel);
    // console.log(dataDefectSel);
    // console.log(colDefectPrev);
    // console.log(dataDefectPrev);
    // console.log(defectList);
    // console.log(defects);
    // console.log(`section: ${record.section}`);
    // loadDefects();
    // console.log(datefr);
    // console.log(dateto);
    // console.log(datefrp);
    // console.log(datetop);
    // const ddd = new Date();
    // console.log(ddd.getUTCMonth());

  }

// ----------------------------------------------

  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ p:2, display:'flex', width:'100%' }}>
        <Button variant="outlined" sx={{ mr:1 }} onClick={test}><AccountCircleIcon sx={{ mr:1 }}/>{ config[config.account].username }</Button>
        <Box sx={{ flexGrow: 1 }}/>
        <IconButton color="inherit" onClick={props.onClose}>
        <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ display:'flex', width:'100%' }}>
        <Grid container sx={{ px:2 }}>

          <Grid item xs={12}>
            <Box sx={{ mt:0 }}>
              {/* <b>{condition.period===3?getLabel('worstlist_prev_months'):getLabel('worstlist_prev_years')}</b> */}
              <b>{condition.period===3?getLabel('worstlist_history'):getLabel('worstlist_history')}</b>
              {/* &nbsp;&nbsp;{getDispDate(datefrp)} */}
            </Box>
            <Box sx={{ my:1 }}>
                <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '320px', width: '100%'}}>
                  { !aggDestroyed &&
                    <AgGridReact
                      defaultColDef={defaultColDef}
                      rowData={gridData1}
                      gridOptions={gridOptionsPrev}
                      onGridReady={onGridReady}
                      onFirstDataRendered={updateGridHeaders}
                      suppressRowClickSelection={true}
                      suppressCellFocus={true}
                    />
                  }
                </div>
              </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my:2 }}/>
          </Grid>

          { colDefectPrev && colDefectPrev.length > 0 && condition.category===1 &&
          <Grid item xs={12}>
            <Box sx={{ mt:0 }}>
              {condition.period===3?`${getLabel('worstlist_prev_month')} ${getDispDate(datefrp)}`:getLabel('worstlist_prev_year')} - {getLabel('worstlist_defective')}
            </Box>
            <Box sx={{ my:1 }}>
                <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '110px', width: '100%'}}>
                  { !aggDestroyed && dataDefectPrev &&
                    <AgGridReact
                      defaultColDef={defaultColDef}
                      rowData={[dataDefectPrev]}
                      gridOptions={gridOptDefectPrev}
                      onGridReady={onGridReady}
                      onFirstDataRendered={updateGridHeaders}
                      suppressRowClickSelection={true}
                      suppressCellFocus={true}
                    />
                  }
                </div>
              </Box>
          </Grid>
          }

          {/* <Grid item xs={12}>
            <Box sx={{ mt:0 }}>
              <b>{condition.period===3?getLabel('worstlist_this_month'):getLabel('worstlist_this_year')}</b>
              &nbsp;&nbsp;{getDispDate(datefr)}
            </Box>
            <Box sx={{ my:1 }}>
                <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '110px', width: '100%'}}>
                  { !aggDestroyed &&
                    <AgGridReact
                      defaultColDef={defaultColDef}
                      rowData={gridData2}
                      gridOptions={gridOptionsSel}
                      onGridReady={onGridReady}
                      onFirstDataRendered={updateGridHeaders}
                      suppressRowClickSelection={true}
                      suppressCellFocus={true}
                    />
                  }
                </div>
              </Box>
          </Grid> */}

          { colDefectSel && colDefectSel.length > 0 && condition.category===1 && 
          <Grid item xs={12}>
            <Box sx={{ mt:0 }}>
              {condition.period===3?`${getLabel('worstlist_this_month')} ${getDispDate(datefr)}`:getLabel('worstlist_this_year')} - {getLabel('worstlist_defective')}
            </Box>
            <Box sx={{ my:1 }}>
                <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '110px', width: '100%'}}>
                  { !aggDestroyed && dataDefectSel &&
                    <AgGridReact
                      defaultColDef={defaultColDef}
                      rowData={[dataDefectSel]}
                      gridOptions={gridOptDefectSel}
                      onGridReady={onGridReady}
                      onFirstDataRendered={updateGridHeaders}
                      suppressRowClickSelection={true}
                    />
                  }
                </div>
              </Box>
          </Grid>
          }

          {/* <Grid item xs={12} md={6}>
            <Box>Grid Item</Box>
          </Grid> */}


        </Grid>
      </Box>
    </Box>
  );
}