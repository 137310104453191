import React from 'react';
import { Grid, Switch, Paper, Stack, Box, Backdrop, CircularProgress } from '@mui/material';
import {Typography, FormGroup, FormControlLabel, TextField, Select, MenuItem } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
// import id from 'date-fns/esm/locale/id/index.js';

export default function EditConfig() {

  const [config, setConfig] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  const [dbconfig, setDbconfig] = React.useState(null);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);
 
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const updateDBValue = (event, key) => {
    // const tmp = dbconfig.findIndex(data => data.id === key);
    // setDbconfig(data => ({...data[tmp], value:event.target.value}));
    const value = event.target.value;
    setDbconfig(dbconfig.map(obj => obj.id === key?{...obj, value:value}:obj));
  }

  const updateDBSwitch = (event, key) => {
    // const tmp = dbconfig.findIndex(data => data.id === key);
    // setDbconfig(data => ({...data[tmp], value:event.target.value}));
    const value = event.target.checked===true?'1':'0';
    setDbconfig(dbconfig.map(obj => obj.id === key?{...obj, value:value}:obj));
    updateConfig(key, value);
    if(key === 'workflow_order') {
      const val = parseInt(value, 10);
      if(val === 0 || val === 1) {
        // setConfig(config => ({...config, iforder:val}));
        setConfig(config => ({...config, [config.account]: {...config[config.account], iforder: val}}));
        // console.log(val);
      }
    }
  }

  const storeDBValue = (event, key) => {
    // const value = event.target.checked===true?'1':'0';
    // setDbconfig(dbconfig.map(obj => obj.id === key?{...obj, value:value}:obj));
    // updateConfig(key, value);
    const value = event.target.value;
    updateConfig(key, value);
  }

  // React.useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [labels]);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    getConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const test = () => {
    // console.log(dbconfig);
  }

  // const changeInputOrder = (e) => {
  //   const checked = e.target.checked;
  //   // console.log(checked);
  //   setConfig(config => ({...config, iforder:checked?1:0}));
    
  // }

  const getConfigs = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    // const rec = { production:status.products, qualified:numQualified, unqualified:numUnqualified, user:config.userid }
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      const df = data.find(v => v.id === 'date_format');
      if(df?.value) {
        setDftype(Number(df.value));
      }
      setLoading(false);
    });
  }

  const updateConfig = (id, value) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/update_config`;
    const arg = { id:id, value:value, auth:config[config.account].auth };
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(_data => {
      setLoading(false);
    });
  }

  const [dftype, setDftype] = React.useState(1);
  const dftypeChange = (e) => {
    const df = e.target.value; 
    setDftype(df);
    updateConfig('date_format', Number(df));
  }

  return (
    <Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('navigation_menu_config')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={6} md={6}>
            <Stack sx={{ m:1 }}>

              <Typography variant="subtitle1">{getLabel('config_date_format')}</Typography>
              <Select
                size="small"
                sx={{ mt:1, mb:2, mr:1, height:0.72 }}
                value={dftype}
                onChange={dftypeChange}
              >
                <MenuItem value={1}>{ `DD/MM/YYYY hh:mm` }</MenuItem>
                <MenuItem value={2}>{ `YYYY/MM/DD hh:mm` }</MenuItem>
              </Select>

              <Typography variant="subtitle1">{getLabel('config_workflow_order')}</Typography>
              <FormGroup sx={{ mb:2 }}>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'workflow_order')}
                checked={getDBValue('workflow_order')==='1'?true:false}/>}
                label=<div>{getLabel('config_workflow_on')}<br/>{getLabel('config_workflow_off')}</div>
                />
              </FormGroup>

              <Typography variant="subtitle1" sx={{ mt:1 }}>{getLabel('config_free_1')}</Typography>
              <FormGroup>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'free_entry_1')}
                  checked={getDBValue('free_entry_1')==='1'?true:false}/>} label=""/>
                <TextField size="small" label={getLabel('config_english')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_1_en')} 
                  value={ getDBValue('free_entry_1_en') } onChange={(e) => updateDBValue(e,'free_entry_1_en')}/>
                <TextField size="small" label={getLabel('config_japanese')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_1_jp')} 
                  value={ getDBValue('free_entry_1_jp') } onChange={(e) => updateDBValue(e,'free_entry_1_jp')}/>
                <TextField size="small" label={getLabel('config_thai')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_1_th')}  
                  value={ getDBValue('free_entry_1_th') } onChange={(e) => updateDBValue(e,'free_entry_1_th')}/>
              </FormGroup>
              <Typography variant="subtitle1" sx={{ mt:1 }}>{getLabel('config_free_2')}</Typography>
              <FormGroup>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'free_entry_2')}
                  checked={getDBValue('free_entry_2')==='1'?true:false}/>} label=""/>
                <TextField size="small" label={getLabel('config_english')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_2_en')} 
                  value={ getDBValue('free_entry_2_en') } onChange={(e) => updateDBValue(e,'free_entry_2_en')}/>
                <TextField size="small" label={getLabel('config_japanese')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_2_jp')} 
                  value={ getDBValue('free_entry_2_jp') } onChange={(e) => updateDBValue(e,'free_entry_2_jp')}/>
                <TextField size="small" label={getLabel('config_thai')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_2_th')} 
                  value={ getDBValue('free_entry_2_th') } onChange={(e) => updateDBValue(e,'free_entry_2_th')}/>
              </FormGroup>
              <Typography variant="subtitle1" sx={{ mt:1 }}>{getLabel('config_free_3')}</Typography>
              <FormGroup>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'free_entry_3')}
                checked={getDBValue('free_entry_3')==='1'?true:false}/>} label=""/>
                <TextField size="small" label={getLabel('config_english')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_3_en')} 
                  value={ getDBValue('free_entry_3_en') } onChange={(e) => updateDBValue(e,'free_entry_3_en')}/>
                <TextField size="small" label={getLabel('config_japanese')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_3_jp')} 
                  value={ getDBValue('free_entry_3_jp') } onChange={(e) => updateDBValue(e,'free_entry_3_jp')}/>
                <TextField size="small" label={getLabel('config_thai')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'free_entry_3_th')} 
                  value={ getDBValue('free_entry_3_th') } onChange={(e) => updateDBValue(e,'free_entry_3_th')}/>
              </FormGroup>
            </Stack>
          </Grid>
          <Grid item xs={6} md={6}>
            <Stack sx={{ m:1 }}>

              <Typography variant="subtitle1">{getLabel('config_approval')}</Typography>
              <FormGroup sx={{ mb:2 }}>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'approval_login')}
                checked={getDBValue('approval_login')==='1'?true:false}/>}
                label=<div>{getLabel('config_approval_on')}<br/>{getLabel('config_approval_off')}</div>
                />
              </FormGroup>

              <Typography variant="subtitle1" sx={{ mt:1 }}>{getLabel('config_approval_1')}</Typography>
              <FormGroup>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'approval_1')}
                  checked={getDBValue('approval_1')==='1'?true:false}/>} label=""/>
                <TextField size="small" label={getLabel('config_english')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_1_en')} 
                  value={ getDBValue('approval_1_en') } onChange={(e) => updateDBValue(e,'approval_1_en')}/>
                <TextField size="small" label={getLabel('config_japanese')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_1_jp')} 
                  value={ getDBValue('approval_1_jp') } onChange={(e) => updateDBValue(e,'approval_1_jp')}/>
                <TextField size="small" label={getLabel('config_thai')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_1_th')}  
                  value={ getDBValue('approval_1_th') } onChange={(e) => updateDBValue(e,'approval_1_th')}/>
              </FormGroup>
              <Typography variant="subtitle1" sx={{ mt:1 }}>{getLabel('config_approval_2')}</Typography>
              <FormGroup>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'approval_2')}
                  checked={getDBValue('approval_2')==='1'?true:false}/>} label=""/>
                <TextField size="small" label={getLabel('config_english')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_2_en')} 
                  value={ getDBValue('approval_2_en') } onChange={(e) => updateDBValue(e,'approval_2_en')}/>
                <TextField size="small" label={getLabel('config_japanese')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_2_jp')} 
                  value={ getDBValue('approval_2_jp') } onChange={(e) => updateDBValue(e,'approval_2_jp')}/>
                <TextField size="small" label={getLabel('config_thai')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_2_th')} 
                  value={ getDBValue('approval_2_th') } onChange={(e) => updateDBValue(e,'approval_2_th')}/>
              </FormGroup>
              <Typography variant="subtitle1" sx={{ mt:1 }}>{getLabel('config_approval_3')}</Typography>
              <FormGroup>
                <FormControlLabel control={<Switch onChange={(e) => updateDBSwitch(e,'approval_3')}
                checked={getDBValue('approval_3')==='1'?true:false}/>} label=""/>
                <TextField size="small" label={getLabel('config_english')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_3_en')} 
                  value={ getDBValue('approval_3_en') } onChange={(e) => updateDBValue(e,'approval_3_en')}/>
                <TextField size="small" label={getLabel('config_japanese')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_3_jp')} 
                  value={ getDBValue('approval_3_jp') } onChange={(e) => updateDBValue(e,'approval_3_jp')}/>
                <TextField size="small" label={getLabel('config_thai')} sx={{ mb:1, width:"300" }} onBlur={(e) => storeDBValue(e,'approval_3_th')} 
                  value={ getDBValue('approval_3_th') } onChange={(e) => updateDBValue(e,'approval_3_th')}/>
              </FormGroup>
            </Stack>
          </Grid>
        </Grid>

        {/* <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => test()} disableElevation>Dev Test</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ ml:1 }} size='small' variant="contained" color="primary" onClick={() => test()} disableElevation>Dev Test</Button>
        </Box> */}

      </Paper>

    </Box>
  );
}

