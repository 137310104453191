import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { blueGrey, blue } from '@mui/material/colors'

import './common.css';
import './App.css';
import Home from './Home';
import Auth from './Auth';
import Process from './Process';
import Customer from './Customer';
import Machine from './Machine';
import Product from './Product';
import Splash from './Splash';
import SignIn from './SignIn';
import Entry from './Entry';
import Production from './Production';
import Volume from './Volume';
// import Register from './Register';
import History from './History';
import NotFound from './NotFound';
import Frame from './admin/Frame';
import WorstListReport from './admin/WorstListReport';


// import Test01 from './Test01';
// import Test02 from './Test02';
// import Debug from './Debug';

function App() {
  let navigate = useNavigate();
  const ColorModeContext = React.createContext();
  const [mode, setMode] = React.useState('light');  
  const [account] = React.useState((window.location.pathname).split("/")[1]);
  const [config, setConfig] = useRecoilState(systemConfig);
  const configRef = React.useRef();
  const [accountCheck] = React.useState('https://dbord.asia:3999/check'); // fixed URL for the authorization

  React.useEffect(() => {
    configRef.current = config[config.account];
    // console.log(configRef.current, config.account, account);
    // if((!configRef.current && account) || (config?.account !== account)) {
    // if((!configRef.current && account)) {
    if(account) {
      checkAccount(account);
      // console.log('pass this...');
      // navigate(`/${account}/auth`, { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const accountExistanceCheck = async(acname) => { // request license server whether the account exists
  //   await checkAccount(acname);
  //   // console.log(`configs for ${account} is not existing!! ${exist}`);
  // }

  const checkAccount = async(accountname) => {
    const url = accountCheck;
    // setLoading(true);
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({account:accountname})})
    .then(res => res.json())
    .then(data => {
      if(data.existance === true) {
        navigate(`/${account}/auth`, { replace: true });
      }

        //   console.log(`true: ${account}`);
      //   console.log(config[account]?.auth);
      //   if(config[account] === undefined || !config[account]?.auth) {
      //     navigate(`/${account}/auth`, { replace: true });
      //   } else {
      //     console.log(`uh.. /${account}/signin`);
      //     navigate(`/${account}/signin`, { replace: true });
      //   }
      //   // navigate(`/${account}/`, { replace: true });
      // } else {
      //   console.log(`false: ${account}`);
      //   navigate(`/`, { replace: true });
      // }
    });
  }

  React.useEffect(() => {
    if(config[config.account]) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], mode:mode }}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);


  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        root: {
          display: 'flex',
        },
        typography: {
          "fontFamily": `apple-system, BlinkMacSystemFont, 'Noto Sans JP',
          'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
          'Droid Sans', 'Helvetica Neue', sans-serif`,
          button: {
            textTransform: "none",
          },
        },
        palette: {
          mode,
          ...(mode === 'light'
          ? {
            primary: {
              main: blue[900],
              light: blue[100],
              dark: blue[900],
              // contrastText: blueGrey[700],
            },
            background: {
              default: '#f8f8f8',
            }
          }
          : {
            primary: {
              main: blueGrey[700],
              light: blueGrey[100],
              dark: blueGrey[700],
              contrastText: blue[50],
            }
          }),
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {/* <Route exact path="/" element={<Debug />} /> */}
          <Route exact path="/" element={<Home />} />
          <Route exact path={`/${config.account}/`} element={<Process />} />
          <Route exact path={`/${config.account}/history`} element={<History />} />
          <Route exact path={`/${account}/auth`} element={<Auth />} />
          <Route exact path={`/${config.account}/splash`} element={<Splash />} />
          <Route exact path={`/${config.account}/signin`} element={<SignIn />} />
          <Route exact path={`/${config.account}/entry`} element={<Entry />} />
          <Route exact path={`/${config.account}/customer`} element={<Customer />} />
          <Route exact path={`/${config.account}/machine`} element={<Machine />} />
          <Route exact path={`/${config.account}/product`} element={<Product />} />
          <Route exact path={`/${config.account}/production`} element={<Production />} />
          <Route exact path={`/${config.account}/volume`} element={<Volume />} />
          <Route exact path={`/${config.account}/admin`} element={<Frame colorMode={colorMode} theme={theme}/>} />
          <Route exact path={`/${config.account}/report`} element={<WorstListReport />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
