import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Button, Backdrop, CircularProgress  } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import ConfirmDialog from "../ConfirmDialog";
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";


export default function EditCompany() {
  const [config] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [isDisabled, setDisabled] = React.useState(false);
  const [recordData, setRecordData] = React.useState(
    { id:'', name:'', abbr:'', address:'', tel:'', fax:'', dept:'', tax:'', userreg:'', datereg:'',
    userupd:'',dateupd:'',user:config.userid, auth:config[config.account].auth }
  );
  const [helpers, setHelpers] = React.useState({ name:'', abbr:'', address:'', tel:'', fax:'', dept:'', tax:'' });
  const [recordList, setRecordList] = React.useState([]);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
      setRecordData(data => ({...data, user:siteRef.current.userid, auth:config[config.account].auth }));
    }
  }

  React.useEffect(() => {
    loadDefault();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const loadData = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/companies`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });

  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnDefs = [
    // { headerName: "会社ID", field: "id", lockPinned: true, pinned: "left", editable:false, }, //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: getLabel('company_name'), field: "name" },
    { headerName: getLabel('company_abbr'), field: "abbr" },
    { headerName: getLabel('company_addr'), field: "address" },
    { headerName: getLabel('company_tel'), field: "tel" },
    { headerName: getLabel('company_fax'), field: "fax" },
    { headerName: getLabel('company_dept'), field: "dept" },
    { headerName: getLabel('company_fax'), field: "tax" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      cdef[0].headerName = getLabel('company_name');
      cdef[1].headerName = getLabel('company_abbr');
      cdef[2].headerName = getLabel('company_addr');
      cdef[3].headerName = getLabel('company_tel');
      cdef[4].headerName = getLabel('company_fax');
      cdef[5].headerName = getLabel('company_dept');
      cdef[6].headerName = getLabel('company_tax');
      cdef[7].headerName = getLabel('common_reg_user');
      cdef[8].headerName = getLabel('common_reg_date');
      cdef[9].headerName = getLabel('common_upd_user');
      cdef[10].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateData = (event) => {
    const data = event.data;
    setEditState(1);
    setRecordData({...recordData, id:data.id, name:data.name, abbr:data.abbr, address:data.address,
      tel:data.tel, fax:data.fax, dept:data.dept, tax:data.tax,
      userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, auth:config[config.account].auth});
    inputValidation(data);
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.id===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onModelUpdated: updateSelectedRow,
    localeText: localeText,
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const newRecord = () => {
    setRecordData({...recordData, id:'', name:'', abbr:'', address:'', tel:'', fax:'', dept:'', tax:'', userreg:'', datereg:'', userupd:'',dateupd:'', auth:config[config.account].auth });
    setHelpers({ name:'', abbr:'', address:'', tel:'', fax:'', dept:'', tax:'' });
    setEditState(0);
  }

  const inputValidation = (data) => {
    let validated = true;
    setHelpers({ name:'', abbr:'', address:'', tel:'', fax:'', dept:'', tax:'' });
    if(data.name.trim().length === 0) {
      setHelpers(helpers => ({...helpers, name:'Required'}));
      validated = false;
    }
    if(data.abbr.trim().length === 0) {
      setHelpers(helpers => ({...helpers, abbr:'Required'}));
      validated = false;
    }
    if(data.address.trim().length === 0) {
      setHelpers(helpers => ({...helpers, address:'Required'}));
      validated = false;
    }
    if(data.tel.trim().length === 0) {
      setHelpers(helpers => ({...helpers, tel:'Required'}));
      validated = false;
    }
    return validated;
  }

  const updateRecord = () => {
    setDisabled(true);
    if(inputValidation(recordData)) {
      if(editState===0) { // new
        const url = `${config[config.account].url}:${config[config.account].port}/api/companyadd`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          loadData();
          newRecord();
          setDisabled(false);
          setSid(data.insertId);
        });
      } else { // update
        const url = `${config[config.account].url}:${config[config.account].port}/api/companyupdate`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(_data => {
          loadData();
          setDisabled(false);
          setSid(recordData.id);
        });
      }
    } else {
      setDisabled(false);
    }
  }

  const deleteRecord = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/companyinuse`;
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
    .then(res => res.json())
    .then(data => {
      Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
    });
    if(sum === 0) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/companydelete`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
      .then(res => res.json())
      .then(_data => {
        newRecord();
        loadData();
      });
    } else {
      openInUse();
    }
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = (e) => setConfirm(true);
  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) deleteRecord();
    setConfirm(false);
  }

  const [inUse, setInUse] = React.useState(false);
  const openInUse = (e) => setInUse(true);
  const closeInUse = (e) => setInUse(false); 


  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <ConfirmDialog 
        open={inUse}
        onClose={closeInUse}
        title={ getLabel('common_info') }
        msg={ getLabel('common_inuse_meg') }
        confirm={ getLabel('common_confirm') }
      />

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('company_list')}</h4>
      </Box>

      <Box color="text.primary" style={{ height: `calc(45vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          { !aggDestroyed &&
          <AgGridReact
            defaultColDef={defaultColDef}
            // columnDefs={columnDefs}
            rowData={recordList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}
          >
          </AgGridReact>
          }
        </div>
      </Box>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{editState===0?getLabel('company_info')+" - "+getLabel('common_new'):getLabel('company_info')+" - "+getLabel('common_update')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="name" label={getLabel('company_name')} variant="standard" size="small" value={recordData.name} 
                onChange={(e) => setRecordData({...recordData, name: e.target.value})}
                helperText={ helpers.name } error={helpers.name.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="abbr" label={getLabel('company_abbr')} variant="standard" size="small" value={recordData.abbr}
                onChange={(e) => setRecordData({...recordData, abbr: e.target.value})}
                helperText={ helpers.abbr } error={helpers.abbr.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="address" label={getLabel('company_addr')} variant="standard" size="small" value={recordData.address} 
                onChange={(e) => setRecordData({...recordData, address: e.target.value})}
                helperText={ helpers.address } error={helpers.address.length>0?true:false} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="tel" label={getLabel('company_tel')} variant="standard" size="small" value={recordData.tel}
                onChange={(e) => setRecordData({...recordData, tel: e.target.value})}
                helperText={ helpers.tel } error={helpers.tel.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="fax" label={getLabel('company_fax')} variant="standard" size="small" value={recordData.fax}
                onChange={(e) => setRecordData({...recordData, fax: e.target.value})} />
              <TextField sx={{ mx:1, mb:1 }} id="dept" label={getLabel('company_dept')} variant="standard" size="small" value={recordData.dept}
                onChange={(e) => setRecordData({...recordData, dept: e.target.value})} />
              <TextField sx={{ mx:1, mb:1 }} id="tax" label={getLabel('company_tax')} variant="standard" size="small" value={recordData.tax}
                onChange={(e) => setRecordData({...recordData, tax: e.target.value})}
                helperText={ helpers.tax } error={helpers.tax.length>0?true:false} />
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => newRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('company_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => openConfirm()} disabled={editState===0?true:false} disableElevation>{getLabel('company_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" disabled={isDisabled} onClick={() => updateRecord()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>
      </Paper>
    </Box>
  );
}