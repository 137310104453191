import * as React from 'react';
// import PropTypes from 'prop-types';
import { Dialog, Grid, Button, Typography, Divider, IconButton, Box } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CancelIcon from '@mui/icons-material/Cancel';

import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";

export default function NumericInput(props) {
  const { onClose, selectedValue, open } = props;
  const [numbers, setNumbers] = React.useState(0);

  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [labels, setLabels] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(() => {
    const lang = config.language;
    setLabels(caption[lang]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(open) {
      setNumbers(selectedValue);
      // console.log(`open: ${selectedValue}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if(numbers <= 0)setNumbers(0);
    if(numbers >= 10000000)setNumbers(10000000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numbers]);

  // const handleClose = (event) => {
  //   const key = event.target.id;
  //   console.log(`key: ${key}, num: ${numbers}, init: ${selectedValue}`);
  //   if(key === 'done')onClose(numbers); else onClose(selectedValue);
  // };

  const handleClose = (key) => {
    // console.log(`key: ${key}, num: ${numbers}, init: ${selectedValue}`);
    if(key === 'done')onClose(numbers); else onClose(selectedValue);
  };

  const inc = () => {
    setNumbers(numbers + 1);
  }

  const dec = () => {
    setNumbers(numbers - 1);
    if(numbers <= 0)setNumbers(0);
  }

  const bksp = () => {
    let tmp = numbers.toString().slice(0, -1);
    if(tmp.length === 0)setNumbers(0); else {
      setNumbers(Number(tmp));
    }
  }

  const cls = () => {
    setNumbers(Number(0));
  }

  const keyin = (event) => {
    const num = event.target.id;
    let tmp = numbers.toString() + num.toString();
    // if(tmp.length === 0)setNumbers(0); else {
    setNumbers(Number(tmp));
    // }
  }

  const isNum = (val) => {
    let pattern = /^\d+\.?\d*$/;
    return pattern.test(val); // return Boolean type
  }

  const typin = (event) => {
    let val = event.nativeEvent.code;
    switch(val) {
      case 'Backspace': bksp(); break;
      case 'NumpadAdd': inc(); break;
      case 'NumpadSubtract': dec(); break;
      case 'KeyC': cls(); break;
      case 'Enter': onClose(numbers); break;
      case 'NumpadEnter': onClose(numbers); break;
      default: {
        if(isNum(val.substr(val.length-1))) {
          if(val.substr(0,6)==='Numpad'||val.substr(0,5)==='Digit') {
            let tmp = numbers.toString() + val.substr(val.length-1).toString();
            setNumbers(Number(tmp));
          }
        }
        // if(val === 'Enter') onClose(numbers);
        // if(val === 'NumpadEnter') onClose(numbers);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} onKeyDown={(e) => typin(e)}>

      <Grid container>

        <Grid item xs={4}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'10vh' }}>
          <IconButton color="primary" aria-label="remove" onClick={dec}>
            <RemoveIcon />
          </IconButton>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'10vh'  }}>
            <Typography variant="h4" >{numbers}</Typography> 
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', height:'10vh' }}>
          <IconButton color="primary" aria-label="add" onClick={inc}>
            <AddIcon />
          </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={4}>
          <Button id="7" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>7</Button>
        </Grid>
        <Grid item xs={4}>
          <Button id="8" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>8</Button>
        </Grid>
        <Grid item xs={4}>
          <Button id="9" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>9</Button>
        </Grid>

        <Grid item xs={4}>
          <Button id="4" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>4</Button>
        </Grid>
        <Grid item xs={4}>
          <Button id="5" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>5</Button>
        </Grid>
        <Grid item xs={4}>
          <Button id="6" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>6</Button>
        </Grid>

        <Grid item xs={4}>
          <Button id="1" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>1</Button>
        </Grid>
        <Grid item xs={4}>
          <Button id="2" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>2</Button>
        </Grid>
        <Grid item xs={4}>
          <Button id="3" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>3</Button>
        </Grid>

        <Grid item xs={4}>
          <Button sx={{ fontSize:'large', width:'100%', height:'10vh' }} endIcon={<ClearAllIcon />} onClick={cls}></Button>
        </Grid>
        <Grid item xs={4}>
          <Button id="0" sx={{ fontSize:'large', width:'100%', height:'10vh' }} onClick={keyin}>0</Button>
        </Grid>
        <Grid item xs={4}>
        <Button sx={{ fontSize:'large', width:'100%', height:'10vh' }} startIcon={<BackspaceIcon />} onClick={bksp}></Button>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <Button id="cancel" sx={{ fontSize:'large', width:'100%', height:'10vh' }} startIcon={<CancelIcon />}
          onClick={() => handleClose("cancel")}>{ getLabel('common_cancel') }</Button>
        </Grid>
        <Grid item xs={6}>
          <Button id="done" sx={{ fontSize:'large', width:'100%', height:'10vh' }} startIcon={<DoneIcon />}
          onClick={() => handleClose("done")}>{ getLabel('entry_confirm') }</Button>
        </Grid>

      </Grid>

    </Dialog>
  );
}

// NumericInput.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.number.isRequired,
// };
  