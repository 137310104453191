/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, Button, AppBar, Toolbar, Typography, IconButton,
  Card, CardContent, Slide, useScrollTrigger, Zoom, Fade, 
  Menu, MenuItem, MenuList, } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import LanguageIcon from '@mui/icons-material/Language';
import SearchIcon from '@mui/icons-material/Search';

const Trans = (e) => {
  const [vis, setVis] = React.useState(false);
  const onView = () => {
    setVis(true);
  }
  return (
    <ScrollTrigger onEnter={onView}>
    {/* <Slide direction="up" in={vis} timeout={1000}><div>{e.children}</div></Slide> */}
    {/* <Grow in={vis} timeout={1000}><div>{e.children}</div></Grow> */}
    <Zoom in={vis} timeout={300}><div>{e.children}</div></Zoom>
    {/* <Fade in={vis} timeout={1000}><div>{e.children}</div></Fade> */}
    {/* <Collapse orientation="vertical" in={vis}><div>{e.children}</div></Collapse> */}
    </ScrollTrigger>
  );
};

export default function Home() {
  const navigate = useNavigate();

  const scrollTrig = useScrollTrigger({
    disableHysteresis: true,
    threshold:200
  });
  const [scrollTime, setScrollTime] = React.useState(0);

  React.useEffect(() => {
    setScrollTime(300);
    // return () => {
    //   setTimeout(() => { setScrollTime(300); }, 100);
    // }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }
  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>

      <Slide direction="down" in={!scrollTrig} timeout={scrollTime}>
        <AppBar position="fixed" elevation={1} sx={{ background: '#2E3B55' }}>
          {/* <Toolbar variant="dense"> */}
          <Toolbar>
            {/* <IconButton color="inherit">
              <AccountCircleIcon />
            </IconButton> */}

            <img src="logo_color.png" alt="DBord Logo" height="24px"/>

            <Box sx={{ display:'flex', width:'100%', alignItems:'center' }}>
              <Typography noWrap color="inherit" sx={{ pb:0.2, pl:2 }}>Real-time Production Information Sharing</Typography>
              {/* <Button color="inherit" sx={{ ml:4, display: { xs:'none', sm:'block' } }}>日本語選択</Button>
              <Button color="inherit" sx={{ ml:1, display: { xs:'none', sm:'block' } }}>日本語選択</Button>
              <Button color="inherit" sx={{ ml:1, display: { xs:'none', sm:'block' } }}>日本語選択</Button>
              <Button color="inherit" sx={{ ml:1, display: { xs:'none', sm:'none', md:'block' } }} onClick={handleClick} onMouseOver={handleClick}>Menu Two</Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave:handleClose }}
              >
                <MenuList dense>
                <MenuItem onClick={handleClose}>Menu Item One</MenuItem>
                <MenuItem onClick={handleClose}>Menu Item Two</MenuItem>
                <MenuItem onClick={handleClose}>メニュー参</MenuItem>
                </MenuList>
              </Menu> */}

              <Box sx={{ flexGrow: 1 }}/>
              {/* <Typography noWrap color="inherit" sx={{ pb:0.2 }}>右端</Typography> */}
              {/* <IconButton color="inherit" size="small">
                <SearchIcon fontSize="small" />
              </IconButton>
              <IconButton color="inherit" size="small">
                <LanguageIcon fontSize="small" />
              </IconButton>
              <IconButton color="inherit" size="small">
                <Brightness4Icon fontSize="small" />
              </IconButton> */}

            </Box>
          </Toolbar>
        </AppBar>
      </Slide>

      <Box sx={{ m:4, mt:10 }}>
        <h3>DBord Leaflet in Japanese</h3>
        <Box sx={{ display:'flex', width:'100%', alignItems:'center' }}>
        <img src="leaflet_jp.jpg" alt="Leaflet in Japanese" width="100%"/>
        </Box>
        <Box sx={{ height:"64px"}}/>
        <h3>DBord Leaflet in Thai</h3>
        <Box sx={{ display:'flex', width:'100%', alignItems:'center' }}>
        <img src="leaflet_th.jpg" alt="Leaflet in Thai" width="100%"/>
        </Box>
        {/* <Button variant='outlined' sx={{ mr:1 }} size="small" onClick={() => navigate('/sample')}>Test Button</Button>
        <Button variant='contained' sx={{ mr:1 }} size="small" onClick={() => setScrollTime(300)}>詳細</Button>
        <Button variant='contained' sx={{ mr:1 }} onClick={() => setScrollTime(1000)}>工程選択</Button> */}
      </Box>

      {/* { [...Array(30)].map((_, i) => (
        <React.Fragment key={i}>
          <Trans>
            <Card sx={{ m:2, minWidth:300, ':hover':{ boxShadow:10 } }} elevation={1}>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  DBord - リアルタイム生産情報共有アプリ
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  現場改善の必須アイテム<br/>
                  作業実績を現場でタブレットに直接入力<br/>
                  クラウドサーバーでデータ処理<br/>
                  既存のエクセル帳票をリアルタイムで更新<br/>
                </Typography>
              </CardContent>
            </Card>
          </Trans>
        </React.Fragment>
      )) } */}

    </Box>
  );
}
