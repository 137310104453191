import React from 'react';
import { Box, Button, Menu, Typography, Divider, Grid, Backdrop, 
  CircularProgress, Dialog, MenuItem, IconButton } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from "./ConfirmDialog";
import UserListDialog from "./UserListDialog";
import TimePickMenu from "./TimePickMenu";
import { useTheme } from '@mui/material/styles'
import TranslateIcon from '@mui/icons-material/Translate';


export default function Volume(props) {
// eslint-disable-next-line no-unused-vars
  const { model, name } = props;
  let navigate = useNavigate();
  const [config, setConfig] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [ctime, setCtime] = React.useState();
  const [products, setProducts] = React.useState();
  const [volumes, setVolumes] = React.useState();
  const [sums, setSums] = React.useState({qualified:0, unqualified:0, rework:0, ef_minutes:0, ef_qualified:0, ef_rate:0});
  const [efficiency, setEfficiency] = React.useState();
  const [mailList, setMailList] = React.useState();
  const [dbconfig, setDbconfig] = React.useState(null);
  const [section, setSection] = React.useState(null);

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };
  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  })
  const [dps] = React.useState(getDisplaySize());

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    if(!status.products)navigate(`/${config.account}/`, { replace: true });
    if(!siteRef.current.authPassed)navigate(`/${config.account}/signin`, { replace: true });

    loadTemp();
    getConfigs();
    if(dps==='xs')setCtime(getCurrentTime(true));
    else setCtime(getCurrentTime(true) + ', ' + getCurrentDate());
    let interval = setInterval(() => {
      const time = dps==='xs'?getCurrentTime(true):getCurrentTime(true) + ', ' + getCurrentDate();
      setCtime(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadProducts(status.processes, status.companies);
    loadVolumes();
    loadMailingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dbconfig]);

  const [loadedImages, setLoadedImages] = React.useState(false);
  React.useEffect(() => {
    loadEfficiency();
    if(products && !loadedImages) {
      convImagePath(products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const loadProducts = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/production`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id:status.products, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setProducts(data);
      setLoading(false);
    });
  }

  const getConfigs = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth })}) // body: JSON.stringify(rec)
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      setLoading(false);
    });
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const convImagePath = (data) => {
    data.forEach((item, index) => {
      if(item.image.length > 0) {
        checkImage(item.pid, index);
      }
    });
    setLoadedImages(true);
  }

  const checkImage = async(id, idx) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/checkfile`;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:id, auth:config[config.account].auth })})
    .then(res => res.blob())
    .then(data => {
      if(data.type.match(/^image\//g)) {
        const imageURL = URL.createObjectURL(data);
        setProducts(v => v.map((v2, i) => (i===idx?{...v2, image:imageURL}:v2)));
      }
    });
  }

  const formatDate = (val, dftype, dfdisp) => {
    if(val !== undefined) {
      const chk = true;
      if(chk) {
        const dt = new Date(val);
        let fdt = '';
        if(dftype === 1) {
          if(dfdisp === 1) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${dt.getFullYear()} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}`;
          }
        } else if(dftype === 2) {
          if(dfdisp === 1) {
            fdt = `${dt.getFullYear()}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')}`;
          }
        }
        return fdt;
      }
      return val;
    } else return '';
  }

  const loadVolumes = () => {
    const dftype = Number(getDBValue('date_format'));
    const url = `${config[config.account].url}:${config[config.account].port}/api/volumes_today`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ production:status.products, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      calcSums(data);
      if(dftype!==0) {
        data = data.map(v => ({...v, dateentf:formatDate(v.dateent, dftype, 1) }));
      }
      setVolumes(data);
      setLoading(false);
    });
  }

  const loadMailingList = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/dailymaillist`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ production:status.products, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setMailList(data);
      setLoading(false);
    });
  }

  const calcSums = (data) => {
    let num_qualified = 0;
    let num_unqualified = 0;
    let num_rework = 0;
    data.forEach((item, _index) => {
      num_qualified += item.qualified;
      num_unqualified += item.unqualified;
      num_rework += item.rework;
    });
    setSums(sums => ({...sums, qualified:num_qualified, unqualified:num_unqualified, rework:num_rework}));
  }

  const loadEfficiency = () => {
    const dftype = Number(getDBValue('date_format'));
    const url = `${config[config.account].url}:${config[config.account].port}/api/efficiency`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ production:status.products, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      if(dftype!==0) {
        data = data.map(v => ({...v, timestf:formatDate(v.timest, dftype, 1), timeedf:formatDate(v.timeed, dftype, 1) }));
      }
      setEfficiency(data);
      calcEfficiency(data);
      setLoading(false);
    });
  }

  const calcEfficiency = (data) => {
    let factor = products && products.find(v => v.id === Number(status.products)).period;
    let ef_minutes = 0;
    let ef_qualified = 0;
    let ef_rate = 0;
    data.forEach((item, _index) => {
      ef_minutes += item.uptimenet;
      ef_qualified += item.qualified;
    });
    ef_rate = (factor * ef_qualified) / ef_minutes;
    setSums(sums => ({...sums, ef_minutes:ef_minutes, ef_qualified:ef_qualified, ef_rate:ef_minutes>0?ef_rate:0}));
  }

  const [temp, setTemp] = React.useState();

  const loadTemp = async() => {
    const tempFile = 'daily.xlsx';
    fetchTemplate(tempFile);
  }

  const fetchTemplate = async(filename) => {
    const args = { auth:config[config.account]?.auth, filename:filename };
    const url = `${config[config.account]?.url}:${config[config.account]?.port}/api/template`;
    if(!config || !config[config.account]?.auth)return;
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(args) })
      .then(res => res.arrayBuffer()) // response by array buffer
      .then(data => {
      setTemp(data);
    });
  }

  const test_excel = async(upload) => { //upload: false - download, ture - upload to the server
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(temp);

    const filename = (products.find(v => v.id === Number(status.products)).pmodel) + '_' + getTimeStamp(true) + '.xlsx';
    workbook.creator = 'F-1 Tools';
    workbook.lastModifiedBy = 'F-1 Tools';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.calcProperties.fullCalcOnLoad = true;

    const worksheet = workbook.getWorksheet('Daily summary');

    let ridx = 1;
    let row = worksheet.getRow(ridx);    
    row.getCell(1).value = getLabel('common_date');
    row.getCell(1).font = { bold: true }
    row.getCell(2).value = getCurrentDate();
    row = worksheet.getRow(ridx+=1);
    row.getCell(1).value = getLabel('entry_model');
    row.getCell(1).font = { bold: true }
    row.getCell(2).value = products.find(v => v.id === Number(status.products)).pmodel;
    row = worksheet.getRow(ridx+=1);
    row.getCell(1).value = getLabel('entry_name');
    row.getCell(1).font = { bold: true }
    row.getCell(2).value = products.find(v => v.id === Number(status.products)).pname;
    row = worksheet.getRow(ridx+=1);
    row.getCell(1).value = getLabel('machine_info');
    row.getCell(1).font = { bold: true }
    row.getCell(2).value = products.find(v => v.id === Number(status.products)).maname + 
    " / " + products.find(v => v.id === Number(status.products)).matype;
    row = worksheet.getRow(ridx+=1);
    row.getCell(1).value = getLabel('production_process');
    row.getCell(1).font = { bold: true }
    row.getCell(2).value = getProcessInfo(products);

    row = worksheet.getRow(ridx+=2);
    row.getCell(1).value = getLabel('entry_volume');
    row.getCell(1).font = { bold: true }
    row = worksheet.getRow(ridx+=1);
    row.getCell(1).value = getLabel('entry_time');
    row.getCell(2).value = getLabel('entry_qualified');
    row.getCell(4).value = getLabel('entry_unqualified');
    row.getCell(3).value = getLabel('entry_rework');
    // eslint-disable-next-line array-callback-return
    volumes.map((val, i) => {
      row = worksheet.getRow(ridx+=1);
      row.getCell(1).value = val.dateent.slice(0,16).toString();
      row.getCell(2).value = val.qualified;
      row.getCell(4).value = val.unqualified;
      row.getCell(3).value = val.rework;
    });
    row = worksheet.getRow(ridx=18);
    row.getCell(1).value = getLabel('entry_sum_today');
    row.getCell(2).value = sums.qualified;
    row.getCell(4).value = sums.unqualified;
    row.getCell(3).value = sums.rework;

    row = worksheet.getRow(ridx+=2);
    row.getCell(1).value = getLabel('entry_manhour_efficiency');
    row.getCell(1).font = { bold: true }
    row = worksheet.getRow(ridx+=1);
    row.getCell(1).value = getLabel('entry_process_start');
    row.getCell(2).value = getLabel('entry_process_end');
    row.getCell(3).value = getLabel('entry_section_period');
    row.getCell(4).value = getLabel('entry_qualified');
    row.getCell(5).value = getLabel('entry_efficiency');
    // eslint-disable-next-line array-callback-return
    efficiency.map((val, i) => {
      row = worksheet.getRow(ridx+=1);
      row.getCell(1).value = val.timest.slice(0, 16).toString();
      row.getCell(2).value = val.timeed.slice(0, 16).toString();
      row.getCell(3).value = val.minutes;
      row.getCell(4).value = val.qualified;
      row.getCell(5).numFmt = '#,##0.00" %";[Red]\\-#,##0.00" %"';
      row.getCell(5).value = val.efficiency;
    });
    row = worksheet.getRow(ridx=26);
    row.getCell(1).value = getLabel('entry_sum_today');
    row.getCell(3).value = sums.ef_minutes;
    row.getCell(4).value = sums.ef_qualified;
    row.getCell(5).numFmt = '#,##0.00" %";[Red]\\-#,##0.00" %"';
    row.getCell(5).value = isNaN(sums.ef_rate)?0:sums.ef_rate * 100;

    const uint8Array = await workbook.xlsx.writeBuffer(); // workbook.csv.writeBuffer()
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });

    if(!upload) {
      const download = require('downloadjs');
      download(blob, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); //application/vnd.ms-excel
    } else {
      setXls(await blobToBase64(blob));
      uploadXls(filename);
      sendReport(filename);
    }
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const [xls, setXls] = React.useState();

  const uploadXls = (filename) => {
    if(xls) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/uploadxls`;
      fetch(url, {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( {filename:filename, file:{xls}, auth:config[config.account].auth} ) })
      .then(res => res.json())
      .then(data => {
        // setRecordData(recordData => ({...recordData, image:data.insertId }));
        // setSnack(snack => ({ open:true, msg:'The image is uploaded to the server.' }));
      })
      .catch(error => {
        console.error(error)
      });
    }
  }

  const sendReport = (filename) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sendxlsmail`;
    const file = [{file:filename, subject:getLabel('entry_daily_subject'), message:getLabel('entry_daily_message'), auth:config[config.account].auth}, {mail: mailList}];
    fetch(url, {
      method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify( file ) })
    .then(res => res.json())
    .then(data => {
      // setRecordData(recordData => ({...recordData, image:data.insertId }));
      // setSnack(snack => ({ open:true, msg:'The image is uploaded to the server.' }));
    })
    .catch(error => {
      console.error(error)
    });
  }

  const getCurrentDate = (separator='-') => {
    let mname = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let newDate = new Date();
    let date = newDate.getDate();
    let month = mname[newDate.getMonth()];
    let year = newDate.getFullYear();
    return `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`;
  }

  const getCurrentTime = (seconds=false) => {
    let newDate = new Date()
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${hrs<10?`0${hrs}`:`${hrs}`}:${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `:${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const getTimeStamp = (seconds=false) => {
    let newDate = new Date()
    let yar = newDate.getFullYear();
    let mth = newDate.getMonth()+1;
    let dte = newDate.getDate();
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${yar}${mth<10?`0${mth}`:`${mth}`}${dte<10?`0${dte}`:`${dte}`}${hrs<10?`0${hrs}`:`${hrs}`}${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const moveTop = () => {
    setStatus(status => ({...status, processes:'', companies:'', products:'' }));
    navigate(`/${config.account}/`, { replace: true });
  }

  const moveBack = () => {
    setStatus(status => ({...status, back:true }));
    navigate(`/${config.account}/production`, { replace: true });
  }

  const [editRecord, setEditRecord] = React.useState();

  const modifyTime = (e, val, typ) => {
    setEditRecord(val);
    setEditRecord((val) => ({...val, isStartTime:typ}));
    setEditTime(typ?val.timest:val.timeed);

    setAnchorPicker(e.currentTarget);
    setTimePicker(true);
  }

  const formatDBDate = (dt) => {
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth()+1)).slice(-2);
    const day = ("00" + (dt.getDate())).slice(-2);
    const hrs = ("00" + (dt.getHours())).slice(-2);
    const mns = ("00" + (dt.getMinutes())).slice(-2);
    const date = `${year}/${month}/${day} ${hrs}:${mns}:00`;
    return date;
  }

  const updateTime = (e, flg) => {
    if(flg) {
      const starting = editRecord.isStartTime;
      const sttime = editRecord.timest;
      const edtime = editRecord.timeed;
      let date = formatDBDate(e);
      if(starting) {
        let stdate = new Date(date);
        let eddate = new Date(edtime);
        if(stdate >= eddate) {
          stdate.setDate(stdate.getDate() - 1);
          date = formatDBDate(stdate);
        }
      } else {
        let stdate = new Date(sttime);
        let eddate = new Date(date);
        if(stdate >= eddate) {
          eddate.setDate(eddate.getDate() + 1);
          date = formatDBDate(eddate);
        }
      }
      updateSectionDate(date);
    }
  }

  const updateSectionDate = (date) => {
    const id = editRecord.section;
    const flg = editRecord.isStartTime;
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectiondate`;
    setLoading(true);
    fetch(url, { method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id:id, flg:flg, date:date, user:config[config.account].userid, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(async(data) => {
      await recalcEfficiency(editRecord);
      loadEfficiency();
      setLoading(false);
    });
  }

  const recalcEfficiency = async(v) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghourcalc`;
    const arg = { section:v.section, nonworking:v.nonworking, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(async(data) => {
      await updateUptime(v.section, data[0]?.uptime_full, data[0]?.uptime_net);
      setLoading(false);
    });
  }

  const updateUptime = async(id, full, net) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionuptime`;
    const arg = { id:id, full:full, net:net, user:config[config.account].userid, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(data => {
      setLoading(false);
    });
  }

  const [editTime, setEditTime ] = React.useState();
  const [approval, setApproval] = React.useState(false);

  const openApproval = (e) => {
    const val = Number(e.target.dataset.section);
    const obj = efficiency.find(v => v.section === val);
    setSection(obj);
    setApproval(true);
  }

  const closeApproval = () => {
    setApproval(false);
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = (e) => {
    const apkey = e.target.id;

    const apr_method = getDBValue('approval_login');
    if(apr_method === '1') {
      setSign(v => ({...v, approval:apkey, section:section.section, aprid:!section[apkey]?config[config.account].userid:'', userid:config[config.account].userid, auth:config[config.account].auth }));
      setConfirm(true);
    } else {
      loadUsers();
      setSign(v => ({...v, approval:apkey, section:section.section, aprid:!section[apkey]?config[config.account].userid:'', userid:config[config.account].userid, auth:config[config.account].auth }));
      setUserListItems(v => ({...v, type:apkey, title:getLabel('entry_approver_select'), msg:getLabel('entry_approver_select_msg'), confirm:getLabel('entry_approver_clear'), cancel:getLabel('common_cancel') }));
      setUserListDlg(true);
    }
  }

  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) {
      updateApproval();
    }
    setConfirm(false);
  }

  const [sign, setSign] = React.useState(null);
  const updateApproval = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionapproval`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sign) })
    .then(res => res.json())
    .then(_data => {
      loadEfficiency();
      setLoading(false);
    });
  }

  React.useEffect(() => {
    if(section?.section && sign?.section) {
      const obj = efficiency.find(v=> v.section === section.section);
      setSection(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [efficiency]);

  const [showApproval, setShowApproval] = React.useState(false); 
  React.useEffect(() => {
    const a1 = getDBValue('approval_1');
    const a2 = getDBValue('approval_2');
    const a3 = getDBValue('approval_3');
    const tot = parseInt(a1) + parseInt(a2) + parseInt(a3); // not Number
    setShowApproval(tot===0?false:true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbconfig])
// ---------------------------------------------- user list dialog related
  const [userList, setUserList] = React.useState(null);
  const [userListItems, setUserListItems] = React.useState({type:'', title:'', msg:'', confirm:'', cancel:'' });
  const [userListDlg, setUserListDlg] = React.useState(false);
  const closeUserListDlg = (e) => {
    const type = e.target.dataset.type;
    const ret = e.target.dataset.return;
    if(ret) {
      const param = {approval:type, section:section.section, aprid:'', userid:config[config.account].userid, auth:config[config.account].auth };
      updateApproval2(param);
    }
    setUserListDlg(false);
  }

  const onSelectUser = (e, id) => {
    const type = e.target.dataset.type;
    const param = {approval:type, section:section.section, aprid:id, userid:config[config.account].userid, auth:config[config.account].auth };
    updateApproval2(param);
    setUserListDlg(false);
  }

  const updateApproval2 = async(param) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionapproval`;
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(param) })
    .then(res => res.json())
    .then(_data => {
      loadEfficiency();
      setLoading(false);
    });
    getRecord(section.section);
  }

  const getRecord = async(id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/summary`;
    setLoading(true);
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id:id, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      // setSummary(data);
      setLoading(false);
    });
  }

  const loadUsers = async() => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/users`;
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ auth:config[config.account].auth}) })
    .then(res => res.json())
    .then(data => {
      setUserList(data);
      setLoading(false);
    });
  }

  // eslint-disable-next-line no-unused-vars
  const test = (e) => {
    // console.log(config);
  }

  const getProcessInfo = (products) => {
    const pname = products.find(v => v.id === Number(status.products))
    [`pcname${config[config.account].language==='en'?'_en':config[config.account].language==='th'?'_th':''}`];
    const sname = products.find(v => v.id === Number(status.products)).sname;
    return `${pname} ${sname?'/':''} ${sname?sname:''}`;
  }

  const [anchorPicker, setAnchorPicker] = React.useState(null);
  const [timePicker, setTimePicker] = React.useState(false);

  const closeTimePicker = (e, u) => {
    if(u)updateTime(e, true);
    setTimePicker(false);
  }

  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <TimePickMenu
        onClose={closeTimePicker}
        open={timePicker}
        anchorEl={anchorPicker}
        value={editTime}
      />

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_input_confirm') }
        msg={ sign?.aprid?getLabel('entry_confirm_approval'):getLabel('entry_cancel_approval') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <UserListDialog 
        type={ userListItems.type }
        open={ userListDlg }
        onClose={ closeUserListDlg }
        onSelect={ onSelectUser }
        title={ userListItems.title }
        msg={ userListItems.msg }
        users={ userList }
        searchText={ getLabel('common_search') }
        confirm={ userListItems.confirm }
        cancel={ userListItems.cancel }
      />

{/* -------------------------------------------------------------------------------------------------------- */}
      <Dialog onClose={closeApproval} open={approval}>
        <Grid container sx={{ p:1 }}>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" sx={{ m:1 }}>{ getLabel('entry_production_approval') }</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ m:1 }} />
          </Grid>

          { getDBValue('approval_1')==='1' && 
          <Grid container>
            <Grid item xs={8}>
              <Box>
                <Typography variant={dps==='xs'?"subtitle2":"h6"} sx={{ m:1 }}>{ section && section.username1?
                  `${getLabel('entry_approved')} ${section.username1}`:getLabel('entry_unapproved') }</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Button id="approval1" variant={section && section.approval1?'contained':'outlined'} data-func="test1"
                onClick={openConfirm} sx={{ m:1, minWidth:90 }}>
                  { getDBValue('approval_1'.concat('_',config[config.account].language)) }</Button>
              </Box>
            </Grid>
          </Grid>
          }
          { getDBValue('approval_2')==='1' && 
          <Grid container>
            <Grid item xs={8}>
              <Box>
                <Typography variant={dps==='xs'?"subtitle2":"h6"} sx={{ m:1 }}>{ section && section.username2?
                `${getLabel('entry_approved')} ${section.username2}`:getLabel('entry_unapproved') }</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Button id="approval2" variant={section && section.approval2?'contained':'outlined'} data-func="test2"
                onClick={openConfirm} sx={{ m:1, minWidth:90 }}>
                  { getDBValue('approval_2'.concat('_',config[config.account].language)) }</Button>
              </Box>
            </Grid>
          </Grid>
          }
          { getDBValue('approval_3')==='1' && 
          <Grid container>
            <Grid item xs={8}>
              <Box>
                <Typography variant={dps==='xs'?"subtitle2":"h6"} sx={{ m:1 }}>{ section && section.username3?
                `${getLabel('entry_approved')} ${section.username3}`:getLabel('entry_unapproved') }</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Button id="approval3" variant={section && section.approval3?'contained':'outlined'} data-func="test3"
                onClick={openConfirm} sx={{ m:1, minWidth:90 }}>
                  { getDBValue('approval_3'.concat('_',config[config.account].language)) }</Button>
              </Box>
            </Grid>
          </Grid>
          }

          <Grid item xs={12}>
            <Divider sx={{ m:1 }} />
          </Grid>
          <Box sx={{ p:1, pb:1, display:'flex', width:'100%' }}>
            <Box sx={{ flexGrow: 1 }}/>
            <Button variant="contained" onClick={closeApproval} sx={{ ml:1 }}>{ getLabel('common_close') }</Button>
          </Box>
        </Grid>
      </Dialog>
{/* -------------------------------------------------------------------------------------------------------- */}

      <Box sx={{ p:0.5, pb:1, display:'flex', width:'100%' }}>
        <Button variant="contained" onClick={moveTop}>{ getLabel('process_list') }</Button>
        <Button variant="contained" onClick={moveBack} sx={{ mx:0.5 }}>{ getLabel('entry_volume_input') }</Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button variant="contained" onClick={() => test_excel(false)} sx={{ mr:1 }}>{ getLabel('entry_output') }</Button>
        <Button variant="contained" onClick={() => test_excel(true)} sx={{ mr:1 }}
          disabled={config[config.account].userrole===4 || (mailList && mailList.length<1)?true:false}>
            { dps==='xs'?getLabel('entry_daily_report_mobile'):getLabel('entry_daily_report') }
          </Button>

        <IconButton
          color="inherit"
          sx={{ mr: 0.5 }}
          onClick={languageClick}
        >
          <TranslateIcon fontSize="small"/>
        </IconButton>
        <Menu
          anchorEl={anchorLanguage}
          open={selectLanguage}
          onClose={languageClose}
        >
          <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account].language==='jp'?true:false}>日本語</MenuItem>
          <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account].language==='en'?true:false}>English</MenuItem>
          <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account].language==='th'?true:false}>ไทย</MenuItem>
        </Menu>

        <Button variant="outlined" onClick={test}>{ ctime }</Button>
      </Box>

      <Grid container sx={{ p:0.1 }}>
        <Grid item xs={9} sm={10} sx={{p:1}}>
          <Grid container>
            <Grid item xs={4} sx={dps==='xs'||dps==='sm'? {p:0.1, display:'flex',justifyContent:'flex-start'} 
              : { p:0.1, display:'flex', justifyContent:'center' }}>
              <Typography variant={dps==='xs'?"subtitle2":"h6"}><b>{ getLabel('entry_model') }</b></Typography>
            </Grid>
            <Grid item xs={8} sx={{ p:0.1 }}>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>{ products && products.find(v => v.id === Number(status.products)).pmodel }</Typography>
            </Grid>

            <Grid item xs={4} sx={dps==='xs'||dps==='sm'? {p:0.1, display:'flex',justifyContent:'flex-start'} 
              : { p:0.1, display:'flex', justifyContent:'center' }}>
              <Typography variant={dps==='xs'?"subtitle2":"h6"}><b>{ getLabel('entry_name') }</b></Typography>
            </Grid>
            <Grid item xs={8} sx={{ p:0.1 }}>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>{ products && products.find(v => v.id === Number(status.products)).pname }</Typography>
            </Grid>

            <Grid item xs={4} sx={dps==='xs'||dps==='sm'? {p:0.1, display:'flex',justifyContent:'flex-start'} 
              : { p:0.1, display:'flex', justifyContent:'center' }}>
              <Typography variant={dps==='xs'?"subtitle2":"h6"}><b>{ getLabel('machine_info') }</b></Typography>
            </Grid>
            <Grid item xs={8} sx={{ p:0.1 }}>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>
                { products && products.find(v => v.id === Number(status.products)).maname } 
                &nbsp;/&nbsp;{ products && products.find(v => v.id === Number(status.products)).matype }
              </Typography>
            </Grid>

            <Grid item xs={4} sx={dps==='xs'||dps==='sm'? {p:0.1, display:'flex',justifyContent:'flex-start'} 
              : { p:0.1, display:'flex', justifyContent:'center' }}>
              <Typography variant={dps==='xs'?"subtitle2":"h6"}><b>{ getLabel('production_process') }</b></Typography>
            </Grid>
            <Grid item xs={8} sx={{ p:0.1 }}>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>
                { products && getProcessInfo(products) }
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3} sm={2}>
          <Button variant="outlined" sx={{
            width: '98%', height: '100%', mr:2,
            backgroundImage: `url(${products && products.find(v => v.id === Number(status.products)).image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '70%',
            backgroundPosition: '50%',
          }}></Button>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ mt:2 }} />
        </Grid>

        <Grid container sx={{m:2}}>
          <Grid item xs={4} sm={4.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'center', alignItems:'center', p:0.1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0 }
              }>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>
                <b> { getLabel('entry_time') } </b>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'end', alignItems:'center', p:0.1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0 }
              }>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>
                <b> { getLabel('entry_qualified') } </b>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'end', alignItems:'center', p:0.1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0 }
              }>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>
                <b> { getLabel('entry_rework') } </b>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} sm={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'end', alignItems:'center', p:0.1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0 }
              }>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>
                <b>{getLabel('entry_unqualified')}</b>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        { volumes && volumes.map((val, i) =>
        <Grid container key={i} >
          <Grid item xs={4.5}>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:1, m:0 }}>
              <Typography variant={dps==='xs'?"subtitle2":"subtitle1"}>{ val.dateentf }</Typography>
            </Box>
          </Grid>

          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'right', alignItems:'center', p:1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:1, m:0 }
              }>
              <Typography variant="subtitle1">{ val.qualified }</Typography>
            </Box>
          </Grid>

          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'right', alignItems:'center', p:1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:1, m:0 }
              }>
              <Typography variant="subtitle1">{ val.rework }</Typography>
            </Box>
          </Grid>

          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'right', alignItems:'center', p:1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:1, m:0 }
              }>
              <Typography variant="subtitle1">{ val.unqualified }</Typography>
            </Box>
          </Grid>
        </Grid>
        )}

        <Grid item xs={12}>
          <Divider sx={{ mt:1 }} />
        </Grid>
        
        <Grid container>
          <Grid item xs={4.5}>
            <Box sx={{ display:'flex', justifyContent:'end', alignItems:'center', p:0, m:1 }}>
              <Typography variant={dps==='xs'?"subtitle1":"h6"}><i>{ getLabel('entry_sum_today') }</i></Typography>
            </Box>
          </Grid>
          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'right', alignItems:'center', p:1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:1, m:0 }
              }>
              <Typography variant={dps==='xs'?"subtitle1":"h6"}>{ sums.qualified }</Typography>
            </Box>
          </Grid>

          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'right', alignItems:'center', p:1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:1, m:0 }
              }>
              <Typography variant={dps==='xs'?"subtitle1":"h6"}>{ sums.rework }</Typography>
            </Box>
          </Grid>

          <Grid item xs={2.5}>
            <Box sx={
                dps==='xs'? { display:'flex', justifyContent:'right', alignItems:'center', p:1, m:0 }
                : { display:'flex', justifyContent:'center', alignItems:'center', p:1, m:0 }
              }>
              <Typography variant={dps==='xs'?"subtitle1":"h6"}>{ sums.unqualified }</Typography>
            </Box>
          </Grid>
        </Grid>
{/* ---------- Man-hour efficiency ---------- */}
        <Grid item xs={12}>
          <Divider sx={{ mt:0 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={dps==='xs'?"subtitle2":"h6"} sx={{ p:1, pt:2, fontWeight:'bold' }}>{ getLabel('entry_manhour_efficiency') }</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt:0 }} />
        </Grid>

        <Grid container>
          <Grid item xs={ showApproval?2.7:3 }>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0, m:1 }}>
              <Typography textAlign={'center'} variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>{ getLabel('entry_process_start') }</Typography>
            </Box>
          </Grid>
          <Grid item xs={ showApproval?2.7:3 }>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0, m:1 }}>
              <Typography textAlign={'center'} variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>{ getLabel('entry_process_end') }</Typography>
            </Box>
          </Grid>
          <Grid item xs={showApproval?1.8:2}>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0, m:1 }}>
              <Typography textAlign={'center'} variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>{ getLabel('entry_section_period') }</Typography>
            </Box>
          </Grid>
          <Grid item xs={ showApproval?1.3:2 }>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0, m:1 }}>
              <Typography textAlign={'center'} variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>{ getLabel('entry_qualified') }</Typography>
            </Box>
          </Grid>
          <Grid item xs={ showApproval?1.5:2 }>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0, m:1 }}>
              <Typography variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>{ getLabel('entry_efficiency') }</Typography>
            </Box>
          </Grid>
          { showApproval &&
          <Grid item xs={2}>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0, m:1 }}>
              <Typography textAlign={'center'} variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>{ getLabel('entry_approval') }</Typography>
            </Box>
          </Grid>
          }
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ mt:0 }} />
        </Grid>

        { efficiency && efficiency.map((val, i) =>
          <Grid container key={i}>
            <Grid container>
              <Grid item xs={ showApproval?2.7:3 }>
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:1, mt:0.5 }}>
                  <Button sx={{ fontSize:dps==='xs'||dps==='sm'?'small':'large' }} variant="outlined" 
                  onClick={(e)=> modifyTime(e, val, true)}
                  disabled={(config[config.account].userrole===4)?true:false} >{ val.timestf }</Button>
                </Box>
              </Grid>
              <Grid item xs={ showApproval?2.7:3 }>
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:1, mt:0.5 }}>
                  <Button  sx={{ fontSize:dps==='xs'||dps==='sm'?'small':'large' }} variant="outlined" 
                  onClick={(e) => modifyTime(e, val, false)}
                  disabled={(config[config.account].userrole===4)?true:false} >{ val.timeedf }</Button>
                </Box>
              </Grid>
              <Grid item xs={ showApproval?1.8:2 }>
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0.5, mt:1.2 }}>
                  <Typography variant={dps==='xs'||dps==='sm'?"subtitle1":"h6"}>{ val.uptimenet }</Typography>
                </Box>
              </Grid>
              <Grid item xs={ showApproval?1.3:2 }>
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0.5, mt:1.2 }}>
                  <Typography variant={dps==='xs'||dps==='sm'?"subtitle1":"h6"}>{ val.qualified }</Typography>
                </Box>
              </Grid>
              <Grid item xs={ showApproval?1.5:2 }>
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0.5, mt:1.2 }}>
                  <Typography variant={dps==='xs'||dps==='sm'?"subtitle1":"h6"}>{ Math.round(val.efficiency) }%</Typography>
                </Box>
              </Grid>
            { showApproval &&
              <Grid item xs={2}>
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:0.5, mt:1 }}>
                  <Button data-section={val.section} variant="contained" onClick={openApproval} sx={{fontSize:'small'}}
                    disabled={(config[config.account].userrole===4)?true:false} >{ getLabel('entry_approval') }</Button>
                </Box>
              </Grid>
            }
          </Grid>
          { (getDBValue('free_entry_1')==='1' || getDBValue('free_entry_2')==='1' || getDBValue('free_entry_3')==='1') &&
          <Grid container>
            { getDBValue('free_entry_1')==='1' &&
              <Grid item xs={12}>
                <Box sx={{ display:'flex', justifyContent:'left', alignItems:'center', p:0, ml:2 }}>
                  <Typography variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>
                    {val.fentry1?`${getDBValue('free_entry_1'.concat('_',config[config.account].language))} : ${val.fentry1}`:''}
                  </Typography>
                </Box>
              </Grid>
            }
            { getDBValue('free_entry_2')==='1' &&
              <Grid item xs={12}>
                <Box sx={{ display:'flex', justifyContent:'left', alignItems:'center', p:0, ml:2 }}>
                  <Typography variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>
                  {val.fentry2?`${getDBValue('free_entry_2'.concat('_',config[config.account].language))} : ${val.fentry2}`:''}
                  </Typography>
                </Box>
              </Grid>
            }
            { getDBValue('free_entry_3')==='1' &&
              <Grid item xs={12}>
                <Box sx={{ display:'flex', justifyContent:'left', alignItems:'center', p:0, ml:2 }}>
                  <Typography variant={dps==='xs'||dps==='sm'?"subtitle2":"h6"}>
                  {val.fentry3?`${getDBValue('free_entry_3'.concat('_',config[config.account].language))} : ${val.fentry3}`:''}
                  </Typography>
                </Box>
              </Grid>
            }
          </Grid>
          }
          <Grid item xs={12}>
            <Divider sx={{ my:0 }} />
          </Grid>
        </Grid>
        )}

        <Grid item xs={showApproval?5.4:6}>
          <Box sx={{ display:'flex', justifyContent:'end', alignItems:'center', pr:5, m:1 }}>
            <Typography variant={dps==='xs'?"subtitle1":"h6"}><i>{ getLabel('entry_sum_today') }</i></Typography>
          </Box>
        </Grid>
        
        <Grid item xs={showApproval?1.8:2}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:1 }}>
            <Typography variant={dps==='xs'?"subtitle1":"h6"}>{ sums.ef_minutes }</Typography>
          </Box>
        </Grid>
        <Grid item xs={showApproval?1.3:2}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:1 }}>
            <Typography variant={dps==='xs'?"subtitle1":"h6"}>{ sums.ef_qualified }</Typography>
          </Box>
        </Grid>
        <Grid item xs={showApproval?1.5:2}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:1 }}>
            <Typography variant={dps==='xs'?"subtitle1":"h6"}>{ isNaN(sums.ef_rate)?'':(Math.round(sums.ef_rate * 100) + '%') }</Typography>
          </Box>
        </Grid>
        { showApproval && 
        <Grid item xs={2}>
          <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', p:0.5, m:1 }}>
            <Typography variant={dps==='xs'?"subtitle1":"h6"}></Typography>
          </Box>
        </Grid>
        }

        <Grid item xs={12}>
          <Divider sx={{ mt:0 }} />
        </Grid>

        <Box sx={{ p:1, pb:1, display:'flex', width:'100%' }}>
          <Box sx={{ flexGrow: 1 }}/>
          <Button variant="contained" onClick={moveBack} sx={{ ml:1 }}>{ getLabel('entry_volume_input') }</Button>
        </Box>
      </Grid>
    </Box>
  );
}