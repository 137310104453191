import React from "react";
import { Box, Button,Dialog, Divider, Grid, Typography, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import ConfirmDialog from "./ConfirmDialog";
import { useTheme } from '@mui/material/styles'

export default function DefectiveDlg(props) {
  // eslint-disable-next-line no-unused-vars
  const { open, onClose, oPtrn, oDfAmt, source, defect, ...other } = props;

  const [config] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [labels, setLabels] = React.useState(null);
  // const arrDfTypes = React.useRef();
  // const [list, setList] = React.useState([]);
  // const [odata, setOdata] = React.useState({sumAmt:0});
  const [confirm, setConfirm] = React.useState(false);

  const titleStyle = () => ({
    // width:'98%',
    textAlign: 'center',
    fontSize: '0.875rem',
    lineHeight: '1.9',
    px:1.2, py:0.6,
    border: '1px solid primary.main',
    borderRadius: '4px',
    color: 'primary.contrastText',
    bgcolor: 'primary.main',
  });

  //-------- Begin Display Rendering --------
  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  });
  const [maxWidth] = React.useState(getDisplaySize()==="xs"?getDisplaySize():getDisplaySize()==="lg"?"md":"sm");
  //-------- End Display Rendering --------

  const getLabel = (key) => {
    const label = labels ? labels[key] : "";
    return label;
  };

  React.useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    if(open) {
      // console.log(props);
      // console.log(defect);
    }
    // loadDfType(oPtrn);
    // setOdata({ ...odata, sumAmt:oDfAmt?.sumAmt?oDfAmt.sumAmt:0, defectdesc:oDfAmt?.defectdesc?oDfAmt.defectdesc:'' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // const numLimit = (num) => {
  //   if((/^\d+\.?\d*$/).test(num)) {
  //     if(num<0)return 0;
  //     if(num>100000)return 100000; // 100k
  //     return num;
  //   } else return 0;
  // };

  // const onChangeQty = (e, index) => {
  //   let value = Number(numLimit(e.target.value));
  //   const newVal = list.map((d,i) => {
  //     if(i===index){return {...d, qty:value}}
  //     else {return {...d}}
  //   })
  //   setOdata({...odata, sumAmt:newVal.reduce((p,d) => { return p + d.qty},0) });
  //   setList(newVal);
  // };
  
  const handleCloseDlg = (key) => {
    if(key==='cancel' || key==='done') onClose();
    // if(key==='done') {
    //   if(source==='record') setConfirm(true);
    //   else {
    //     // let obj = mapToOdata(list);
    //     // setOdata(obj);
    //     // onClose(obj);
    //   }
    // }
  }

  const clsConfDlg = (e) => {
    if(e.currentTarget.dataset.key==="confirm" && source!=="worstList") {
      // let obj = mapToOdata(list);
      // setOdata(obj);
      // onClose(obj);
    } else onClose();
    setConfirm(false);
  }

  const truncateString = (str, len=255) => {
    return str.length<=len? str:str.substring(0, len);
  }

  const [total, setTotal] = React.useState(0);
  const [remarks, setRemarks] = React.useState('');

  // const onChange = (e) => {
  //   const regex = /^[0-9\b]+$/;
  //   let val = e.target.value;
  //   if (val === "" || regex.test(val))return val;
  // }

  const updateValues = (e, id) => {
    const val = e.target.value;
    defect.map((v) => (v.id===id?v.val=val:v));
    getTotal();
  }

  const onFocus = (e) => {
    e.target.select();
  }

  const getTotal = () => {
    let tot = 0;
    defect.map(v => (tot += Number(v.val)));
    setTotal(tot);
    props.onUpdateDefectTotal(tot);
  }

  const updateRemarks = (e) => {
    let txt = e.target.value;
    txt = truncateString(txt);
    setRemarks(txt);
    props.onUpdateDefectRemark(txt);
  }

  // eslint-disable-next-line no-unused-vars
  const test = () => {
    // console.log(props);
    // getTotal();
  }

//-------------------------------------------------------------

  return (
    <Box>

      <ConfirmDialog 
        open={(confirm && source==='record')}
        onClose={clsConfDlg}
        title={ getLabel('defectconf_title') }
        msg={ getLabel('defectconf_warning_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
        id={'confDefectDlg'}
      />

      <Dialog open={open} onClose={handleCloseDlg} fullWidth maxWidth={maxWidth}>

        <Typography variant="h6" align="center" sx={{ m:2 }}>
          {defect && defect[0]?.name?defect[0]?.name:''}
        </Typography>

        <Divider />

        <Box sx={{ m:2 }}>
          <Grid container spacing={1}>
            { defect?.map((v,i) => (
            <React.Fragment key={i}>
            <Grid item xs={12} sm={8}>
              <Box component="div" sx={ titleStyle }>
                {config[config.account].language==='en'?`${v.name_en}`:config[config.account].language==='th'?`${v.name_th}`:`${v.name_jp}`}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField type="number" variant="outlined" size="small" sx={{ width:'100%', "& input": {textAlign:"right"}}}
                InputProps={{ style:{height:'2.3rem'} }} defaultValue={v.val}
                onFocus={onFocus} onBlur={(e) => updateValues(e, v.id)}/>
            </Grid>
            </React.Fragment>
            ))}
          </Grid>
        </Box>

        <Divider />

        <Grid container >
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex-start" }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ mr:3, mt:1, display:"flex" }}>
              <Typography variant="h6" flexGrow={1} textAlign="right" width="30%">{getLabel('defective_total')}</Typography>
              <Typography variant="h6" flexGrow={1} textAlign="right" width="50%">{ total }</Typography>
              {/* <Typography variant="h6" flexGrow={1} textAlign="right" width="50%">{odata.sumAmt}</Typography> */}
            </Box>
          </Grid>
        </Grid>
        <TextField id="defectdescText" sx={{ mx:2, my:1 }} label={getLabel('common_remarks')} size="small"
          multiline value={remarks} onChange={(e) => updateRemarks(e)}/>
        {/* <TextField id="defectdescText" sx={{ m:1 }} label={'Remarks'} size="small" multiline value={odata.defectdesc} onChange={(e) => setOdata({...odata, defectdesc: limitStrLen(e.target.value)})}/> */}

        <Divider />
        <Box sx={{ p:1, display: "flex" }}>
          {/* { source==='worstList'? null :
            <Button variant="contained" key='reset' onClick={resetClick} sx={{ m: 1 }}>
              {getLabel('common_reset')}
            </Button>
          } */}

          {/* <Button variant="contained" key='test' onClick={test} sx={{ m:1 }} >
            { `Test` }
          </Button> */}

          <Box sx={{ flexGrow: 1 }} />
          {/* <Button variant="outlined" key='cancel' onClick={()=>handleCloseDlg('cancel')} sx={{ m:1 }} >
            {getLabel('common_cancel')}
          </Button> */}
          { source==='worstList'? null :
            <Button variant="contained" key='done' onClick={()=>handleCloseDlg('done')} sx={{ m:1 }} >
              {getLabel('common_close')}
            </Button>
          }
        </Box>
      </Dialog>
    </Box>
  );
}



