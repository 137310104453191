import * as React from 'react';
// import PropTypes from 'prop-types';
import { Grid, Button, Typography, Box, Menu, List, ListItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";

export default function TimePickMenu(props) {
  const { onClose, value, open, anchorEl } = props;

  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [labels, setLabels] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dbconfig, setDbconfig] = React.useState(null);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  // const [cyear, setCyear] = React.useState();
  // const [cmonth, setCmonth] = React.useState();
  // const [cdate, setCdate] = React.useState();
  const [hours, setHours] = React.useState([]);
  const [minutes, setMinutes] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [targetDate, setTargetDate] = React.useState();

  const hourRef = React.useRef(null);
  const minuteRef = React.useRef(null);

  React.useEffect(() => {
    const lang = config.language;
    setLabels(caption[lang]);
    initTime();
    getConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConfigs = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    // const rec = { production:status.products, qualified:numQualified, unqualified:numUnqualified, user:config.userid }
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) }) // body: JSON.stringify(rec)
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      setLoading(false);
    });
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const initTime = () => {
    setHours([]);
    setMinutes([]);
    [...Array(24)].map((_v,i) => setHours(hours => [...hours, { hour:i, selected:false } ]));
    [...Array(60)].map((_v,i) => setMinutes(minutes => [...minutes, { minute:i, selected:false } ]));
  }

  const formatDate = (val, dftype, dfdisp) => {
    // const date_exp = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) ([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    // getInfo();
    if(val !== undefined) {
      // const chk = date_exp.test(val);
      const chk = true;
      if(chk) {
        const dt = new Date(val);
        let fdt = '';
        if(dftype === 1) {
          if(dfdisp === 1) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${dt.getFullYear()} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}`;
          }
        } else if(dftype === 2) {
          if(dfdisp === 1) {
            fdt = `${dt.getFullYear()}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')}`;
          }
        }
        return fdt;
      }
      return val;
    } else return '';
  }

  const [dtcap, setDtcap] = React.useState(['-1','0','+1']);
  const setCaptions = (date) => {
    let ndate = new Date(date);
    const dftype = getDBValue('date_format');
    if(dftype==='1') {
      const r1 = formatDate(ndate.toString(), 1, 3);
      ndate.setDate(ndate.getDate()-1);
      const r0 = formatDate(ndate.toString(), 1, 3); 
      ndate.setDate(ndate.getDate()+2);
      const r2 = formatDate(ndate.toString(), 1, 3);
      setDtcap([r0, r1, r2]);
    } else if(dftype==='2') {
      const r1 = formatDate(ndate.toString(), 2, 3);
      ndate.setDate(ndate.getDate()-1);
      const r0 = formatDate(ndate.toString(), 2, 3); 
      ndate.setDate(ndate.getDate()+2);
      const r2 = formatDate(ndate.toString(), 2, 3);
      setDtcap([r0, r1, r2]);
    } 
  }

  React.useEffect(() => {
    if(open && value) {
      setUpdate(false);
      setDay(0);
      setTargetDate(new Date(value));
      setCaptions(value);
      // setCyear(value.slice(0,4));
      // setCmonth(value.slice(5,7));
      // setCdate(value.slice(8,10));
      const h = Number(value.slice(11,13));
      const m = Number(value.slice(14,16));
      setHours(hours.map((v,i) => (i===h?
        {hour:i, selected:true}:{hour:i, selected:false})));
      setMinutes(minutes.map((v,i) => (i===m?
        {minute:i, selected:true}:{hour:i, selected:false})));
    }
    const timer = setTimeout(() => {
      if(hourRef.current!==null)hourRef.current.scrollIntoView();
      if(minuteRef.current!==null)minuteRef.current.scrollIntoView();
    }, 100);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  
  // React.useEffect(() => {
  //   console.log(year, month, date);
  // }, [year, month, date]);

  const hourChange = (e) => {
    const h = Number(e.target.dataset.hour);
    setHours(hours.map((v,i) => (i===h?
      {hour:i, selected:true, element:null}:{hour:i, selected:false})));
    e.target.scrollIntoView();
  } 

  const minuteChange = (e) => {
    const m = Number(e.target.dataset.minute);
    setMinutes(minutes.map((v,i) => (i===m?
      {minute:i, selected:true, element:null}:{minute:i, selected:false})));
    e.target.scrollIntoView();
    setUpdate(true);
  } 

  React.useEffect(()=> {
    if(update) {
      // console.log(props);
      const ymd = `${targetDate.getFullYear()}-${(targetDate.getMonth()+1).toString().padStart(2,'0')}-${(targetDate.getDate()).toString().padStart(2,'0')}`;
      const h = Number(hourRef.current.getAttribute("data-hour"));
      const m = Number(minuteRef.current.getAttribute("data-minute"));
      // const ndt = `${value.slice(0,10)} ${("00"+h).slice(-2)}:${("00"+m).slice(-2)}:00`.replace(/-/g, "/");
      const ndt = `${ymd} ${("00"+h).slice(-2)}:${("00"+m).slice(-2)}:00`.replace(/-/g, "/");
      // handleClose(new Date(ndt));
      setUpdate(false);
      onClose(new Date(ndt), true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const handleClose = (event) => {
    let dt = new Date(value);
    setUpdate(false);
    onClose(dt, false);
  };

  const test = () => {
    // console.log(hours);
    // console.log(minutes);
  }

  const [day, setDay] = React.useState(0);

  const dayChange = (e, v) => {
    if(v!==null) {
      setDay(v);
      var dt = new Date(value);
      dt.setDate(dt.getDate()+v);
      setTargetDate(dt);
    }
  }

  return (
    <Menu onClose={handleClose} open={open} anchorEl={anchorEl}>
      <Box sx={{ p:0, m:0, width:'200px' }}>

      <Box sx={{ m:1, mt:0, textAlign:'center'}}>
          <ToggleButtonGroup
            color="primary"
            value={day}
            onChange={dayChange}
            exclusive >
            <ToggleButton value={-1}>{dtcap[0]}</ToggleButton>
            <ToggleButton value={0}>{dtcap[1]}</ToggleButton>
            <ToggleButton value={1}>{dtcap[2]}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Grid container>

          <Grid item xs={6} sx={{ textAlign:'center'}}>
            <List sx={{ maxHeight: 150, overflow: 'auto', position: 'relative',
              // scrollbarWidth: 'none',
              // "&::-webkit-scrollbar": { display: "none" },
              }}
              >
              { hours.map((v, i) => (
                <ListItem key={i} sx={{ m:0, p:0 }}>
                  <Button
                    ref={v.selected?hourRef:null}
                    variant={v.selected?'contained':'text'}
                    data-hour={i}
                    onClick={hourChange}
                    sx={{ width:'100%', fontSize:18 }}>{ String(i).padStart(2, '0') }</Button>
                </ListItem>
                ))
              }
            </List>
            <Typography onClick={test}>{ getLabel('entry_time_hour') }</Typography>
          </Grid>

          <Grid item xs={6} sx={{ textAlign:'center'}}>
            <List sx={{ maxHeight: 150, overflow: 'auto', position: 'relative',
              // scrollbarWidth: 'none',
                // "&::-webkit-scrollbar": { display: "none" },
                }}
              >
              { minutes.map((v, i) => (
                <ListItem key={i} sx={{ m:0, p:0 }}>
                  <Button
                    ref={v.selected?minuteRef:null}
                    variant={v.selected?'contained':'text'}
                    data-minute={i}
                    onClick={minuteChange}
                    sx={{ width:'100%', fontSize:18 }}>{ String(i).padStart(2, '0') }</Button>
                </ListItem>
                ))
              }
            </List>
            <Typography onClick={test}>{ getLabel('entry_time_minute') }</Typography>
          </Grid>

        </Grid>

      </Box>
    </Menu>
  );
}

