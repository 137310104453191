import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Button, Backdrop, CircularProgress, Snackbar, IconButton } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from "../ConfirmDialog";
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";
import Resizer from "react-image-file-resizer";

export default function EditProduct() {

  const [config] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, _setCaption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false); 
  const [labels, setLabels] = React.useState(null);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [isDisabled, setDisabled] = React.useState(false);
  const [recordData, setRecordData] = React.useState(
    { id:'', model:'', internal:'', name:'', image:'', userreg:'', datereg:'', userupd:'',dateupd:'',
    user:config.userid, auth:config[config.account].auth }
  );
  const [helpers, setHelpers] = React.useState({ model:'',internal:'', name:'' });
  const [recordList, setRecordList] = React.useState([]);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
      setRecordData(data => ({...data, user:siteRef.current.userid, auth:config[config.account].auth }));
    }
  }

  React.useEffect(() => {
    loadDefault();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/products`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });
  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnDefs = [
    { headerName: getLabel('product_model'), field: "model" },
    { headerName: getLabel('product_internal'), field: "internal" },
    { headerName: getLabel('product_name'), field: "name" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
    { headerName: 'image', field: "image", hide: true },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      cdef[0].headerName = getLabel('product_model');
      cdef[1].headerName = getLabel('product_internal');
      cdef[2].headerName = getLabel('product_name');
      cdef[3].headerName = getLabel('common_reg_user');
      cdef[4].headerName = getLabel('common_reg_date');
      cdef[5].headerName = getLabel('common_upd_user');
      cdef[6].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateData = (event) => {
    const data = event.data;
    setEditState(1);
    setRecordData({...recordData, id:data.id, model:data.model, internal:data.internal, name:data.name, image:data.image,
      userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, auth:config[config.account].auth});
    checkImage(data.id);
    inputValidation(data);
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.id===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onModelUpdated: updateSelectedRow,
    localeText: localeText,
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const newRecord = () => {
    setRecordData({...recordData, id:'', model:'', internal:'', name:'', image:'', userreg:'', datereg:'', userupd:'',dateupd:'', auth:config[config.account].auth });
    setHelpers({ model:'',internal:'', name:'' });
    setImage();
    setEditState(0);
  }

  const inputValidation = (data) => {
    let validated = true;
    setHelpers({ model:'',internal:'', name:'' });
    if(data.model.trim().length === 0) {
      setHelpers(helpers => ({...helpers, model:'Required'}));
      validated = false;
    }
    if(data.name.trim().length === 0) {
      setHelpers(helpers => ({...helpers, name:'Required'}));
      validated = false;
    }
    return validated;
  }

  const updateRecord = () => {
    setDisabled(true);
    if(inputValidation(recordData)) {
      if(editState===0) { // new user
        const url = `${config[config.account].url}:${config[config.account].port}/api/productadd`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          setEditState(1);
          if(image)uploadImage(data.insertId);
          loadData();
          setDisabled(false);
          setSid(data.insertId);
        });
      } else { // update user
        const url = `${config[config.account].url}:${config[config.account].port}/api/productupdate`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          if(image)uploadImage(recordData.id);
          loadData();
          setDisabled(false);
          setSid(recordData.id);
        });
      }
    } else {
      setDisabled(false);
    }
  }

  const deleteRecord = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/productinuse`;
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
    .then(res => res.json())
    .then(data => {
      Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
    });
    if(sum === 0) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/productdelete`;
      clearImage(recordData.id);
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
      .then(res => res.json())
      .then(_data => {
        newRecord();
        loadData();
      });
    } else {
      openInUse();
    }
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = () => setConfirm(true);
  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) deleteRecord();
    setConfirm(false);
  }
  const [inUse, setInUse] = React.useState(false);
  const openInUse = () => setInUse(true);
  const closeInUse = () => setInUse(false);

  const [image, setImage] = React.useState();
  const uploadImage = async(id) => {
    if(image) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/uploadfile`;
      await fetch(url, {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify( {id:id, file:{image}, auth:config[config.account].auth } ) })
      .then(res => res.json())
      .then(data => { })
      .catch(error => { });
    }
  }

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(file, 400, 400, "PNG", 100, 0, (uri) => {
        resolve(uri);
      }, "base64");
  });

  const selectImage = async(event) => {
    const files = event.target.files;
    let validImage = true;
    if(files.length !== 0) {
      const file = files[0];
      const max_file_size = 5000000;
      if(file.size > max_file_size) {
        validImage = false;
      }
      if((file.type !== 'image/png') && (file.type !== 'image/jpeg')) {
        validImage = false;
      }
      if(validImage) {
        const image = await resizeFile(file);
        setImage(image);
      }
    }
    event.target.value = ''; // really important...
  }

  // const checkImage_bk = async(id) => {
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/checkfile`;
  //   await fetch(url,
  //   { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:id, auth:config[config.account].auth })})
  //   .then(res => res.json())
  //   .then(data => {
  //     const file = data.file;
  //     console.log(data.file);
  //     if(file.length !== 0) {
  //       const zfid = ('000000'+ id).slice(-6);
  //       const fext = data.file.slice(-4);
  //       const imagepath = siteRef.current.deployurl + '/images/products/' + zfid + `${fext}?=` + new Date().getTime();
  //       setImage(imagepath);
  //     } else {
  //       setImage();
  //     }
  //   });
  // }

  const checkImage = async(id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/checkfile`;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:id, auth:config[config.account].auth })})
    .then(res => res.blob())
    .then(data => {
      if(data.type.match(/^image\//g)) {
        const imageURL = URL.createObjectURL(data);
        setImage(imageURL);
      } else {
        setImage();
      }
    });
  }

  const clearImage = async(id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/removefile`;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:id, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      setImage();
    });
  }

  const [snack, setSnack] = React.useState({open:false, msg:'default message'});
  const closeSnack = () => {
    setSnack(snack => ({open:false, msg:''}));
  }

  const snackAction = (
    <React.Fragment>
      <Button color="primary" variant="contained" size="small" onClick={closeSnack}>
        Cancel
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function limitStrLen(str) {
    return str.length<=64? str:str.substring(0,64);
  }

  return (
    <Box>
      <Snackbar
        open={snack.open}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={6000}
        onClose={closeSnack}
        message={snack.msg}
        action={snackAction}
      >
      </Snackbar>  

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <ConfirmDialog 
        open={inUse}
        onClose={closeInUse}
        title={ getLabel('common_info') }
        msg={ getLabel('common_inuse_meg') }
        confirm={ getLabel('common_confirm') }
      />

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('product_list')}</h4>
      </Box>

      <Box color="text.primary" style={{ height: `calc(50vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          { !aggDestroyed &&
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={recordList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}>
          </AgGridReact>
          }
          </div>
      </Box>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{editState===0?getLabel('product_info')+" - "+getLabel('common_new'):getLabel('product_info')+" - "+getLabel('common_update')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="model" label={getLabel('product_model')} variant="standard" size="small" value={recordData.model}
                onChange={(e) => setRecordData({...recordData, model: limitStrLen(e.target.value)})}
                helperText={ helpers.model } error={helpers.model.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="internal" label={getLabel('product_internal')} variant="standard" size="small" value={recordData.internal}
                onChange={(e) => setRecordData({...recordData, internal: limitStrLen(e.target.value)})}
                helperText={ helpers.internal } error={helpers.internal.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="name" label={getLabel('product_name')} variant="standard" size="small" value={recordData.name} 
                onChange={(e) => setRecordData({...recordData, name: limitStrLen(e.target.value)})}
                helperText={ helpers.name } error={helpers.name.length>0?true:false} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>

            <Grid container>

              <Grid item xs={12} md={6}>
                 <Button sx={{ m:1, height:'10vh', width:'100%', 
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '9vh',
                    backgroundPosition: '0.5vh',
                  }} 
                  key={`key_${image}`}
                  variant='outlined'>{ getLabel('product_image_preview') }</Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack>
                  <Button variant="contained" component="label" size="small" sx={{ m:1, ml:2 }}>
                    { getLabel('product_image_select') }
                    <input id={'png' + recordData.id} accept="image/png,image/jpeg" type="file" hidden onChange={(e) => selectImage(e)}/>
                  </Button>
                  <Button variant="contained" component="label" size="small" sx={{ ml:2, mr:1 }} onClick={() => clearImage(recordData.id)}
                  disabled={image === undefined?true:false}>
                    { getLabel('product_image_clear') }
                  </Button>
                </Stack>
              </Grid>

            </Grid>

          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => newRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('product_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => openConfirm()} disabled={editState===0?true:false} disableElevation>{getLabel('product_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" disabled={isDisabled} onClick={() => updateRecord()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>
      </Paper>
    </Box>
  );
}