import React from 'react';
import { Box, Button, Backdrop, CircularProgress, Typography, Paper, Stack, Menu, MenuItem, IconButton, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';

import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import TranslateIcon from '@mui/icons-material/Translate';
import SearchIcon from '@mui/icons-material/Search';


export default function Customer({ prop }) {
  let navigate = useNavigate();
  const theme = useTheme();

  const [config, setConfig] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [ctime, setCtime] = React.useState();
  const [processes, setProcesses] = React.useState();
  const [clients, setClients] = React.useState();
  const aData = React.useRef([]);
  // const [products, setProducts] = React.useState();
  // const [machines, setMachines]  = React.useState();

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    if(!siteRef.current.authPassed)navigate(`/${config.account}/signin`, { replace: true });
    loadProcesses();
    loadClients(status.processes);
    if(dps==='xs')setCtime(getCurrentTime(true));
    else setCtime(getCurrentTime(true) + ', ' + getCurrentDate());
    let interval = setInterval(() => {
      const time = dps==='xs'?getCurrentTime(true):getCurrentTime(true) + ', ' + getCurrentDate();
      setCtime(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(() =>{
    if(status.back) {
      // loadProducts(status.processes, status.companies);
      setStatus(status => ({...status, back:false}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status.back])

  const loadProcesses = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/processes_lang`;
    const lang = config[config.account].language==='jp'?'name':('name_' + config[config.account].language);
    setLoading(true);
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({lang:lang, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      setProcesses(data);
      setLoading(false);
    });
  }

  const [ condClients, setCondClients] = React.useState({len:0, dvn:0, rem:0});

  const loadClients = (process_id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/companies_process`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:process_id, auth:config[config.account].auth}) })
    .then(res => res.json())
    .then(data => {
      aData.current = data;
      let len = data.length;
      let dvn = parseInt(len / 4);
      const rem = len % 4;
      if(rem > 0)dvn++;
      setCondClients({...condClients, len:len, dvn:dvn, rem:rem });
      setClients(data);
      setLoading(false);
    });
  }

  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  });
  // eslint-disable-next-line no-unused-vars
  const [dps, setDps] = React.useState(getDisplaySize());

  const handleSearch = (e) => {
    let filter = aData.current.filter(data => (data.abbr.toLowerCase()).includes(e.target.value.toLowerCase())
      || (data.name.toLowerCase()).includes(e.target.value.toLowerCase()));
    setClients(filter);
    let len = filter.length;
    let dvn = parseInt(len/4);
    const rem = len % 4;
    if(rem > 0) dvn++;
    setCondClients({...condClients, len:len, dvn:dvn, rem:rem })
  }

  const getCurrentDate = (separator='-') => {
    let mname = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let newDate = new Date()
    let date = newDate.getDate();
    let month = mname[newDate.getMonth()];
    let year = newDate.getFullYear();
    return `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`;
  }

  const getCurrentTime = (seconds=false) => {
    let newDate = new Date()
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${hrs<10?`0${hrs}`:`${hrs}`}:${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `:${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const moveTop = () => {
    setStatus(status => ({...status, processes:'', companies:'', products:'' }));
    navigate(`/${config.account}/`, { replace: true });
  }

  const updateCompanies = (val) => {
    const id = val.target.id;
    setStatus(status => ({...status, companies:id, products:'' }));
    navigate(`/${config.account}/product`, { replace: true });
  }

  const test = (e) => {
    // console.log(status);
    // console.log(processes);
    // console.log(clients);
    // setAnchorEl(e.currentTarget);
    // setMachineSelect(true);
    // console.log(condProducts);
    // console.log(getCurrentDate('/'));
    // console.log(getCurrentTime(true));
  }


  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ p:2, pb:1, display:'flex', width:'100%' }}>
        <Button variant="contained" onClick={moveTop}>{ getLabel('entry_select_process') }</Button>

        <Box sx={{ flexGrow: 1 }}/>
          <IconButton
            color="inherit"
            sx={{ mr: 1 }}
            onClick={languageClick}
          >
            <TranslateIcon fontSize="small"/>
          </IconButton>
          <Menu
            anchorEl={anchorLanguage}
            open={selectLanguage}
            onClose={languageClose}
          >
            <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config.language==='jp'?true:false}>日本語</MenuItem>
            <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config.language==='en'?true:false}>English</MenuItem>
            <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config.language==='th'?true:false}>ไทย</MenuItem>
          </Menu>
          <Button variant="outlined" onClick={test} id="test">{ ctime }</Button>
        </Box>

      <Box sx={{ p:2, pb:1, display:'flex', width:'100%' }}>
        <Paper component={Stack} direction="column"
          sx={{ height:'6vh', width:'100%', p:1, textAlign:'center', justifyContent:'center', background:theme.palette.primary.main }}>
          <Typography variant="h6" color={theme.palette.primary.contrastText}>
            { processes && status && processes.find(v => v.id === Number(status.processes)).name}
          </Typography>
        </Paper>
      </Box>

      <Box sx={{ width:'100%', textAlign:'center' }}>
        <Typography variant="h5" sx={{ m:2 }}>{ getLabel('entry_message_customer') }</Typography>
      </Box>

      <Box sx={{ width:'100%', height:'5vh', px:3, mb:1 }} >
        <SearchIcon fontSize={"large"} color={'primary'} />
        {dps==='xs'?
          <TextField sx={{ mx:2, mb:2, height:'3vh', width:'80%'}} variant='standard' color='primary' type="search" 
            placeholder={getLabel('common_search')} onChange={handleSearch}/>
          : <TextField sx={{ mx:2, mb:2, height:'3vh', width:'25%'}} variant='standard' color='primary' type="search" 
            placeholder={getLabel('common_search')} onChange={handleSearch}/>
        }
      </Box>
      
      <Box sx={{ px:1 }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar ]} 
          spaceBetween={0}
          slidesPerView={dps==='xs'? 1 : dps==='sm'? 2 : 3}
          navigation
          pagination={{ clickable: true, type: "fraction" }}
        >
        {clients && [...Array(condClients.dvn)].map((v, i) =>
          <SwiperSlide key={ i }>
            {(i*4<condClients.len  ) && <Button id={ clients[i*4].id   } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={updateCompanies}>{ clients[i*4].abbr   }<br/>{ `(${clients[i*4].name})`   }</Button> }
            {(i*4+1<condClients.len) && <Button id={ clients[i*4+1].id } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={updateCompanies}>{ clients[i*4+1].abbr }<br/>{ `(${clients[i*4+1].name})` }</Button> }
            {(i*4+2<condClients.len) && <Button id={ clients[i*4+2].id } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={updateCompanies}>{ clients[i*4+2].abbr }<br/>{ `(${clients[i*4+2].name})` }</Button> }
            {(i*4+3<condClients.len) && <Button id={ clients[i*4+3].id } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={updateCompanies}>{ clients[i*4+3].abbr }<br/>{ `(${clients[i*4+3].name})` }</Button> }
          </SwiperSlide>
        )}
        </Swiper>
      </Box>
    </Box>
  );
}