import React from "react";
import { Box, Dialog, Typography, IconButton, Backdrop, CircularProgress } from "@mui/material";
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { useTheme } from '@mui/material/styles'

import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_EN } from "./ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "./ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "./ag-grid-locale.th";
import './ag-grid-overrides.scss';
import CloseIcon from '@mui/icons-material/Close';

export default function DefectiveTableDialog(props) {
  // eslint-disable-next-line no-unused-vars
  const { open, onClose, defect, records, onDataChanged, ...other } = props;

  const [config] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [labels, setLabels] = React.useState(null);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [loading, setLoading] = React.useState(false);
  // const [defectList, setDefectList] = React.useState(props.defect);

  //-------- Begin Display Rendering --------
  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  });
  // eslint-disable-next-line no-unused-vars
  const [maxWidth] = React.useState(getDisplaySize()==="xs"?getDisplaySize():getDisplaySize()==="lg"?"md":"sm");
  //-------- End Display Rendering --------

  const getLabel = (key) => {
    const label = labels ? labels[key] : "";
    return label;
  };

  React.useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(open) {
      // console.log(props);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const handleCloseDlg = (key) => {
    onClose(key);
  };

  // const onChange = (e) => {
  //   const regex = /^[0-9\b]+$/;
  //   let val = e.target.value;
  //   if (val === "" || regex.test(val))return val;
  // }

//------------------------------------------------------------- AG Grid related

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const defaultColDef = {
    resizable: true,
    // editable: false,
    sortable: true,
    filter: true,
  };

  const columnTypes = {
    editableNumber: {
      editable: true,
      valueParser:(v) => editNumber(v),
      // filter: 'agNumberColumnFilter',
    },
    editableText: {
      editable: true,
      valueParser:(v) => editText(v),
      // filter: 'agTextColumnFilter',
    },    
  };

  const editNumber = (v) => {
    const oldVal = v.oldValue;
    const newVal = v.newValue;
    if(newVal.match(/^[0-9]+$/) && (oldVal!==newVal)) {
      // console.log(getTotalDefects());
      updateDefect(v);
      return Number(newVal);
    } else {
      return oldVal;
    }
  }

  const updateDefect = async(v) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectupdate`;
    const args = { section:v.data.section, volume:v.data.volume, pattern:v.data.pattern,
      defect:[{ tid:v.colDef.field ,val:v.newValue }],
      user:config[config.account].userid, auth:config[config.account].auth };
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(args) })
    .then(res => res.json())
    .then(_data => {
      // console.log(data);
      onDataChanged(getTotalDefects(records));
      setLoading(false);
    });
  }

  const getTotalDefects = () => {
    let sum = 0;
    records.forEach((i) => {
      Object.keys(i).forEach((v) => {
        // console.log(`${v}: ${i[v]}`);
        if(v.match(/^[0-9]+$/))sum += Number(i[v]);
      })
    });
    // console.log(`the sum is: ${sum}`);
    return sum;
  }


  const editText = (v) => {
    const oldVal = v.oldValue;
    const newVal = v.newValue;
    if(oldVal!==newVal) {
      const updateValue = truncateString(newVal);
      v.remark = updateValue;
      updateVolumeRemark(v);
      return updateValue;
    } else {
      return oldVal;
    }
  }

  const truncateString = (str, len=255) => {
    return str.length<=len? str:str.substring(0, len);
  };

  const updateVolumeRemark = async(v) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/volume_remark`;
    const args = { section:v.data.section, volume:v.data.volume, pattern:v.data.pattern, remark:v.remark,
      user:config[config.account].userid, auth:config[config.account].auth };
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(args) })
    .then(res => res.json())
    .then(_data => {
      // console.log(data);
      setLoading(false);
    });
  }

  const [columnDefs, setColumnDefs] = React.useState([]);

  React.useEffect(() => {
    if(defect) {
      const data = defect.map(v => ({ headerName:config[config.account].language==='en'?
      `${v.name_en}`:config[config.account].language==='th'?`${v.name_th}`:`${v.name_jp}`, field:`${v.id}`,
      type:`editableNumber` }));
      data.push({headerName:getLabel('common_remarks'), field:'remark', type:`editableText` });
      setColumnDefs(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defect]);

  const updateGridHeaders = (params) => {
    params.api.sizeColumnsToFit();
    window.setTimeout(() => {
      const colIds = params.columnApi.getAllColumns().map(c => c.colId);
      params.columnApi.autoSizeColumns(colIds);
    }, 50);    
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    // onRowClicked: updateData,
    // onModelUpdated: updateSelectedRow,
    // onRowDataUpdated: getTotalDefects,
    localeText: localeText,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  // eslint-disable-next-line no-unused-vars
  const transpose = (m) => {
    return m[0].map((c, i) => m.map(r => r[i]));
    // e.g.: const trans = transpose([[1,2,3],[4,5,6],[7,8,9]]); -> [[1,4,7],[2,5,8],[3,6,9]]
  }

  // eslint-disable-next-line no-unused-vars
  const transposeHash = (array) => {
    const transposed = {};
    for(const obj of array) {
      for(const key in obj) {
        if(!transposed[key])transposed[key] = [];
        transposed[key].push(obj[key]);
      }
    }
    return transposed;
  }

  // eslint-disable-next-line no-unused-vars
  const divideArray = (array, key) => {
    const transposed = [];
    let temp = [];
    array.forEach((v, i) => {
      if(v[key]===array[i+1]?.[key]) {
        temp.push(v);
      } else {
        temp.push(v);
        transposed.push(temp);
        temp = [];
      }
    });
    return transposed;
  }

  // React.useEffect(() => {
  //   if(records) {
  //     console.log(`records had updated!!`);
  //   }
  // }, [records]);

  // eslint-disable-next-line no-unused-vars
  const test = () => {
    // console.log(defect);
    // console.log(records);
    // autoSizeAll();
    // const data = divideArray(records, 'volume');
    // console.log(data);
    // console.log(columnDefs);
  }

//-------------------------------------------------------------

  return (
    <Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={open} onClose={handleCloseDlg} fullWidth maxWidth={false}>

        <Box sx={{ display: 'flex', m:2, alignItems:'center' }}>
          <Typography variant="h6" align="center">
            {/* { getLabel('prodution_defectdetail_btn') } */}
            { defect && defect[0]?.name }
          </Typography>
          {/* <Button variant="contained" onClick={test} sx={{ mx:2 }} size="small">Test</Button> */}
          <Box sx={{ flexGrow: 1 }} />

          <IconButton color="inherit" onClick={handleCloseDlg}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ mx:2, mb:2 }}>
          <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '30vh', width: '100%'}}>
            { !aggDestroyed &&
              <AgGridReact
              defaultColDef={defaultColDef}
              columnTypes={columnTypes}
              rowData={records}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              onFirstDataRendered={updateGridHeaders}
            >
            </AgGridReact>
            }
          </div>
        </Box>

        {/* <Box sx={{ display:'flex', m:2 }}>
          <Button variant="contained" onClick={test}>Test</Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" onClick={test}>Close</Button>
        </Box> */}

      </Dialog>

    </Box>
  );
}



