import React from 'react';
import { Box, Button, Stack, Grid, Backdrop, CircularProgress,
  TextField, Select, MenuItem, Menu, IconButton } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from "./ConfirmDialog";

import NumericInput from './NumericInput';
import ConfirmEntry from './ConfirmEntry';
import DefectiveDlg from './DefectiveDialog';
// import DefectiveDlg from './TestDialog';

import TranslateIcon from '@mui/icons-material/Translate';
import { useTheme } from '@mui/material/styles';

export default function Production({ prop }) {
  let navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [ctime, setCtime] = React.useState();
  const [products, setProducts] = React.useState();
  const [section, setSection] = React.useState();
  const [dbconfig, setDbconfig] = React.useState(null);
  const [nonworkings, setNonworkings] = React.useState();

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  const formatDate = (val, dftype, dfdisp) => {
    const date_exp = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) ([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    if(val !== undefined) {
      const chk = date_exp.test(val);
      if(chk) {
        const dt = new Date(val);
        let fdt = '';
        if(dftype === 1) {
          if(dfdisp === 1) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${dt.getFullYear()} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}`;
          }
        } else if(dftype === 2) {
          if(dfdisp === 1) {
            fdt = `${dt.getFullYear()}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')}`;
          }
        }
        return fdt;
      }
      return val;
    } else return '';
  }

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    if(!siteRef.current.authPassed)navigate(`/${config.account}/signin`, { replace: true });

    loadProduction();
    loadNonworking();
    getConfigs();
    checkSection();
    if(dps==='xs')setCtime(getCurrentTime(true));
    else setCtime(getCurrentTime(true) + ', ' + getCurrentDate());
    let interval = setInterval(() => {
      const time = dps==='xs'?getCurrentTime(true):getCurrentTime(true)+', '+getCurrentDate();
      setCtime(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(() =>{
    if(status.back) {
      // loadProducts(status.processes, status.companies);
      setStatus(status => ({...status, back:false}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status.back])

  const loadProduction = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/production`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:status.products, auth:config[config.account].auth}) })
    .then(res => res.json())
    .then(data => {
      // if(data.length>0){
      //   loadDefectDetail(data[0]?.defectptrn);
      // }
      // console.log(data);
      setProducts(data);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    if(products) {
      loadDefectList(products[0].defectptrn);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const [defectList, setDefectList] = React.useState(null);

  const loadDefectList = async(gid) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectlist`;
    const arg = { id:gid, user:config.userid, auth:config[config.account].auth }
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(arg)})
      .then(res => res.json())
      .then(data => {
        data = data.map((v,i) => ({...v, val:0 }));
        setDefectList(data);
    });
  }

  const loadNonworking = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghours`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({auth:config[config.account].auth}) })
    .then(res => res.json())
    .then(data => {
      // setProduction(data[0]);
      setNonworkings(data);
      if(data.length>0) {
        setStatus(v => ({...v, nonworking:data[0].id.toString()}));
      }
      setLoading(false);
    });
  }

  const updateNonworking = (e) => {
    const id = e.target.value;
    setStatus(v => ({...v, nonworking:id.toString()}));
  }

  const getConfigs = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({auth:config[config.account].auth}) }) // body: JSON.stringify(rec)
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      setLoading(false);
    });
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const getCurrentDate = (separator='-') => {
    let mname = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let newDate = new Date()
    let date = newDate.getDate();
    let month = mname[newDate.getMonth()];
    let year = newDate.getFullYear();
    return `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`;
  }

  const getCurrentTime = (seconds=false) => {
    let newDate = new Date()
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${hrs<10?`0${hrs}`:`${hrs}`}:${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `:${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const moveTop = () => {
    setStatus(status => ({...status, processes:'', companies:'', products:'', fentry1:'', fentry2:'', fentry3:'', nonworking:'', filter:'' }));
    navigate(`/${config.account}/`, { replace: true });
  }

  const moveBack = () => {
    setStatus(status => ({...status, back:true, fentry1:'', fentry2:'', fentry3:'', nonworking:''}));
    navigate(`/${config.account}/product`, { replace: true });
  }

  const [numericDlg, setNumericDlg] = React.useState(false);
  const [numQualified, setNumQualified] = React.useState(0);
  const [numUnqualified, setNumUnqualified] = React.useState(0);
  const [defectRemark, setDefectRemark] = React.useState('');
  const [numRework, setNumRework] = React.useState(0);
  const [updateId, setUpdateId] = React.useState();
  const [selectedNum, setSelectedNum] = React.useState(0);

  const openNumericDlg = (event) => {
    setNumericDlg(true);
    setUpdateId(event.target.id);
    if(event.target.id === 'qualified')setSelectedNum(numQualified);
    if(event.target.id === 'unqualified')setSelectedNum(numUnqualified);
    if(event.target.id === 'rework')setSelectedNum(numRework);
  }

  const closeNumericDlg = (value) => {
    if(updateId === 'qualified')setNumQualified(value);
    if(updateId === 'unqualified')setNumUnqualified(value);
    if(updateId === 'rework')setNumRework(value);
    setNumericDlg(false);
  }

  const [confirmDlg, setConfirmDlg] = React.useState(false);

  const openConfirmDlg = (event) => {
    const sum = numQualified + numUnqualified + numRework;
    if(sum === 0) {
      openZeroQty();
    } else {
      setConfirmDlg(true);
    }
  }

  const insertVolume = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/volumeadd`;
    const rec = {
      production:status.products,
      qualified:numQualified,
      rework:numRework,
      unqualified:numUnqualified,
      defectptrn:products[0]?.defectptrn, 
      defectdesc:defectRemark, 
      user:siteRef.current.userid, section:section[0].id, auth:config[config.account].auth }
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
    .then(res => res.json())
    .then(data => {
      // oDfAmt.current = { user:siteRef.current.userid, section:section[0].id, auth:config[config.account].auth };
      const sectionId = section[0]?.id;
      const volumeId = data?.insertId;
      const patternId = products[0]?.defectptrn;
      const rec = { section:sectionId, volume:volumeId, pattern:patternId, defect:defectList, 
        user:siteRef.current.userid, auth:config[config.account].auth };
      // console.log(rec);
      insertDefect(rec);
      setNumQualified(0);
      setNumRework(0);
      setNumUnqualified(0);
      setLoading(false);
    });
  }

  const insertDefect = async(args) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectupdate`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(args) })
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      setLoading(false);
    });
  }

  const checkSection = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectioncheck`;
    const rec = { production:status.products, user:config.userid, auth:config[config.account].auth }
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
    .then(res => res.json())
    .then(data => {
      if(data[0]) {
        data[0].timestf = formatDate(data[0].timest, Number(getDBValue('date_format')), 1);
      }
      setSection(data);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    if(dbconfig) {
      checkSection();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbconfig]);

  const totalRef = React.useRef();

  const checkQuantity = async(id) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/quantitycheck`;
    const rec = { section:id, auth:config[config.account].auth }
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
    .then(res => res.json())
    .then(data => {
      setLoading(false);
      totalRef.current = data[0]?.total;
    });
  }

  const insertSection = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionadd`;
    const rec = { production:status.products, user:siteRef.current.userid, auth:config[config.account].auth };
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
    .then(res => res.json())
    .then(data => {
      checkSection();
      setLoading(false);
    });
  }

  const [dupMachine, setDupMachine] = React.useState(false);

  const duplicationCheck = async() => {
    const mcid = products[0]?.machine;
    if(mcid !== undefined) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/sectiondupcheck`;
      const rec = { machine:mcid, auth:config[config.account].auth };
      await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
      .then(res => res.json())
      .then(data => {
        if(data[0]?.duplication === 0) {
          insertSection();
        } else {
          setDupMachine(true);
        }
      });
    }
  }

  const closeDupMachine = () => {
    setDupMachine(false);
  }

  const cancelSection = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectioncancel`;
    const rec = { id:section[0].id, auth:config[config.account].auth }
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
    .then(res => res.json())
    .then(data => {
      checkSection();
      oDfAmt.current = {};
      setLoading(false);
      setStatus(status => ({...status, fentry1:'', fentry2:'', fentry3:''}));
    });
  }

  const completeSection = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionend`;
    const rec = { production:status.products, user:config.userid,
      fentry1:status.fentry1, fentry2:status.fentry2, fentry3:status.fentry3, nonworking: status.nonworking, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
    .then(res => res.json())
    .then(async(_data) => {
      // console.log(data);
      await recalcEfficiency(section[0]?.id, status.nonworking);
      checkSection();
      setStatus(status => ({...status, fentry1:'', fentry2:'', fentry3:''}));
      setLoading(false);
    });
  }

  const recalcEfficiency = async(section, nonworking) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghourcalc`;
    if(nonworking === undefined || nonworking?.length === 0)nonworking = 0;
    const arg = { section:section, nonworking:nonworking, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(async(data) => {
      // console.log(data);
      await updateUptime(section, data[0]?.uptime_full, data[0]?.uptime_net);
      setLoading(false);
    });
  }

  const updateUptime = async(id, full, net) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionuptime`;
    const arg = { id:id, full:full, net:net, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(data => {
      setLoading(false);
    });
  }

  const closeConfirmDlg = async(value) => {
    if(value === 1) {
      await insertVolume();
     
    } else if(value === 2) {
      await insertVolume();
      await completeSection();
      navigate(`/${config.account}/volume`, { replace:true, 
        state: { model:products.find(v => v.id === Number(status.products)).model,
        name:products.find(v => v.id === Number(status.products)).name }});
    }
    setConfirmDlg(false);
  }

  const moveToVolume = () => {
    navigate(`/${config.account}/volume`, { replace:true, 
      state: { model:products.find(v => v.id === Number(status.products)).model,
      name:products.find(v => v.id === Number(status.products)).name }});
  }

  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  })
  const [dps] = React.useState(getDisplaySize());

  function limitStrLen(str,limit) {
    return str.length<=limit? str:str.substring(0,limit);
  }

  const test = async(e) => {
    // console.log(products[0]?.defectptrn);
    // console.log(section);
    // console.log(defectList);
    // insertDefect();
    // console.log(defectRemark);
    // console.log(products[0]?.machine);
    // checkSection();
    // calcNonworking();
    // console.log(status);
    // console.log(section[0].id);
    // console.log(config);
    // console.log(dbconfig);
    // console.log(section);
    // console.log(products);
    // await test1();
    // await test2();
    // await test3();
  }

  //-----------------------------------------------------------------------------------------------------
  const [endProc, setEndProc] = React.useState(false);
  const [endProcMsg, setEndProcMsg] = React.useState('');
  const [endProcCancel, setEndProcCancel] = React.useState('');

  const openEndProc = async() => {
    checkSection();
    await checkQuantity(section[0].id);
    const fe1 = getDBValue('free_entry_1')==='1'?`"${getDBValue('free_entry_1'.concat('_',config[config.account].language))}"`:'';
    const fe2 = getDBValue('free_entry_2')==='1'?`"${getDBValue('free_entry_2'.concat('_',config[config.account].language))}"`:'';
    const fe3 = getDBValue('free_entry_3')==='1'?`"${getDBValue('free_entry_3'.concat('_',config[config.account].language))}"`:'';
    // const nwk = nonworkings.length>0?`"${getLabel('entry_production_shift')}"`:'';
    // const msg2 = `${fe1}${fe2?', ':''}${fe2}${fe3?', ':''}${fe3}${nonworkings.length>0?', ':''}${nwk}`;
    const msg2 = `${fe1}${fe2?', ':''}${fe2}${fe3?', ':''}${fe3}`;

    let msg = `${getLabel('entry_endproc_itemcheck')}: \n ${msg2}`;
    if(msg2.length===0)msg = getLabel('entry_endproc_confirm');
    if(totalRef.current===0) {
      msg = getLabel('entry_endproc_noquantity');
    } else {
      setEndProcCancel(getLabel('common_cancel'));
    }
    setEndProcMsg(msg);
    setEndProc(true);
  }

  const closeEndProc = (e) => {
    if(e.target.dataset?.return) {
      if(totalRef.current!==0)completeSection();
    }
    setEndProc(false);
  }

  //-----------------------------------------------------------------------------------------------------
  const [zeroQty, setZeroQty] = React.useState(false);

  const openZeroQty = () => {
    setZeroQty(true);
  }

  const closeZeroQty = () => {
    setZeroQty(false);
  }

  //-----------------------------------------------------------------------------------------------------
  const getProcessInfo = (products) => {
    const pname = products.find(v => v.id === Number(status.products))
    [`pcname${config[config.account].language==='en'?'_en':config[config.account].language==='th'?'_th':''}`];
    const sname = products.find(v => v.id === Number(status.products)).sname;
    return `${pname} ${sname?'/':''} ${sname?sname:''}`;
  }

  //-----------------------------------------------------------------------------------------------------
  const [defectDlg, setDefectDlg] = React.useState(false);
  const oPtrn = React.useRef({});
  const oDfAmt = React.useRef({});
  
  const openDefectDlg = () => {
    setSelectedNum(numUnqualified);
    setDefectDlg(true);
  }
  const closeDefectDlg = (obj) => {
    setDefectDlg(false);
    if(obj) {
      setNumUnqualified(obj?.sumAmt);
      oDfAmt.current = obj;
    }
  }

  // const loadDefectDetail = (idNum) => {
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/defectptrns`;
  //   const rec = { id:idNum, user:config.userid, auth:config[config.account].auth }
  //   setLoading(true);
  //   fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
  //   .then(res => res.json())
  //   .then(data => {
  //     if(data[0]) {
  //       oPtrn.current = data[0];
  //     }
  //     setLoading(false);
  //   });
  // };

  const updateDefectTotal = (val) => {
    setNumUnqualified(val);
  }

  const updateDefectRemark = (val) => {
    setDefectRemark(val);
  }


  //-----------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={endProc}
        onClose={closeEndProc}
        title={ getLabel('entry_endproc') }
        msg={ endProcMsg }
        confirm={ getLabel('common_confirm') }
        cancel={ endProcCancel }
      />

      <ConfirmDialog 
        open={zeroQty}
        onClose={closeZeroQty}
        title={ getLabel('entry_zero_quantity') }
        msg={ getLabel('entry_zero_quantity_msg') }
        confirm={ getLabel('common_confirm') }
      />

      <ConfirmDialog 
        open={dupMachine}
        onClose={closeDupMachine}
        title={ getLabel('entry_duplicated') }
        msg={ getLabel('entry_duplicated_msg') }
        confirm={ getLabel('common_confirm') }
      />

      <Box sx={{ p:2, pb:1, display:'flex', width:'100%' }}>
        <Button variant="contained" onClick={moveTop}>{ getLabel('entry_select_process') }</Button>
        <Button sx={{ ml:1 }} variant="contained" onClick={moveBack}>{ getLabel('entry_select_parts') }</Button>
        <Box sx={{ flexGrow: 1 }}/>

        <IconButton
          color="inherit"
          sx={{ mr: 1 }}
          onClick={languageClick}
        >
          <TranslateIcon fontSize="small"/>
        </IconButton>
        <Menu
          anchorEl={anchorLanguage}
          open={selectLanguage}
          onClose={languageClose}
        >
          <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account].language==='jp'?true:false}>日本語</MenuItem>
          <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account].language==='en'?true:false}>English</MenuItem>
          <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account].language==='th'?true:false}>ไทย</MenuItem>
        </Menu>

        <Button variant="outlined" onClick={test} id="test">{ ctime }</Button>
      </Box>

      <Grid container>

        {status.products && products &&
        <Grid item xs={12} md={12}>
          <Box sx={{ px:2, pr:1, py:1 }}>
            <Stack direction="row">
              <Button onClick={test} sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                { getLabel('entry_model') }</Button>
              <Button sx={{ mr:1, mb:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }}
                variant="outlined">{ products && products.find(v => v.id === Number(status.products)).pmodel}</Button>
            </Stack>

            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                { getLabel('entry_name') }</Button>
              <Button sx={{ mr:1, mb:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }} 
                variant="outlined">{ products && products.find(v => v.id === Number(status.products)).pname}</Button>
            </Stack>

            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                { getLabel('machine_info') }</Button>
              <Button sx={{ mr:1, mb:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }} 
                variant="outlined">
                { products && products.find(v => v.id === Number(status.products)).maname}&nbsp;/&nbsp;
                { products && products.find(v => v.id === Number(status.products)).matype}</Button>
            </Stack>

            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                { getLabel('production_process') }</Button>
              <Button sx={{ mr:1, mb:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }} variant="outlined">
                { products && getProcessInfo(products) }
              </Button>
            </Stack>

            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                { getLabel('entry_qualified') }</Button>
              <Button id='qualified' sx={{ mr:1, mb:1, width:'100%',  fontSize:dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }}
                onClick={openNumericDlg} variant="outlined"
                disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false}>{ numQualified }</Button>
                <NumericInput 
                  selectedValue={selectedNum}
                  open={numericDlg}
                  onClose={closeNumericDlg}
                />
            </Stack>

            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">{ getLabel('entry_rework') }</Button>
              <Button id='rework' sx={{ mr:1, mb:1, width:'100%',  fontSize:dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }}
                onClick={openNumericDlg} variant="outlined"
                disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false}>{ numRework }</Button>
            </Stack>

            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'39.7%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">{ getLabel('entry_unqualified') }</Button>
              {
                (products && products[0].defectptrn)?
                  (<Stack direction="row" sx={{ width:'100%' }}>
                    <Button id='unqualified' sx={{ mr:1, mb:1, width:'60%',  fontSize:dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }}
                      disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false} variant="outlined">
                        { numUnqualified }
                    </Button>
                    <Button id='dftDetails' sx={{ mr:1, mb:1, width:'40%',  fontSize:dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-center" }}
                      onClick={openDefectDlg} variant="contained" disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false}
                    >
                      {getLabel('prodution_defectdetail_btn')?getLabel('prodution_defectdetail_btn'):'Defect Details'}
                    </Button>
                  </Stack> ) 
                  : (<Button id='unqualified' sx={{ mr:1, mb:1, width:'100%',  fontSize:dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start" }}
                    onClick={openNumericDlg} variant="outlined"
                    disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false}>
                      { numUnqualified }
                  </Button>
                )}
            </Stack>
            { 
              oPtrn && oDfAmt && 
              <DefectiveDlg
                open={defectDlg}
                onClose={closeDefectDlg}

                oPtrn={oPtrn.current}
                oDfAmt={oDfAmt.current}
                source={'prd'}

                defect={defectList}
                onUpdateDefectTotal={updateDefectTotal}
                onUpdateDefectRemark={updateDefectRemark}
              />
            }
            
            { getDBValue('free_entry_1')==='1' &&
              <Stack direction="row">
                <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                  { getDBValue('free_entry_1'.concat('_',config[config.account].language)) }</Button>
                <TextField id="fentry1" size="small" variant="outlined" InputProps={{ style: { fontSize: dps==='xs'||dps==='sm'?'small':'large' } }} multiline
                  value={ status.fentry1?status.fentry1:'' } onChange={(e) => setStatus(status => ({...status, fentry1:limitStrLen(e.target.value,255)})) }
                  sx={{ mr:1, mb:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start",  }}
                  disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false} />
              </Stack>
            }
            { getDBValue('free_entry_2')==='1' &&
              <Stack direction="row">
                <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                  { getDBValue('free_entry_2'.concat('_',config[config.account].language)) }</Button>
                <TextField id="fentry2" size="small" variant="outlined" InputProps={{ style: { fontSize: dps==='xs'||dps==='sm'?'small':'large' } }} multiline
                  value={ status.fentry2?status.fentry2:'' } onChange={(e) => setStatus(status => ({...status, fentry2:limitStrLen(e.target.value,255)})) }
                  sx={{ mr:1, mb:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start",  }}
                  disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false} />
              </Stack>
            }
            { getDBValue('free_entry_3')==='1' &&
            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">
                { getDBValue('free_entry_3'.concat('_',config[config.account].language)) }</Button>
              <TextField id="fentry3" size="small" variant="outlined" InputProps={{ style: { fontSize: dps==='xs'||dps==='sm'?'small':'large' } }} multiline
                  value={ status.fentry3?status.fentry3:'' } onChange={(e) => setStatus(status => ({...status, fentry3:limitStrLen(e.target.value,255)})) }
                  sx={{ mr:1, mb:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'large', justifyContent: "flex-start",  }}
                  disabled={(config[config.account].userrole===4 || (section&&section.length<1))?true:false} />
            </Stack>
            }

            { nonworkings && nonworkings.length > 0 &&
            <Stack direction="row">
              <Button sx={{ mr:1, mb:1, width:'40%', fontSize: dps==='xs'||dps==='sm'?'small':'large' }} variant="contained">{ getLabel('entry_production_shift') }</Button>
              <Select sx={{ mr:1, width:'100%', fontSize: dps==='xs'||dps==='sm'?'small':'medium' }}
                id="select-shift" size="small" fontSize={dps==='xs'||dps==='sm'?'small':'medium'}
                defaultValue={ nonworkings && nonworkings.length > 0?nonworkings[0].id:'' }
                onChange={updateNonworking}
                disabled={(config[config.account].userrole===4)?true:false}
              >
                { nonworkings && nonworkings.map(v => (
                <MenuItem sx={{ fontSize:dps==='xs'||dps==='sm'?'small':'large'}} key={ `${v.id}-${v.name}` } value={v.id}>{ v.name }</MenuItem> 
                ))}
              </Select>
            </Stack>
            }

            <Box sx={{ p:1, pt:1, pl:0, display:'flex', width:'100%' }}>
              <Button sx={{ fontSize: dps==='xs'||dps==='sm'?'small':'large', width:'100%' }} variant="contained"
                onClick={moveToVolume}>{ getLabel('entry_breakdown') }</Button>
              <Box sx={{ flexGrow: 1 }}/>
              { section && section.length>0 &&
                <Button sx={{ fontSize: dps==='xs'||dps==='sm'?'small':'large', width:'100%', ml:1 }} variant="contained" onClick={cancelSection}
                  disabled={(config[config.account].userrole===4)?true:false}>
                  { getLabel('entry_process_cancel') }
                </Button>
              }
            </Box>

            <Box sx={{ p:1, pt:0, pl:0, display:'flex', width:'100%' }}>
              <Button sx={{ fontSize: dps==='xs'||dps==='sm'?'small':'large', width:'100%' }} variant="contained" onClick={duplicationCheck}
              disabled={(config[config.account].userrole===4 || (section && section.length>0))?true:false}>
                { dps==='xs' || dps==='sm'? section&&section.length>0?section[0].timestf:getLabel('entry_process_start') 
                : section&&section.length>0? getLabel('entry_process_start') +' :  '+ section[0].timestf:getLabel('entry_process_start')}
              </Button>

              <Button sx={{ fontSize:dps==='xs'||dps==='sm'?'small':'large', width:'100%', ml:1 }} variant="contained" onClick={openConfirmDlg}
                disabled={(config[config.account].userrole!==4 && (section && section.length>0))?false:true}>{ getLabel('entry_register') }</Button>
              <ConfirmEntry 
                model={products && products.find(v => v.id === Number(status.products)).pmodel}
                name={products && products.find(v => v.id === Number(status.products)).pname}
                qualified={numQualified}
                unqualified={numUnqualified}
                rework={numRework}
                open={confirmDlg}
                onClose={closeConfirmDlg}
              />

              <Button sx={{ fontSize: dps==='xs'||dps==='sm'?'small':'large', width:'100%', ml:1 }} variant="contained" onClick={openEndProc}
                disabled={(config[config.account].userrole!==4 && (section && section.length>0))?false:true}>
                { getLabel('entry_process_end') }
              </Button>
            </Box>

          </Box>
        </Grid>
        }
      </Grid>

    </Box>
  );
}

