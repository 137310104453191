import React from 'react';
import { Box, Typography, Button, Stack} from '@mui/material';
// import { useNavigate, useLocation } from 'react-router-dom';

export default function NotFound({ prop }) {

  React.useEffect(() => {
    // console.log('not found...');
  }, []);

  const backPrevious = () => {
    window.history.back();
  }

  return (
    <Box component={Stack} direction="column" justifyContent="center" sx={{ height:'100vh'}}>
      <Typography sx={{ p:2, textAlign:"center" }} variant="h4">Contents Not Found</Typography>
      <Typography sx={{ p:2, textAlign:"center" }} variant="h6">We're sorry, the content you request could not be found. Please go back to the previous page.</Typography>
      <Box sx={{ m:2, textAlign:"center" }}>
      <Button variant="contained" onClick={backPrevious}>Back to the previous page</Button>
      </Box>
      <Box sx={{ m:3, textAlign:"center" }}>
      <img src="logo_color_dark.png" height="32px" alt="DBord logo" />
      </Box>
    </Box>
  );

}
