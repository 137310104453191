import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Backdrop, Box, Button, CircularProgress, Autocomplete, 
  Grid, TextField, Stack, FormControl, InputLabel, Select,
  MenuItem, FormHelperText, ToggleButtonGroup, ToggleButton, Dialog } from '@mui/material';
import WeekPicker from './WeekPicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale_en from 'date-fns/locale/en-US';
import locale_ja from 'date-fns/locale/ja';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// import Record from '../Record';
// import ConfirmDialog from '../ConfirmDialog';
// import DefectiveDlg from '../DefectTableDialog';
// import { systemStatus } from "../../atoms/systemStatus";
import WorstlistDialog from './WorstlistDialog';

export default function WorstListReport () {
  const debug = false;
  const simple = true;
  const location = useLocation();
  const navigate = useNavigate();

  if(locale_en && locale_ja) {
    locale_en.options.weekStartsOn = 0;
    locale_ja.options.weekStartsOn = 0;
  }

  // const [temp, setTemp] = React.useState();
  // const loadTemp = async() => {
  //   const tempFile = 'general.xlsx'; // B4 -> 1st header, B5 -> 1st item 
  //   fetchTemplate(tempFile);
  // }

  // const fetchTemplate = async(filename) => {
  //   const args = { auth:config[config.account]?.auth, filename:filename };
  //   const url = `${config[config.account]?.url}:${config[config.account]?.port}/api/template`;
  //   if(!config || !config[config.account]?.auth)return;
  //   await fetch(url,
  //     { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(args) })
  //     .then(res => res.arrayBuffer()) // response by array buffer
  //     .then(data => {
  //     setTemp(data);
  //   });
  // }

  const [config] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  // const [status, setStatus] = useRecoilState(systemStatus);
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  const [dbconfig, setDbconfig] = React.useState(null);
  const [helpers, setHelpers] = React.useState({ rtype:'' });
  const [locale, setLocale] = React.useState();
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    setLocale(lang==='jp'?locale_ja:locale_en);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp?.value?tmp.value:'';
  }

  const getConfigs = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    setLoading(true);
    fetch(url, { method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    if(!simple) {
      loadCompanies();
      loadMachines();
      loadMagterials();
      loadProcesses();
      loadProducts();
      loadUsers();
    }
    initGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, dbconfig]);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    setColumns();
    loadDefault();
    getConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getDateStamp = (sdate) => {
  //   let yar = sdate.getFullYear();
  //   let mth = sdate.getMonth()+1;
  //   let dte = sdate.getDate();
  //   const tmp = `${dte<10?`0${dte}`:`${dte}`}/${mth<10?`0${mth}`:`${mth}`}/${yar}`;
  //   return tmp;
  // }

  const inputValidation = (data) => {
    let validated = true;
    if(!isNumber(data)) {
      setHelpers(helpers => ({...helpers, rtype:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, rtype:''}));
    return validated;
  }

  function isNumber(numVal){
    var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
    return pattern.test(numVal);
  } 

  // ------------------ variables for report period selection ------------------
  const [rcategory, setRcategory] = React.useState(1);
  const [rbkcategory, setRbkcategory] = React.useState(rcategory);
  const [rtype, setRtype] = React.useState(3);
  const [rcount, setRcount] = React.useState(10);

  React.useEffect(() => {
    // console.log(rtype);
    if(rtype===3) { calcRmonth(rmonth); }
    if(rtype===5) { calcRyear(ryear); }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtype]);


  const rcategoryChange = (event) => {
    const val = event.target.value
    setRcategory(val);
  }

  const rcountChange = (event) => {
    const val = event.target.value
    setRcount(val);
  }

  const rtypeChange = (event) => {
    const val = event.target.value
    setRtype(val);
    switch(val) {
      case 1:
        calcRday(rday);
        break;
      case 2:
        calcRweek(rweek);
        break;
      case 3:
        calcRmonth(rmonth);
        break;
      case 4:
        calcRqmonth(rquarter);
        break;
      case 5:
        calcRyear(ryear);
        break;
      case 6:
        calcRangeFrom(rday);
        calcRangeTo(tday);
        break;
        default:
    }
    inputValidation(val);
  };

  const [rday, setRday] = React.useState(new Date());
  const [tday, setTday] = React.useState(new Date());

  const rangeFromChage = (event) => {
    setRday(event);
    calcRangeFrom(event);
  }
  const calcRangeFrom = (date) => {
    const dt = new Date(date);
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
  }
  const rangeToChage = (event) => {
    setTday(event);
    calcRangeTo(event);
  }
  const calcRangeTo = (date) => {
    const dt = new Date(date);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const rdayChange = (event) => {
    setRday(event);
    calcRday(event);
  };
  const calcRday = (date) => {
    const dt = new Date(date);
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const [rweek, setRweek] = React.useState(new Date());
  const rweekChange = (event) => {
    setRweek(event);
    calcRweek(event);
  };
  const calcRweek = (date) => {
    const mon = getMonday(date);
    const sun = getSunday(date);
    const from = new Date(`${mon.getFullYear()}/${mon.getMonth()+1}/${mon.getDate()} 00:00:00`);
    setRfrom(from);
    const to = new Date(`${sun.getFullYear()}/${sun.getMonth()+1}/${sun.getDate()} 23:59:59`);
    setRto(to);
  }

  const [rmonth, setRmonth] = React.useState(new Date());
  const rmonthChange = (event) => {
    setRmonth(event);
    calcRmonth(event);
  };
  const calcRmonth = (date) => {
    const dt = new Date(date);
    dt.setDate(1); // the first day of the month
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    dt.setDate(1); // the final day of the month
    dt.setMonth(dt.getMonth() + 1);
    dt.setDate(0);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const defaultDate = () => {
    const dt = new Date();
    // console.log(dt);
    return new Date(`${dt.getFullYear()}/01/01 00:00:00`);
  }
  const [rqmonth, setRqmonth] = React.useState(defaultDate);
  const rqmonthChange = (event) => {
    setRqmonth(event);
  };

  const [rquarter, setRquarter] = React.useState('0');
  const rquarterChange = (event, value) => {
    if(value !== null) {
      setRquarter(value);
      calcRqmonth(value);
    }
  };
  const calcRqmonth = (value) => {
    const dt = new Date(rqmonth);
    const mul = value * 3;
    dt.setMonth(dt.getMonth() + mul);
    dt.setDate(1); // the first day of the month
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    dt.setMonth(dt.getMonth() + 3);
    dt.setDate(0); // the final day of the 3rd month * 
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const [ryear, setRyear] = React.useState(new Date());
  const ryearChange = (event) => {
    setRyear(event);
    calcRyear(event);
  };
  const calcRyear = (value) => {
    const date = new Date(value);
    const from = new Date(`${date.getFullYear()}/01/01 00:00:00`);
    const to = new Date(`${date.getFullYear()}/12/31 23:59:59`);
    setRfrom(from);
    setRto(to);
  }

  const [rfrom, setRfrom] = React.useState();
  const [rto, setRto] = React.useState();
  // ------------------ report period selection variables ------------------

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1);
    return new Date(d.setDate(diff));
  }

  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? 0:7);
    return new Date(d.setDate(diff));
  }

  // ------------------ report main key items ------------------
  const [group, setGroup] = React.useState();
  const [selectedGroup, setSelectedGroup] = React.useState('product');

  const initGroup = () => {
    setGroup([
      {id:'company', name:getLabel('worstlist_group_company'), wekey:'tbl.company_id', match:'company_id', dfkey:'production.company', hskey:'production.company' },
      {id:'machine', name:getLabel('worstlist_group_machine'), wekey:'tbl.machine_id', match:'machine_id', dfkey:'production.machine', hskey:'production.machine' },
      {id:'material', name:getLabel('worstlist_group_material'), wekey:'tbl.material_id', match:'material_id', dfkey:'production.material', hskey:'production.material' },
      {id:'process', name:getLabel('worstlist_group_process'), wekey:'tbl.process_id', match:'process_id', dfkey:'production.process', hskey:'production.process' },
      {id:'sequence', name:getLabel('worstlist_group_sequence'), wekey:'tbl.sequence_id', match:'sequence_id', dfkey:'production.sequence', hskey:'production.sequence' },
      {id:'product', name:getLabel('worstlist_group_product'), wekey:'tbl.product_id', match:'product_id', dfkey:'production.product', hskey:'production.product' },
      {id:'register', name:getLabel('worstlist_group_register'), wekey:'tbl.userreg', match:'userreg', dfkey:'production.userreg', hskey:'src.userreg' },
      {id:'update', name:getLabel('worstlist_group_update'), wekey:'tbl.userupd', match:'userupd', dfkey:'production.userupd', hskey:'src.userupd' },
    ]);
    const ap1 = config[config.account].language==='en'?getDBValue('approval_1_en'):
      config[config.account].language==='th'?getDBValue('approval_1_th'):getDBValue('approval_1_jp');
    const ap2 = config[config.account].language==='en'?getDBValue('approval_2_en'):
      config[config.account].language==='th'?getDBValue('approval_2_th'):getDBValue('approval_2_jp');
    const ap3 = config[config.account].language==='en'?getDBValue('approval_3_en'):
      config[config.account].language==='th'?getDBValue('approval_3_th'):getDBValue('approval_3_jp');
    if(getDBValue('approval_1')==='1') {
      setGroup(v => [...v, {id:'appr1', name:ap1, wekey:'tbl.approval1', match:'approval1', dfkey:'section.approval1', hskey:'src.approval1' },]);
    }
    if(getDBValue('approval_2')==='1') {
      setGroup(v => [...v, {id:'appr2', name:ap2, wekey:'tbl.approval2', match:'approval2', dfkey:'section.approval2', hskey:'src.approval2' },]);
    }
    if(getDBValue('approval_3')==='1') {
      setGroup(v => [...v, {id:'appr3', name:ap3, wekey:'tbl.approval3', match:'approval3', dfkey:'section.approval3', hskey:'src.approval3' },]);
    }
  }

  // -------------------- Customer info  
  const [companies, setCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  React.useEffect(() => {
    if(companies.length > 0)setSelectedCompany(companies[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const loadCompanies = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/companies`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`, dept:'', field:'client_name' });
      setCompanies(data);
      setLoading(false);
    });
  }
// -------------------- Machine info  
  const [machines, setMachines] = React.useState([]);
  const [selectedMachine, setSelectedMachine] = React.useState(null);

  React.useEffect(() => {
    if(machines.length > 0)setSelectedMachine(machines[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines]);

  const loadMachines = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/machines`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`});
      setMachines(data);
      setLoading(false);
    });
  }
// -------------------- Material info  
  const [materials, setMaterials] = React.useState([]);
  const [selectedMaterial, setSelectedMaterial] = React.useState(null);

  React.useEffect(() => {
    if(materials.length > 0)setSelectedMaterial(materials[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials]);

  const loadMagterials = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/materials`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`});
      setMaterials(data);
      setLoading(false);
    });
  }
// -------------------- Process info  
  const [processes, setProcesses] = React.useState([]);
  const [selectedProcess, setSelectedProcess] = React.useState(null);

  React.useEffect(() => {
    if(processes.length > 0) {
      setSelectedProcess(processes[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processes]);

  const loadProcesses = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/processes`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`, name_en:`[ ${getLabel('common_all')} ]`, name_th:`[ ${getLabel('common_all')} ]`});
      setProcesses(data);
      setLoading(false);
    });
  }
// -------------------- Sequence info  
  const [sequences, setSequences] = React.useState([]);
  const [selectedSequence, setSelectedSequence] = React.useState(null);

  React.useEffect(() => {
    if(sequences.length > 0)setSelectedSequence(sequences[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequences]);

  React.useEffect(() => {
    if(selectedProcess && selectedProcess?.id !== 0) {
      setSelectedSequence(sequences[0]);
      loadSequences();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProcess]);

  const loadSequences = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/sequenceprocess`;
    fetch(url, { method: 'POST' ,headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ process:selectedProcess.id, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, name:`[ ${getLabel('common_all')} ]`});
      setSequences(data);
      setLoading(false);
    });
  }
// -------------------- Product info  
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  React.useEffect(() => {
    if(products.length > 0) {
      setSelectedProduct(products[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const loadProducts = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/products`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({id:0, model:`[ ${getLabel('common_all')} ]`, name:`[ ${getLabel('common_all')} ]`});
      setProducts(data);
      setLoading(false);
    });
  }
// -------------------- Register, Update and approval user info  
  const [regUsers, setRegUsers] = React.useState([]);
  const [selectedRegUser, setSelectedRegUser] = React.useState(null);
  const [updUsers, setUpdUsers] = React.useState([]);
  const [selectedUpdUser, setSelectedUpdUser] = React.useState(null);
  const [ap1Users, setAp1Users] = React.useState([]);
  const [selectedAp1User, setSelectedAp1User] = React.useState(null);
  const [ap2Users, setAp2Users] = React.useState([]);
  const [selectedAp2User, setSelectedAp2User] = React.useState(null);
  const [ap3Users, setAp3Users] = React.useState([]);
  const [selectedAp3User, setSelectedAp3User] = React.useState(null);

  React.useEffect(() => {
    if(regUsers.length > 0 && updUsers.length > 0) {
      setSelectedRegUser(regUsers[0]);
      setSelectedUpdUser(updUsers[0]);
      setSelectedAp1User(ap1Users[0]);
      setSelectedAp2User(ap2Users[0]);
      setSelectedAp3User(ap3Users[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regUsers, updUsers]);

  const loadUsers = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/users`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      data.unshift({userid:0, username:`[ ${getLabel('common_all')} ]`});
      setRegUsers(data);
      setUpdUsers(data);
      setAp1Users(data);
      setAp2Users(data);
      setAp3Users(data);
      setLoading(false);
    });
  }

  // const [noSummary, setNoSummary] = React.useState(false);
  // const openNoSummary = () => setNoSummary(true);
  // const closeNoSummary = () => setNoSummary(false);

// -------------------- getting the reports  

  const getWorstList = async(type) => {
    setLoading(true);
    const groupkey = group.filter(v => v.id === selectedGroup)[0]?.wekey; 
    const datefr = `${rfrom.toLocaleDateString('sv-SE')} 00:00:00`;
    const dateto = `${rto.toLocaleDateString('sv-SE')} 23:59:59`;
    setColumns();
    const url = `${config[config.account].url}:${config[config.account].port}/api/worst_list`;
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth, group:groupkey, datefr:datefr, dateto:dateto, type:type ,limit:rcount })})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      setDataList(data);
      setLoading(false);
    });
  }

// -------------------- AGGrid related  

  const [recordData, setRecordData] = React.useState({});
  const [listCond, setListCond] = React.useState({});

  const captionRef = React.useRef();
  captionRef.current = getLabel('common_detail');

  const timeFormat = (val) => { // minutes -> hours
    const time = val.data?.uptime || 0;
    const format = (time / 60).toLocaleString();
    return format;
  }

  const buttonRenderer = (params) => {
    const buttonItem = (_event) => {
      setRecordData(params.data);
      let groupkey = group.filter(v => v.id === selectedGroup)[0]?.wekey; //tukey;
      let match = group.filter(v => v.id === selectedGroup)[0]?.match;
      let defect = group.filter(v => v.id === selectedGroup)[0]?.dfkey; 
      let history = group.filter(v => v.id === selectedGroup)[0]?.hskey; 
      // if (rcategory !== 1) {
      //   groupkey = group.filter(v => v.id === selectedGroup)[0]?.wekey; 
      // }
      const datefr = `${rfrom.toLocaleDateString('sv-SE')} 00:00:00`;
      const dateto = `${rto.toLocaleDateString('sv-SE')} 23:59:59`;
      setListCond({ category:rcategory, group:groupkey, period:rtype, datefr:datefr, dateto:dateto, limit:rcount, match:match, defect:defect, history:history });
      // recorddlgOpen();
      // console.log(params.data);
      setDetail(true); 
    }
    if(params.data.sid !== undefined)return (
      <Box sx={{ mt:0.5, display:'flex', justifyContent:'center'}}>
        <Button variant="contained" color="primary" size="small" onClick={buttonItem} >
        { captionRef.current }
        </Button>
      </Box>
    );
    return ('');
  }

  const valRound = (value, base) => {
    value = value.replace(/,/g, "");
    value = Math.round(value * base) / base; 
    return String(value);
  }

  // eslint-disable-next-line no-unused-vars
  const valCeil = (value, base) => {
    value = value.replace(/,/g, "");
    return Math.ceil(value * base) / base;
  }

  // eslint-disable-next-line no-unused-vars
  const valFloor = (value, base) => {
    value = value.replace(/,/g, "");
    return Math.floor(value * base) / base;
  }

  const modelUnqualified = [
    {headerName:getLabel('worstlist_uptime'), field:'uptime', valueFormatter:val=>timeFormat(val), type:'rightAligned'},
    {headerName:getLabel('worstlist_qualified'), field:'qualified', valueFormatter:val => val.data?.qualified?.toLocaleString() || '', type:'rightAligned'},
    {headerName:getLabel('worstlist_unqualified'), field:'unqualified', valueFormatter:val => val.data?.unqualified?.toLocaleString() || '', type:'rightAligned'},
    {headerName:getLabel('worstlist_defect_ratio'), field:'defect_ratio', valueFormatter:val => valRound(val.data?.defect_ratio?.toLocaleString(), 10) || '', type:'rightAligned'},
  ];

  const modelEfficiency = [
    {headerName:getLabel('worstlist_uptime'), field:'uptime', valueFormatter:val=>timeFormat(val), type:'rightAligned'},
    {headerName:getLabel('worstlist_target'), field:'target', valueFormatter:val => val.data?.target?.toLocaleString() || '', type:'rightAligned'},
    {headerName:getLabel('worstlist_qualified'), field:'qualified', valueFormatter:val => val.data?.qualified?.toLocaleString() || '', type:'rightAligned'},
    {headerName:getLabel('worstlist_efficiency'), field:'erate', valueFormatter:val => valRound(val.data?.erate?.toLocaleString(), 10) || '', type:'rightAligned'},
  ];

  // const modelUnqualified = [
  //   {headerName:getLabel('worstlist_uptime'), field:'uptime', valueFormatter:val=>timeFormat(val), type:'rightAligned'},
  //   {headerName:getLabel('worstlist_qualified'), field:'qualified', valueFormatter:val => val.data?.qualified?.toLocaleString() || '', type:'rightAligned'},
  //   {headerName:getLabel('worstlist_unqualified'), field:'unqualified', valueFormatter:val => val.data?.unqualified?.toLocaleString() || '', type:'rightAligned'},
  //   {headerName:getLabel('worstlist_defect_ratio'), field:'defect_ratio', valueFormatter:val => val.data?.defect_ratio?.toLocaleString() || '', type:'rightAligned'},
  // ];

  // const modelEfficiency = [
  //   {headerName:getLabel('worstlist_uptime'), field:'uptime', valueFormatter:val=>timeFormat(val), type:'rightAligned'},
  //   {headerName:getLabel('worstlist_target'), field:'target', valueFormatter:val => val.data?.target?.toLocaleString() || '', type:'rightAligned'},
  //   {headerName:getLabel('worstlist_qualified'), field:'qualified', valueFormatter:val => val.data?.qualified?.toLocaleString() || '', type:'rightAligned'},
  //   {headerName:getLabel('worstlist_efficiency'), field:'erate', valueFormatter:val => val.data?.erate?.toLocaleString() || '', type:'rightAligned'},
  // ];

  const [, setGridApi] = React.useState({});
  const [, setGridColumnApi] = React.useState({});
  const [columnDefs, setColumnsDefs] = React.useState([]);

  const setColumns = () => {
    const ap1 = config[config.account].language==='en'?getDBValue('approval_1_en'):
      config[config.account].language==='th'?getDBValue('approval_1_th'):getDBValue('approval_1_jp');
    const ap2 = config[config.account].language==='en'?getDBValue('approval_2_en'):
      config[config.account].language==='th'?getDBValue('approval_2_th'):getDBValue('approval_2_jp');
    const ap3 = config[config.account].language==='en'?getDBValue('approval_3_en'):
      config[config.account].language==='th'?getDBValue('approval_3_th'):getDBValue('approval_3_jp');
    switch(selectedGroup) {
      case 'company':
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_company_abbr'), field:'cabbr'});
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_company'), field:'customer'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_company_abbr'), field:'cabbr'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_company'), field:'customer'});
        break;
      case 'machine':
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_machine_type'), field:'mctype'});
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_machine'), field:'mcname'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_machine_type'), field:'mctype'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_machine'), field:'mcname'});
        break;
      case 'material':
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_material_type'), field:'matmodel'});
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_material'), field:'matname'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_material_type'), field:'matmodel'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_material'), field:'matname'});
        break;
      case 'process':
        // modelUnqualified.unshift({headerName:'Sequence', field:'seqname'});
        const pname = config[config.account].language==='en'?'prcname_en':
          config[config.account].language==='th'?'prcname_th':'prcname';
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_process'), field:pname});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_process'), field:pname});
        break;
      case 'sequence':
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_sequence'), field:'seqname'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_sequence'), field:'seqname'});
        // modelUnqualified.unshift({headerName:'Process', field:'prcname'});
        break;
      case 'product':
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_product'), field:'prdname'});
        modelUnqualified.unshift({headerName:getLabel('worstlist_productmodel'), field:'prdmodel'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_product'), field:'prdname'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_productmodel'), field:'prdmodel'});
        break;
      case 'register':
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_register'), field:'userreg'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_register'), field:'userreg'});
        break;
      case 'update':
        modelUnqualified.unshift({headerName:getLabel('worstlist_group_update'), field:'userupd'});
        modelEfficiency.unshift({headerName:getLabel('worstlist_group_update'), field:'userupd'});
        break;
      case 'appr1':
        modelUnqualified.unshift({headerName:ap1, field:'userupd'});
        modelEfficiency.unshift({headerName:ap1, field:'userupd'});
        break;
      case 'appr2':
        modelUnqualified.unshift({headerName:ap2, field:'approval2'});
        modelEfficiency.unshift({headerName:ap2, field:'approval2'});
        break;
      case 'appr3':
        modelUnqualified.unshift({headerName:ap3, field:'approval3'});
        modelEfficiency.unshift({headerName:ap3, field:'approval3'});
        break;
        default:
    }
    const detail = { headerName: getLabel('common_detail'), field: "detail", lockPinned: true, pinned: "right", cellRenderer: "ButtonCellRenderer", sortable: false, filter: false };
    modelUnqualified.unshift(detail);
    modelEfficiency.unshift(detail);

    if (rcategory === 1) {
      setColumnsDefs(modelUnqualified);
    } else {
      setColumnsDefs(modelEfficiency);
    }
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
  }

  const [dataList, setDataList] = React.useState([]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const updateGridHeaders = (params) => {
    params.api.sizeColumnsToFit();
    window.setTimeout(() => {
      const colIds = params.columnApi.getAllColumns().map(c => c.colId);
      params.columnApi.autoSizeColumns(colIds);
    }, 50);
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
  };

  const gridOptions = {
    columnDefs: columnDefs,
    components: {
      ButtonCellRenderer: buttonRenderer,
    },
    rowSelection: 'single',
    localeText: localeText,
  };

  const displayList = async() => {
    if (rcategory === 1) {
      // await getTopUnqualified();
      await getWorstList(true);
    } else {
      // await getWorstEfficiency();
      await getWorstList(false);
    }
    setRbkcategory(rcategory);
  }

// ----------------------------------------------------------------------
  const [detail, setDetail] = React.useState(false);

  const closeDetail = () => {
    setDetail(false);
  }

  const moveTop = () => {
    navigate(`/${config.account}/`, { replace: true });
  }

  const test = async() => {
    // console.log(`-------------------------------------------------`);
    // console.log(`Category: ${rcategory}`);
    // console.log(`Period: ${rtype}`);
    // console.log(`Counts: ${rcount}`);
    // console.log(`Period Type: ${rtype}`);
    // console.log(`Month: ${rmonth}`);
    // console.log(`Year: ${ryear}`);
    // console.log(selectedGroup);
    // console.log(selectedCompany);
    // console.log(selectedMachine);
    // console.log(selectedMaterial);
    // console.log(selectedProcess);
    // console.log(selectedSequence);
    // console.log(selectedProduct);
    // console.log(selectedRegUser);
    // console.log(selectedUpdUser);
    // console.log(selectedAp1User);
    // console.log(selectedAp2User);
    // console.log(selectedAp3User);
    // console.log(`top unqualified ------------------------------------------------------------`);
    // await getTopUnqualified();
    // console.log(`worst efficiency ------------------------------------------------------------`);
    // await getWorstEfficiency();
    // getWorstList(true);
  }

  // -------------------- Feed view --------------------  
  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={detail} onClose={closeDetail} fullScreen={true}>
        {/* <WorstlistDialog onClose={closeDetail} recordData={recordData} uncompleted={uncompleted} open={detail}/> */}
        <WorstlistDialog onClose={closeDetail} open={detail} recordData={recordData} condition={listCond} column={columnDefs}/>
      </Dialog>

      {/* <ConfirmDialog 
        open={noSummary}
        onClose={closeNoSummary}
        title={ getLabel('report_dialog_download') }
        msg={ getLabel('report_dialog_nodata') }
        confirm={ getLabel('common_confirm') }
      /> */}

      { location?.state?.origin === 'home' &&
      <Box sx={{ p:2, display:'flex', width:'100%' }}>
        <Button variant="outlined" sx={{ mr:1 }} onClick={test}><AccountCircleIcon sx={{ mr:1 }}/>{ config[config.account].username }</Button>
         <Button size="small" variant="contained" onClick={moveTop}>{ getLabel('entry_select_process') }</Button>
        <Box sx={{ flexGrow: 1 }}/>
      </Box>
      }

      <Box sx={{ mx:2, px:0 }}>
        <h4>
          {getLabel('navigation_menu_worstList')}&nbsp;
          { false && <Button size="small" variant="outlined" onClick={test}>DEV</Button>}
        </h4>
      </Box>

      <Box sx={{ display:'flex', flexWrap:'wrap', mx:1.5}}>

{/* ---------- Type Select ---------- */}
        <Box sx={{ mx:0.5, py:0.5 }}>
          <FormControl fullWidth size="small" sx={{ width:'250px' }}>
            <InputLabel id="report-category">{ getLabel('worstlist_category') }</InputLabel>
            <Select
              value={rcategory}
              label={ getLabel('worstlist_category') }
              onChange={rcategoryChange}
            >
              <MenuItem value={1}>{ getLabel('worstlist_worst_qualified') }</MenuItem>
              <MenuItem value={2}>{ getLabel('worstlist_worst_efficiency') }</MenuItem>
            </Select>
          </FormControl>
        </Box>
{/* ---------- Period Select ---------- */}
        <Box sx={{ mx:0.5, py:0.5 }}>
          <FormControl fullWidth size="small" sx={{ width:'250px' }}>
            <InputLabel id="report-type">{ getLabel('report_type') }</InputLabel>
            <Select
              value={rtype}
              label={ getLabel('report_type') }
              onChange={rtypeChange}
              error={helpers.rtype.length>0?true:false}
            >
              {/* <MenuItem value={1}>{ getLabel('report_daily') }</MenuItem>
              <MenuItem value={2}>{ getLabel('report_weekly') }</MenuItem> */}
              <MenuItem value={3}>{ getLabel('report_monthly') }</MenuItem>
              {/* <MenuItem value={4}>{ getLabel('report_quarterly') }</MenuItem> */}
              <MenuItem value={5}>{ getLabel('report_Annually') }</MenuItem>
              {/* <MenuItem value={6}>{ getLabel('report_Range')?getLabel('report_Range'):'Custom Range Report' }</MenuItem> */}
            </Select>
            <FormHelperText error={helpers.rtype.length>0?true:false}>{helpers.rtype}</FormHelperText>
          </FormControl>
        </Box>
{/* ---------- Daily picker ---------- */}
        { (rtype && rtype === 1) || debug?
        <Box sx={{ mx:0.5, py:0.5 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}
            localeText={{ cancelButtonLabel:getLabel('common_cancel'), okButtonLabel:getLabel('common_ok') }}>
            <MobileDatePicker
              showToolbar={false}
              label={ getLabel('report_target_day') }
              inputFormat="dd/MM/yyyy"
              value={rday}
              onChange={rdayChange}
              renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
            />
          </LocalizationProvider>
        </Box>
        :''}
{/* ---------- Weekly picker ---------- */}
        { (rtype && rtype === 2) || debug?
        <Box sx={{ mx:0.5, py:0.5 }}>
          <WeekPicker
            label={ getLabel('report_target_week') }
            inputFormat={ `'${getLabel('report_week_of')}' MMM d` }
            value={rweek}
            onChange={rweekChange}
            okText={getLabel('common_ok')}
            cancelText={getLabel('common_cancel')}
          />
        </Box>
        :''}
{/* ---------- Monthly picker ---------- */}
        { (rtype && rtype === 3) || debug?
        <Box sx={{ mx:0.5, py:0.5 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}
            localeText={{ cancelButtonLabel:getLabel('common_cancel'), okButtonLabel:getLabel('common_ok') }}>
            <MobileDatePicker
              showToolbar={false}
              views={['year', 'month']}
              label={ getLabel('report_target_month') }
              value={rmonth}
              onChange={rmonthChange}
              renderInput={(params) => <TextField {...params} size="small" sx={{ width:'250px' }} />}
            />
          </LocalizationProvider>
        </Box>
        :''}
{/* ---------- Quarterly picker ---------- */}
        { (rtype && rtype === 4) || debug?
        <Box sx={{ mx:0.5, py:0.5 }}>
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}
            localeText={{ cancelButtonLabel:getLabel('common_cancel'), okButtonLabel:getLabel('common_ok') }}>
            <MobileDatePicker
              showToolbar={false}
              views={['year', 'month']}
              label={ getLabel('report_target_quarter_begin') }
              value={rqmonth}
              onChange={rqmonthChange}
              renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
            />
          </LocalizationProvider>
          <ToggleButtonGroup
              sx={{ my:1 }}
            color="primary"
            value={rquarter}
            exclusive
            onChange={rquarterChange}
            aria-label="Quarter"
            size="medium"
          >
            <ToggleButton value="0">{ getLabel('report_target_quarter_1') }</ToggleButton>
            <ToggleButton value="1">{ getLabel('report_target_quarter_2') }</ToggleButton>
            <ToggleButton value="2">{ getLabel('report_target_quarter_3') }</ToggleButton>
            <ToggleButton value="3">{ getLabel('report_target_quarter_4') }</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        </Box>
        :''}
{/* ---------- Anually picker ---------- */}
        { (rtype && rtype === 5) || debug?
        <Box sx={{ mx:0.5, py:0.5 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}
            localeText={{ cancelButtonLabel:getLabel('common_cancel'), okButtonLabel:getLabel('common_ok') }}>
            <MobileDatePicker
              showToolbar={false}
              views={['year']}
              label={ getLabel('report_target_year') }
              value={ryear}
              onChange={ryearChange}
              renderInput={(params) => <TextField {...params} size="small" sx={{ width:'250px' }} />}
            />
          </LocalizationProvider>
        </Box>
        :''}
{/* ---------- Range picker ---------- */}              
        { (rtype && rtype === 6) || debug?
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}
          localeText={{ cancelButtonLabel:getLabel('common_cancel'), okButtonLabel:getLabel('common_ok') }}>
          <MobileDatePicker
            showToolbar={false}
            label={ getLabel('report_target_from') }
            inputFormat="dd/MM/yyyy"
            value={rday}
            onChange={rangeFromChage}
            renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
          />
          <MobileDatePicker
            showToolbar={false}
            label={ getLabel('report_target_to') }
            inputFormat="dd/MM/yyyy"
            value={tday}
            onChange={rangeToChage}
            renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
          />
        </LocalizationProvider>
        :''}

{/* ---------- Reporting key items ---------- */}
        { group &&
        <Box sx={{ mx:0.5, py:0.5 }}>
          <FormControl fullWidth size="small" sx={{ width:'250px' }}>
            <InputLabel id="report-group">{ getLabel('worstlist_group_title') }</InputLabel>
            <Select
              // value={selectedGroup}
              value={selectedGroup}
              label={ getLabel('worstlist_group_title') }
              onChange={(e,val) => {
                const selected = group.find(v => v.id === val.props.value);
                setSelectedGroup(selected.id);
              }}
              >
              { group && group.map((v) => (
                <MenuItem key={v.id} value={v.id} selected={v.id===selectedGroup.id?true:false}>{ v.name }</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        }

{/* ---------- Customer picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedCompany || null }
            id="combo-company"
            getOptionLabel={(option) => option.name + (option.dept?` - ${option.dept}`:'') }
            options={companies}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('company_name')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedCompany(companies.find(v => v.id === value.id));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Machine picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedMachine || null }
            id="combo-machine"
            getOptionLabel={(option) => option.name }
            options={machines}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('machine_name')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedMachine(machines.find(v => v.id === value.id));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Material picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedMaterial || null }
            id="combo-material"
            getOptionLabel={(option) => option.name }
            options={materials}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('specification_material_name')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedMaterial(materials.find(v => v.id === value.id));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Process picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedProcess || null }
            id="combo-process"
            getOptionLabel={(option) => config[config.account].language === 'en'?option.name_en:config[config.account].language === 'th'?option.name_th:option.name }
            options={processes}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('process_name')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedProcess(processes.find(v => v.id === value.id));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Sequence picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedSequence || null }
            id="combo-sequence"
            getOptionLabel={(option) => option.name }
            options={sequences}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('sequence_name')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedSequence(sequences.find(v => v.id === value.id));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Product picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedProduct || null }
            id="combo-product"
            getOptionLabel={(option) => `${option.model} - ${option.name}` }
            options={products}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('product_name')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedProduct(products.find(v => v.id === value.id));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Register user picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedRegUser || null }
            id="combo-reguser"
            getOptionLabel={(option) => `${option.username}` }
            options={regUsers}
            isOptionEqualToValue={(option, value) => option.userid === value.userid}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('common_reg_user')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedRegUser(regUsers.find(v => v.userid === value.userid));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Update user picker ---------- */}
        { !simple ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedUpdUser || null }
            id="combo-upduser"
            getOptionLabel={(option) => `${option.username}` }
            options={updUsers}
            isOptionEqualToValue={(option, value) => option.userid === value.userid}
            renderInput={(params) => <TextField {...params} size="small" label={getLabel('common_upd_user')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedUpdUser(updUsers.find(v => v.userid === value.userid));
              }
            }}
          />
        </Box>
        ) : null}

{/* ---------- Approval1 user picker ---------- */}
        { !simple && getDBValue('approval_1')==='1' ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedAp1User || null }
            id="combo-ap1user"
            getOptionLabel={(option) => `${option.username}` }
            options={ap1Users}
            isOptionEqualToValue={(option, value) => option.userid === value.userid}
            renderInput={(params) => <TextField {...params} size="small"
            label={config[config.account].language==='en'?getDBValue('approval_1_en'):config[config.account].language==='th'?getDBValue('approval_1_th'):getDBValue('approval_1_jp')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedAp1User(ap1Users.find(v => v.userid === value.userid));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Approval2 user picker ---------- */}
        { !simple && getDBValue('approval_2')==='1' ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedAp2User || null }
            id="combo-ap2user"
            getOptionLabel={(option) => `${option.username}` }
            options={ap2Users}
            isOptionEqualToValue={(option, value) => option.userid === value.userid}
            renderInput={(params) => <TextField {...params} size="small"
            label={config[config.account].language==='en'?getDBValue('approval_2_en'):config[config.account].language==='th'?getDBValue('approval_2_th'):getDBValue('approval_2_jp')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedAp2User(ap2Users.find(v => v.userid === value.userid));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Approval3 user picker ---------- */}
        { !simple && getDBValue('approval_3')==='1' ? (
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Autocomplete
            sx={{ width:'250px' }}
            disablePortal
            value = { selectedAp3User || null }
            id="combo-ap3user"
            getOptionLabel={(option) => `${option.username}` }
            options={ap3Users}
            isOptionEqualToValue={(option, value) => option.userid === value.userid}
            renderInput={(params) => <TextField {...params} size="small"
            label={config[config.account].language==='en'?getDBValue('approval_3_en'):config[config.account].language==='th'?getDBValue('approval_3_th'):getDBValue('approval_3_jp')} sx={{ mb:1 }} />}
            onChange={(_event, value) => {
              if(value) {
                setSelectedAp3User(ap3Users.find(v => v.userid === value.userid));
              }
            }}
          />
        </Box>
        ) : null}
{/* ---------- Max items ---------- */}
        <Box sx={{ mx:0.5, py:0.5 }}>
          <FormControl fullWidth size="small" sx={{ width:'250px' }}>
            <InputLabel id="report-max-count">{ getLabel('worstlist_display_max_count') }</InputLabel>
            <Select
              value={rcount}
              label={ getLabel('worstlist_display_max_count') }
              onChange={rcountChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
{/* ---------- Display List ---------- */}
        <Box sx={{ mx:0.5, py:0.5 }}>
          <Button variant="contained" sx={{ mt:0.2 }} onClick={displayList}>{ getLabel('worstlist_display_list') }</Button>
          {/* <Button variant="outlined" sx={{ mt:0.2, ml:1 }} onClick={test}>{ `Test` }</Button> */}
        </Box>
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ m:2 }}>
              <h4>{rbkcategory===1?getLabel('worstlist_worst_qualified'):getLabel('worstlist_worst_efficiency')}</h4>
            </Box>
            <Box sx={{ m:2 }}>
              <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '500px', width: '100%'}}>
                { !aggDestroyed &&
                  <AgGridReact
                    defaultColDef={defaultColDef}
                    // columnTypes={columnTypes}
                    rowData={dataList}
                    gridOptions={gridOptions}
                    onGridReady={onGridReady}
                    onFirstDataRendered={updateGridHeaders}
                    suppressRowClickSelection={true}
                    suppressCellFocus={true}
                  />
                }
              </div>
            </Box>
          </Grid>
        </Grid>  
      </Box>

    </Box>
  );
}

