import React from 'react';
import { Box, Button, Backdrop, CircularProgress, Menu, MenuItem, IconButton, Typography, TextField } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';

import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import TranslateIcon from '@mui/icons-material/Translate';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';

export default function Process({ prop }) {
  let navigate = useNavigate();
  // const location = useLocation(); 
  const [config, setConfig] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  // eslint-disable-next-line
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [dbconfig, setDbconfig] = React.useState(null);
  const [processes, setProcesses] = React.useState();
  const aData = React.useRef([]);

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  // const [account] = React.useState((window.location.pathname).split("/")[1]);
  // const [sites, setSites] = React.useState(null);
  // const loadSites = async() => {
  //   // console.log(`this must be runs first!`);
  //   await fetch('/data/sites.json').then((res) => res.json()).then((data) => {
  //     const ret = data.sites?.find(v => (v.name === account));
  //     if(ret !== undefined) {
  //       setConfig(v => ({...v, account: account }));
  //       if(!(account in config)) {
  //         setConfig(config => ({...config, [config.account]: {...config[config.account], 
  //           backend: ret.backend,
  //           deployurl: ret.deployurl,
  //           theme: ret.theme,
  //           language: ret.language,
  //         }}));
  //       }
  //     } else {
  //       // console.log(`illegal access!!`);
  //       navigate(`/`, { replace: true })
  //     }
  //     setSites(data);
  //     // console.log(`fetched sites data`);
  //     // console.log(data);
  //   });
  //   // }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }

  // const siteRef = React.useRef();

  React.useEffect(() => {
    // console.log(`get in process`);
    // loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if(!config[config.account].authPassed)navigate(`/${config.account}/signin`, { replace: true });
    const lang = config[config.account].language;
    // const auth = config[config.account].authPassed;
    // console.log(auth);
    setLabels(caption[lang]);
    getConfigs();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   if(sites) {
  //     siteRef.current = config[config.account];
  //     const lang = siteRef.current.language;
  //     setLabels(caption[lang]);
  //     getConfigs();
  //     let hashkey = null;
  //     if(location.search) {
  //       const search = location.search;
  //       hashkey = decodeURIComponent(search.replace('?a=',''));
  //       if(hashkey) {
  //         navigate(`/${config.account}/register`, { replace: true, state:{ hashkey:hashkey } });
  //       }
  //     }
  //     if(!hashkey && !siteRef.current.authPassed)navigate(`/${config.account}/splash`, { replace: true });
  //     loadProcesses();
  //     // console.log(`sites updated.`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sites]);

  // React.useEffect(() => {
  //   loadDefault();
  //   console.log(siteRef.current);
  //   const lang = siteRef.current.language;
  //   setLabels(caption[lang]);
  //   getConfigs();
  //   let hashkey = null;
  //   if(location.search) {
  //     const search = location.search;
  //     hashkey = decodeURIComponent(search.replace('?a=',''));
  //     if(hashkey) {
  //       // navigate("/register", { replace: true, state:{ hashkey:hashkey } });
  //       navigate(`/${config.account}/register`, { replace: true, state:{ hashkey:hashkey } });
  //     }
  //   }
  //   // if(!hashkey && !config.authPassed)navigate("/signin", { replace: true });
  //   if(!hashkey && !siteRef.current.authPassed)navigate(`/${config.account}/splash`, { replace: true });
  //   loadProcesses();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sites]);
  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  });
  // eslint-disable-next-line no-unused-vars
  const [dps, setDps] = React.useState(getDisplaySize());

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const getConfigs = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    // const rec = { production:status.products, qualified:numQualified, unqualified:numUnqualified, user:config.userid }
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ auth:config[config.account].auth }) }) // body: JSON.stringify(rec)
    .then(res => res.json())
    .then(data => {
      // if(data?.code === '400') { // unauthorized, navigate to login
      //   console.log(`unauthorized 1`);
      // }
      // console.log(data);
      setDbconfig(data);
      setLoading(false);
    });
  }
  
  const [ condProcesses, setCondProcesses] = React.useState({len:0, dvn:0, rem:0});

  // eslint-disable-next-line no-unused-vars
  const loadProcesses = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/processes_lang`;
    const lang = config[config.account].language==='jp'?'name':('name_' + config[config.account].language);
    setLoading(true);
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ auth:config[config.account].auth ,lang:lang })})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      // if(data?.code === '400') { // unauthorized, navigate to login
      //   console.log(`unauthorized 2`);
      // }
      aData.current = data;
      setProcesses(data);

      let len = data.length;
      let dvn = parseInt(len / 4);
      const rem = len % 4;
      if(rem > 0)dvn++;
      setCondProcesses({...condProcesses, len:len, dvn:dvn, rem:rem });
      setLoading(false);
    });
  }

  React.useEffect(() => {
    if(config[config.account]?.language) {
      loadProcesses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config[config.account]?.language])


  const signOut = () => {
    // setConfig(config => ({...config, userid:'', username:'', authPassed:false }));
    setConfig(config => ({...config, [config.account]: {...config[config.account],
      userid:'', username:'', authPassed:false, status_backup:status  }}));
    navigate(`/${config.account}/splash`, { replace: true });
  }

  // const moveEntry = (val) => {
  //   const id = val.target.id;
  //   setStatus(status => ({...status, processes:id }));
  //   navigate("/entry", { replace: true });
  // }

  const moveNext = (val) => {
    const id = val.target.id;
    let url = `/${config.account}/customer`;
    setStatus(status => ({...status, processes:id }));
    // setConfig(config => ({...config, [config.account]: {...config[config.account], processes:id }}));
    // if(config.iforder === 1)url = "/machine";
    // console.log(getDBValue('workflow_order'));

    const iforder = Number(getDBValue('workflow_order'));
    setConfig(config => ({...config, [config.account]: {...config[config.account], iforder: iforder }}));

    if(iforder === 1)url = `/${config.account}/machine`;
    navigate(url, { replace: true });
  }

  const moveAdmin = (val) => {
    // const id = val.target.id;
    setStatus(status => ({...status, page:'Home' }));
    // setConfig(config => ({...config, [config.account]: {...config[config.account], page:'Home' }}));
    navigate(`/${config.account}/admin`, { replace: true });
  }

  const moveHistory = () => {
    // const id = val.target.id;
    // setStatus(status => ({...status, page:'Home' }));
    navigate(`/${config.account}/history`, { replace: true });
  }

  const moveWorstReport = () => {
    navigate(`/${config.account}/report`, { state:{ origin:'home' }, replace: true });
  }

  // eslint-disable-next-line no-unused-vars
  const test = () => {
    // console.log(dbconfig);
    // console.log(config[config.account]);
  }

  const handleSearch = (e) => {
    let filter = aData.current.filter(data => (data.name.toLowerCase()).includes(e.target.value.toLowerCase()));
    setProcesses(filter);
    let len = filter.length;
    let dvn = parseInt(len/4);
    const rem = len % 4;
    if(rem > 0) dvn++;
    setCondProcesses({...condProcesses, len:len, dvn:dvn, rem:rem })
  }

  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ p:2, display:'flex', width:'100%' }}>
        <Button variant="outlined" sx={dps==='xs'?{mr:1, width:'50%'}:{ mr:1 }} startIcon={dps==='xs'?null:(<AccountCircleIcon sx={{mr:'3%'}}/>)}> 
          { config[config.account]?
              dps==='xs'||dps==='sm'?config[config.account].userid:config[config.account].username
            :'' }
        </Button>
        <Button variant="contained" sx={{ mr:1 }} onClick={signOut}>{ getLabel('common_signout') }</Button>
        <Button variant="contained" sx={{ mr:1 }} onClick={moveHistory}>{ getLabel('history_title') }</Button>
        { config[config.account]?.userrole===1 &&
        <Button variant="contained" sx={{ mr:1 }} onClick={moveWorstReport}>{ getLabel('navigation_menu_worstList') }</Button>
        }
        <Box sx={{ flexGrow: 1 }}/>
        <IconButton
          color="inherit" 
          sx={{ mr: 1 }}
          onClick={languageClick}
        >
          <TranslateIcon fontSize="small"/>
        </IconButton>
        <Menu
          anchorEl={anchorLanguage}
          open={selectLanguage}
          onClose={languageClose}
        >
          <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account] && config[config.account].language==='jp'?true:false}>日本語</MenuItem>
          <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account] && config[config.account].language==='en'?true:false}>English</MenuItem>
          <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account] && config[config.account].language==='th'?true:false}>ไทย</MenuItem>
        </Menu>

        { config[config.account]?.userrole === 1 &&
        <Button variant="contained" onClick={moveAdmin}>{ getLabel('common_admin_menu') }</Button>
        }
      </Box>

      <Box sx={{ width:'100%', height:'3vh' }}/>

      <Box sx={{ p:1, width:'100%', textAlign:'center' }}>
        <Typography variant="h5">{ getLabel('entry_message_process') }</Typography>
      </Box>

      <Box sx={{ width:'100%', height:'5vh', px:3 }} >
        <SearchIcon fontSize={"large"} color={'primary'} />
        {dps==='xs'?
          <TextField sx={{ mx:2, mb:2, height:'3vh', width:'80%'}} variant='standard' color='primary' type="search" 
            placeholder={getLabel('common_search')} onChange={handleSearch}/>
          : <TextField sx={{ mx:2, mb:2, height:'3vh', width:'25%'}} variant='standard' color='primary' type="search" 
            placeholder={getLabel('common_search')} onChange={handleSearch}/>
        }
      </Box>

      <Box sx={{ p:1 }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar]}
          spaceBetween={0}
          slidesPerView={dps==='xs'? 1 : dps==='sm'? 2 : 3}
          navigation
          pagination={{ clickable: true, type: "fraction" }}
          // style={{ zIndex:0 }}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
        >
        {processes && [...Array(condProcesses.dvn)].map((x, i) =>
          <SwiperSlide key={ processes[i*4].id }>
            {(i*4<condProcesses.len) && <Button id={ processes[i*4].id } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={moveNext}>{ processes[i*4].name }</Button> }
            {(i*4+1<condProcesses.len) && <Button id={ processes[i*4+1].id } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={moveNext} >{ processes[i*4+1].name }</Button> }
            {(i*4+2<condProcesses.len) && <Button id={ processes[i*4+2].id } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={moveNext} >{ processes[i*4+2].name }</Button> }
            {(i*4+3<condProcesses.len) && <Button id={ processes[i*4+3].id } variant="outlined"
              sx={{ m:1, width:'97%', height:'12vh', fontSize:'large' }} onClick={moveNext} >{ processes[i*4+3].name }</Button> }
          </SwiperSlide>
        )}
        </Swiper>
      </Box>

    </Box>
  );
}

