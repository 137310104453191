import { atom } from "recoil";

export const systemCaption = atom({
  key: "systemCaption",
  default: {
    jp: {
      navigation_title: 'リアルタイム生産情報共有 DBord',
      navigation_short_title: 'DBord',
      navigation_menu_user: 'システムユーザー管理',
      navigation_menu_company: '会社情報登録',
      navigation_menu_person: '担当者情報登録',
      navigation_menu_material: '材料情報登録',
      navigation_menu_product: '商品情報登録',
      navigation_menu_machine: '加工機情報登録',
      navigation_menu_specification: '商品詳細情報登録',
      navigation_menu_process: '工程名称情報登録',
      navigation_menu_sequence: '工程詳細名称情報登録',
      navigation_menu_production: '商品工程情報登録',
      navigation_menu_production_order: '生産指示情報登録',
      navigation_menu_mail: 'レポート送信メール情報登録',
      navigation_menu_home: 'ホーム',
      navigation_menu_non_working_hours: '非稼働時間帯情報登録',
      navigation_seconds_signin: '秒後、自動的にログイン画面へ移動します。',
      navigation_to_signin: 'ログイン画面へ',
      navigation_menu_config: 'システム設定',
      navigation_menu_reporting: 'レポーティング',
      navigation_menu_defective: '不良品情報',
      navigation_menu_excel_report: 'レポート出力',
      navigation_menu_worstList: 'ワーストレポート',
      home_menu_title: 'システムメニュー',
      auth_title: 'オーソライズ',
      auth_code: 'オーソライゼーションコード',
      auth_code_required: 'オーソライゼーションコードが必要です。',
      auth_failed: 'オーソライズに失敗しました。',
      auth_dbord: 'DBordをオーソライズ',
      common_new: '新規',
      common_update: '更新',
      common_save: '保存',
      common_delete: '削除',
      common_reset: 'リセット',
      common_search: '検索',
      common_go: '実行',
      common_defect: '不良品',
      common_password: 'パスワード',
      common_new_password: '新規 パスワード',
      common_confirm: '確認',
      common_ok: 'OK',
      common_cancel: 'キャンセル',
      common_open: '開く',
      common_close: '閉じる',
      common_date: '日付',
      common_input_confirm: '入力確認',
      common_delete_confirm: '削除確認',
      common_delete_msg: '選択中の項目を削除しますか？',
      common_info: '情報',
      common_inuse_meg: '選択中の項目は使用中のため、削除できません。',
      common_country: '国',
      common_email: 'E-mail アドレス',
      common_signin_form: 'ログインフォーム',
      common_signin_user: 'ログインユーザ',
      common_signin: 'ログイン',
      common_signout: 'ログアウト',
      common_register: 'ユーザ登録',
      common_required: '必須入力',
      common_incorrect_email: 'E-mail アドレスが正しくない',
      common_incorrect_password: 'または、パスワードが正しくありません',
      common_admin_menu: '管理者メニュー',
      common_top: 'トップ',
      common_detail: '詳細',
      common_all: '全て',
      common_reg_user: '登録ユーザ',
      common_reg_date: '登録日時',
      common_upd_user: '更新ユーザ',
      common_upd_date: '更新日時',
      common_keyword_filter: 'キーワードフィルタ',
      common_items: '件',
      common_remarks: '備考',
      config_date_format: '一覧日時フォーマット',
      config_workflow_order: '実績入力順序',
      config_workflow_on: 'ON: 工程 -> 加工機 -> 部品',
      config_workflow_off: 'OFF: 工程 -> 顧客 -> 部品 -> 加工機',
      config_approval: 'ログインユーザ承認',
      config_approval_on: 'ON: ログインユーザで承認',
      config_approval_off: 'OFF: 承認者を一覧から選択',
      config_free_1: 'フリーエントリ 1',
      config_free_2: 'フリーエントリ 2',
      config_free_3: 'フリーエントリ 3',
      config_approval_1: '承認 1',
      config_approval_2: '承認 2',
      config_approval_3: '承認 3',
      config_english: 'English',
      config_japanese: '日本語',
      config_thai: 'ภาษาไทย',
      entry_model: '品番',
      entry_name: '品名',
      entry_qualified: '良品数',
      entry_unqualified: '不良品数',
      entry_rework: 'リワーク数',
      entry_register: '数量登録',
      entry_register_msg: '入力した良品数量と不良品数量を登録しますか？',
      entry_breakdown: '当日明細',
      entry_register_check: '登録して続行',
      entry_register_complete: '登録して作業終了',
      entry_register_complete_mobile: '登録して作業終了',
      entry_confirm: '決定',
      entry_back: '戻る',
      entry_time: '登録時刻',
      entry_sum: '合計',
      entry_sum_today: '当日合計',
      entry_process_start: '作業開始',
      entry_process_cancel: '作業取消',
      entry_process_end: '作業終了',
      entry_manhour_efficiency: '作業能率',
      entry_efficiency: '作業能率',
      entry_section_period: '作業時間（分）',
      entry_volume: '出来高',
      entry_output: 'エクセル出力',
      entry_daily_report: '日報メール送信',
      entry_daily_report_mobile: '日報メール送信',
      entry_daily_subject: 'DBord - 日報メール受信',
      entry_daily_message: '日報メールを受信しました。詳細は添付ファイルをご確認下さい。',
      entry_select_customer: '顧客選択',
      entry_select_process: '工程選択',
      entry_select_parts: '部品選択',
      entry_select_machine: '設備選択',
      entry_message_customer: '顧客名を選択して下さい。',
      entry_message_process: '入力する工程名を選択して下さい。',
      entry_message_parts: '部品名を選択して下さい。',
      entry_message_machine: '設備名を選択して下さい。',
      entry_volume_input: '数量入力',
      entry_report_machine: '月次設備サマリ',
      entry_approval: '署名',
      entry_production_approval: '生産確認署名',
      entry_approved: '署名者:',
      entry_unapproved: '確認署名されておりません。',
      entry_confirm_approval: '現在のログインユーザにて確認署名します。',
      entry_cancel_approval: '確認署名をクリアします。',
      entry_production_shift: '生産シフト',
      entry_customer_name: '顧客名',
      entry_endproc: '作業終了確認',
      entry_endproc_noquantity: '実績数量が登録されていないため、作業終了処理を実行できません。',
      entry_endproc_itemcheck: '次の項目に登録漏れはありませんか？',
      entry_endproc_confirm: '作業終了処理を実行します。',
      entry_approver_select: '署名者選択',
      entry_approver_select_msg: '署名者を選択してください。',
      entry_approver_clear: '署名クリア',
      entry_zero_quantity: '数量未入力',
      entry_zero_quantity_msg: '数量が未入力です。良品数、リワーク数、不良品数の何れかに数量を入力して下さい。',
      entry_time_hour: '時',
      entry_time_minute: '分',
      entry_time_second: '秒',
      entry_duplicated: '重複処理確認',
      entry_duplicated_msg: '生産に使用する加工機は現在使用中の為、作業を開始することができません。',
      entry_text_input: 'テキスト入力',
      register_inst: 'ユーザ登録後、入力したE-mailアドレスに確認用メールが届きますので、このメールに記載されたリンクからアカウントを有効化して下さい。',
      register_subject: 'ユーザ仮登録のご連絡',
      register_message: 'ご入力頂きました内容でユーザを仮登録しました。\r\nこのユーザアカウントを有効にするには、https://www.f-1tools.com/prodman/?a={code} へアクセスして下さい。\r\nなお本メールはシステムより自動送信されたものになりますため、返信頂くことはできません。',
      register_sent: 'リクエストは正常に送信されました。',
      register_check_email: 'このシステムから送信された電子メールを確認し、ユーザーアカウントを有効化してください。',
      register_activation: 'アカウント有効化',
      register_activation_message: '電子メールで届いたユーザアカウントを有効化',
      register_activation_success: 'ユーザアカウントの有効化に成功しました',
      register_activation_success_message: 'ユーザ登録に使用した電子メールアドレスとパスワードでログインしてください。',
      user_info: 'ユーザ情報',
      user_list: 'ユーザ一覧',
      user_id: 'ユーザID',
      user_name: 'ユーザ名',
      user_email: 'E-Mail',
      user_enabled: '有効',
      user_add: '新規ユーザ',
      user_delete: 'ユーザ削除',
      user_signature: '署名',
      user_role: 'ユーザ権限',
      user_role_administrator: '管理者権限',
      user_role_supervisor: '監督者権限',
      user_role_operator: 'オペレーター権限',
      user_role_viewer: '閲覧者権限',
      company_list: '会社一覧',
      company_info: '会社情報',
      company_name: '会社名',
      company_abbr: '略称',
      company_addr: '住所',
      company_tel: 'TEL',
      company_fax: 'FAX',
      company_dept: '事業所',
      company_tax: 'TAX ID',
      company_add: '新規会社情報',
      company_delete: '会社情報削除',
      person_list: '担当者一覧',
      person_info: '担当者情報',
      person_company: '所属会社',
      person_lastnm: '姓',
      person_firstnm: '名',
      person_mail: 'E-mail',
      person_mobile: '携帯',
      person_add: '新規担当者',
      person_delete: '担当者削除',
      material_list: '材料一覧',
      material_info: '材料',
      material_major: '大分類',
      material_minor: '中分類',
      material_maker: 'メーカー',
      material_name: '名称',
      material_model: '番号',
      material_add: '新規材料',
      material_delete: '材料削除',
      product_list: '商品一覧',
      product_info: '商品',
      product_model: '品番',
      product_internal: '内部品番',
      product_name: '品名',
      product_add: '新規商品',
      product_delete: '商品削除',
      product_image_preview: '画像プレビュー',
      product_image_select: '画像選択 (JPEG/PNG形式5MB以内)',
      product_image_clear: '画像クリア',
      machine_list: '加工機一覧',
      machine_info: '加工機',
      machine_category: '加工機種別',
      machine_name: '加工機名',
      machine_maker: '加工機メーカー',
      machine_type: '加工機型式',
      machine_add: '新規加工機',
      machine_delete: '加工機削除',
      specification_list: '商品詳細情報一覧',
      specification_info: '商品詳細情報',
      specification_product: '商品',
      specification_product_model: '商品品番',
      specification_product_name: '商品品名',
      specification_material: '材料',
      specification_material_model: '材料番号',
      specification_material_name: '材料名',
      specification_heat: '熱処理',
      specification_hardness: '硬度',
      specification_surface: '表面処理',
      specification_color: '色番号',
      specification_weight: '重量',
      specification_material_weight: '原料重量',
      specification_product_weight: '製品重量',
      specification_unit: '重量単位',
      specification_add: '新規商品詳細情報',
      specification_delete: '商品詳細情報削除',
      process_list: '工程名称一覧',
      process_info: '工程',
      process_name: '工程名',
      process_add: '新規工程',
      process_delete: '工程削除',
      sequence_list: '工程詳細名称一覧',
      sequence_info: '工程詳細',
      sequence_name: '工程詳細名',
      sequence_add: '新規工程詳細',
      sequence_delete: '工程詳細削除',
      production_list: '商品工程情報一覧',
      production_info: '商品工程情報一覧',
      production_product: '商品',
      production_product_model: '商品品番',
      production_product_internal: '商品内部品番',
      production_product_name: '商品品名',
      production_sequence: '工程順番',
      production_company: '会社',
      production_company_name: '会社名',
      production_company_dept: '事業所名',
      production_process: '工程',
      production_period: '標準時間',
      production_machine: '加工機',
      production_material: '材料',
      production_material_model: '材料番号',
      production_material_name: '材料名称',
      production_weight: '重量',
      production_unit: '重量単位',
      prodution_defectdetail_btn: '不良品詳細',
      production_defectptrn: '不良品パターン',
      production_report_to: '日報メール送信先',
      production_add: '新規商品工程情報',
      production_delete: '商品工程情報削除',
      production_order_list: '生産指示 - 商品工程選択',
      production_order_day_production: '生産日',
      production_order_new_allocation: '新規割当',
      production_order_delete_allocation: '割当削除',
      production_order_start_time: '開始時間',
      production_order_end_time: '終了時間',
      production_order_valid_time: '有効時間',
      production_order_target: '目標数',
      production_order_result: '実績',
      production_order_balance: 'バランス',
      production_order_preparation: '準備時間',
      production_order_time_stop: '停止時間',
      production_order_remark: '停止事由',
      report_mail_list: 'レポート送信メール情報',
      nonworkinghour_list: '非稼働時間帯情報',
      nonworkinghour_name: '非稼働時間帯名',
      nonworkinghour_period1: '非稼働時間帯1',
      nonworkinghour_period2: '非稼働時間帯2',
      nonworkinghour_period3: '非稼働時間帯3',
      nonworkinghour_period4: '非稼働時間帯4',
      nonworkinghour_period5: '非稼働時間帯5',
      nonworkinghour_info: '非稼働時間帯',
      nonworkinghour_add: '新規非稼働時間帯',
      nonworkinghour_delete: '非稼働時間帯削除',
      history_title: '生産履歴',
      history_show_unclosed: '生産作業中を表示',
      history_short_show: '作業中',
      report_output_target: '出力対象',
      report_output_exception: '出力対象外',
      report_output: 'レポート出力',
      report_reset_items: '出力対象、対象外リセット',
      report_type: '対象期間',
      report_daily: '日次',
      report_weekly: '週次',
      report_monthly: '月次',
      report_quarterly: '四半期',
      report_Annually: '年間',
      report_Range: '任意期間',
      report_target_day: '対象日',
      report_target_week: '対象週',
      report_target_month: '対象月',
      report_target_quarter_begin: '起算月',
      report_target_quarter_1: '第1四半期',
      report_target_quarter_2: '第2四半期',
      report_target_quarter_3: '第3四半期',
      report_target_quarter_4: '第4四半期',
      report_target_year: '対象年',
      report_target_from: '開始日付',
      report_target_to: '終了日付',
      report_week_of: '週',
      report_type_daily: '日次レポート',
      report_type_weekly: '週間レポート',
      report_type_monthly: '月次レポート',
      report_type_quarterly: '四半期レポート',
      report_type_annually: '年間レポート',
      report_type_range: '任意期間レポート',
      report_dialog_download: 'エクセルレポートファイルダウンロード',
      report_dialog_nodata: '指定した条件に該当するデータが存在しないため、ダウンロードされませんでした。',
      report_dlg_nodata_title: '該当データが存在しません。',
      report_dlg_nodata_msg: '指定した期間にデータが存在しません。期間指定を確認してください。',
      defective_types: '不良品種別',
      defective_pattern_list: '不良品パターン',
      defective_name: '不良名',
      defective_info: '不良品種別',
      defective_new: '新規不良品種別',
      defective_delete: '不良品種別削除',
      defective_selectall_checkbox: 'すべて選択',
      defective_pattern_new: '変更をキャンセル',
      defective_pattern_delete: 'パターン削除',
      defective_pattern_create: 'パターン作成',
      defective_pattern_update: 'パターン更新',
      defective_over_length: '登録数制限',
      defective_over_length_msg: '不良品パターンには最大15 種までしか登録できません。',
      defective_newpattern_btn: '新規パターン',
      defective_newpattern_name: 'パターン名',
      defective_total: '合計',
      defectconf_title: '警告',
      defectconf_warning_msg: 'データのレコードが複数ある場合、編集はデータの最新の行のみが行われ、他のレコードは削除されます。',
      defectconf_title_nodata: '該当データが存在しません。',
      defectconf_datanotfound_msg: '不良品パターンが見つかりません。 不良パターンIDを確認してください。パターンが削除され、データを取得できなくなっている可能性があります。',
      worstlist_category: 'レポートタイプ',
      worstlist_worst_qualified: 'ワースト不良率',
      worstlist_worst_efficiency: 'ワースト作業能率',
      worstlist_productname: '品名',
      worstlist_productmodel: '品番',
      worstlist_percent_unqualified: '不良率',
      worstlist_unqual_amount: '良品数 (不良品数)',
      worstlist_customer: '顧客名',
      worstlist_efficiency: '作業能率 (%)',
      worstlist_uptime: '総稼働時間 (h)',
      worstlist_qualified: '良品数',
      worstlist_unqualified: '不良品数',
      worstlist_target: '目標数',
      worstlist_defect_ratio: '不良率 (%)',
      worstlist_this_month: '当月',
      worstlist_prev_months: '当月以前',
      worstlist_prev_month: '前月',
      worstlist_this_year: '当年',
      worstlist_prev_years: '当年以前',
      worstlist_prev_year: '前年',
      worstlist_defective: '不良詳細',
      worstlist_year: '年',
      worstlist_month: '月',
      worstlist_history: '履歴',
      worstlist_group_title: 'データ抽出グループ',
      worstlist_group_company: '顧客',
      worstlist_group_company_abbr: '略称',
      worstlist_group_machine: '加工機',
      worstlist_group_machine_type: '型式',
      worstlist_group_material: '材料',
      worstlist_group_material_type: '型式',
      worstlist_group_process: '工程',
      worstlist_group_sequence: '工程詳細',
      worstlist_group_product: '製品',
      worstlist_group_register: '登録者',
      worstlist_group_update: '更新者',
      worstlist_display_list: 'リスト表示',
      worstlist_display_max_count: '最大表示件数',
    },
    en: {
      navigation_title: 'DBord - Real-time production information sharing',
      navigation_short_title: 'DBord',
      navigation_menu_user: 'System User',
      navigation_menu_company: 'Company Info.',
      navigation_menu_person: 'PIC Info.',
      navigation_menu_material: 'Material Info.',
      navigation_menu_product: 'Product Info.',
      navigation_menu_machine: 'Machine Info.',
      navigation_menu_specification: 'Specification Info.',
      navigation_menu_process: 'Process Name Info.',
      navigation_menu_sequence: 'Process Sequence Info.',
      navigation_menu_production: 'Production Info.',
      navigation_menu_production_order: 'Production Order Info.',
      navigation_menu_mail: 'Mail Reporting Info.',
      navigation_menu_home: 'Home Menu',
      navigation_menu_non_working_hours: 'Non-working Hours Info.',
      navigation_seconds_signin: 'seconds later, moving to the Sign-in screen automatically.',
      navigation_to_signin: 'To Sign-in',
      navigation_menu_config: 'System Configuration',
      navigation_menu_reporting: 'Reports',
      navigation_menu_defective: 'Defective Info.',
      navigation_menu_excel_report: 'Download Excel Report',
      navigation_menu_worstList: 'Worst List Report',
      home_menu_title: 'System Menu',
      auth_title: 'Authorization',
      auth_code: 'Authorization Code',
      auth_code_required: 'Authorization code required',
      auth_failed: 'Authorization failed',
      auth_dbord: 'Authorize DBord',
      common_new: 'New',
      common_update: 'Update',
      common_save: 'Save',
      common_delete: 'Delete',
      common_reset: 'Reset',
      common_search: 'Search',
      common_go: 'Go',
      common_defect: 'Defect',
      common_password: 'Password',
      common_new_password: 'New Password',
      common_confirm: 'Confirm',
      common_ok: 'OK',
      common_cancel: 'Cancel',
      common_open: 'Open',
      common_close: 'Close',
      common_date: 'Date',
      common_input_confirm: 'Confirmation',
      common_delete_confirm: 'Delete confirmation',
      common_delete_msg: 'Delete selected item?',
      common_info: 'Information',
      common_inuse_meg: 'The selected item is using and cannot be delete.',
      common_country: 'Country',
      common_email: 'E-mail Address',
      common_signin_form: 'Sign In',
      common_signin_user: 'Sign In User',
      common_signin: 'Sign In',
      common_signout: 'Logout',
      common_register: 'Register',
      common_required: 'Required',
      common_incorrect_email: 'Incorrect E-mail Address',
      common_incorrect_password: 'Or incorrect Password',
      common_admin_menu: 'Admin Menu',
      common_top: 'Top',
      common_detail: 'Detail',
      common_all: 'All',
      common_reg_user: 'Register User',
      common_reg_date: 'Registered Date',
      common_upd_user: 'Update User',
      common_upd_date: 'Updated Date',
      common_keyword_filter: 'Keyword Filter',
      common_items: 'Items',
      common_remarks: 'Remarks',
      config_date_format: 'Date Format',
      config_workflow_order: 'Workflow Input Order',
      config_workflow_on: 'ON: Process -> Machine -> Parts',
      config_workflow_off: 'OFF: Process -> Client -> Parts -> Machine',
      config_approval: 'Approval with Login User',
      config_approval_on: 'ON: Sign with Current Login User',
      config_approval_off: 'OFF: Sign with Selected User',
      config_free_1: 'Free Column 1',
      config_free_2: 'Free Column 2',
      config_free_3: 'Free Column 3',
      config_approval_1: 'Approval 1',
      config_approval_2: 'Approval 2',
      config_approval_3: 'Approval 3',
      config_english: 'English',
      config_japanese: '日本語',
      config_thai: 'ภาษาไทย',
      entry_model: 'Model',
      entry_name: 'Name',
      entry_qualified: 'Qualified',
      entry_unqualified: 'Unqualified',
      entry_rework: 'Rework',
      entry_register: 'Register Amounts',
      entry_register_msg: 'Register the amounts as below listed?',
      entry_breakdown: 'History for today',
      entry_register_check: 'Save and continue',
      entry_register_complete: 'Save and End Process',
      entry_register_complete_mobile: 'Save and End Proc.',
      entry_confirm: 'Done',
      entry_back: 'Back',
      entry_time: 'Time',
      entry_sum: 'Total',
      entry_sum_today: 'Today\'s Total',
      entry_process_start: 'Start Processing',
      entry_process_cancel: 'Cancel Processing',
      entry_process_end: 'End Processing',
      entry_manhour_efficiency: 'Man-hour Efficiency',
      entry_efficiency: 'Efficiency',
      entry_section_period: 'Process Time (mins)',
      entry_volume: 'Volumes',
      entry_output: 'Output XLSX',
      entry_daily_report: 'Submit Daily Report',
      entry_daily_report_mobile: 'Send Report',
      entry_daily_subject: 'DBord - Received a Daily Report',
      entry_daily_message: 'Received a daily report email. Please check the attached file for details.',
      entry_select_customer: 'Select Customer',
      entry_select_process: 'Select Process',
      entry_select_parts: 'Select Parts',
      entry_select_machine: 'Select Machine',
      entry_message_customer: 'Please select a Customer Name.',
      entry_message_process: 'Please select a Process Name.',
      entry_message_parts: 'Please select a Parts Name.',
      entry_message_machine: 'Please select a Machine Name.',
      entry_volume_input: 'Register Amounts',
      entry_report_machine: 'Monthly Machinery Summary',
      entry_approval: 'Approval',
      entry_production_approval: 'Production Approval',
      entry_approved: 'Signed by:',
      entry_unapproved: '* Unsigned / Unapproved',
      entry_confirm_approval: 'Approving with the current login user.',
      entry_cancel_approval: 'The approved signature will be cleared.',
      entry_production_shift: 'Shift',
      entry_customer_name: 'Customer Name',
      entry_endproc: 'End processing Confirmation',
      entry_endproc_noquantity: 'End processing cannot be executed because the quantity is not registered.',
      entry_endproc_itemcheck: 'Are there any omissions in the registration of the following items?',
      entry_endproc_confirm: 'Completing the process session.',
      entry_approver_select: 'Approver Selection',
      entry_approver_select_msg: 'Please select an approver',
      entry_approver_clear: 'Clear the Approval',
      entry_zero_quantity: 'No Quantity Entry',
      entry_zero_quantity_msg: 'No quantity was entered. Input the number of Qualified, Rework or Unqualified.',
      entry_time_hour: 'Hour',
      entry_time_minute: 'Minute',
      entry_time_second: 'Second',
      entry_duplicated: 'Duplication Check',
      entry_duplicated_msg: 'The processing machine used for production is currently in use, so work cannot be started.',
      entry_text_input: 'Edit the text',
      register_inst: 'A confirmation email will be sent to the email address you entered after the registration. Please activate your account from the link provided in the email.',
      register_subject: 'Notification of temporary user registration',
      register_message: 'The user was temporarily registered with the entered information.\r\nTo enable this user account, please visit https://www.f-1tools.com/prodman/?a={code} \r\nPlease note that this email will be automatically sent from the system and cannot be replied.',
      register_sent: 'The request was sent successfully.',
      register_check_email: 'Please check the e-mail sent from this system and activate the user account.',
      register_activation: 'Activation',
      register_activation_message: 'Activate the user account with your E-mail address',
      register_activation_success: 'Successfully activated your user account',
      register_activation_success_message: 'Please sign-in with your E-mail address and the registered password.',
      user_info: 'User',
      user_list: 'User List',
      user_id: 'User ID',
      user_name: 'User Name',
      user_email: 'E-mail',
      user_enabled: 'Enabled',
      user_add: 'New User',
      user_delete: 'Delete User',
      user_signature: 'Signature',
      user_role: 'User Role',
      user_role_administrator: 'Administrator',
      user_role_supervisor: 'Supervisor',
      user_role_operator: 'Operator',
      user_role_viewer: 'Viewer',
      company_list: 'Company List',
      company_info: 'Company',
      company_name: 'Company Name',
      company_abbr: 'Abbreviation',
      company_addr: 'Address',
      company_tel: 'TEL',
      company_fax: 'FAX',
      company_dept: 'Branch / Department',
      company_tax: 'TAX ID',
      company_add: 'New Company',
      company_delete: 'Delete Company',
      person_list: 'Person List',
      person_info: 'Person',
      person_company: 'Company',
      person_lastnm: 'Last Name',
      person_firstnm: 'First Name',
      person_mail: 'E-mail',
      person_mobile: 'Mobile',
      person_add: 'New Person',
      person_delete: 'Delete Person',
      material_list: 'Material List',
      material_info: 'Material',
      material_major: 'Major Category',
      material_minor: 'Minor Category',
      material_maker: 'Supplier',
      material_name: 'Material Name',
      material_model: 'Model',
      material_add: 'New Material',
      material_delete: 'Delete Material',
      product_list: 'Product List',
      product_info: 'Product',
      product_model: 'Product Model Code',
      product_internal: 'Internal Model Code',
      product_name: 'Product Name',
      product_add: 'New Product',
      product_delete: 'Delete Product',
      product_image_preview: 'Image Preview',
      product_image_select: 'Select the image (JPEG/PNG File < 5MB)',
      product_image_clear: 'Clear the image',
      machine_list: 'Machine List',
      machine_info: 'Machine',
      machine_category: 'Machine Category',
      machine_name: 'Machine Name',
      machine_maker: 'Machine Supplier',
      machine_type: 'Machine Model',
      machine_add: 'New Machine',
      machine_delete: 'Delete Machine',
      specification_list: 'Specification List',
      specification_info: 'Specification',
      specification_product: 'Product',
      specification_product_model: 'Product Model',
      specification_product_name: 'Product Name',
      specification_material: 'Material',
      specification_material_model: 'Material Model',
      specification_material_name: 'Material Name',
      specification_heat: 'Heat Treatment',
      specification_hardness: 'Hardness',
      specification_surface: 'Surface Treatment',
      specification_color: 'Color Code',
      specification_weight: 'Weight',
      specification_material_weight: 'Material Weight',
      specification_product_weight: 'Product Weight',
      specification_unit: 'Weight Unit',
      specification_add: 'New Specification',
      specification_delete: 'Delete Specification',
      process_list: 'Process List',
      process_info: 'Process',
      process_name: 'Process Name',
      process_add: 'New Process',
      process_delete: 'Delete Process',
      sequence_list: 'Process Sequence List',
      sequence_info: 'Process Sequence',
      sequence_name: 'Sequence Name',
      sequence_add: 'New Sequence',
      sequence_delete: 'Delete Sequence',
      production_list: 'Production List',
      production_info: 'Production',
      production_product: 'Product',
      production_product_model: 'Product Model',
      production_product_internal: 'Internal Model',
      production_product_name: 'Product Name',
      production_sequence: 'Process Sequence',
      production_company: 'Company',
      production_company_name: 'Company Name',
      production_company_dept: 'Branch / Department',
      production_process: 'Process',
      production_period: 'Estimated Man-hour',
      production_machine: 'Machine',
      production_material: 'Material',
      production_material_model: 'Material Model',
      production_material_name: 'Material Name',
      production_weight: 'Weight',
      production_unit: 'Weight Unit',
      prodution_defectdetail_btn: 'Detail',
      production_defectptrn: 'Defective Pattern',
      production_report_to: 'Daily Report Mail To',
      production_add: 'New Production',
      production_delete: 'Delete Production',
      production_order_list: 'Production Order - Select a production',
      production_order_day_production: 'Production Date',
      production_order_new_allocation: 'New Allocation',
      production_order_delete_allocation: 'Delete Selected',
      production_order_start_time: 'Time Start',
      production_order_end_time: 'Time Stop',
      production_order_valid_time: 'Time Valid',
      production_order_target: 'Target',
      production_order_result: 'Actual',
      production_order_balance: 'Balance',
      production_order_preparation: 'Preparation Time',
      production_order_time_stop: 'Suspended Time',
      production_order_remark: 'Suspended Reason',
      report_mail_list: 'EMail Reporting  List',
      nonworkinghour_list: 'Non-working Hours List',
      nonworkinghour_name: 'Non-working Hour Name',
      nonworkinghour_period1: 'Period 1',
      nonworkinghour_period2: 'Period 2',
      nonworkinghour_period3: 'Period 3',
      nonworkinghour_period4: 'Period 4',
      nonworkinghour_period5: 'Period 5',
      nonworkinghour_info: 'Non-working Hours',
      nonworkinghour_add: 'New Non-working Hours',
      nonworkinghour_delete: 'Delete Non-working Hours',
      history_title: 'Production History',
      history_show_unclosed: 'show unclosed processes',
      history_short_show: 'Unclose',
      report_output_target: 'Output Target',
      report_output_exception: 'Output Excluding',
      report_output: 'Output Report',
      report_reset_items: 'Reset Output targets',
      report_type: 'Output Period',
      report_daily: 'Daily',
      report_weekly: 'Weekly',
      report_monthly: 'Monthly',
      report_quarterly: 'Quarterly',
      report_Annually: 'Annually',
      report_Range: 'Custom Range',
      report_target_day: 'Select the day',
      report_target_week: 'Select the week',
      report_target_month: 'Select the month',
      report_target_quarter_begin: 'Select the beginning of the month',
      report_target_quarter_1: 'Quarter 1',
      report_target_quarter_2: 'Quarter 2',
      report_target_quarter_3: 'Quarter 3',
      report_target_quarter_4: 'Quarter 4',
      report_target_year: 'Select the year',
      report_target_from: 'Select date from',
      report_target_to: 'Select date to',
      report_week_of: 'Week of',
      report_type_daily: 'Daily Report',
      report_type_weekly: 'Weekly Report',
      report_type_monthly: 'Monthly Report',
      report_type_quarterly: 'Quarterly Report',
      report_type_annually: 'Annual Report',
      report_type_range: 'Custom Range Report',
      report_dialog_download: 'Downloading Report Excel File',
      report_dialog_nodata: 'The data was not downloaded because the data corresponding to the specified conditions does not exist.',
      report_dlg_nodata_title: 'No data found',
      report_dlg_nodata_msg: 'No data found on the requested period. Please check your input request period.',
      defective_types: 'Defective Type',
      defective_pattern_list: 'Defective Configuration',
      defective_name: 'Defect Name',
      defective_info: 'Defect Type',
      defective_new: 'New Defect Type',
      defective_delete: 'Delete Defect Type',
      defective_selectall_checkbox: 'Select All',
      defective_pattern_new: 'Cancel',
      defective_pattern_delete: 'Delete Pattern',
      defective_pattern_create: 'Create',
      defective_pattern_update: 'Update',
      defective_over_length: 'Selected over length',
      defective_over_length_msg: 'Defective Pattern can contain only 15 types per Pattern.',
      defective_newpattern_btn: 'New Pattern',
      defective_newpattern_name: 'Pattern Name',
      defective_total: 'Total',
      defectconf_title: 'Warning',
      defectconf_warning_msg: 'If there are multiple records of data, Editing will happen only the latest row of data and will delete other records.',
      defectconf_title_nodata: 'Data not found',
      defectconf_datanotfound_msg: 'Unable to find Defective Pattern. Please check your Defective Pattern ID, the pattern might be deleted unable to retrieve the data.',
      worstlist_category: 'Type of Report',
      worstlist_worst_qualified: 'Worst Unqualified Ratio',
      worstlist_worst_efficiency: 'Worst Efficiency',
      worstlist_productname: 'Product Name',
      worstlist_productmodel: 'Product Model',
      worstlist_percent_unqualified: 'Unqualified Ratio',
      worstlist_unqual_amount: 'Qualified (Unqualified)',
      worstlist_customer: 'Customer',
      worstlist_efficiency: 'Efficiency (%)',
      worstlist_uptime: 'Gross Uptime (h)',
      worstlist_qualified: 'Qualified',
      worstlist_unqualified: 'Unqualified',
      worstlist_target: 'Target',
      worstlist_defect_ratio: 'Defect Ratio (%)',
      worstlist_this_month: 'Selected Month',
      worstlist_prev_months: 'Previous Months',
      worstlist_prev_month: 'Previous Month',
      worstlist_this_year: 'Selected Year',
      worstlist_prev_years: 'Previous Years',
      worstlist_prev_year: 'Previous Year',
      worstlist_defective: 'Defect details',
      worstlist_year: 'Year',
      worstlist_month: 'Month',
      worstlist_history: 'History',
      worstlist_group_title: 'Data Extraction Group',
      worstlist_group_company: 'Customer',
      worstlist_group_company_abbr: 'Abbreviation',
      worstlist_group_machine: 'Machine',
      worstlist_group_machine_type: 'Model',
      worstlist_group_material: 'Material',
      worstlist_group_material_type: 'Model',
      worstlist_group_process: 'Process',
      worstlist_group_sequence: 'Process Detail',
      worstlist_group_product: 'Product',
      worstlist_group_register: 'Register User',
      worstlist_group_update: 'Update User',
      worstlist_display_list: 'Display List',
      worstlist_display_max_count: 'Max Items',
    },
    th: {
      navigation_title: 'DBord - ข้อมูลการผลิตสินค้าออนไลน์',
      navigation_short_title: 'DBord',
      navigation_menu_user: 'จัดการข้อมูลผู้ใช้งาน',
      navigation_menu_company: 'จัดการข้อมูลทะเบียนบริษัท',
      navigation_menu_person: 'จัดการข้อมูลผู้ติดต่อ',
      navigation_menu_material: 'จัดการข้อมูลวัตถุดิบ',
      navigation_menu_product: 'จัดการข้อมูลสินค้า',
      navigation_menu_machine: 'จัดการข้อมูลเครื่องจักร',
      navigation_menu_specification: 'จัดการข้อมูลจำเพาะของสินค้า',
      navigation_menu_process: 'จัดการกระบวนการผลิต',
      navigation_menu_sequence: 'จัดการขั้นตอนการผลิต',
      navigation_menu_production: 'จัดการข้อมูลการผลิต',
      navigation_menu_production_order: 'ข้อมูลคำสั่งการผลิต',
      navigation_menu_mail: 'ข้อมูลการส่งรายงาน',
      navigation_menu_home: 'เมนูหลัก',
      navigation_menu_non_working_hours: 'จัดการข้อมูลช่วงเวลาพัก',
      navigation_seconds_signin: 'วินาที ย้ายไปที่หน้าจอลงชื่อเข้าใช้โดยอัตโนมัติ',
      navigation_to_signin: 'ลงชื่อเข้าใช้',
      navigation_menu_config: 'ตั้งค่าการแสดงผล',
      navigation_menu_reporting: 'รายงาน',
      navigation_menu_defective: 'จัดการแบบข้อบกพร่อง',
      navigation_menu_excel_report: 'ดาวน์โหลดรายงาน Excel',
      navigation_menu_worstList: 'รายงานการผลิตที่แย่ที่สุด',
      home_menu_title: 'จัดการเมนู',
      auth_title: 'อนุมัติการใช้งาน',
      auth_code: 'รหัสอนุมัติการใช้งาน',
      auth_code_required: 'กรณากรอกรหัสอนุมัติ',
      auth_failed: 'ไม่สามารถเข้าสู่ระบบ',
      auth_dbord: 'เข้าสู่ระบบ',
      common_new: 'รายการใหม่',
      common_update: 'แก้ไข',
      common_save: 'บันทึก',
      common_delete: 'ลบ',
      common_reset: 'ล้างค่า',
      common_search: 'ค้นหา',
      common_go: 'ไป',
      common_defect: 'ข้อบกพร่อง',
      common_password: 'รหัสผ่าน',
      common_new_password: 'รหัสผ่านใหม่',
      common_confirm: 'ยืนยัน',
      common_ok: 'ตกลง',
      common_cancel: 'ยกเลิก',
      common_open: 'เปิด',
      common_close: 'ปิด',
      common_date: 'วันที่',
      common_input_confirm: 'การยืนยัน',
      common_delete_confirm: 'ลบการยืนยัน',
      common_delete_msg: 'ต้องการลบรายการที่เลือกหรือไม่?',
      common_info: 'ข้อมูล',
      common_inuse_meg: 'รายการที่เลือกอยู่ระหว่างการใช้งานและไม่สามารถลบได้',
      common_country: 'ประเทศ',
      common_email: 'อีเมล',
      common_signin_form: 'เข้าสู่ระบบ',
      common_signin_user: 'ผู้ใช้งาน',
      common_signin: 'เข้าสู่ระบบ',
      common_signout: 'ออกจากระบบ',
      common_register: 'ลงทะเบียน',
      common_required: 'จำเป็น',
      common_incorrect_email: 'อีเมลไม่ถูกต้อง',
      common_incorrect_password: 'หรือ รหัสผ่านไม่ถูกต้อง',
      common_admin_menu: 'เมนูแอดมิน',
      common_top: 'สูงสุด',
      common_detail: 'รายละเอียด',
      common_all: 'ทั้งหมด',
      common_reg_user: 'ผู้ลงทะเบียน',
      common_reg_date: 'วันที่ลงทะเบียน',
      common_upd_user: 'ผู้แก้ไขล่าสุด',
      common_upd_date: 'วันที่แก้ไขล่าสุด',
      common_keyword_filter: 'ตัวกรองคำหลัก',
      common_items: 'จำนวนทั้งหมด',
      common_remarks: 'หมายเหตุ',
      config_date_format: 'รูปแบบวันที่',
      config_workflow_order: 'ลำดับการป้อนข้อมูลการทำงาน',
      config_workflow_on: 'เปิด: กระบวนการ -> เครื่องจักร -> ชิ้นส่วน',
      config_workflow_off: 'ปิด: กระบวนการ -> ลูกค้า -> ชิ้นส่วน -> เครื่องจักร',
      config_approval: 'ตั้งค่าการอนุมัติ',
      config_approval_on: 'เปิด: ลงชื่อโดยผู้ใช้งานระบบปัจจุบัน',
      config_approval_off: 'ปิด: ลงชื่อโดยการเลือกชื่อผู้ใช้งาน',
      config_free_1: 'หัวข้อปรับแต่ง 1',
      config_free_2: 'หัวข้อปรับแต่ง 2',
      config_free_3: 'หัวข้อปรับแต่ง 3',
      config_approval_1: 'การอนุมัติ 1',
      config_approval_2: 'การอนุมัติ 2',
      config_approval_3: 'การอนุมัติ 3',
      config_english: 'English',
      config_japanese: '日本語',
      config_thai: 'ภาษาไทย',
      entry_model: 'แบบ/รุ่น',
      entry_name: 'ชื่อ',
      entry_qualified: 'ผ่านเกณฑ์',
      entry_unqualified: 'ไม่ผ่าน',
      entry_rework: 'ทำใหม่',
      entry_register: 'บันทึกข้อมูลจำนวน',
      entry_register_msg: 'ลงทะเบียนจำนวนตามรายการนี้?',
      entry_breakdown: 'ประวัติวันนี้',
      entry_register_check: 'บันทึกและดำเนินการต่อ',
      entry_register_complete: 'บันทึกและสิ้นสุดกระบวนการ',
      entry_register_complete_mobile: 'บันทึกและสิ้นสุด',
      entry_confirm: 'เสร็จสิ้น',
      entry_back: 'กลับ',
      entry_time: 'เวลา',
      entry_sum: 'ยอดรวม',
      entry_sum_today: 'ยอดรวมวันนี้',
      entry_process_start: 'เริ่มดำเนินการ',
      entry_process_cancel: 'ยกเลิกกระบวนการ',
      entry_process_end: 'สิ้นสุดกระบวนการ',
      entry_manhour_efficiency: 'ประสิทธิภาพต่อระยะเวลาการทำงาน',
      entry_efficiency: 'ประสิทธิภาพ',
      entry_section_period: 'ระยะเวลา (นาที)',
      entry_volume: 'ปริมาณ',
      entry_output: 'ส่งออกไฟล์ XLSX',
      entry_daily_report: 'ส่งรายงานประจำวัน',
      entry_daily_report_mobile: 'ส่งรายงาน',
      entry_daily_subject: 'DBord - รายงานประจำวัน',
      entry_daily_message: 'ได้รับอีเมลรายงานประจำวัน กรุณาตรวจสอบรายละเอียดในไฟล์แนบ',
      entry_select_customer: 'เลือกลูกค้า',
      entry_select_process: 'เลือกกระบวนการ',
      entry_select_parts: 'เลือกชิ้นงาน',
      entry_select_machine: 'เลือกเครื่องจักร',
      entry_message_customer: 'กรุณาเลือกลูกค้า',
      entry_message_process: 'กรุณาเลือกกระบวนการ',
      entry_message_parts: 'กรุณาเลือกชิ้นงาน',
      entry_message_machine: 'กรุณาเลือกเครื่องจักร',
      entry_volume_input: 'กรอกข้อมูลจำนวนสินค้า',
      entry_report_machine: 'รายงานสรุปเครื่องจักรรายเดือน',
      entry_approval: 'การอนุมัติ',
      entry_production_approval: 'การอนุมัติการผลิต',
      entry_approved: 'ลงนามโดย:',
      entry_unapproved: '* ไม่ลงนาม / ไม่อนุมัติ',
      entry_confirm_approval: 'กำลังอนุมัติโดยผู้ใช้งานปัจจุบัน',
      entry_cancel_approval: 'ลายเซ็นที่ได้รับอนุมัติจะถูกลบ',
      entry_production_shift: 'เวลาการทำงาน',
      entry_customer_name: 'ชื่อลูกค้า',
      entry_endproc: 'สิ้นสุดกระบวนการ',
      entry_endproc_noquantity: 'ไม่สามารถยืนยันสิ้นสุดกระบวนการได้ เนื่องจากไม่พบการกรอกข้อมูลการผลิต',
      entry_endproc_itemcheck: 'ต้องการเว้นข้อมูลรายการต่อไปนี้หรือไม่ ?',
      entry_endproc_confirm: 'ยืนยันสิ้นสุดกระบวนการผลิต ?',
      entry_approver_select: 'รายนามผู้อนุมัติ',
      entry_approver_select_msg: 'กรุณาเลือกผู้อนุมัติ',
      entry_approver_clear: 'ล้างรายการอนุมัติ',
      entry_zero_quantity: 'ไม่พบข้อมูลปริมาณการผลิต',
      entry_zero_quantity_msg: 'กรุณากรอกข้อมูลปริมาณผลิตภัณฑ์ผ่านเกณฑ์, ทำงานซ้ำ หรือไม่ผ่านเกณฑ์',
      entry_time_hour: 'ชั่วโมง',
      entry_time_minute: 'นาที',
      entry_time_second: 'วินาที',
      entry_duplicated: 'การตรวจสอบความซ้ำซ้อน',
      entry_duplicated_msg: 'เครื่องจักรที่ใช้ในการผลิตอยู่ระหว่างการใช้งาน ไม่สามารถเริ่มกระบวนการได้',
      entry_text_input: 'แก้ไขข้อความ',
      register_inst: 'อีเมลยืนยันจะถูกส่งไปยังที่อยู่อีเมลที่ท่านได้ลงทะเบียนไว้ กรุณาเปิดใช้งานบัญชีของคุณจากลิงก์ภายในอีเมลของท่าน',
      register_subject: 'แจ้งการลงทะเบียนผู้ใช้งานชั่วคราว',
      register_message: 'ผู้ใช้ได้รับการลงทะเบียนชั่วคราวด้วยข้อมูลที่ป้อน\r\nหากต้องการเปิดใช้งานบัญชีผู้ใช้นี้ โปรดไปที่ https://www.f-1tools.com/prodman/?a={code} \r\nโปรดทราบว่าอีเมลนี้จะ ถูกส่งจากระบบโดยอัตโนมัติและไม่สามารถตอบกลับได้',
      register_sent: 'ส่งคำขอเรียบร้อยแล้ว',
      register_check_email: 'โปรดตรวจสอบอีเมลที่ส่งออกจากระบบนี้และเปิดใช้งานบัญชีผู้ใช้งาน',
      register_activation: 'การเปิดใช้งาน',
      register_activation_message: 'เปิดใช้งานบัญชีผู้ใช้ด้วยที่อยู่อีเมลของคุณ',
      register_activation_success: 'เปิดใช้งานบัญชีผู้ใช้ของคุณสำเร็จแล้ว',
      register_activation_success_message: 'กรุณาเข้าสู่ระบบด้วยที่อยู่อีเมลของคุณและรหัสผ่านที่ลงทะเบียน',
      user_info: 'ผู้ใช้',
      user_list: 'รายชื่อผู้ใช้',
      user_id: 'ID ผู้ใช้',
      user_name: 'ชื่อผู้ใช้',
      user_email: 'อีเมล',
      user_enabled: 'เปิดใช้งาน',
      user_add: 'ผู้ใช้ใหม่',
      user_delete: 'ลบผู้ใช้',
      user_signature: 'ลายเซ็น',
      user_role: 'บทบาทของผู้ใช้',
      user_role_administrator: 'ผู้ดูแลระบบ',
      user_role_supervisor: 'หัวหน้างาน',
      user_role_operator: 'ปฏิบัติการ',
      user_role_viewer: 'แสดงข้อมูลเท่านั้น',
      company_list: 'รายชื่อบริษัท',
      company_info: 'บริษัท',
      company_name: 'ชื่อ บริษัท',
      company_abbr: 'อักษรย่อ',
      company_addr: 'ที่อยู่',
      company_tel: 'โทรศัพท์',
      company_fax: 'แฟกซ์',
      company_dept: 'สาขา / แผนก',
      company_tax: 'หมายเลขประจำตัวผู้เสียภาษี',
      company_add: 'บริษัทใหม่',
      company_delete: 'ลบบริษัท',
      person_list: 'รายชื่อบุคคล',
      person_info: 'ข้อมูลบุคคล',
      person_company: 'บริษัท',
      person_lastnm: 'นามสกุล',
      person_firstnm: 'ชื่อ',
      person_mail: 'อีเมล',
      person_mobile: 'โทรศัพท์มือถือ',
      person_add: 'บุคคลใหม่',
      person_delete: 'ลบบุคคล',
      material_list: 'รายการวัสดุ',
      material_info: 'วัสดุ',
      material_major: 'หมวดหมู่หลัก',
      material_minor: 'ประเภทย่อย',
      material_maker: 'ผู้ผลิต',
      material_name: 'ชื่อวัสดุ',
      material_model: 'แบบ/รุ่น',
      material_add: 'วัสดุใหม่',
      material_delete: 'ลบวัสดุ',
      product_list: 'รายการผลิตภัณฑ์',
      product_info: 'ผลิตภัณฑ์',
      product_model: 'ชื่อแบบ/รุ่น (ลูกค้า)',
      product_internal: 'ชื่อแบบ/รุ่น (ภายใน)',
      product_name: 'ชื่อผลิตภัณฑ์',
      product_add: 'ผลิตภัณฑ์ใหม่',
      product_delete: 'ลบสินค้า',
      product_image_preview: 'ภาพตัวอย่าง',
      product_image_select: 'เลือกภาพ (ไฟล์ JPEG/PNG < 5MB)',
      product_image_clear: 'ล้างภาพ',
      machine_list: 'รายการเครื่องจักร',
      machine_info: 'เครื่องจักร',
      machine_category: 'หมวดหมู่เครื่องจักร',
      machine_name: 'ชื่อเครื่องจักร',
      machine_maker: 'ชื่อผู้ผลิตเครื่องจักร',
      machine_type: 'แบบ/รุ่นของเครื่องจักร',
      machine_add: 'เครื่องใหม่',
      machine_delete: 'ลบเครื่อง',
      specification_list: 'รายการข้อมูลจำเพาะ',
      specification_info: 'ข้อมูลจำเพาะ',
      specification_product: 'ผลิตภัณฑ์',
      specification_product_model: 'แบบสินค้า',
      specification_product_name: 'ชื่อผลิตภัณฑ์',
      specification_material: 'วัตถุดิบ',
      specification_material_model: 'แบบ/รุ่นของวัสดุ',
      specification_material_name: 'ชื่อวัสดุ',
      specification_heat: 'กระบวนการชุบแข็ง',
      specification_hardness: 'ค่าความแข็ง',
      specification_surface: 'การเคลือบผิว',
      specification_color: 'รหัสสี',
      specification_weight: 'น้ำหนัก',
      specification_material_weight: 'น้ำหนักวัสดุ',
      specification_product_weight: 'น้ำหนักผลิตภัณฑ์',
      specification_unit: 'หน่วยน้ำหนัก',
      specification_add: 'ข้อมูลใหม่',
      specification_delete: 'ลบข้อมูล',
      process_list: 'รายการกระบวนการ',
      process_info: 'กระบวนการ',
      process_name: 'ชื่อกระบวนการ',
      process_add: 'กระบวนการใหม่',
      process_delete: 'ลบกระบวนการ',
      sequence_list: 'รายการลำดับกระบวนการ',
      sequence_info: 'ลำดับกระบวนการ',
      sequence_name: 'ชื่อลำดับ',
      sequence_add: 'ลำดับใหม่',
      sequence_delete: 'ลบลำดับ',
      production_list: 'รายการผลิต',
      production_info: 'การผลิต',
      production_product: 'ผลิตภัณฑ์',
      production_product_model: 'แบบ/รุ่นสินค้า (ลูกค้า)',
      production_product_internal: 'แบบ/รุ่นสินค้า (ภายใน)',
      production_product_name: 'ชื่อผลิตภัณฑ์',
      production_sequence: 'ลำดับกระบวนการ',
      production_company: 'บริษัท',
      production_company_name: 'ชื่อ บริษัท',
      production_company_dept: 'สาขา / แผนก',
      production_process: 'กระบวนการ',
      production_period: 'ประมาณการเวลาต่อชิ้นงาน (นาที)',
      production_machine: 'เครื่องจักร',
      production_material: 'วัสดุ',
      production_material_model: 'แบบ/รุ่น วัสดุ',
      production_material_name: 'ชื่อวัสดุ',
      production_weight: 'น้ำหนัก',
      production_unit: 'หน่วยน้ำหนัก',
      prodution_defectdetail_btn: 'รายละเอียด',
      production_defectptrn: 'แบบข้อมูลข้อบกพร่อง',
      production_report_to: 'ส่งรายงานประจำวัน',
      production_add: 'การผลิตใหม่',
      production_delete: 'ลบการผลิต',
      production_order_list: 'ใบสั่งผลิต - เลือกการผลิต',
      production_order_day_production: 'วันที่ผลิต',
      production_order_new_allocation: 'การจัดสรรใหม่',
      production_order_delete_allocation: 'ลบรายการที่เลือก',
      production_order_start_time: 'เวลาเริ่มต้น',
      production_order_end_time: 'เวลาหยุดการทำงาน',
      production_order_valid_time: 'ระยะเวลา',
      production_order_target: 'เป้าหมาย',
      production_order_result: 'แท้จริง',
      production_order_balance: 'สมดุล',
      production_order_preparation: 'เวลาเตรียมการ',
      production_order_time_stop: 'เวลาที่ถูกระงับ',
      production_order_remark: 'เหตุผลที่ถูกระงับ',
      report_mail_list: 'รายการอีเมลรายงาน',
      nonworkinghour_list: 'ข้อมูลเวลาทำการ',
      nonworkinghour_name: 'ตั้งชื่อช่วงเวลา',
      nonworkinghour_period1: 'ช่วงที่ 1',
      nonworkinghour_period2: 'ช่วงที่ 2',
      nonworkinghour_period3: 'ช่วงที่ 3',
      nonworkinghour_period4: 'ช่วงที่ 4',
      nonworkinghour_period5: 'ช่วงที่ 5',
      nonworkinghour_info: 'ข้อมูลนอกเวลาทำการ',
      nonworkinghour_add: 'ช่วงเวลาใหม่',
      nonworkinghour_delete: 'ลบช่วงเวลา',
      history_title: 'ประวัติการผลิต',
      history_show_unclosed: 'แสดงกระบวนการที่ไม่ถูกปิด',
      history_short_show: 'กำลังผลิต',
      report_output_target: 'แสดงผลข้อมูล',
      report_output_exception: 'ไม่ต้องการแสดงข้อมูล',
      report_output: 'ดาวน์โหลดรายงานข้อมูล',
      report_reset_items: 'ล้างค่าการแสดงผล',
      report_type: 'ตัวเลือกการแสดงผลข้อมูล',
      report_daily: 'รายวัน',
      report_weekly: 'รายสัปดาห์',
      report_monthly: 'รายเดือน',
      report_quarterly: 'รายไตรมาส',
      report_Annually: 'รายปี',
      report_Range: 'กำหนดช่วงเวลา',
      report_target_day: 'เลือกวัน',
      report_target_week: 'เลือกสัปดาห์',
      report_target_month: 'เลือกเดือน',
      report_target_quarter_begin: 'เลือกเดือนเริ่มต้น',
      report_target_quarter_1: 'ไตรมาสที่ 1',
      report_target_quarter_2: 'ไตรมาสที่ 2',
      report_target_quarter_3: 'ไตรมาสที่ 3',
      report_target_quarter_4: 'ไตรมาสที่ 4',
      report_target_year: 'เลือกปี',
      report_target_from: 'เลือกวันที่เริ่ม',
      report_target_to: 'เลือกวันที่จบ',
      report_week_of: 'สัปดาห์ที่',
      report_type_daily: 'รายงานประจำวัน',
      report_type_weekly: 'รายงานประจำสัปดาห์',
      report_type_monthly: 'รายงานประจำเดือน',
      report_type_quarterly: 'รายงานรายไตรมาส',
      report_type_annually: 'รายงานประจำปี',
      report_type_range: 'รายงานตามช่วงเวลา',
      report_dialog_download: 'กำลังดาวน์โหลดไฟล์รายงาน Excel',
      report_dialog_nodata: 'ไม่สามารถดาวน์โหลดข้อมูลได้ เนื่องจากไม่มีข้อมูลที่สอดคล้องกับเงื่อนไขที่ระบุ',
      report_dlg_nodata_title: 'ไม่พบข้อมูลรายงาน',
      report_dlg_nodata_msg: 'ไม่สามารถแสดงข้อมูลได้ กรุณาตรวจสอบช่วงเวลาที่ต้องการเรียกดูข้อมูล',
      defective_types: 'ประเภทข้อบกพร่อง',
      defective_pattern_list: 'ตั้งค่าแบบข้อบกพร่อง',
      defective_name: 'ชื่อข้อบกพร่อง',
      defective_info: 'ข้อบกพร่อง',
      defective_new: 'รายการใหม่',
      defective_delete: 'ลบรายการ',
      defective_selectall_checkbox: 'เลือกทุกรายการ',
      defective_pattern_new: 'ยกเลิก',
      defective_pattern_delete: 'ลบแบบ',
      defective_pattern_create: 'บันทึก',
      defective_pattern_update: 'แก้ไขแบบ',
      defective_over_length: 'ตัวเลือกเกินกว่าที่กำหนด',
      defective_over_length_msg: 'แบบข้อบกพร่องสามารถรองรับได้เพียงแบบละ 15 ตัวเลือกเท่านั้น',
      defective_newpattern_btn: 'สร้างแบบใหม่',
      defective_newpattern_name: 'ชื่อแบบข้อบกพร่อง',
      defective_total: 'รวม',
      defectconf_title: 'คำเตือน',
      defectconf_warning_msg: 'ในกรณีที่มีการบันทึกข้อมูลจำนวนหลายครั้ง  การแก้ไขข้อมูลจะทำให้คงเหลือข้อมูลเพียงครั้งล่าสุดครั้งเดียวเท่านั้น...',
      defectconf_title_nodata: 'ไม่พบข้อมูล',
      defectconf_datanotfound_msg: 'ไม่พบข้อมูลแบบข้อบกพร่อง กรุณาตรวจสอบ แบบข้อบกพร่องอาจถูกลบจากฐานข้อมูลแล้ว',
      worstlist_category: 'ประเภทของรายงาน',
      worstlist_worst_qualified: 'อัตราส่วนที่ไม่มีเงื่อนไขที่แย่ที่สุด',
      worstlist_worst_efficiency: 'ประสิทธิภาพการผลิตต่ำที่สุด',
      worstlist_productname: 'สินค้า',
      worstlist_productmodel: 'โมเดล',
      worstlist_percent_unqualified: '% ไม่ผ่านเกณฑ์',
      worstlist_unqual_amount: 'ผ่าน (ไม่ผ่าน)',
      worstlist_customer: 'ลูกค้า',
      worstlist_efficiency: 'ประสิทธิภาพ (%)',
      worstlist_uptime: 'ระยะเวลาการทำงานโดยรวม (h)',
      worstlist_qualified: 'ผ่านเกณฑ์',
      worstlist_unqualified: 'ไม่ผ่าน',
      worstlist_target: 'เป้า',
      worstlist_defect_ratio: 'อัตราส่วนข้อบกพร่อง (%)',
      worstlist_this_month: 'เดือนที่เลือก',
      worstlist_prev_months: 'เดือนก่อนหน้า',
      worstlist_prev_month: 'เดือนที่แล้ว',
      worstlist_this_year: 'ปีที่เลือก',
      worstlist_prev_years: 'ปีที่ผ่านมา',
      worstlist_prev_year: 'ปีก่อน',
      worstlist_defective: 'รายละเอียดข้อบกพร่อง',
      worstlist_year: 'ปี',
      worstlist_month: 'เดือน',
      worstlist_history: 'ประวัติศาสตร์',
      worstlist_group_title: 'กลุ่มสกัดข้อมูล',
      worstlist_group_company: 'ลูกค้า',
      worstlist_group_company_abbr: 'คำย่อ',
      worstlist_group_machine: 'เครื่องจักร',
      worstlist_group_machine_type: 'แบบอย่าง',
      worstlist_group_material: 'วัสดุ',
      worstlist_group_material_type: 'แบบอย่าง',
      worstlist_group_process: 'กระบวนการ',
      worstlist_group_sequence: 'รายละเอียดกระบวนการ',
      worstlist_group_product: 'สินค้า',
      worstlist_group_register: 'ลงทะเบียนผู้ใช้',
      worstlist_group_update: 'อัปเดตผู้ใช้',
      worstlist_display_list: 'แสดงรายการ',
      worstlist_display_max_count: 'รายการสูงสุด',
    },
  },
});
