import React from 'react';
import { Box, Button, Paper, Typography, Stack, IconButton } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { useNavigate } from 'react-router-dom';

import TranslateIcon from '@mui/icons-material/Translate';

export default function Splash({ prop }) {
  let navigate = useNavigate();

  const [config, setConfig] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [labels, setLabels] = React.useState(null);
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    if(siteRef.current.authPassed)navigate(`/${config.account}/signin`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [counter, setCounter] = React.useState(10);

  React.useEffect(() => {
    let interval = setInterval(() => {
      setCounter(counter-1);
      if(counter===1)moveLogin();
    }, 1000);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[counter]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const moveLogin = () => {
    navigate(`/${config.account}/signin`, { replace: true });
  }

  return (
    <Box sx={{ height:'100vh' }}>

      <Box sx={{ p:2, display:'flex', width:'100%' }}>
        <Box sx={{ flexGrow: 1 }}/>
        {/* ---------- change language menu ---------- */}
        <IconButton color="inherit" sx={{ mr: 1 }} onClick={languageClick}>
          <TranslateIcon fontSize="small"/>
        </IconButton>
        <Menu anchorEl={anchorLanguage} open={selectLanguage} onClose={languageClose}>
          <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account].language==='jp'?true:false}>日本語</MenuItem>
          <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account].language==='en'?true:false}>English</MenuItem>
          <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account].language==='th'?true:false}>ไทย</MenuItem>
        </Menu>
      </Box>

      <Box sx={{ p:2, width:'100%', height:'80vh', display:'flex', justifyContent:'center', alignItems:'center' }} >
        <Paper sx={{ p:2, width:'600px'}}>
          <Stack direction="column" sx={{ justifyContent:'center', alignItems:'center' }}>
            <Typography sx={{ m:1 }} variant="h6">{ getLabel('navigation_title') }</Typography>
            <Button  onClick={moveLogin}>{ counter } { getLabel('navigation_seconds_signin') }</Button>
            <Button variant="contained" onClick={moveLogin} sx={{ mt:1 }}>{ getLabel('navigation_to_signin') }</Button>
          </Stack>
        </Paper>
      </Box>

    </Box>
  );
}

