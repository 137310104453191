import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Button, Menu, FormGroup,
  FormControlLabel, Switch, Backdrop, CircularProgress, Typography } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import ConfirmDialog from "../ConfirmDialog";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';

import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";

export default function EditWorkhour() {

  const [config] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, _setCaption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [isDisabled, setDisabled] = React.useState(false);
  const [recordData, setRecordData] = React.useState({ id:'', name:'', p1_st:null, p1_ed:null,
  p2_st:null, p2_ed:null, p3_st:null, p3_ed:null, p4_st:null, p4_ed:null, p5_st:null, p5_ed:null,
  p1_stx:0, p1_edx:0, p2_stx:0, p2_edx:0, p3_stx:0, p3_edx:0, p4_stx:0, p4_edx:0, p5_stx:0, p5_edx:0, 
  userreg:'', datereg:'', userupd:'',dateupd:'', user:config.userid, auth:config[config.account].auth });
  const [helpers, setHelpers] = React.useState({ name:'' });

  const [recordList, setRecordList] = React.useState([]);
  const [modifiedList, setModifiedList] = React.useState([]);

  const [selectTime, setSelectTime ] = React.useState(false);
  const [editTime, setEditTime ] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const closeTimeSelection = () => {
    setSelectTime(false);
  }

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
      setRecordData(data => ({...data, user:siteRef.current.userid, auth:config[config.account].auth }));
    }
  }

  React.useEffect(() => {
    loadDefault();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setModifiedList(recordList.map( (v) => ({
      id: v.id,
      name: v.name,
      period1: v.p1_st?`${v.p1_st?.slice(0,5)} - ${v.p1_ed?.slice(0,5)}`:'',
      period2: v.p2_st?`${v.p2_st?.slice(0,5)} - ${v.p2_ed?.slice(0,5)}`:'',
      period3: v.p3_st?`${v.p3_st?.slice(0,5)} - ${v.p3_ed?.slice(0,5)}`:'',
      period4: v.p4_st?`${v.p4_st?.slice(0,5)} - ${v.p4_ed?.slice(0,5)}`:'',
      period5: v.p5_st?`${v.p5_st?.slice(0,5)} - ${v.p5_ed?.slice(0,5)}`:'',
      userreg: v.userreg,
      datereg: v.datereg,
      userupd: v.userupd,
      dateupd: v.dateupd,
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordList]);

  const loadData = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghours`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });
  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnDefs = [
    { headerName: getLabel('nonworkinghour_name'), field: "name" },
    { headerName: getLabel('nonworkinghour_period1'), field: "period1" },
    { headerName: getLabel('nonworkinghour_period2'), field: "period2" },
    { headerName: getLabel('nonworkinghour_period3'), field: "period3" },
    { headerName: getLabel('nonworkinghour_period4'), field: "period4" },
    { headerName: getLabel('nonworkinghour_period5'), field: "period5" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      cdef[0].headerName = getLabel('nonworkinghour_name');
      cdef[1].headerName = getLabel('nonworkinghour_period1');
      cdef[2].headerName = getLabel('nonworkinghour_period2');
      cdef[3].headerName = getLabel('nonworkinghour_period3');
      cdef[4].headerName = getLabel('nonworkinghour_period4');
      cdef[5].headerName = getLabel('nonworkinghour_period5');
      cdef[6].headerName = getLabel('common_reg_user');
      cdef[7].headerName = getLabel('common_reg_date');
      cdef[8].headerName = getLabel('common_upd_user');
      cdef[9].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const listRef = React.useRef();
  listRef.current = recordList;
  const updateData = (e) => {
    const id = e.data.id;
    const rec = listRef.current;
    const obj = rec.find(v => v.id === id);
    obj['user'] = config[config.account].userid;
    obj['auth'] = config[config.account].auth;
    setEditState(1);
    setRecordData(obj);
    inputValidation(obj);
  }

  const [nextday, setNextday] = React.useState(false);

  const modifyTime = (e, val, typ) => {
    setEditTime(`1971/07/02 ${val}`);
    setAnchorEl(e.currentTarget);
    setNextday(recordData[`${e.target.id}x`]===1?true:false);
    setSelectTime(true);
  }

  const updateTime = (e, flg) => {
    if(flg) {
      const hrs = ("00" + (e.getHours())).slice(-2);
      const mns = ("00" + (e.getMinutes())).slice(-2);
      const time = `${hrs}:${mns}`;
      setRecordData(v => ({...v, [anchorEl.id]:time, [`${anchorEl.id}x`]:nextday?1:0 }));
      setSelectTime(false);
    }
  }

  const updateNextday = (e) => {
    let checked = e.target.checked;
    checked = !checked;
    setNextday(!checked);
  }

  const clearTime = () => {
    setRecordData(v => ({...v, [anchorEl.id]:null, [`${anchorEl.id}x`]:0 }));
    closeTimeSelection();
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.id===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onModelUpdated : updateSelectedRow,
    localeText: localeText,
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const newRecord = () => {
    setRecordData(v => ({...v, id:'', name:'', p1_st:null, p1_ed:null,
      p2_st:null, p2_ed:null, p3_st:null, p3_ed:null, p4_st:null, p4_ed:null, p5_st:null, p5_ed:null,
      p1_stx:0, p1_edx:0, p2_stx:0, p2_edx:0, p3_stx:0, p3_edx:0, p4_stx:0, p4_edx:0, p5_stx:0, p5_edx:0, 
      userreg:'', datereg:'', userupd:'',dateupd:'', user:config[config.account].userid, auth:config[config.account].auth }));
    setHelpers({ name: '' });
    setEditState(0);
  }

  const inputValidation = (data) => {
    let validated = true;
    setHelpers({ name: '' });
    if(data.name.trim().length === 0) {
      setHelpers(helpers => ({...helpers, name:'Required'}));
      validated = false;
    }
    return validated;
  }

  const updateRecord = () => {
    setDisabled(true);
    if(inputValidation(recordData)) {
      if(editState===0) { // new machine
        const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghouradd`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          loadData();
          setDisabled(false);
          setSid(data.insertId);
        });
      } else { // update machine
        const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghourupdate`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          loadData();
          setDisabled(false);
          setSid(recordData.id);
        });
      }
    } else {
      setDisabled(false);
    }
  }

  const deleteRecord = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghourinuse`;
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
    .then(res => res.json())
    .then(data => {
      Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
    });
    if(sum === 0) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghourdelete`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
      .then(res => res.json())
      .then(_data => {
        newRecord();
        loadData();
      });
    } else {
      openInUse();
    }
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = () => setConfirm(true);
  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) deleteRecord();
    setConfirm(false);
  }

  const [inUse, setInUse] = React.useState(false);
  const openInUse = () => setInUse(true);
  const closeInUse = () => setInUse(false);


  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <ConfirmDialog 
        open={inUse}
        onClose={closeInUse}
        title={ getLabel('common_info') }
        msg={ getLabel('common_inuse_meg') }
        confirm={ getLabel('common_confirm') }
      />

      {/* -------------------------------------------------------------------------------------------------------- */}
      <Menu id="select-time" open={selectTime} onClose={closeTimeSelection} anchorEl={anchorEl}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticTimePicker 
          displayStaticWrapperAs="desktop"
          ampm={false}
          value={editTime}
          onChange={(e) => updateTime(e, false)}
          onAccept={(e) => updateTime(e, true)}
          renderInput={(params) => <TextField {...params} />}
          ToolbarComponent={(props) => (
            <Stack direction="row" spacing={2}>
              <Button onClick={clearTime} sx={{ m:1, mt:0.5 }} variant="contained">Clear</Button>
              <FormGroup> 
                <FormControlLabel control={<Switch checked={nextday} onChange={updateNextday} sx={{ mt:0.5}} />} label="Next day" />
              </FormGroup>
            </Stack>
          )}
          showToolbar={true}
        />
        </LocalizationProvider>
      </Menu>
      {/* -------------------------------------------------------------------------------------------------------- */}

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('nonworkinghour_list')}</h4>
      </Box>

      <Box color="text.primary" style={{ height: `calc(40vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          { !aggDestroyed &&
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={modifiedList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}>
          </AgGridReact>
          }
        </div>
      </Box>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{editState===0?getLabel('nonworkinghour_info')+" - "+getLabel('common_new'):getLabel('nonworkinghour_info')+" - "+getLabel('common_update')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
               <TextField sx={{ mx:1, mb:1 }} id="name" label={getLabel('nonworkinghour_name')}
                variant="standard" size="small" value={recordData?.name}
                onChange={(e) => setRecordData(v=> ({...v, name: e.target.value}))}
                helperText={ helpers.name } error={helpers.name.length>0?true:false}
               />

              <Box sx={{ display:'flex', py:1, px:1 }}>
                <Typography sx={{ lineHeight:2, mr:2 }}>{ getLabel('nonworkinghour_period1') }</Typography>
                <Button id="p1_st" onClick={(e) => modifyTime(e, recordData.p1_st, false)} sx={{ mr:1, backgroundColor:recordData.p1_stx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p1_st?recordData.p1_st.slice(0,5):'---' }</Button>
                <Button id="p1_ed" onClick={(e) => modifyTime(e, recordData.p1_ed, false)} sx={{ backgroundColor:recordData.p1_edx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p1_ed?recordData.p1_ed.slice(0,5):'---' }</Button>
              </Box>

              <Box sx={{ display:'flex', py:1, px:1 }}>
                <Typography sx={{ lineHeight:2, mr:2 }}>{ getLabel('nonworkinghour_period2') }</Typography>
                <Button id="p2_st" onClick={(e) => modifyTime(e, recordData.p2_st, false)} sx={{ mr:1, backgroundColor:recordData.p2_stx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p2_st?recordData.p2_st.slice(0,5):'---' }</Button>
                <Button id="p2_ed" onClick={(e) => modifyTime(e, recordData.p2_ed, false)} sx={{ backgroundColor:recordData.p2_edx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p2_ed?recordData.p2_ed.slice(0,5):'---' }</Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <Box sx={{ display:'flex', py:1, px:1 }}>
                <Typography sx={{ lineHeight:2, mr:2 }}>{ getLabel('nonworkinghour_period3') }</Typography>
                <Button id="p3_st" onClick={(e) => modifyTime(e, recordData.p3_st, false)} sx={{ mr:1, backgroundColor:recordData.p3_stx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p3_st?recordData.p3_st.slice(0,5):'---' }</Button>
                <Button id="p3_ed" onClick={(e) => modifyTime(e, recordData.p3_ed, false)} sx={{ backgroundColor:recordData.p3_edx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p3_ed?recordData.p3_ed.slice(0,5):'---' }</Button>
              </Box>
              <Box sx={{ display:'flex', py:1, px:1 }}>
                <Typography sx={{ lineHeight:2, mr:2 }}>{ getLabel('nonworkinghour_period4') }</Typography>
                <Button id="p4_st" onClick={(e) => modifyTime(e, recordData.p4_st, false)} sx={{ mr:1, backgroundColor:recordData.p4_stx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p4_st?recordData.p4_st.slice(0,5):'---' }</Button>
                <Button id="p4_ed" onClick={(e) => modifyTime(e, recordData.p4_ed, false)} sx={{ backgroundColor:recordData.p4_edx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p4_ed?recordData.p4_ed.slice(0,5):'---' }</Button>
              </Box>
              <Box sx={{ display:'flex', py:1, px:1 }}>
                <Typography sx={{ lineHeight:2, mr:2 }}>{ getLabel('nonworkinghour_period5') }</Typography>
                <Button id="p5_st" onClick={(e) => modifyTime(e, recordData.p5_st, false)} sx={{ mr:1, backgroundColor:recordData.p5_stx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p5_st?recordData.p5_st.slice(0,5):'---' }</Button>
                <Button id="p5_ed" onClick={(e) => modifyTime(e, recordData.p5_ed, false)} sx={{ backgroundColor:recordData.p5_edx===1?'#DDEEFF':'' }} variant="outlined">{ recordData.p5_ed?recordData.p5_ed.slice(0,5):'---' }</Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => newRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('nonworkinghour_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => openConfirm()} disabled={editState===0?true:false} disableElevation>{getLabel('nonworkinghour_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" disabled={isDisabled} onClick={() => updateRecord()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>
      </Paper>
    </Box>
  );
}