import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Box, Button, Backdrop, CircularProgress, IconButton, Grid, Stack } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
// eslint-disable-next-line no-unused-vars
import { useNavigate, useLocation } from 'react-router-dom';
import ConfirmDialog from "./ConfirmDialog";
import UserListDialog from "./UserListDialog";
import TimePickMenu from "./TimePickMenu";
import NumericInput from './NumericInput';
import TextInput from "./TextInput";
// import DefectiveDlg from './DefectiveDialog';
import DefectiveDlg from './DefectTableDialog';

import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export default function Record(props) {
  const [record, setRecord] = React.useState(props.recordData);
  const [uncompleted] = React.useState(props.uncompleted);
  const [open] = React.useState(props.open);
  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [dbconfig, setDbconfig] = React.useState(null);
  
  const titleStyle = () => ({
    width:'98%',
    textAlign: 'center',
    fontSize: '0.875rem',
    lineHeight: '1.9',
    px:1.2, py:0.6,
    border: '1px solid primary.main',
    borderRadius: '4px',
    color: 'primary.contrastText',
    bgcolor: 'primary.main',
  });

  const alleftStyle = () => ({
    width:'95%',
    textAlign: 'left',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    px:1.2, py:0.6,
    border: '1px solid',
    borderRadius: '4px',
    color: 'primary.main',
  });

  const alrightStyle = () => ({
    width:'95%',
    textAlign: 'right',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    px:1.2, py:0.6,
    border: '1px solid',
    borderRadius: '4px',
    color: 'primary.main',
  });

  // eslint-disable-next-line no-unused-vars
  const almiddleStyle = () => ({
    width:'95%',
    textAlign: 'center',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    px:1.2, py:0.6,
    border: '1px solid',
    borderRadius: '4px',
    color: 'primary.main',
  });

  const getConfigs = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      setLoading(false);
    });
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  const formatDate = (val, dftype, dfdisp) => {
    const date_exp = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) ([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    if(val !== undefined) {
      const chk = date_exp.test(val);
      if(chk) {
        const dt = new Date(val);
        let fdt = '';
        if(dftype === 1) {
          if(dfdisp === 1) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${dt.getFullYear()} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}`;
          }
        } else if(dftype === 2) {
          if(dfdisp === 1) {
            fdt = `${dt.getFullYear()}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')}`;
          }
        }
        return fdt;
      }
      return val;
    } else return '';
  }

  React.useEffect(() => {
    loadDefault();
    const lang = siteRef.current.language;
    setLabels(caption[lang]);
    getConfigs();
    if(record.defectptrn!==null) {
      // loadDefectDetail(record.defectptrn);
      loadDefectList(record.defectptrn);
      // loadDefectAmt(record.section);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(()=> {
    if(open && dbconfig) {
      const dftype = Number(getDBValue('date_format'));
      const stf = formatDate(record.timest, dftype, 1);
      const edf = formatDate(record.timeed, dftype, 1);
      setRecord(v => ({...v, timestf:stf, timeedf:edf}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dbconfig]);

  // ---------------------------------------------- approval related
  const [sign, setSign] = React.useState(null);
  const approve1 = () => {
    const apr_method = getDBValue('approval_login');
    if(apr_method === '1') {
      setDlgItem({...dlgItem, id:'approval1', title:getLabel('common_input_confirm'),
      msg:!record.approval1?getLabel('entry_confirm_approval'):getLabel('entry_cancel_approval'),
      confirm:getLabel('common_confirm'), cancel:getLabel('common_cancel')});
      setSign({approval:'approval1', section:record.section, aprid:!record.approval1?siteRef.current.userid:'', userid:siteRef.current.userid, auth:config[config.account].auth });
      setConfirm(true);
    } else {
      loadUsers();
      setUserListItems(v => ({...v, type:'approval1', title:getLabel('entry_approver_select'), msg:getLabel('entry_approver_select_msg'), confirm:getLabel('entry_approver_clear'), cancel:getLabel('common_cancel') }));
      setUserListDlg(true);
    }
  }

  const approve2 = () => {
    const apr_method = getDBValue('approval_login');
    if(apr_method === '1') {
      setDlgItem({...dlgItem, id:'approval2', title:getLabel('common_input_confirm'),
      msg:!record.approval2?getLabel('entry_confirm_approval'):getLabel('entry_cancel_approval'),
      confirm:getLabel('common_confirm'), cancel:getLabel('common_cancel')});
      setSign({approval:'approval2', section:record.section, aprid:!record.approval2?siteRef.current.userid:'', userid:siteRef.current.userid, auth:config[config.account].auth });
      setConfirm(true);
    } else {
      loadUsers();
      setUserListItems(v => ({...v, type:'approval2', title:getLabel('entry_approver_select'), msg:getLabel('entry_approver_select_msg'), confirm:getLabel('entry_approver_clear'), cancel:getLabel('common_cancel') }));
      setUserListDlg(true);
    }
  }

  const approve3 = () => {
    const apr_method = getDBValue('approval_login');
    if(apr_method === '1') {
      setDlgItem({...dlgItem, id:'approval3', title:getLabel('common_input_confirm'),
      msg:!record.approval3?getLabel('entry_confirm_approval'):getLabel('entry_cancel_approval'),
      confirm:getLabel('common_confirm'), cancel:getLabel('common_cancel')});
      setSign({approval:'approval3', section:record.section, aprid:!record.approval3?siteRef.current.userid:'', userid:siteRef.current.userid, auth:config[config.account].auth });
      setConfirm(true);
    } else {
      loadUsers();
      setUserListItems(v => ({...v, type:'approval3', title:getLabel('entry_approver_select'), msg:getLabel('entry_approver_select_msg'), confirm:getLabel('entry_approver_clear'), cancel:getLabel('common_cancel') }));
      setUserListDlg(true);
    }
  }

  // ---------------------------------------------- dialog related
  const [dlgItem, setDlgItem] = React.useState({ id:'', title:'', msg:'', confirm:'', cancel: ''});
  const [confirm, setConfirm] = React.useState(false);
  const closeConfirm = (e) => {
    const id = e.target.dataset.id;
    const ret = e.target.dataset.return;
    if(ret) {
      switch(id) {
        case 'approval1':
          updateApproval();
          break;
        case 'approval2':
          updateApproval();
          break;
        case 'approval3':
          updateApproval();
          break;
        default:
      }
    }
    setConfirm(false);
  }

  const updateApproval = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionapproval`;
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sign) })
    .then(res => res.json())
    .then(_data => {
      setLoading(false);
    });
    getRecord(record.section);
  }

  const getRecord = async(section) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/summary`;
    setLoading(true);
    var incompFlg = false;
    if(editRecord?.isStartTime === false) {
      incompFlg = false;
    } else {
      incompFlg = uncompleted;
    }
    await fetch(url, { 
      method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id:section, auth:config[config.account].auth, incomp:incompFlg })
    })
    .then(res => res.json())
    .then(data => {
      setRecord(data[0]);
      setLoading(false);
    });
  }
  
  // ---------------------------------------------- user list dialog related
  const [userList, setUserList] = React.useState(null);
  const [userListItems, setUserListItems] = React.useState({type:'', title:'', msg:'', confirm:'', cancel:'' });
  const [userListDlg, setUserListDlg] = React.useState(false);
  const closeUserListDlg = (e) => {
    const type = e.target.dataset.type;
    const ret = e.target.dataset.return;
    if(ret) {
      const param = {approval:type, section:record.section, aprid:'', userid:siteRef.current.userid, auth:config[config.account].auth };
      updateApproval2(param);
    }
    setUserListDlg(false);
  }

  const onSelectUser = (e, id) => {
    const type = e.target.dataset.type;
    const param = {approval:type, section:record.section, aprid:id, userid:siteRef.current.userid, auth:config[config.account].auth };
    updateApproval2(param);
    setUserListDlg(false);
  }

  const updateApproval2 = async(param) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionapproval`;
    setLoading(true);
    await fetch(url, { 
      method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(param) 
    })
    .then(res => res.json())
    .then(_data => {
      setLoading(false);
    });
    getRecord(record.section);
  }

  const loadUsers = async() => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/users`;
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({auth:config[config.account].auth}) })
    .then(res => res.json())
    .then(data => {
      setUserList(data);
      setLoading(false);
    });
  }

  // ---------------------------------------------- production time related
  const [editRecord, setEditRecord] = React.useState();

  const modifyTime = (e, val, typ) => { // typ: true(start), false(end)
    setEditRecord(val);
    setEditRecord((val) => ({...val, isStartTime:typ}));
    const time = typ?val.timest:val.timeed;
    setEditTime(time===null?val.timest:time);
    setAnchorPicker(e.currentTarget);
    setTimePicker(true);
  }

  const formatDBDate = (dt) => {
    const year = dt.getFullYear();
    const month = ("00" + (dt.getMonth()+1)).slice(-2);
    const day = ("00" + (dt.getDate())).slice(-2);
    const hrs = ("00" + (dt.getHours())).slice(-2);
    const mns = ("00" + (dt.getMinutes())).slice(-2);
    const date = `${year}/${month}/${day} ${hrs}:${mns}:00`;
    return date;
  }

  const updateTime = (e, flg) => {
    if(flg) {
      const starting = editRecord.isStartTime;
      const sttime = editRecord.timest;
      const edtime = editRecord.timeed;
      let date = formatDBDate(e);
      if(starting) {
        let stdate = new Date(date);
        let eddate = new Date(edtime);
        if(stdate >= eddate) {
          stdate.setDate(stdate.getDate() - 1);
          date = formatDBDate(stdate);
        }
      } else {
        let stdate = new Date(sttime);
        let eddate = new Date(date);
        if(stdate >= eddate) {
          eddate.setDate(eddate.getDate() + 1);
          date = formatDBDate(eddate);
        }
      }
      updateSectionDate(date);
    }
  }

  const updateSectionDate = async(date) => {
    const id = editRecord.section;
    const flg = editRecord.isStartTime;
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectiondate`;
    setLoading(true);
    await fetch(url, { method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id:id, flg:flg, date:date, user:siteRef.current.userid, auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(async(data) => {
      await recalcEfficiency(editRecord);
      setLoading(false);
    });
  }

  const recalcEfficiency = async(v) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/nonworkinghourcalc`;
    const arg = { section:v.section, nonworking:v.nonworking, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(async(data) => {
      await updateUptime(v.section, data[0]?.uptime_full, data[0]?.uptime_net);
      setLoading(false);
    });
  }

  const updateUptime = async(id, full, net) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionuptime`;
    const arg = { id:id, full:full, net:net, user:siteRef.current.userid, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(data => {
      setLoading(false);
      getRecord(record.section);
    });
  }

  const [editTime, setEditTime ] = React.useState();
  const [anchorPicker, setAnchorPicker] = React.useState(null);
  const [timePicker, setTimePicker] = React.useState(false);

  const closeTimePicker = (e, u) => {
    if(u) {
      updateTime(e, true);
    }
    setTimePicker(false);
  }

  // ----------------------------------------------
  React.useEffect(() => {
    setNumQualified(record.qualified);
    setNumUnqualified(record.unqualified);
    setNumRework(record.rework);
  }, [record]);
  
  const [numericDlg, setNumericDlg] = React.useState(false);
  const [numQualified, setNumQualified] = React.useState(0);
  const [numUnqualified, setNumUnqualified] = React.useState(0);
  const [numRework, setNumRework] = React.useState(0);
  const [updateId, setUpdateId] = React.useState(null);
  const [selectedNum, setSelectedNum] = React.useState(0);

  const openNumericDlg = (event) => {
    setUpdateId(event.target.id);
    if(event.target.id === 'qualified')setSelectedNum(numQualified);
    if(event.target.id === 'unqualified')setSelectedNum(numUnqualified);
    if(event.target.id === 'rework')setSelectedNum(numRework);
    setNumericDlg(true);
  }

  const closeNumericDlg = (value) => {
    if(updateId === 'qualified')setNumQualified(value);
    if(updateId === 'unqualified')setNumUnqualified(value);
    if(updateId === 'rework')setNumRework(value);
    setNumericDlg(false);
  }

  const updateQuantities = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/volumes_quantity`;
    let arg = {
      section:record.section, user:siteRef.current.userid, auth:config[config.account].auth,
      qualified:numQualified, rework:numRework, unqualified:numUnqualified,
    };
    
    // if(record.defectptrn!==null) {
    //   arg = {
    //     section:record.section, user:siteRef.current.userid, auth:config[config.account].auth,
    //     qualified:numQualified, rework:numRework, unqualified:numUnqualified,
    //   };
    // }
    
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(data => {
      if(data?.affectedRows === 0) { // new volume record required!!
        arg.production = record.production;
        insertVolume(arg);
      }
      setLoading(false);
      reloadRecord();
      // loadDefectAmt(record.section);
    });
  }

  const insertVolume = async(arg) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/volumeadd`;
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(data => {
      setLoading(false);
    });
  }

  const reloadRecord = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/summary`;
    setLoading(true);
    var incompFlg = false;
    if(editRecord?.isStartTime === false) {
      incompFlg = false;
    } else {
      incompFlg = uncompleted;
    }
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id:record.section, auth:config[config.account].auth, incomp:incompFlg })})
    .then(res => res.json())
    .then(data => {
      setRecord(data[0]);
      setLoading(false);
    });
  }

// ----------------------------------------------
  const [openTI, setOpenTI] = React.useState(false);
  const [valueTI, setValueTI] = React.useState('');
  const [idTI, setIdTI] = React.useState(null);

  const openTextInput = (id, val) => {
    setIdTI(id);
    setValueTI(val);
    setOpenTI(true);
  };

  const closeTextInput = (newValue) => {
    setOpenTI(false);
    if (newValue!==undefined) {
      setValueTI(newValue);
      updateFreeEntry(idTI, newValue);
    }
  };

  const updateFreeEntry = async(column, value) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/sectionfreeentry`;
    const arg = { section:record.section, userid:siteRef.current.userid,
      col:column, value:value, auth:config[config.account].auth };
    setLoading(true);
    await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(arg) })
    .then(res => res.json())
    .then(data => {
      setLoading(false);
      reloadRecord();
    });
  }

// ------------ Defective Informations ------------
  const [defectDlg, setDefectDlg] = React.useState(false);
  // const aDfAmt = React.useRef([]);
  // const oDfAmt = React.useRef({});
  // const oPtrn = React.useRef({});

  // const loadDefectAmt = async (secId) => {
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/volume_getdefectqty`;
  //   const rec = { section:secId, user:config.userid, auth:config[config.account].auth }
  //   setLoading(true);
  //   await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
  //   .then(res => res.json())
  //   .then(data => {
  //     aDfAmt.current = data;
  //     gen_oDfAmt(data);
  //     setLoading(false);
  //   });
  // };

  // const loadDefectDetail = async (idNum) => {
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/defectptrns`;
  //   const rec = { id:idNum, user:config.userid, auth:config[config.account].auth }
  //   setLoading(true);
  //   await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rec) })
  //   .then(res => res.json())
  //   .then(data => {
  //     if(data[0]) {
  //       oPtrn.current = data[0];
  //     } else oPtrn.current = null;
  //     setLoading(false);
  //   });
  // };

  // const loadDefectList = async(gid) => {
  //   const url = `${config[config.account].url}:${config[config.account].port}/api/defectlist`;
  //   const arg = { id:gid, user:config.userid, auth:config[config.account].auth }
  //   fetch(url,
  //     { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(arg)})
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log(data);
  //   });
  // }

  // const gen_oDfAmt = (arr) => {
  //   oDfAmt.current = {
  //     qualified: arr.reduce((t,c)=>{ return t+c.qualified },0),
  //     rework: arr.reduce((t,c)=>{ return t+c.rework },0),
  //     unqualified: arr.reduce((t,c)=>{ return t+c.unqualified },0),
  //     sumAmt: arr.reduce((t,c)=>{ return t+c.unqualified },0),
  //     defectdesc: arr.map(each=>each.defectdesc).join(' ').trim(),
  //     qty01: arr.reduce((t,c)=>{ return t+c.qty01!==0?t+c.qty01:null },0), qty02: arr.reduce((t,c)=>{ return t+c.qty02!==0?t+c.qty02:null },0), qty03: arr.reduce((t,c)=>{ return t+c.qty03!==0?t+c.qty03:null },0),
  //     qty04: arr.reduce((t,c)=>{ return t+c.qty04!==0?t+c.qty04:null },0), qty05: arr.reduce((t,c)=>{ return t+c.qty05!==0?t+c.qty05:null },0), qty06: arr.reduce((t,c)=>{ return t+c.qty06!==0?t+c.qty06:null },0),
  //     qty07: arr.reduce((t,c)=>{ return t+c.qty07!==0?t+c.qty07:null },0), qty08: arr.reduce((t,c)=>{ return t+c.qty08!==0?t+c.qty08:null },0), qty09: arr.reduce((t,c)=>{ return t+c.qty09!==0?t+c.qty09:null },0),
  //     qty10: arr.reduce((t,c)=>{ return t+c.qty10!==0?t+c.qty10:null },0), qty11: arr.reduce((t,c)=>{ return t+c.qty11!==0?t+c.qty11:null },0), qty12: arr.reduce((t,c)=>{ return t+c.qty12!==0?t+c.qty12:null },0),
  //     qty13: arr.reduce((t,c)=>{ return t+c.qty13!==0?t+c.qty13:null },0), qty14: arr.reduce((t,c)=>{ return t+c.qty14!==0?t+c.qty14:null },0), qty15: arr.reduce((t,c)=>{ return t+c.qty15!==0?t+c.qty15:null },0),
  //   }
  // }

  const openDefectDlg = () => {
    // gen_oDfAmt(aDfAmt.current);
    setDefectDlg(true);
  }

  const closeDefectDlg = (e) => {
    setDefectDlg(false);
    // console.log(e);
    // if(obj) {
    //   setRecord({ ...record, unqualified:obj.sumAmt, defectdesc:obj.defectdesc });
    //   // oDfAmt.current = { ...oDfAmt.current, 
    //   //   unqualified:obj.sumAmt, sumAmt:obj.sumAmt, defectdesc:obj.defectdesc,
    //   //   qty01:obj.qty01, qty02:obj.qty02, qty03:obj.qty03, qty04:obj.qty04, qty05:obj.qty05, 
    //   //   qty06:obj.qty06, qty07:obj.qty07, qty08:obj.qty08, qty09:obj.qty09, qty10:obj.qty10, 
    //   //   qty11:obj.qty11, qty12:obj.qty12, qty13:obj.qty13, qty14:obj.qty14, qty15:obj.qty15 
    //   // };
    //   setUpdateId('unqualified');
    //   setNumUnqualified(obj.sumAmt);
    // }
  }

  React.useEffect(()=> {
    if(updateId != null) {
      setRecord(v => ({...v, qualified:numQualified, unqualified:numUnqualified, rework:numRework }));
      updateQuantities();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numQualified, numUnqualified, numRework]);


  const [defectList, setDefectList] = React.useState(null);
  const [defects, setDefects] = React.useState(null);

  // React.useEffect(()=> {
  //   console.log(defectList);
  // }, [defectList]);

  const loadDefectList = async(gid) => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defectlist`;
    const arg = { id:gid, user:config.userid, auth:config[config.account].auth }
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(arg)})
      .then(res => res.json())
      .then(data => {
        data = data.map((v,i) => ({...v, val:0 }));
        setDefectList(data);
        loadDefects();
    });
  }

  // eslint-disable-next-line no-unused-vars
  const loadDefects_old = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/defects`;
    const arg = { section:record.section, user:config.userid, auth:config[config.account].auth };
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(arg)})
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        setDefects(data);
    });
  }

  const loadDefects = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/transposedefects`;
    const arg = { section:record.section, user:config.userid, auth:config[config.account].auth };
    fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(arg)})
      .then(res => res.json())
      .then(data => {
        setDefects(data[4]);
    });
  }

  const dataChanged = (v) => {
    setUpdateId('unqualified');
    setNumUnqualified(v);
    // updateQuantities();
    // console.log(v);
  }

  const test = () => {
    // console.log(defectList);
    // console.log(defects);
    // console.log(`section: ${record.section}`);
    // loadDefects();
  }

// ----------------------------------------------

  return (
    <Box sx={{ height:'100vh' }}>

      {/* <DefectiveDlg
        open={defectDlg}
        onClose={closeDefectDlg}
        oPtrn={oPtrn.current}
        oDfAmt={oDfAmt.current}
        source={'record'}
      /> */}

      <DefectiveDlg
        open={defectDlg}
        onClose={closeDefectDlg}
        defect={defectList}
        records={defects}
        onDataChanged={dataChanged}
        // onUpdateDefectTotal={updateDefectTotal}
        // onUpdateDefectRemark={updateDefectRemark}
      />

      <TextInput
        id="text-input"
        keepMounted
        open={openTI}
        title={ getLabel('entry_text_input') }
        ok_text={ getLabel('common_confirm') }
        cancel_text={ getLabel('common_cancel') }
        onClose={closeTextInput}
        value={valueTI}
      />

      <ConfirmDialog 
        id={ dlgItem.id }
        open={ confirm }
        onClose={ closeConfirm }
        title={ dlgItem.title }
        msg={ dlgItem.msg }
        confirm={ dlgItem.confirm }
        cancel={ dlgItem.cancel }
      />

      <UserListDialog 
        type={ userListItems.type }
        open={ userListDlg }
        onClose={ closeUserListDlg }
        onSelect={ onSelectUser }
        title={ userListItems.title }
        msg={ userListItems.msg }
        users={ userList }
        searchText={ getLabel('common_search') }
        confirm={ userListItems.confirm }
        cancel={ userListItems.cancel }
      />

      <TimePickMenu
        onClose={closeTimePicker}
        open={timePicker}
        anchorEl={anchorPicker}
        value={editTime}
      />

      <NumericInput 
        selectedValue={selectedNum}
        open={numericDlg}
        onClose={closeNumericDlg}
      />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ p:2, display:'flex', width:'100%' }}>
        <Button variant="outlined" sx={{ mr:1 }} onClick={test}><AccountCircleIcon sx={{ mr:1 }}/>{ config[config.account].username }</Button>
        <Box sx={{ flexGrow: 1 }}/>
        <IconButton color="inherit" onClick={props.onClose}>
        <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ display:'flex', width:'100%' }}>
        <Grid container sx={{ p:2 }}>
          <Grid item xs={12} md={6}>

          { Number(getDBValue('free_entry_1'))===1 &&
            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{
                config[config.account].language==='en'?getDBValue('free_entry_1_en'):config[config.account].language==='th'?getDBValue('free_entry_1_th'):getDBValue('free_entry_1_jp')
              }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===3 || config[config.account].userrole===4) &&
                  <Box component="div" sx={ alleftStyle }>{ record.fentry1?record.fentry1:'---' }</Box>
                }
                { (config[config.account].userrole===1 || config[config.account].userrole===2) &&
                  <Button variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE', justifyContent: "flex-start" }}
                  onClick={() => openTextInput('fentry1', record.fentry1)}>{ record.fentry1?record.fentry1:'---' }</Button>
                }
              </Grid>
            </Grid>
          }

          { Number(getDBValue('free_entry_2'))===1 &&
            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{
                config[config.account].language==='en'?getDBValue('free_entry_2_en'):config[config.account].language==='th'?getDBValue('free_entry_2_th'):getDBValue('free_entry_2_jp')
              }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===3 || config[config.account].userrole===4) &&
                  <Box component="div" sx={ alleftStyle }>{ record.fentry2?record.fentry2:'---' }</Box>
                }
                { (config[config.account].userrole===1 || config[config.account].userrole===2) &&
                  <Button variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE', justifyContent: "flex-start" }}
                  onClick={() => openTextInput('fentry2', record.fentry2)}>{ record.fentry2?record.fentry2:'---' }</Button>
                }
              </Grid>
            </Grid>
          }

          { Number(getDBValue('free_entry_3'))===1 &&
            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{
                config[config.account].language==='en'?getDBValue('free_entry_3_en'):config[config.account].language==='th'?getDBValue('free_entry_3_th'):getDBValue('free_entry_3_jp')
              }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===3 || config[config.account].userrole===4) &&
                  <Box component="div" sx={ alleftStyle }>{ record.fentry3?record.fentry3:'---' }</Box>
                }
                { (config[config.account].userrole===1 || config[config.account].userrole===2) &&
                  <Button variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE', justifyContent: "flex-start" }}
                  onClick={() => openTextInput('fentry3', record.fentry3)}>{ record.fentry3?record.fentry3:'---' }</Button>
                }
              </Grid>
            </Grid>
          }

          { Number(getDBValue('approval_1'))===1 &&
            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{
                config[config.account].language==='en'?getDBValue('approval_1_en'):config[config.account].language==='th'?getDBValue('approval_1_th'):getDBValue('approval_1_jp')
              }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===1 || config[config.account].userrole===2 || config[config.account].userrole===3) &&
                  <Button variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE'}} onClick={approve1}>{ record.sign1?record.sign1:'---' }</Button>
                }
                { (config[config.account].userrole===4) &&
                  <Box component="div" sx={ almiddleStyle }>{ record.sign1?record.sign1:'---' }</Box>
                }
              </Grid>
            </Grid>
          }

          { Number(getDBValue('approval_2'))===1 &&
            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{
                config[config.account].language==='en'?getDBValue('approval_2_en'):config[config.account].language==='th'?getDBValue('approval_2_th'):getDBValue('approval_2_jp')
              }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===1 || config[config.account].userrole===2 || config[config.account].userrole===3) &&
                  <Button variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE'}} onClick={approve2}>{ record.sign2?record.sign2:'---' }</Button>
                }
                { (config[config.account].userrole===4) &&
                  <Box component="div" sx={ almiddleStyle }>{ record.sign2?record.sign2:'---' }</Box>
                }
              </Grid>
            </Grid>
          }

          { Number(getDBValue('approval_3'))===1 &&
            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{
                config[config.account].language==='en'?getDBValue('approval_3_en'):config[config.account].language==='th'?getDBValue('approval_3_th'):getDBValue('approval_3_jp')
              }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===1 || config[config.account].userrole===2 || config[config.account].userrole===3) &&
                  <Button variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE'}} onClick={approve3}>{ record.sign3?record.sign3:'---' }</Button>
                }
                { (config[config.account].userrole===4) &&
                  <Box component="div" sx={ almiddleStyle }>{ record.sign3?record.sign3:'---' }</Box>
                }
              </Grid>
            </Grid>
          }

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('entry_process_start') }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===1 || config[config.account].userrole===2 || config[config.account].userrole===3) &&
                  <Button onClick={(e)=> modifyTime(e, record, true)} variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE'}}>{ record.timestf }</Button>
                }
                { (config[config.account].userrole===4) &&
                  <Box component="div" sx={ almiddleStyle }>{ record.timestf }</Box>
                }
              </Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('entry_process_end') }</Box></Grid>
              <Grid item xs={8}>
              { (config[config.account].userrole===1 || config[config.account].userrole===2 || config[config.account].userrole===3) &&
                  <Button onClick={(e)=> modifyTime(e, record, false)} variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE'}}>{ record.timeedf?record.timeedf:'---' }</Button>
                }
                { (config[config.account].userrole===4) &&
                  <Box component="div" sx={ almiddleStyle }>{ record.timeedf?record.timeedf:'---' }</Box>
                }
              </Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('process_name') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>
                { config[config.account].language==='en'?record.process_en:config[config.account].language==='th'?record.process_th:record.process_jp }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('production_sequence') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.seqno?record.seqno:'---' }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('sequence_name') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.seqname?record.seqname:'---' }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('product_model') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.product_model }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('product_internal') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.product_internal?record.product_internal:'---' }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('product_name') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.product_name }</Box></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('machine_name') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.machine_name }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('production_order_target') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alrightStyle }>{ record.target.toLocaleString() }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('entry_qualified') }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===1 || config[config.account].userrole===2) &&
                  <Button id='qualified' variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE', justifyContent: "flex-end" }}
                  onClick={openNumericDlg}>{ record.qualified.toLocaleString() }</Button>
                }
                { (config[config.account].userrole===3 || config[config.account].userrole===4) &&
                  <Box component="div" sx={ alrightStyle }>{ record.qualified.toLocaleString() }</Box>
                }
              </Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('entry_rework') }</Box></Grid>
              <Grid item xs={8}>
                { (config[config.account].userrole===1 || config[config.account].userrole===2) &&
                  <Button id='rework' variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE', justifyContent: "flex-end" }}
                  onClick={openNumericDlg}>{ record.rework.toLocaleString() }</Button>
                }
                { (config[config.account].userrole===3 || config[config.account].userrole===4) &&
                  <Box component="div" sx={ alrightStyle }>{ record.rework.toLocaleString() }</Box>
                }
              </Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('entry_unqualified') }</Box></Grid>
              <Grid item xs={8} >
                {/* Editable */}
                { (config[config.account].userrole===1 || config[config.account].userrole===2) &&
                  (record.defectptrn!==null? 
                    ( <Stack direction="row" sx={{ width:'95%'}} justifyContent="space-evenly" >
                        <Box component="div" sx={{ width:'59%',textAlign:'right',fontSize:'0.875rem',lineHeight:'1.75',px:1.2,py:0.6,mr:0.5,
                          border:'1px solid',borderRadius:'4px',color:'primary.main'}}>
                            { record.unqualified.toLocaleString() }
                        </Box>
                        <Button variant="contained" sx={{ width:'40%',textAlign:'center',fontSize:'0.875rem',lineHeight:'1.9',px:1.2, py:0.6,
                          border:'1px solid primary.main',borderRadius:'4px',color:'primary.contrastText',bgcolor:'primary.main'}}
                          onClick={openDefectDlg} disabled={false}>
                            {getLabel('prodution_defectdetail_btn')}
                        </Button>
                      </Stack> )
                    : (<Button id='unqualified' variant="outlined" sx={{ width:'95%', backgroundColor: '#FFFFEE', justifyContent: "flex-end" }}
                        onClick={openNumericDlg}>{ record.unqualified.toLocaleString() }</Button>)
                )}
                {/* View only */}
                { (config[config.account].userrole===3 || config[config.account].userrole===4) &&
                  <Box component="div" sx={ alrightStyle }>{ record.unqualified.toLocaleString() }</Box>
                }
              </Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('entry_section_period') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alrightStyle }>{ record.uptimenet }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('entry_efficiency') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alrightStyle }>{ `${Number(record.efficiency).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,",")} %` }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('common_reg_user') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.userreg_name?record.userreg_name:'---' }</Box></Grid>
            </Grid>

            <Grid container sx={{ pb:0.5 }}>
              <Grid item xs={4}><Box component="div" sx={ titleStyle }>{ getLabel('common_upd_user') }</Box></Grid>
              <Grid item xs={8}><Box component="div" sx={ alleftStyle }>{ record.userupd_name?record.userupd_name:'---' }</Box></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}