import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Button, Autocomplete, Backdrop, CircularProgress } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import ConfirmDialog from "../ConfirmDialog";
import { AG_GRID_LOCALE_EN } from "../ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "../ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "../ag-grid-locale.th";


export default function EditPerson() {

  const [config] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, _setCaption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [isDisabled, setDisabled] = React.useState(false);
  const [recordData, setRecordData] = React.useState(
    { id:'', company:'', lastnm:'', firstnm:'', mail:'', mobile:'', userreg:'', datereg:'',
    userupd:'',dateupd:'',user:config.userid, cname:'', cdept:'', auth:config[config.account].auth }
  );
  const [helpers, setHelpers] = React.useState({ company:'', lastnm:'', firstnm:'', mail:'', mobile:'' });
  const [recordList, setRecordList] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
      setRecordData(data => ({...data, user:siteRef.current.userid, auth:config[config.account].auth }));
    }
  }

  React.useEffect(() => {
    loadDefault();
    loadData();
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setSelectedCompany(companies.find(v => v.id === recordData.company));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.company]);
  
  const loadData = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/persons`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });
  }

  const loadCompanies = () => {
    setLoading(true);
    const url = `${config[config.account].url}:${config[config.account].port}/api/companies`;
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setCompanies(data);
      setLoading(false);
    });
  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnDefs = [
    { headerName: getLabel('person_company'), field: "cname" },
    { headerName: getLabel('person_lastnm'), field: "lastnm" },
    { headerName: getLabel('person_firstnm'), field: "firstnm" },
    { headerName: getLabel('person_mail'), field: "mail" },
    { headerName: getLabel('person_mobile'), field: "mobile" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      cdef[0].headerName = getLabel('person_company');
      cdef[1].headerName = getLabel('person_lastnm');
      cdef[2].headerName = getLabel('person_firstnm');
      cdef[3].headerName = getLabel('person_mail');
      cdef[4].headerName = getLabel('person_mobile');
      cdef[5].headerName = getLabel('common_reg_user');
      cdef[6].headerName = getLabel('common_reg_date');
      cdef[7].headerName = getLabel('common_upd_user');
      cdef[8].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateData = (event) => {
    const data = event.data;
    setEditState(1);
    setRecordData({...recordData, id:data.id, company:data.company, lastnm:data.lastnm, firstnm:data.firstnm,
      mail:data.mail, mobile:data.mobile, cname:data.cname, cdept:data.cdept,
      userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, auth:config[config.account].auth});
    inputValidation(data);
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.id===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onModelUpdated: updateSelectedRow,
    localeText: localeText,

  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  function isNumber(numVal){
    var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
    return pattern.test(numVal);
  } 
  
  const newRecord = () => {
    setRecordData({...recordData, id:'', company:'', lastnm:'', firstnm:'', mail:'', mobile:'', userreg:'', datereg:'', userupd:'',dateupd:'', auth:config[config.account].auth });
    setHelpers({ company:'', lastnm:'', firstnm:'', mail:'', mobile:'' });
    setEditState(0);
  }
  
  const inputValidation = (data) => {
    let validated = true;
    setHelpers({ company:'', lastnm:'', firstnm:'', mail:'', mobile:'' });
    if(!isNumber(data.company)) {
      setHelpers(helpers => ({...helpers, company:'Required'}));
      validated = false;
    }
    if(data.lastnm.trim().length === 0) {
      setHelpers(helpers => ({...helpers, lastnm:'Required'}));
      validated = false;
    }
    if(data.firstnm.trim().length === 0) {
      setHelpers(helpers => ({...helpers, firstnm:'Required'}));
      validated = false;
    }
    if(data.mobile.trim().length === 0) {
      setHelpers(helpers => ({...helpers, mobile:'Required'}));
      validated = false;
    }
    return validated;
  }

  const updateRecord = () => {
    setDisabled(true);
    if(inputValidation(recordData)) {
      if(editState===0) { // new user
        const url = `${config[config.account].url}:${config[config.account].port}/api/personadd`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(data => {
          loadData();
          newRecord();
          setDisabled(false);
          setSid(data.insertId);
        });

      } else { // update user
        const url = `${config[config.account].url}:${config[config.account].port}/api/personupdate`;
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(_data => {
          loadData();
          setDisabled(false);
          setSid(recordData.id);
        });
      }
    } else {
      setDisabled(false);
    }
  }

  const deleteRecord = async() => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/personinuse`;
    let sum = 0;
    await fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
    .then(res => res.json())
    .then(data => {
      Object.keys(data[0]).map((key) => ( sum += Number(data[0][key]) ));
    });
    if(sum === 0) {
      const url = `${config[config.account].url}:${config[config.account].port}/api/persondelete`;
      fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
      .then(res => res.json())
      .then(_data => {
        newRecord();
        loadData();
      });
    } else {
      openInUse();
    }
  }

  const [confirm, setConfirm] = React.useState(false);
  const openConfirm = (e) => setConfirm(true);
  const closeConfirm = (e) => {
    const ret = Boolean(e.target.dataset.return);
    if(ret) deleteRecord();
    setConfirm(false);
  }

  const [inUse, setInUse] = React.useState(false);
  const openInUse = (e) => setInUse(true);
  const closeInUse = (e) => setInUse(false);


  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ConfirmDialog 
        open={confirm}
        onClose={closeConfirm}
        title={ getLabel('common_delete_confirm') }
        msg={ getLabel('common_delete_msg') }
        confirm={ getLabel('common_confirm') }
        cancel={ getLabel('common_cancel') }
      />

      <ConfirmDialog 
        open={inUse}
        onClose={closeInUse}
        title={ getLabel('common_info') }
        msg={ getLabel('common_inuse_meg') }
        confirm={ getLabel('common_confirm') }
      />

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('person_list')}</h4>
      </Box>

      <Box color="text.primary" style={{ height: `calc(50vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          { !aggDestroyed &&
          <AgGridReact
            defaultColDef={defaultColDef}
            // columnDefs={columnDefs}
            rowData={recordList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}>
          </AgGridReact>
          }
        </div>
      </Box>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{editState===0?getLabel('person_info')+" - "+getLabel('common_new'):getLabel('person_info')+" - "+getLabel('common_update')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedCompany || null }
                id="combo-company"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name + (option.dept?` - ${option.dept}`:'') }
                options={companies}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('person_company')} helperText={ helpers.company } error={helpers.company.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, company: value.id});
                  setSelectedCompany(companies.find(v => v.id === value.id));
                }}
              />
              ) : null}
              <TextField sx={{ mx:1, mb:1 }} id="familynm" label={getLabel('person_lastnm')} variant="standard" size="small" value={recordData.lastnm}
                onChange={(e) => setRecordData({...recordData, lastnm: e.target.value})}
                helperText={ helpers.lastnm } error={helpers.lastnm.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="firstnm" label={getLabel('person_firstnm')} variant="standard" size="small" value={recordData.firstnm} 
                onChange={(e) => setRecordData({...recordData, firstnm: e.target.value})}
                helperText={ helpers.firstnm } error={helpers.firstnm.length>0?true:false} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="mail" label={getLabel('person_mail')} variant="standard" size="small" value={recordData.mail}
                onChange={(e) => setRecordData({...recordData, mail: e.target.value})}
                helperText={ helpers.mail } error={helpers.mail.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="mobile" label={getLabel('person_mobile')} variant="standard" size="small" value={recordData.mobile}
                onChange={(e) => setRecordData({...recordData, mobile: e.target.value})}
                helperText={ helpers.mobile } error={helpers.mobile.length>0?true:false} />
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => newRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('person_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" onClick={() => openConfirm()} disabled={editState===0?true:false} disableElevation>{getLabel('person_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" disabled={isDisabled} onClick={() => updateRecord()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>
      </Paper>
    </Box>
  );
}