import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Grid, TextField, Paper, Stack, Box, Button, Autocomplete, Backdrop, CircularProgress } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";


export default function EditMail() {

  const [config] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, _setCaption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false);
  const [labels, setLabels] = React.useState(null);
  React.useEffect(() => {
    const lang = config.language;
    setLabels(caption[lang]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config.language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(() => {
    updateGridHeaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [recordData, setRecordData] = React.useState(
    { id:'', company:'', lastnm:'', firstnm:'', mail:'', mobile:'', userreg:'', datereg:'', userupd:'',dateupd:'',user:config.userid, cname:'', cdept:'' }
  );
  const [helpers, setHelpers] = React.useState({ company:'', lastnm:'', firstnm:'', mail:'', mobile:'' });

  const [recordList, setRecordList] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = React.useState(null);

  React.useEffect(() => {
    // console.log('Initialized.');
    loadData();
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setSelectedCompany(companies.find(v => v.id === recordData.company));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData.company]);
  
  const loadData = () => {
    setLoading(true);
    const url = config.backend + '/api/persons';
    fetch(url, { method: 'POST' })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });
  }

  const loadCompanies = () => {
    setLoading(true);
    const url = config.backend + '/api/companies';
    fetch(url, { method: 'POST' })
    .then(res => res.json())
    .then(data => {
      setCompanies(data);
      setLoading(false);
    });
  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    // console.log('onGridReady');
  };

  const columnDefs = [
    // { headerName: "ID", field: "id", lockPinned: true, pinned: "left", editable:false, }, //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: getLabel('person_company'), field: "cname" },
    { headerName: getLabel('person_lastnm'), field: "lastnm" },
    { headerName: getLabel('person_firstnm'), field: "firstnm" },
    { headerName: getLabel('person_mail'), field: "mail" },
    { headerName: getLabel('person_mobile'), field: "mobile" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      cdef[0].headerName = getLabel('person_company');
      cdef[1].headerName = getLabel('person_lastnm');
      cdef[2].headerName = getLabel('person_firstnm');
      cdef[3].headerName = getLabel('person_mail');
      cdef[4].headerName = getLabel('person_mobile');
      cdef[5].headerName = getLabel('common_reg_user');
      cdef[6].headerName = getLabel('common_reg_date');
      cdef[7].headerName = getLabel('common_upd_user');
      cdef[8].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateData = (event) => {
    const data = event.data;
    setEditState(1);
    setRecordData({...recordData, id:data.id, company:data.company, lastnm:data.lastnm, firstnm:data.firstnm,
      mail:data.mail, mobile:data.mobile, cname:data.cname, cdept:data.cdept,
      userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, });
    inputValidation(data);
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onRowDoubleClicked: () => console.log("onRowDoubleClicked"),
    onRowDataChanged: () => console.log("onRowDataChanged"),
    onSelectionChanged: () => console.log("onSelectionChanged"),
  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  // eslint-disable-next-line no-unused-vars
  const test = () => {
    // console.log(selectedCompany);
    // console.log(recordData);
    // console.log(config);
  }

  function isNumber(numVal){
    var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
    return pattern.test(numVal);
  } 
  
  const newRecord = () => {
    setRecordData({...recordData, id:'', company:'', lastnm:'', firstnm:'', mail:'', mobile:'', userreg:'', datereg:'', userupd:'',dateupd:'' });
    setEditState(0);
  }
  
  const inputValidation = (data) => {
    let validated = true;
    if(!isNumber(data.company)) {
      setHelpers(helpers => ({...helpers, company:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, company:''}));

    if(data.lastnm.trim().length === 0) {
      setHelpers(helpers => ({...helpers, lastnm:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, lastnm:''}));

    if(data.firstnm.trim().length === 0) {
      setHelpers(helpers => ({...helpers, firstnm:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, firstnm:''}));

    if(data.mail.trim().length === 0) {
      setHelpers(helpers => ({...helpers, mail:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, mail:''}));

    if(data.mobile.trim().length === 0) {
      setHelpers(helpers => ({...helpers, mobile:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, mobile:''}));

    return validated;
  }

  const updateRecord = () => {
    if(inputValidation(recordData)) {
      if(editState===0) { // new user
        const url = config.backend + '/api/personadd';
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(_data => {
          loadData();
        });

      } else { // update user
        const url = config.backend + '/api/personupdate';
        fetch(url,
        { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
        .then(res => res.json())
        .then(_data => {
          loadData();
        });
      }
    }
  }

  const deleteRecord = () => {
    const url = config.backend + '/api/persondelete';
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(recordData)})
    .then(res => res.json())
    .then(_data => {
      loadData();
    });
  }

  return (
    <Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('report_mail_list')}</h4>
      </Box>

      <Box color="text.primary" style={{ height: `calc(50vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config.theme==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          <AgGridReact
            defaultColDef={defaultColDef}
            // columnDefs={columnDefs}
            rowData={recordList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}>
          </AgGridReact>
        </div>
      </Box>

      {/* <Box sx={{ mx:2, px:0 }}>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => sizeToFit()} disableElevation>テーブル幅に合わせる</Button>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => autoSizeAll(false)} disableElevation>幅全てを自動調整する</Button>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => autoSizeAll(true)} disableElevation>ヘッダーを除く全てを自動調整する</Button>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => test()} disableElevation>I'm not doing anything...</Button>
      </Box> */}

      <Box sx={{ mx:2, px:0 }}>
        <h4>{editState===0?getLabel('person_info')+" - "+getLabel('common_new'):getLabel('person_info')+" - "+getLabel('common_update')}</h4>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              {/* <TextField sx={{ mx:1, mb:1 }} id="id" label="Company ID" variant="standard" size="small" disabled={editState==1?true:false} value={recordData.id}
                 onChange={(e) => setRecordData({...recordData, id: e.target.value})} /> */}

              { true ? (
              <Autocomplete
                disablePortal
                value = { selectedCompany || null }
                // defaultValue = { companies[0] }
                id="combo-company"
                sx={{ mx:1, mb:1 }}
                getOptionLabel={(option) => option.name + ' - ' + option.dept }
                options={companies}
                // defaultValue={companies.find(v => v.id[0])}
                renderInput={(params) => <TextField {...params} size="small" label={getLabel('person_company')} helperText={ helpers.company } error={helpers.company.length>0?true:false} />}
                onChange={(_event, value) => {
                  setRecordData({...recordData, company: value.id});
                  setSelectedCompany(companies.find(v => v.id === value.id));
                }}
              />
              ) : null}

              {/* <TextField sx={{ mx:1, mb:1 }} id="company" label="Company" variant="standard" size="small" value={recordData.company} 
                onChange={(e) => setRecordData({...recordData, company: e.target.value})} /> */}
              <TextField sx={{ mx:1, mb:1 }} id="familynm" label={getLabel('person_lastnm')} variant="standard" size="small" value={recordData.lastnm}
                onChange={(e) => setRecordData({...recordData, lastnm: e.target.value})}
                helperText={ helpers.lastnm } error={helpers.lastnm.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="firstnm" label={getLabel('person_firstnm')} variant="standard" size="small" value={recordData.firstnm} 
                onChange={(e) => setRecordData({...recordData, firstnm: e.target.value})}
                helperText={ helpers.firstnm } error={helpers.firstnm.length>0?true:false} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="mail" label={getLabel('person_mail')} variant="standard" size="small" value={recordData.mail}
                onChange={(e) => setRecordData({...recordData, mail: e.target.value})}
                helperText={ helpers.mail } error={helpers.mail.length>0?true:false} />
              <TextField sx={{ mx:1, mb:1 }} id="mobile" label={getLabel('person_mobile')} variant="standard" size="small" value={recordData.mobile}
                onChange={(e) => setRecordData({...recordData, mobile: e.target.value})}
                helperText={ helpers.mobile } error={helpers.mobile.length>0?true:false} />
              {/* <TextField sx={{ mx:1, mb:1 }} id="userreg" label="Register User ID" variant="standard" size="small" value={recordData.userreg} disabled/>
              <TextField sx={{ mx:1, mb:1 }} id="datereg" label="Date Register" variant="standard" size="small" value={recordData.datereg} disabled/>
              <TextField sx={{ mx:1, mb:1 }} id="userupd" label="Update User ID" variant="standard" size="small" value={recordData.userupd} disabled/>
              <TextField sx={{ mx:1, mb:1 }} id="dateupd" label="Date Update" variant="standard" size="small" value={recordData.dateupd} disabled/> */}
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          {/* <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => test()} disableElevation>Dev Test</Button> */}
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => newRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('person_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => deleteRecord()} disabled={editState===0?true:false} disableElevation>{getLabel('person_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" color="primary" onClick={() => updateRecord()} disableElevation>{editState===0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>

      </Paper>

    </Box>
  );
}

