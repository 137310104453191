import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const systemConfig = atom({
  key: "DBordConfig",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

