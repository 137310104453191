import React from 'react';
import { Box, Button, Divider, Grid, Modal, Paper, Stack, Typography } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import { systemStatus } from "../../atoms/systemStatus";
import { useTheme } from '@mui/material/styles'


export default function Home() {

  const [config] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, _setCaption] = useRecoilState(systemCaption);
  const [labels, setLabels] = React.useState(null);

  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  })
  const [dps] = React.useState(getDisplaySize());

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLabels(caption[lang]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [modal, setModal] = React.useState(false);
  const page = (val) => setStatus(status => ({...status, page:val}));
  const openModal = () => setModal(true);
  const clsModal = () => setModal(false);

  return (
    <Box>
{/*----------------------------------Report---------------------------------------------*/}
      <Modal open={modal} onClose={clsModal} >
        <Paper elevation={4} sx={dps==='xs'?
          {py:1,width:'70%',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)',textAlign:'center'}
        : {py:1,width:'35%',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)',textAlign:'center'}}>
          <Typography variant="h6" align="center">{getLabel('navigation_menu_reporting')}</Typography>
          <Divider/>
          <Button sx={{ mx:1, my:1, width:'90%'}} variant="contained" onClick={() => {page('EditReport')}}>{getLabel('navigation_menu_excel_report')}</Button>
          <Button sx={{ mx:1, my:1, width:'90%'}} variant="contained" onClick={() => {page('WorstListReport')}}>{getLabel('navigation_menu_worstList')}</Button>
        </Paper>
      </Modal>
{/*-------------------------------------------------------------------------------------*/}
      <Box sx={{ mx:2, mt:2 }}>
        <Typography variant="h6" align="center">{getLabel('home_menu_title')}</Typography>
      </Box>

      <Paper sx={{ mx:2, my:2, p:1 }} elevation={4}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditUser')}}>{getLabel('navigation_menu_user')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditCompany')}}>{getLabel('navigation_menu_company')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditPerson')}}>{getLabel('navigation_menu_person')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditMaterial')}}>{getLabel('navigation_menu_material')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditMachine')}}>{getLabel('navigation_menu_machine')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditProcess')}}>{getLabel('navigation_menu_process')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditSequence')}}>{getLabel('navigation_menu_sequence')}</Button>
              {/* Next Buttond menu here */}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditProduct')}}>{getLabel('navigation_menu_product')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditSpecification')}}>{getLabel('navigation_menu_specification')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditDefect')}}>{getLabel('navigation_menu_defective')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditProduction')}}>{getLabel('navigation_menu_production')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditWorkhour')}}>{getLabel('navigation_menu_non_working_hours')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => openModal()}>{getLabel('navigation_menu_reporting')}</Button>
              <Button sx={{ mx:1, my:1 }} variant="contained" onClick={() => {page('EditConfig')}}>{getLabel('navigation_menu_config')}</Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
