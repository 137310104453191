import React from 'react';
import { Box, Button, Grid, Backdrop, CircularProgress, Menu, MenuItem } from '@mui/material';
import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';

import { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function Entry({ prop }) {
  let navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [config, setConfig] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, setCaption] = useRecoilState(systemCaption);
  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [ctime, setCtime] = React.useState();
  const [processes, setProcesses] = React.useState();
  const [clients, setClients] = React.useState();
  const [products, setProducts] = React.useState();
  const [machines, setMachines]  = React.useState();


  React.useEffect(() => {
    const lang = config.language;
    setLabels(caption[lang]);
    if(!config.authPassed)navigate(`/${config.account}/signin`, { replace: true });
    loadProcesses();
    loadClients(status.processes);
    // loadProducts(status.processes, status.companies);
    setCtime(getCurrentTime(true) + ', ' + getCurrentDate());
    let interval = setInterval(() => {
      const time = getCurrentTime(true) + ', ' + getCurrentDate();
      setCtime(time);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(() =>{
    if(status.back) {
      loadProducts(status.processes, status.companies);
      setStatus(status => ({...status, back:false}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status.back])

  const loadProcesses = () => {
    const url = config.backend + '/api/processes';
    setLoading(true);
    fetch(url, { method: 'POST' })
    .then(res => res.json())
    .then(data => {
      setProcesses(data);
      setLoading(false);
    });
  }

  const loadClients = (process_id) => {
    const url = config.backend + '/api/companies_process';
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:process_id}) })
    .then(res => res.json())
    .then(data => {
      setClients(data);
      setLoading(false);
    });
  }

  const [ condProducts, setCondProducts] = React.useState({len:0, dvn:0, rem:0});

  const loadProducts = (process_id, company_id) => {
    const url = config.backend + '/api/products_process_company_imagepath';
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ process:process_id, company:company_id }) })
    .then(res => res.json())
    .then(data => {
      convImagePath(data);
      setProducts(data);
      // console.log(data);
      let len = data.length;
      let dvn = parseInt(len / 4);
      const rem = len % 4;
      if(rem > 0)dvn++;
      setCondProducts({...condProducts, len:len, dvn:dvn, rem:rem });
      setLoading(false);
    });
  }

  const convImagePath = (data) => {
    data.forEach((item, index) => {
      if(item.image.length > 0) {
        const path = config.deployurl + '/images/products/' + item.image + '?=' + new Date().getTime(); 
        item.image = path;
      }
    });
  }

  const loadMachines = (production_id) => {
    const url = config.backend + '/api/machine_production';
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:production_id}) })
    .then(res => res.json())
    .then(data => {
      setMachines(data);
      setLoading(false);
    });
  }

  const getCurrentDate = (separator='-') => {
    let mname = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let newDate = new Date()
    let date = newDate.getDate();
    let month = mname[newDate.getMonth()];
    let year = newDate.getFullYear();
    return `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`;
  }

  const getCurrentTime = (seconds=false) => {
    let newDate = new Date()
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${hrs<10?`0${hrs}`:`${hrs}`}:${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `:${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const moveTop = () => {
    setStatus(status => ({...status, processes:'', companies:'', products:'' }));
    navigate(`/${config.account}/`, { replace: true });
  }

  const updateProcesses = (val) => {
    const id = val.target.id;
    // console.log('clearing status...');
    setStatus(status => ({...status, processes:id, companies:'', products:'' }));
    loadClients(id);
    setCondProducts({...condProducts, len:0, dvn:0, rem:0 });
    setProducts(null);
  }

  const updateCompanies = (val) => {
    const id = val.target.id;
    setStatus(status => ({...status, companies:id, products:'' }));
    loadProducts(status.processes, id);
  }

  const updateProducts = (val) => {
    const id = val.target.id;
    loadMachines(id);
    setAnchorEl(val.currentTarget);
    setMachineSelect(true);
    setStatus(status => ({...status, products:id }));
  }

  const selectProduction = (id) => {
    setMachineSelect(false);
    setStatus(status => ({...status, products:id }));
    navigate(`/${config.account}/production`, { replace:true });

  }

  const [machineSelect, setMachineSelect ] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const closeMachineSelect = () => {
    setMachineSelect(false);
  }

  const test = (e) => {
    // console.log(products);
    // setAnchorEl(e.currentTarget);
    // setMachineSelect(true);
    // console.log(condProducts);
    // console.log(status);
    // console.log(getCurrentDate('/'));
    // console.log(getCurrentTime(true));
  }


  return (
    <Box sx={{ height:'100vh' }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      { machines &&
      <Menu id="select-machine" open={machineSelect} onClose={closeMachineSelect} anchorEl={anchorEl}>
        { machines.map((val) =>
        <MenuItem key={val.id}><Button variant="contained" sx={{ fontSize:'large' }} onClick={() => selectProduction(val.id)}>{ val.maname }: { val.matype }</Button></MenuItem>
        )}
      </Menu>
      }

      <Box sx={{ p:2, pb:1, display:'flex', width:'100%' }}>
        <Button variant="contained" onClick={moveTop}>{ getLabel('common_top') }</Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button variant="outlined" onClick={test} id="test">{ ctime }</Button>
      </Box>

      {/* <Box sx={{ width:'100%', height:'30vh' }}/> */}

      <Box sx={{ px:1 }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar ]}
          spaceBetween={0}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true, type: "fraction" }}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
        >
        {processes && processes.map((val) =>
          <SwiperSlide key={ val.id }>
            <Button id={ val.id } sx={{ m:1, width:'96%', height:'11vh', fontSize:'large' }}
              variant={Number(status.processes)===Number(val.id)?"contained":"outlined"} onClick={updateProcesses}>{ val.name }</Button>
          </SwiperSlide>
        )}
        </Swiper>
      </Box>

      <Box sx={{ px:1 }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar ]} 
          spaceBetween={0}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true, type: "fraction" }}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
        >
        {clients && clients.map((val) =>
          <SwiperSlide key={ val.id }>
            <Button id={ val.id } sx={{ m:1, width:'96%', height:'11vh', fontSize:'large' }}
            variant={Number(status.companies)===Number(val.id)?"contained":"outlined"} onClick={updateCompanies}>{ val.abbr }</Button>
          </SwiperSlide>
        )}
        </Swiper>
      </Box>

      <Grid container>
        <Grid item xs={12} md={12}>
          <Box sx={{ pl:1 }}>
            {/* <DrawProducts /> */}
            <Swiper
              modules={[Navigation, Pagination, Scrollbar ]} 
              spaceBetween={8}
              slidesPerView={2}
              navigation
              pagination={{ clickable: true, type: "fraction" }}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
            {products && [...Array(condProducts.dvn)].map((x, i) =>
              <SwiperSlide key={ products[i*4].id }>
              {(i*4<condProducts.len  ) && <Button id={ products[i*4].id   } variant={Number(status.products)===Number(products[i*4].id)?"contained":"outlined"}
              sx={{ m:1, width:'97%', height:'10vh', fontSize:'large',
                backgroundImage: `url(${products[i*4].image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '9vh',
                backgroundPosition: '0.5vh',
              }} 
              onClick={updateProducts}>{ products[i*4].model  }<br/>{ products[i*4].name  }</Button> }

              {(i*4+1<condProducts.len) && <Button id={ products[i*4+1].id } variant={Number(status.products)===Number(products[i*4+1].id)?"contained":"outlined"}
              sx={{ m:1, width:'97%', height:'10vh', fontSize:'large',
                backgroundImage: `url(${products[i*4+1].image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '9vh',
                backgroundPosition: '0.5vh',
              }} 
              onClick={updateProducts} >{ products[i*4+1].model }<br/>{ products[i*4+1].name  }</Button> }

              {(i*4+2<condProducts.len) && <Button id={ products[i*4+2].id } variant={Number(status.products)===Number(products[i*4+2].id)?"contained":"outlined"}
              sx={{ m:1, width:'97%', height:'10vh', fontSize:'large',
                backgroundImage: `url(${products[i*4+2].image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '9vh',
                backgroundPosition: '0.5vh',
              }} 
              onClick={updateProducts} >{ products[i*4+2].model }<br/>{ products[i*4+2].name  }</Button> }

              {(i*4+3<condProducts.len) && <Button id={ products[i*4+3].id } variant={Number(status.products)===Number(products[i*4+3].id)?"contained":"outlined"}
              sx={{ m:1, width:'97%', height:'10vh', fontSize:'large',
                backgroundImage: `url(${products[i*4+3].image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '9vh',
                backgroundPosition: '0.5vh',
              }} 
              onClick={updateProducts} >{ products[i*4+3].model }<br/>{ products[i*4+3].name  }</Button> }

              </SwiperSlide>
            )}
            </Swiper>
          </Box>
        </Grid>


      </Grid>

    </Box>
  );
}

