import React from 'react';
import { Box, Button, Backdrop, CircularProgress, Select, MenuItem,
  FormGroup, Paper, Stack, TextField, IconButton, Menu, Dialog,
  FormControlLabel, Switch, useTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import WeekPicker from './admin/WeekPicker';
import locale_en from 'date-fns/locale/en-US';
import locale_ja from 'date-fns/locale/ja';
import './ag-grid-overrides.scss';
import { AgGridReact } from 'ag-grid-react';

import TranslateIcon from '@mui/icons-material/Translate';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Record from './Record';

import { useRecoilState } from "recoil";
import { systemConfig } from "../atoms/systemConfig";
import { systemCaption } from "../atoms/systemCaption";
import { systemStatus } from "../atoms/systemStatus";
import { useNavigate } from 'react-router-dom';

import { AG_GRID_LOCALE_EN } from "./ag-grid-locale.en";
import { AG_GRID_LOCALE_JA } from "./ag-grid-locale.ja";
import { AG_GRID_LOCALE_TH } from "./ag-grid-locale.th";


export default function History({ prop }) {
  let navigate = useNavigate();
  const theme = useTheme();
  const getDisplaySize = (() => {
    if (window.innerWidth < theme.breakpoints.values.sm) {
      return "xs";
    } else if (window.innerWidth < theme.breakpoints.values.md) {
      return "sm";
    } else if (window.innerWidth < theme.breakpoints.values.lg) {
      return "md";
    } else if (window.innerWidth < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  })
  const [dps] = React.useState(getDisplaySize());

  const debug = false;
  if(locale_en && locale_ja) {
    locale_en.options.weekStartsOn = 1;
    locale_ja.options.weekStartsOn = 1;
  }

  const gridRef = React.useRef();
  const [config, setConfig] = useRecoilState(systemConfig);
  const [caption] = useRecoilState(systemCaption);
  const [status, setStatus] = useRecoilState(systemStatus);
  const [labels, setLabels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [ctime, setCtime] = React.useState();
  const [rtype, setRtype] = React.useState('');
  const [locale, setLocale] = React.useState();
  const [localeText, setLocaleText] = React.useState(AG_GRID_LOCALE_EN);
  const [aggDestroyed, setAggDestroyed] = React.useState(false);

  const [anchorLanguage, setAnchorLanguage] = React.useState(null);
  const selectLanguage = Boolean(anchorLanguage);
  const languageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const languageClose = (event) => {
    setAnchorLanguage(null);
    const { valueLanguage } = event.currentTarget.dataset;
    if (valueLanguage) {
      setConfig(config => ({...config, [config.account]: {...config[config.account], language: valueLanguage}}));
      setLabels(caption[valueLanguage]);
    }
  };

  const [dftype, setDftype] = React.useState(1);

  const [dbconfig, setDbconfig] = React.useState(null);
  const getConfigs = () => {
    const url = `${config[config.account].url}:${config[config.account].port}/api/configs`;
    setLoading(true);
    fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ auth:config[config.account].auth }) })
    .then(res => res.json())
    .then(data => {
      setDbconfig(data);
      const df = data.find(v => v.id === 'date_format');
      if(df?.value) {
        setDftype(Number(df.value));
      }
      setLoading(false);
    });
  }

  const getDBValue = (key) => {
    const tmp = dbconfig?dbconfig.find((db) => db.id === key):'';
    return tmp.value?tmp.value:'';
  }

  const rtypeChange = (event) => {
    const val = event.target.value;
    setRtype(val);
    switch(val) {
      case 1:
        calcRday(rday);
        break;
      case 2:
        calcRweek(rweek);
        break;
      case 3:
        calcRmonth(rmonth);
        break;
      // for custom range requested
      // case 4:
      //   break;
        default:
    }
  };

  const [windowHeight, setWindowHeight] = React.useState();
  const handleResize = (e) => {
    setWindowHeight(e.currentTarget.innerHeight);
  }

  const siteRef = React.useRef();
  const loadDefault = () => {
    if(config?.account) {
      siteRef.current = config[config.account];
    }
  }

  React.useEffect(() => {
    loadDefault();
    setWindowHeight(window.innerHeight);
    const lang = siteRef.current.language;
    setColumnDefs(defaultColumnDefs);

    if(status?.history_type && status?.history_date) {
      const ttype = status.history_type;
      const tdate = status.history_date;
      setRtype(status.history_type);
      if(ttype === 1) {
        setRday(tdate);
        calcRday(tdate);
      } else if(ttype === 2) {
        setRweek(tdate);
        calcRweek(tdate);
      } else if(ttype === 3) {
        setRmonth(tdate);
        calcRmonth(tdate);
      }
    } else {
      setRtype(1);
      calcRday(rday);
    }


    setLabels(caption[lang]);
    getConfigs();
    if(!siteRef.current.authPassed)navigate(`/${config.account}/signin`, { replace: true });
    if(dps==='xs')setCtime(getCurrentTime(true));
    else setCtime(getCurrentTime(true) + ', ' + getCurrentDate());
    let interval = setInterval(() => {
      const time = dps==='xs'?getCurrentTime(true):getCurrentTime(true) + ', ' + getCurrentDate();
      setCtime(time);
    }, 1000);
    window.addEventListener('resize', handleResize);
    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storeColumnsOrder= () => {
    const model = gridRef.current?.api?.columnModel?.displayedColumns;
    if(model) {
      let columnBk = [];
      model.forEach(d => { columnBk.push(d.colId) });
      setStatus(status => ({...status, history_column:columnBk}));
    }
  };

  React.useEffect(() => {
    const lang = config[config.account].language;
    setLocale(lang==='jp'?locale_ja:locale_en);
    lang==='th'?setLocaleText(AG_GRID_LOCALE_TH):lang==='jp'?setLocaleText(AG_GRID_LOCALE_JA):setLocaleText(AG_GRID_LOCALE_EN);
    setAggDestroyed(true);
    setTimeout(() => setAggDestroyed(false), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config[config.account].language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  const getCurrentDate = (separator='-') => {
    let mname = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let newDate = new Date()
    let date = newDate.getDate();
    let month = mname[newDate.getMonth()];
    let year = newDate.getFullYear();
    return `${date<10?`0${date}`:`${date}`}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`;
  }

  const getCurrentTime = (seconds=false) => {
    let newDate = new Date()
    let hrs = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();
    const tmp = `${hrs<10?`0${hrs}`:`${hrs}`}:${min<10?`0${min}`:`${min}`}`;
    return seconds?tmp + `:${sec<10?`0${sec}`:`${sec}`}`:tmp;
  }

  const moveTop = () => {
    setStatus(status => ({...status, processes:'', companies:'', products:'' }));
    navigate(`/${config.account}/`, { replace: true });
  }

  const test = () => { }

  const [rfrom, setRfrom] = React.useState();
  const [rto, setRto] = React.useState();

  const [rday, setRday] = React.useState(new Date());
  const rdayChange = (event) => {
    setRday(event);
    calcRday(event);
  };
  const calcRday = (date) => {
    const dt = new Date(date);
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  const [rweek, setRweek] = React.useState(new Date());
  const rweekChange = (event) => {
    setRweek(event);
    calcRweek(event);
  };
  const calcRweek = (date) => {
    const mon = getMonday(date);
    const sun = getSunday(date);
    const from = new Date(`${mon.getFullYear()}/${mon.getMonth()+1}/${mon.getDate()} 00:00:00`);
    setRfrom(from);
    const to = new Date(`${sun.getFullYear()}/${sun.getMonth()+1}/${sun.getDate()} 23:59:59`);
    setRto(to);
  }

  const [rmonth, setRmonth] = React.useState(new Date());
  const rmonthChange = (event) => {
    setRmonth(event);
    calcRmonth(event);
  };
  const calcRmonth = (date) => {
    const dt = new Date(date);
    dt.setDate(1); // the first day of the monthdftype
    const from = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 00:00:00`);
    setRfrom(from);
    dt.setDate(1); // the final day of the month
    dt.setMonth(dt.getMonth() + 1);
    dt.setDate(0);
    const to = new Date(`${dt.getFullYear()}/${dt.getMonth()+1}/${dt.getDate()} 23:59:59`);
    setRto(to);
  }

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1);
    return new Date(d.setDate(diff));
  }

  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? 0:7);
    return new Date(d.setDate(diff));
  }

  React.useEffect(() => {
    if(rfrom && rto) {
      setStatus(v => ({...v, history_date: rfrom, history_type: rtype }));
      requestReport(rfrom, rto);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfrom, rto]);

  const requestReport = async(from, to) => {
    const f_yr = from.getFullYear();
    const f_mn = from.getMonth()+1;
    const f_dt = from.getDate();
    const date_from = `${f_yr}-${f_mn<10?`0${f_mn}`:`${f_mn}`}-${f_dt<10?`0${f_dt}`:`${f_dt}`} 00:00:00`;
    const t_yr = to.getFullYear();
    const t_mn = to.getMonth()+1;
    const t_dt = to.getDate();
    const date_to = `${t_yr}-${t_mn<10?`0${t_mn}`:`${t_mn}`}-${t_dt<10?`0${t_dt}`:`${t_dt}`} 23:59:59`;
    const url = `${config[config.account].url}:${config[config.account].port}/api/summary`;
    setLoading(true);
    await fetch(url,
      { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ from:date_from, to:date_to, incomp:uncompleted, auth:config[config.account].auth })})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      setRecordList(data);
      setLoading(false);
    });
  }

  const [sid, setSid] = React.useState();
  const sidRef = React.useRef();
  sidRef.current = sid;

  const updateSelectedRow = (e) => {
    const id = sidRef.current;
    const api = e.api;
    let row = null;
    if(Object.keys(api).length) {
      try {
        api.forEachNode((node) => {
          if(node.data.section===id)row = node.rowIndex;
        });
        api.getDisplayedRowAtIndex(row).setSelected(true);
        api.ensureIndexVisible(row);
      } catch(e) {}
    }
  }

  // AGGrid related --------------------------------------------------------------------
  const gridBoxRef = React.useRef();
  
  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [recordList, setRecordList] = React.useState();
  const captionRef = React.useRef();
  captionRef.current = getLabel('common_detail');
  const [recordData, setRecordData] = React.useState();

  const buttonRenderer = (params) => {
    const buttonItem = (_event) => {
      setRecordData(params.data);
      recorddlgOpen();
    }
    if(params.data.production !== undefined)return (
      <Box sx={{ mt:0.5, display:'flex', justifyContent:'center'}}><Button variant="contained" color="primary" size="small" onClick={buttonItem} >{ captionRef.current }</Button></Box>
    );
    return ('');
  }

  const sigDigits = (val, digits) => {
    if(val !== null) {
      const num = Number(val).toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g,",");
      return `${num} %`;
    } else return '';
  }

  const formatDate = (val) => {
    const date_exp = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) ([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    if(val !== undefined) {
      const chk = date_exp.test(val);
      if(chk) {
        const dt = new Date(val);
        let fdt = '';
        if(dftype === 1) {
          if(dfdisp === 1) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${dt.getFullYear()} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getDate()).toString().padStart(2,'0')}/${(dt.getMonth()+1).toString().padStart(2,'0')}`;
          }
        } else if(dftype === 2) {
          if(dfdisp === 1) {
            fdt = `${dt.getFullYear()}/${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 2) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')} \
            ${('00' + dt.getHours()).slice(-2)}:${('00' + dt.getMinutes()).slice(-2)}`;
          } else if(dfdisp === 3) {
            fdt = `${(dt.getMonth()+1).toString().padStart(2,'0')}/${(dt.getDate()).toString().padStart(2,'0')}`;
          }
        }
        return fdt;
      }
      return val;
    } else return '';
  }

  const defaultColumnDefs = [
    { headerName: config[config.account].language==='en'?getDBValue('free_entry_1_en'):config[config.account].language==='th'?getDBValue('free_entry_1_th'):getDBValue('free_entry_1_jp'), field: "fentry1", hide: Number(getDBValue('free_entry_1'))===1?false:true, }, // justify-content: center;
    { headerName: config[config.account].language==='en'?getDBValue('free_entry_2_en'):config[config.account].language==='th'?getDBValue('free_entry_2_th'):getDBValue('free_entry_2_jp'), field: "fentry2", hide: Number(getDBValue('free_entry_2'))===1?false:true },
    { headerName: config[config.account].language==='en'?getDBValue('free_entry_3_en'):config[config.account].language==='th'?getDBValue('free_entry_3_th'):getDBValue('free_entry_3_jp'), field: "fentry3", hide: Number(getDBValue('free_entry_3'))===1?false:true },
    { headerName: getDBValue('approval_1_en'), field: "sign1", hide: Number(getDBValue('approval_1'))===1?false:true },
    { headerName: getDBValue('approval_2_en'), field: "sign2", hide: Number(getDBValue('approval_2'))===1?false:true },
    { headerName: getDBValue('approval_3_en'), field: "sign3", hide: Number(getDBValue('approval_3'))===1?false:true },
    { headerName: getLabel('common_detail'), field: "detail", lockPinned: true, pinned: "right", cellRenderer: "ButtonCellRenderer", sortable: false, filter: false },
    { headerName: getLabel('entry_process_start'), field: "timest", valueFormatter: val => formatDate(val.data.timest) },
    { headerName: getLabel('entry_process_end'), field: "timeed", valueFormatter: val => formatDate(val.data.timeed) },
    { headerName: getLabel('process_name'), field: config[config.account].language==='en'?'process_en':config[config.account].language==='th'?'process_th':'process_jp' },
    { headerName: getLabel('sequence_name'), field: "seqname" },
    { headerName: getLabel('production_sequence'), field: "seqno", type: 'rightAligned' },
    { headerName: getLabel('product_model'), field: "product_model" },
    { headerName: getLabel('product_internal'), field: "product_internal" },
    { headerName: getLabel('product_name'), field: "product_name" },
    { headerName: getLabel('machine_name'), field: "machine_name" },
    { headerName: getLabel('production_order_target'), field: "target", valueFormatter: val => val.data.target.toLocaleString() ,type: 'rightAligned'},
    { headerName: getLabel('entry_qualified'), field: "qualified", valueFormatter: val => val.data.qualified.toLocaleString() ,type: 'rightAligned' },
    { headerName: getLabel('entry_rework'), field: "rework", valueFormatter: val => val.data.rework.toLocaleString() ,type: 'rightAligned' },
    { headerName: getLabel('entry_unqualified'), field: "unqualified", valueFormatter: val => val.data.unqualified.toLocaleString() ,type: 'rightAligned' },
    { headerName: getLabel('entry_efficiency'), field: "efficiency", valueFormatter: val => sigDigits(val.data.efficiency, 2), type: 'rightAligned' },
    { headerName: getLabel('common_reg_user'), field: "userreg_name" },
    { headerName: getLabel('common_upd_user'), field: "userupd_name" },
  ];

  const [columnDefs, setColumnDefs] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [modifiedDefs, setModifiedDefs] = React.useState(null);

  React.useEffect(() => {
    if(labels && dbconfig) {
      // console.log(labels);
      // console.log(dbconfig);
      if(status.history_column) {
        const history_column = status.history_column;
        defaultColumnDefs.sort((x, y) => (history_column.indexOf(x.field) - history_column.indexOf(y.field)));
        setModifiedDefs(defaultColumnDefs);
      }
      setColumnDefs(defaultColumnDefs);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbconfig, labels]);


  const columnApiRef = React.useRef();

  const onGridReady = (e) => {
    columnApiRef.current = e.columnApi;
    if(status?.history_filter) {
      const fmodel = status.history_filter;
      gridRef.current.api.setFilterModel(fmodel);
    }
    restoreSort();
  }

  const autoSizeAll = React.useCallback((skipHeader=false) => {
    const allColumnIds = [];
    gridRef.current?.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current?.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  const columnFit = () => {
    setTimeout(() => {
      autoSizeAll();
    }, 500);    
  }

  const [sums, setSums] = React.useState({t:0, q:0, r:0, u:0});

  const updateSums = () => {
    let t=0, q=0, r = 0, u=0;
    gridRef.current.api.forEachNodeAfterFilter(node => {
      t = t + node.data.target;
      q = q + node.data.qualified;
      r = r + node.data.rework;
      u = u + node.data.unqualified;
    });
    setSums({t:t, q:q, r:r, u:u});

    const cfilter = gridRef.current.api.getFilterModel();
    if(Object.keys(cfilter).length > 0) {
      setStatus(v => ({...v, history_filter: cfilter}));
    } else {
      setStatus(v => ({...v, history_filter: {}}));
    }
  }

  const storeSort = () => {
    const colst = gridRef.current.columnApi.getColumnState();
    const isort = [];
    if(colst.length > 0) {
      colst.forEach(v => {
        if(v.sort !== null)isort.push({colId:v.colId, sort:v.sort, sortIndex:v.sortIndex});
      });
      setStatus(v => ({...v, history_sort: isort}));
    }
  }

  const restoreSort = () => {
    if(status?.history_sort) {
      const psort = status.history_sort;
      gridRef.current.columnApi.applyColumnState({ // set sorts
        state: psort,
        defaultState: { sort: null },
      });
    }
  }

  const pinnedTopRowData = () => {
    const col = gridRef.current?.api?.columnModel?.displayedColumns[0]?.colId;
    return [{
      [`${col}`]:getLabel('entry_sum'),
      target:`${sums.t.toLocaleString()}`,
      qualified:`${sums.q.toLocaleString()}`,
      rework:`${sums.r.toLocaleString()}`,
      unqualified:`${sums.u.toLocaleString()}`,
      efficiency:`${(sums.t>0?((sums.q/sums.t)*100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,","):0)}`,
      detail:null,
    }];
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    components: {
      ButtonCellRenderer: buttonRenderer,
    },
    onRowDataChanged: () => updateSums(),
    onModelUpdated: updateSelectedRow,
    localeText: localeText,
  };

  const [recorddlg, setRecorddlg] = React.useState(false);
  const recorddlgOpen = () => {
    setRecorddlg(true);  
  }
  const recorddlgClose = (e) => {
    const event = { target: {value: rtype}};
    rtypeChange(event);
    setRecorddlg(false);
  }

  React.useEffect(() => {
    if(recordData) {
      setSid(recordData.section);
    }
  }, [recordData]);

  const [uncompleted, setUncompleted] = React.useState(false);
  const handleUncompleted = (e) => {
    setUncompleted(e.target.checked);
  }

  React.useEffect(()=>{
    if(rfrom && rto) {
      requestReport(rfrom, rto);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uncompleted]);

  // eslint-disable-next-line no-unused-vars
  const [dfdisp, setDfdisp] = React.useState(1);

  React.useEffect(() => {
    if(gridRef.current?.api) {
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [dfdisp]);


  return (
    <Box sx={{ height:'100vh' }}>

      <Dialog open={recorddlg} onClose={recorddlgClose} fullScreen={true}>
        <Record onClose={recorddlgClose} recordData={recordData} uncompleted={uncompleted} open={recorddlg}/>
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ p:2, pb:0, display:'flex', width:'100%' }}>
        <Button variant="outlined" sx={{ mr:1 }} startIcon={dps==='xs'?null:(<AccountCircleIcon sx={{mr:'3%'}}/>)}> 
          { config[config.account]?
              dps==='xs'||dps==='sm'?config[config.account].userid:config[config.account].username
            :'' }
        </Button>
        <Button variant="contained" onClick={moveTop}>{ getLabel('entry_select_process') }</Button>
        <Box sx={{ flexGrow: 1 }}/>
        <IconButton
          color="inherit"
          sx={{ mr: 1 }}
          onClick={languageClick}
        >
          <TranslateIcon fontSize="small"/>
        </IconButton>
        <Menu
          anchorEl={anchorLanguage}
          open={selectLanguage}
          onClose={languageClose}
        >
          <MenuItem dense data-value-language={'jp'} onClick={languageClose} disabled={config[config.account] && config[config.account].language==='jp'?true:false}>日本語</MenuItem>
          <MenuItem dense data-value-language={'en'} onClick={languageClose} disabled={config[config.account] && config[config.account].language==='en'?true:false}>English</MenuItem>
          <MenuItem dense data-value-language={'th'} onClick={languageClose} disabled={config[config.account] && config[config.account].language==='th'?true:false}>ไทย</MenuItem>
        </Menu>
        <Button variant="outlined" onClick={test} id="test">{ ctime }</Button>
      </Box>

      <Box sx={{ p:2, py:1, display:'flex', width:'100%' }}>
        <Paper component={Stack} direction="row" sx={{ p:0.5, pl:1, pr:1 }}>
          <Select
            size="small"
            sx={{ mt:1, mr:1, height:0.72 }}
            value={rtype}
            onChange={rtypeChange}
          >
            <MenuItem value={1}>{ getLabel('report_daily') }</MenuItem>
            <MenuItem value={2}>{ getLabel('report_weekly') }</MenuItem>
            <MenuItem value={3}>{ getLabel('report_monthly') }</MenuItem>
          </Select>
          {/* ---------- Daily picker ---------- */}
          { ((rtype && rtype === 1) || debug) &&
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
          <MobileDatePicker
            sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'}, 
            '& .css-1ay6ak0-MuiDialogActions-root': {display: 'none'},
            }}
            label={ getLabel('report_target_day') }
            inputFormat="dd/MM/yyyy"
            value={rday}
            onChange={rdayChange}
            renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
            okText={getLabel('common_ok')}
            cancelText={getLabel('common_cancel')}
          />
          </LocalizationProvider>
          }
          {/* ---------- Weekly picker ---------- */}
          { ((rtype && rtype) === 2 || debug) &&
          <WeekPicker
            label={ getLabel('report_target_week') }
            inputFormat={ `'${getLabel('report_week_of')}' MMM d` }
            value={rweek}
            onChange={rweekChange}
            okText={getLabel('common_ok')}
            cancelText={getLabel('common_cancel')}
          />
          }
          {/* ---------- Monthly picker ---------- */}
          { ((rtype && rtype === 3) || debug ) &&
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
          <MobileDatePicker
            sx={{ '& .PrivateDatePickerToolbar-penIcon': {display: 'none'} }}
            views={['year', 'month']}
            label={ getLabel('report_target_month') }
            value={rmonth}
            onChange={rmonthChange}
            renderInput={(params) => <TextField {...params} size="small" sx={{ my:1 }} />}
            okText={getLabel('common_ok')}
            cancelText={getLabel('common_cancel')}
          />
          </LocalizationProvider>
          }

          <FormGroup sx={{ m:2 }}>
            <FormControlLabel control={<Switch size="small" checked={uncompleted} onChange={handleUncompleted} />} label={ dps==='xs'?getLabel('history_short_show'):getLabel('history_show_unclosed')} />
          </FormGroup>          

        </Paper>
      </Box>

      <Box ref={gridBoxRef} color="text.primary" style={{ height: `${windowHeight - gridBoxRef?.current?.offsetTop - 16}px` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config[config.account].mode==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          { !aggDestroyed &&
          <AgGridReact
            ref={gridRef}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={recordList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFilterChanged={updateSums}
            onSortChanged={storeSort}
            onModelUpdated={columnFit}
            onColumnMoved={storeColumnsOrder}
            pinnedTopRowData={pinnedTopRowData()}
            suppressColumnVirtualisation={true} // important for resizing columns
            enableCellChangeFlash={true}
          >
          </AgGridReact>
          }
        </div>
      </Box>


    </Box>
  );
}

