import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { TextField, Box, Button, Checkbox, Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import '../ag-grid-overrides.scss';
import { useRecoilState } from "recoil";
import { systemConfig } from "../../atoms/systemConfig";
import { systemCaption } from "../../atoms/systemCaption";
import { format } from "date-fns";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DatePicker from '@mui/lab/DatePicker';

// import MobileTimePicker from '@mui/lab/MobileTimePicker';

export default function EditOrder() {

  const [config] = useRecoilState(systemConfig);
  // eslint-disable-next-line no-unused-vars
  const [caption, _setCaption] = useRecoilState(systemCaption);
  const [loading, setLoading] = React.useState(false);  
  const [labels, setLabels] = React.useState(null);
  React.useEffect(() => {
    const lang = config.language;
    setLabels(caption[lang]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[config.language]);

  const getLabel = (key) => {
    const label = labels?labels[key]:'';
    return label;
  }

  React.useEffect(() => {
    updateGridHeaders();
    updateGridHeaders_order();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  // eslint-disable-next-line no-unused-vars
  const [editState, setEditState] = React.useState(0); // 0 - new, 1 - update
  const [recordData, setRecordData] = React.useState(
    { id:'', product:'', seq:'', company:'', process:'', period:'', material:'', weight:'', unit:'', userreg:'', datereg:'', userupd:'',dateupd:'',user:config.userid,
    pmodel:'', pname:'', mmodel:'', mname:'', cname:'', cdept:'', pcname:'', uname:'' }
  );
  // eslint-disable-next-line no-unused-vars
  const [helpers, setHelpers] = React.useState({ name:'', type:'' });

  const [recordList, setRecordList] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [orderData, setOrderData] = React.useState([]);
  const [productionDate, setProductionDate] = React.useState(new Date());

  const [redrawOrders, setRedrawOrders] = React.useState(false);
  React.useEffect(() => {
    setRedrawOrders(false);
    const date = format(productionDate,"yyyy-MM-dd");
    loadOrders(recordData.id, date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redrawOrders]);

  React.useEffect(() => {
    // console.log('Initialized.');
    loadData();
    // loadTestOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    setLoading(true);
    const url = config.backend + '/api/productions';
    fetch(url, { method: 'POST' })
    .then(res => res.json())
    .then(data => {
      setRecordList(data);
      setLoading(false);
    });
  }

  const loadOrders = (id, date) => {
    setLoading(true);
    const url = config.backend + '/api/orders';
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({id:id, date:date })})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      setOrderList(data);
      setLoading(false);
    });
  }

  // eslint-disable-next-line no-unused-vars
  const loadTestOrders = () => {
    let records = [];
    let record1 = { id:0, timest:0, timeed:0, timeen:0, numtgt:0, numprd:0, numdif:0,timepr:0, 
      stoptx:'Stopping reason', stoptm:'', userreg:'', datereg:'', userupd:'',dateupd:'',user:config.userid };
    let record2 = { id:1, timest:0, timeed:0, timeen:0, numtgt:0, numprd:0, numdif:0,timepr:0, 
      stoptx:'Stopping reason', stoptm:'', userreg:'', datereg:'', userupd:'',dateupd:'',user:config.userid };
    let record3 = { id:2, timest:0, timeed:0, timeen:0, numtgt:0, numprd:0, numdif:0,timepr:0, 
      stoptx:'Stopping reason', stoptm:'', userreg:'', datereg:'', userupd:'',dateupd:'',user:config.userid };
    records[0] = record1;
    records[1] = record2;
    records[2] = record3;
    setOrderList(records);
  }

  const newOrderTime = () => {
    const date = format(productionDate,"yyyy-MM-dd");
    const newtime = { id:null, production:recordData.id, dateprod:date, timest:0, timeed:0, timeen:0, numtgt:0, numprd:0, numdif:0,timepr:0, 
      stoptx:'', stoptm:'', userreg:'', datereg:'', userupd:'',dateupd:'',user:config.userid };

    // axios.post(`http://f-1tools.com:8081/api/orderadd`, newtime)
    // .then(res => {
    //   const data = res.data;
    //   console.log(res);
    // });

    setLoading(true);
    const url = config.backend + '/api/orderadd';
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(newtime)})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      setLoading(false);
    });
    setRedrawOrders(true);
  }

  const deleteOrderTime = () => {
    // console.log(orderData[0].id);
    // axios.post(`http://f-1tools.com:8081/api/orderdelete`, { id:orderData[0].id })
    // .then(res => {
    //   const data = res.data;
    // });

    setLoading(true);
    const url = config.backend + '/api/orderdelete';
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id:orderData[0].id })})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      setLoading(false);
    });
    setRedrawOrders(true);
  }

  const updateOrderTime = (param, redraw) => {
    const data = param.data;
    data.user = config.userid;
    // axios.post(`http://f-1tools.com:8081/api/orderupdate`, data)
    // .then(res => {
    //   const data = res.data;
    // });

    setLoading(true);
    const url = config.backend + '/api/orderupdate';
    fetch(url,
    { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data)})
    .then(res => res.json())
    .then(data => {
      // console.log(data);
      setLoading(false);
    });

    if(redraw)setRedrawOrders(true);
    // console.log("onCellChanged!!!");
  }

  const defaultColDef = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const defaultColDef_order = {
    resizable: true,
    editable: false,
    sortable: true,
    filter: true,
  };

  const [gridApi, setGridApi] = React.useState({});
  const [gridColumnApi, setGridColumnApi] = React.useState({});

  const [gridApi2, setGridApi2] = React.useState({});
  // eslint-disable-next-line no-unused-vars
  const [gridColumnApi2, setGridColumnApi2] = React.useState({});

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    // console.log('onGridReady');
    // updateGridHeaders();
    // updateGridHeaders_order();
  };

  const onGridReady_order = (params) => {
    setGridApi2(params.api);
    setGridColumnApi2(params.columnApi);
    // console.log('onGridReady');
  };

  const checkBoxRenderer = (params) => {
    const checkItem = (event) => {
      if(params.data.timest)params.data.timest = 0; else params.data.timest = 1;
      // console.log(params.data);
      params.api.refreshCells({columns : ["timest","timest"]});
    }
    return (
      <Checkbox color="primary" size="small" checked={params.value===1?true:false} onClick={checkItem}></Checkbox>
    );
  }

  const testRenderer = (params) => {
    // eslint-disable-next-line no-unused-vars
    const checkItem = (event) => {
      if(params.data.timest)params.data.timest = 0; else params.data.timest = 1;
      // console.log(params.data);
      params.api.refreshCells({columns : ["timest","timest"]});
    }
    return (
      <LinearProgress variant="determinate" value={Number(params.value)} sx={{ mt:1.8, height:10 }}/>
    );
  }

  const validTime = (params) => {
    const st = params.data.timest;
    const ed = params.data.timeed;
    const di = ed - st;
    if(di >= 0) {
      params.data.timeen = di;
      params.api.refreshCells({columns : ["timeen", "timeen"]});
    }
  }


  const timePickerRenderer = (params) => {
    const checkItem = (event) => {
      const field = params.colDef.field;
      params.data[field] = getMins(event);
      params.api.refreshCells({columns : [field, field]});
      validTime(params);
      if(params.value !== getMins(event)) {
        updateOrderTime(params, false);
      }
    }
    const getMins = (date) => {
      const hours = date.getHours();
      const mins = date.getMinutes();
      return ((hours * 60) + mins);
    }
    const getDate = (val) => {
      const date = new Date();
      date.setHours(val / 60);
      date.setMinutes(val % 60);
      return date;
    }
    return (
      <Box sx={{ my:0.6, width:100 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          ampm={false}
          minutesStep={5}
          value={getDate(params.data[params.colDef.field]) || null}
          onChange={checkItem}
          // onOpen={ console.log('onOpen!!') }
          // onViewChange={ console.log('onViewChange!!') }
          // onClose={ console.log('onClose!!') }
          // onAccept={ console.log('onAccept!!') }
          InputProps={{
            disableUnderline: true,
            style: {fontSize: 14}
          }}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
        </LocalizationProvider>
      </Box>
    );
  }

  const columnDefs = [
    // { headerName: "ID", field: "id", lockPinned: true, pinned: "left", editable:false, }, //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: getLabel('production_product_model'), field: "pmodel" },
    { headerName: getLabel('production_product_name'), field: "pname" },
    { headerName: getLabel('production_sequence'), field: "seq" },
    { headerName: getLabel('production_company_name'), field: "cname" },
    { headerName: getLabel('production_company_dept'), field: "cdept" },
    { headerName: getLabel('production_process'), field: "pcname" },
    { headerName: getLabel('production_period'), field: "period" },
    { headerName: getLabel('production_material_model'), field: "mmodel" },
    { headerName: getLabel('production_material_name'), field: "mname" },
    { headerName: getLabel('production_weight'), field: "weight" },
    { headerName: getLabel('production_unit'), field: "uname" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const columnDefs_order = [
    // { headerName: "ID", field: "id", lockPinned: true, pinned: "left", editable:false, }, //headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true },
    { headerName: getLabel('production_order_start_time'), field: "timest", editable: false, cellRenderer: "TimePickerCellRenderer",  }, //"CheckboxCellRenderer", },
    { headerName: getLabel('production_order_end_time'), field: "timeed", editable: false, cellRenderer: "TimePickerCellRenderer",  },
    { headerName: getLabel('production_order_valid_time'), field: "timeen", cellClass: 'ag-right-aligned-cell' },
    { headerName: getLabel('production_order_target'), field: "numtgt", editable: true, cellClass: 'ag-right-aligned-cell' },
    { headerName: getLabel('production_order_result'), field: "numprd", editable: true, cellClass: 'ag-right-aligned-cell' },
    { headerName: getLabel('production_order_balance'), field: "numdif", editable: true, cellClass: 'ag-right-aligned-cell' },
    { headerName: getLabel('production_order_preparation'), field: "timepr", editable: true, cellClass: 'ag-right-aligned-cell' },
    { headerName: getLabel('production_order_remark'), field: "stoptx", editable: true },
    { headerName: getLabel('production_order_time_stop'), field: "stoptm", editable: true, cellClass: 'ag-right-aligned-cell', }, //cellRenderer: "TestCellRenderer" },
    { headerName: getLabel('common_reg_user'), field: "userreg" },
    { headerName: getLabel('common_reg_date'), field: "datereg" },
    { headerName: getLabel('common_upd_user'), field: "userupd" },
    { headerName: getLabel('common_upd_date'), field: "dateupd" },
  ];

  const updateGridHeaders = () => {
    if('columnModel' in gridApi) {
      const cdef = gridApi.getColumnDefs();
      cdef[0].headerName = getLabel('production_product_model');
      cdef[1].headerName = getLabel('production_product_name');
      cdef[2].headerName = getLabel('production_sequence');
      cdef[3].headerName = getLabel('production_company_name');
      cdef[4].headerName = getLabel('production_company_dept');
      cdef[5].headerName = getLabel('production_process');
      cdef[6].headerName = getLabel('production_period');
      cdef[7].headerName = getLabel('production_material_model');
      cdef[8].headerName = getLabel('production_material_name');
      cdef[9].headerName = getLabel('production_weight');
      cdef[10].headerName = getLabel('production_unit');
      cdef[11].headerName = getLabel('common_reg_user');
      cdef[12].headerName = getLabel('common_reg_date');
      cdef[13].headerName = getLabel('common_upd_user');
      cdef[14].headerName = getLabel('common_upd_date');
      const goption = gridApi.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateGridHeaders_order = () => {
    if('columnModel' in gridApi2) {
      const cdef = gridApi2.getColumnDefs();
      cdef[0].headerName = getLabel('production_order_start_time');
      cdef[1].headerName = getLabel('production_order_end_time');
      cdef[2].headerName = getLabel('production_order_valid_time');
      cdef[3].headerName = getLabel('production_order_target');
      cdef[4].headerName = getLabel('production_order_result');
      cdef[5].headerName = getLabel('production_order_balance');
      cdef[6].headerName = getLabel('production_order_preparation');
      cdef[7].headerName = getLabel('production_order_remark');
      cdef[8].headerName = getLabel('production_order_time_stop');
      cdef[9].headerName = getLabel('common_reg_user');
      cdef[10].headerName = getLabel('common_reg_date');
      cdef[11].headerName = getLabel('common_upd_user');
      cdef[12].headerName = getLabel('common_upd_date');
      const goption = gridApi2.gridOptionsWrapper.gridOptions.api;
      goption.setColumnDefs(cdef);
    }
  }

  const updateData = (event) => {
    const data = event.data;
    // console.log(data);
    setEditState(1);
    setRecordData({...recordData, id:data.id, product:data.product, seq:data.seq, company:data.company,
      process:data.process, period:data.period, material:data.material, weight:data.weight, unit:data.unit,
      cdept:data.cdept, cname:data.cname, mmodel:data.mmodel, mname:data.mname, pcname:data.pcname, pname:data.pname, pmodel:data.pmodel,  uname:data.uname,
      userreg:data.userreg, datereg:data.datereg, userupd:data.userupd, dateupd:data.dateupd, });
    setRedrawOrders(true);
  }

  const gridOptions = {
    columnDefs: columnDefs,
    rowSelection: 'single',
    onRowClicked: updateData,
    onRowDoubleClicked: () => console.log("onRowDoubleClicked"),
    onRowDataChanged: () => console.log("onRowDataChanged"),
    // onSelectionChanged: () => { loadOrders(recordData.id, '2022-05-31') },
    onSelectionChanged: () => console.log("onSelectionChanged"),
  };

  const gridOptions_order = {
    columnDefs: columnDefs_order,
    rowSelection: 'single',
    components: {
      CheckboxCellRenderer: checkBoxRenderer,
      TimePickerCellRenderer: timePickerRenderer,
      TestCellRenderer: testRenderer,
    },
    onRowClicked: () => {
      const selectedRows = gridOptions_order.api.getSelectedRows();
      setOrderData(selectedRows);
    },
    onRowDoubleClicked: () => console.log("onRowDoubleClicked"),
    onRowDataChanged: () => console.log("onRowDataChanged"),
    onSelectionChanged: () => console.log("onSelectionChanged"),
    onCellValueChanged: (param) => updateOrderTime(param, false),
    onCellEditingStopped: () => console.log("onCellEditingStopped"),
    onSortChanged: () => console.log("onSortChanged"),
    onFilterChanged: () => console.log("onFilterChanged"),

  };

  // eslint-disable-next-line no-unused-vars
  const sizeToFit = () => {
    gridApi.sizeColumnsToFit();
  };

  // eslint-disable-next-line no-unused-vars
  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridColumnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  // eslint-disable-next-line no-unused-vars
  const test = () => {
    // console.log(recordList);
    // console.log(productionDate);
    // console.log(orderList);
    // console.log(recordData);
    // console.log(config);
  }

  // eslint-disable-next-line no-unused-vars
  const newRecord = () => {
    setRecordData({...recordData, id:'', name:'', type:'', userreg:'', datereg:'', userupd:'',dateupd:'' });
    setEditState(0);
  }

  // eslint-disable-next-line no-unused-vars
  const inputValidation = () => {
    let validated = true;
    if(recordData.name.trim().length === 0) {
      setHelpers(helpers => ({...helpers, name:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, name:''}));

    if(recordData.type.trim().length === 0) {
      setHelpers(helpers => ({...helpers, type:'Required'}));
      validated = false;
    } else setHelpers(helpers => ({...helpers, type:''}));

    return validated;
  }

  const checkNewTimeline = () => {
    if(productionDate && recordData.id)return true;
    return false;
  }


  return (
    <Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ mx:2, px:0 }}>
        <h4>{getLabel('production_order_list')}</h4>
      </Box>

      <Box color="text.primary" style={{ height: `calc(30vh)` }} sx={{ mx:2, mb:0, px:0 }}>
        <div className={config.theme==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          <AgGridReact
            defaultColDef={defaultColDef}
            // columnDefs={columnDefs}
            rowData={recordList}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={updateGridHeaders}>
          </AgGridReact>
        </div>
      </Box>

      {/* ------------------------------------------------------------------------------ */}

      <Box sx={{ display:'flex', px:2, py:1 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            ampm={false}
            label={getLabel('production_order_day_production')}
            inputFormat='dd/MM/yyyy'
            value={ productionDate || null }
            onChange={(date) => { setProductionDate(date); setRedrawOrders(true); } }
            InputProps={{
              style: {fontSize:14,  }
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
        <Button sx={{ mt:0.5, ml:1, height:30 }} size='small' variant="contained" color="primary" 
          disabled={!checkNewTimeline()} onClick={() => newOrderTime()} disableElevation>{getLabel('production_order_new_allocation')}</Button>
        <Button sx={{ mt:0.5, ml:1, height:30 }} size='small' variant="contained" color="primary" 
          disabled={ orderData.length>0?false:true } onClick={() => deleteOrderTime()} disableElevation>{getLabel('production_order_delete_allocation')}</Button>
      </Box>

      {/* ------------------------------------------------------------------------------ */}

      <Box color="text.primary" style={{ height: `calc(50vh)` }} sx={{ mx:2, mb:1, px:0 }}>
        <div className={config.theme==='light'?'ag-theme-alpine':'ag-theme-alpine-dark'} style={{height: '100%', width: '100%'}}>
          <AgGridReact
            defaultColDef={defaultColDef_order}
            columnDefs={columnDefs_order}
            rowData={orderList}
            gridOptions={gridOptions_order}
            onGridReady={onGridReady_order}
            onFirstDataRendered={updateGridHeaders_order} 
          >
          </AgGridReact>
        </div>
      </Box>

      {/* <Box sx={{ mx:2, px:0 }}>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => sizeToFit()} disableElevation>テーブル幅に合わせる</Button>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => autoSizeAll(false)} disableElevation>幅全てを自動調整する</Button>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => autoSizeAll(true)} disableElevation>ヘッダーを除く全てを自動調整する</Button>
        <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => test()} disableElevation>I'm not doing anything...</Button>
      </Box> */}

      {/* <Box sx={{ mx:2, px:0 }}>
        <h4>生産指示</h4>
      </Box> */}

      {/* <Paper sx={{ mx:2, my:2, p:1 }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="name" label={getLabel('machine_name')} variant="standard" size="small" value={recordData.name}
                onChange={(e) => setRecordData({...recordData, name: e.target.value})}
                helperText={ helpers.name } error={helpers.name.length>0?true:false} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack>
              <TextField sx={{ mx:1, mb:1 }} id="type" label={getLabel('machine_type')} variant="standard" size="small" value={recordData.type} 
                onChange={(e) => setRecordData({...recordData, type: e.target.value})}
                helperText={ helpers.type } error={helpers.type.length>0?true:false} />
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ display:'flex', py:1, px:1 }}>
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => test()} disableElevation>Dev Test</Button>
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => newRecord()} disabled={editState==0?true:false} disableElevation>{getLabel('machine_add')}</Button>
          <Box sx={{ flexGrow: 1 }}/>
          <Button sx={{ mr:1 }} size='small' variant="contained" color="primary" onClick={() => deleteRecord()} disabled={editState==0?true:false} disableElevation>{getLabel('machine_delete')}</Button>
          <Button sx={{ mr:0 }} size='small' variant="contained" color="primary" onClick={() => updateRecord()} disableElevation>{editState==0?getLabel('common_save'):getLabel('common_update')}</Button>
        </Box>
      </Paper> */}

    </Box>
  );
}

